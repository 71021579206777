import React, { useEffect, FC, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Divider, Row, Spin } from 'antd'
import { compose } from 'redux'
import { useNavigate, useParams } from 'react-router-dom'
import { change, getFormValues, initialize } from 'redux-form'
import { cloneDeep } from 'lodash'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import ChangelogForm from './components/ChangelogForm'
import WhatsNewModal, { WhatsNewModalContent } from '../../components/WhatsNewModal'
import ErrorsReportModal, { ErrorsReportModalProps } from './components/ErrorsReportModal'
import ConfirmSubmitModal from './components/ConfirmSubmitModal'

// types
import { IBreadcrumbs, IChangelogForm, IChangelogLocalizations, PostUrls, RequestPayload } from '../../types/interfaces'
import { RootState } from '../../reducers'
import { IChangelogMainFeature, IChangelogOtherFeatures } from '../../types/schemaTypes'

// utils
import { withPermissions } from '../../utils/Permissions'
import { CHANGELOG_MAIN_FEATURES_MAX_COUNT, CHANGELOG_PLATFORM, CHANGELOG_STATUS, DEFAULT_LANGUAGE, FORM, LANGUAGE } from '../../utils/enums'
import { isEnumValue } from '../../utils/intl'
import { patchReq, postReq } from '../../utils/request'
import { scrollToTopFn } from '../../components/ScrollToTop'
import { CHANGELOG_PLATFORM_TRANSLATIONS, getChangelogStatusTag } from '../../utils/helper'

// hooks
import useBackUrl from '../../hooks/useBackUrl'

// assets
import BellIcon from '../../assets/icons/bell-icon.svg?react'

// redux
import { getChangelog, getChangelogs } from '../../reducers/changelogs/changelogsActions'

const DEFAULT_MAIN_FEATURES: IChangelogForm['mainFeatures'] = [{ name: '', description: '' }]
const DEFAULT_OTHER_FEATURES: IChangelogForm['otherFeatures'] = {
	description: ''
}
const MAX_ALLOWED_VERSION = 999

type Props = {}

type SubmitBody = RequestPayload<PostUrls['/api/b2b/admin/changelogs/']>
type SubmitBodyLanguage = NonNullable<SubmitBody['otherFeatures']>[0]['language']

const increaseVersion = (input: string): string => {
	const split = input.split('.')
	let majorVersion = !Number.isNaN(split[0]) ? Number(split[0]) : null
	let minorVersion = !Number.isNaN(split[1]) ? Number(split[1]) : null

	if (majorVersion === null || minorVersion === null) {
		return ''
	}

	if (minorVersion === MAX_ALLOWED_VERSION && majorVersion === MAX_ALLOWED_VERSION) {
		return input
	}

	if (minorVersion >= MAX_ALLOWED_VERSION) {
		minorVersion = 0
		majorVersion += 1
	} else {
		minorVersion += 1
	}

	return `${majorVersion}.${minorVersion}`
}

const transformOtherFeaturesForPreview = (value: string | undefined | null) => {
	if (!value) {
		return []
	}
	const trimmedDescription = value.replace(/\s*;\s*/g, ';')
	return trimmedDescription.split(';').filter((desc) => desc)
}

/**
 * merge saved data for all localizations with current form data
 * filter empty main features
 */
const mergeLocalizationsAndFormData = (tabKey: LANGUAGE, localizationsData: IChangelogLocalizations, formValues: IChangelogForm | undefined): IChangelogLocalizations => {
	return Object.entries(cloneDeep(localizationsData)).reduce((acc, [lng, data]) => {
		let mainFeatures: IChangelogMainFeature[] = []
		let otherFeatures: IChangelogOtherFeatures = {}

		if (tabKey === lng) {
			mainFeatures = [...(formValues?.mainFeatures || [])]
			otherFeatures = formValues?.otherFeatures || DEFAULT_OTHER_FEATURES
		} else {
			mainFeatures = data.mainFeatures
			otherFeatures = data.otherFeatures
		}

		return {
			...acc,
			[lng]: {
				mainFeatures: mainFeatures.filter((feature) => feature.name || feature.description),
				otherFeatures
			}
		}
	}, {} as IChangelogLocalizations)
}

const getWhatsNewModalPreviewContent = (tabKey: LANGUAGE, localizationsData: IChangelogLocalizations, formValues: IChangelogForm | undefined) => {
	const mergedLocalizationsDataAndFormData = mergeLocalizationsAndFormData(tabKey, localizationsData, formValues)

	const entries = Object.entries(mergedLocalizationsDataAndFormData)

	const editedData = entries.reduce(
		(acc, value) => {
			const [lng, data] = value

			const localizationContent: WhatsNewModalContent = {
				mainFeatures: [...data.mainFeatures],
				otherFeatures: {
					...data.otherFeatures,
					description: transformOtherFeaturesForPreview(data.otherFeatures.description)
				}
			}

			const newData = {
				...acc,
				[lng]: localizationContent
			}

			return newData
		},
		{} as Record<LANGUAGE, WhatsNewModalContent>
	)

	return editedData
}

const getChangelogErrorsReportData = (data: IChangelogLocalizations): ErrorsReportModalProps['errorReports'] | null => {
	const entries = Object.entries(cloneDeep(data))
	const firstLocalizationMainFeaturesLength = entries[0][1].mainFeatures.length
	const hasAllLocalizationsSameMainFeaturesLength = !entries.some((localization) => localization[1].mainFeatures.length !== firstLocalizationMainFeaturesLength)
	const firstLocalizationOtherFeaturesLength = entries[0][1].otherFeatures.description?.length || 0
	const hasAllLocalizationsSameOtherFeaturesLength = !entries.some((localization) => {
		if (firstLocalizationOtherFeaturesLength) {
			return !localization[1].otherFeatures.description?.length
		}
		return localization[1].otherFeatures.description?.length
	})

	if (hasAllLocalizationsSameOtherFeaturesLength && hasAllLocalizationsSameMainFeaturesLength) {
		return null
	}

	return entries.reduce(
		(acc, [lng, value]) => {
			return {
				...acc,
				[lng]: {
					mainFeaturesCount: value.mainFeatures.length,
					hasOtherFeatures: !!value.otherFeatures.description
				}
			}
		},
		{} as ErrorsReportModalProps['errorReports']
	)
}

const ChangelogPage: FC<Props> = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { changelogID: changelogIDparam } = useParams<{ changelogID?: string }>()
	const changelogID = changelogIDparam !== t('paths:createEntity') ? changelogIDparam : undefined

	const authUser = useSelector((state: RootState) => state.user.authUser)
	const changelog = useSelector((state: RootState) => state.changelogs.changelog)
	const changelogs = useSelector((state: RootState) => state.changelogs.changelogs)

	const formValues = useSelector((state: RootState) => getFormValues(FORM.CHANGELOG)(state)) as IChangelogForm | undefined

	const [tabKey, setTabKey] = useState<LANGUAGE>()
	const [localizationsData, setLocalizationsData] = useState<IChangelogLocalizations>(
		Object.values(LANGUAGE).reduce((acc, lng) => {
			return {
				...acc,
				[lng]: {
					mainFeatures: DEFAULT_MAIN_FEATURES,
					otherFeatures: DEFAULT_OTHER_FEATURES
				}
			}
		}, {} as IChangelogLocalizations)
	)

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isWhatsNewModalOpen, setIsWhatsNewModalOpen] = useState(false)
	const [errorsReportData, setErrorsReportData] = useState<ErrorsReportModalProps['errorReports'] | null>(null)
	const [submitModalData, setSubmitModalData] = useState<{ handler?: () => void } | null>(null)

	const isFormInitialized = useRef(false)

	const loading = changelog.isLoading || changelogs.isLoading || authUser.isLoading || isSubmitting

	const [backUrl] = useBackUrl(t('paths:notifications/changelogs'))

	const fetchData = useCallback(
		async (id: string, currentLng: LANGUAGE) => {
			const { data } = await dispatch(getChangelog(id))

			let currentLngMainFeatures: IChangelogForm['mainFeatures'] = []
			let currentLngOtherFeatures: IChangelogForm['otherFeatures'] = {}

			const newLocalizationsData: IChangelogLocalizations = Object.values(LANGUAGE).reduce((acc, lng) => {
				let mainFeatures: IChangelogMainFeature[] = []
				let otherFeatures: IChangelogOtherFeatures = DEFAULT_OTHER_FEATURES

				data?.changelog.mainFeatures.forEach((feature) => {
					feature?.forEach((featureLocalization) => {
						if (featureLocalization.language === lng) {
							mainFeatures.push({
								name: featureLocalization.name,
								description: featureLocalization.description
							})
						}
					})
				})

				const otherFeaturesLocalization = data?.changelog.otherFeatures.find((featureLocalization) => featureLocalization.language === lng)

				if (otherFeaturesLocalization) {
					otherFeatures = {
						description: otherFeaturesLocalization.value || ''
					}
				}

				mainFeatures = mainFeatures.length ? mainFeatures : DEFAULT_MAIN_FEATURES

				if (currentLng === lng) {
					currentLngMainFeatures = mainFeatures
					currentLngOtherFeatures = otherFeatures
				}

				return {
					...acc,
					[lng]: {
						mainFeatures,
						otherFeatures
					}
				}
			}, {} as IChangelogLocalizations)

			setLocalizationsData(newLocalizationsData)

			const initData: Partial<IChangelogForm> = {
				platform: isEnumValue(data?.changelog.platform, CHANGELOG_PLATFORM) ? data.changelog.platform : undefined,
				version: data?.changelog.version,
				mainFeatures: currentLngMainFeatures,
				otherFeatures: currentLngOtherFeatures
			}

			dispatch(initialize(FORM.CHANGELOG, initData))
		},
		[dispatch]
	)

	useEffect(() => {
		if (authUser.data?.language) {
			setTabKey(isEnumValue(authUser.data.language, LANGUAGE) ? authUser.data.language : DEFAULT_LANGUAGE)
		}
	}, [authUser.data?.language])

	useEffect(() => {
		;(async () => {
			if (isFormInitialized.current) {
				return
			}

			if (changelogID) {
				if (tabKey) {
					await fetchData(changelogID, tabKey)
					isFormInitialized.current = true
				}
			} else {
				const initData: Partial<IChangelogForm> = {
					mainFeatures: DEFAULT_MAIN_FEATURES,
					otherFeatures: DEFAULT_OTHER_FEATURES
				}
				dispatch(initialize(FORM.CHANGELOG, initData))
				isFormInitialized.current = true
			}
		})()
	}, [dispatch, changelogID, fetchData, tabKey, authUser.isLoading])

	useEffect(() => {
		if (!changelogID) {
			dispatch(
				getChangelogs({
					order: 'updatedAt:desc',
					limit: 100
				})
			)
		}
	}, [dispatch, changelogID])

	const onTabChange = (newActiveKey: string) => {
		if (!tabKey || !formValues) {
			return
		}

		setLocalizationsData((prevValues) => ({
			...prevValues,
			[tabKey]: {
				mainFeatures: formValues.mainFeatures,
				otherFeatures: formValues.otherFeatures
			}
		}))

		if (isEnumValue(newActiveKey, LANGUAGE)) {
			setTabKey(newActiveKey)
			scrollToTopFn()
			dispatch(change(FORM.CHANGELOG, 'mainFeatures', localizationsData[newActiveKey].mainFeatures, false))
			dispatch(change(FORM.CHANGELOG, 'otherFeatures', localizationsData[newActiveKey].otherFeatures, false))
		}
	}

	const pageTitle = changelogID ? t('loc:Detail upozornenia') : t('loc:Vytvoriť nové upozornenie')

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam upozornení'),
				link: backUrl
			},
			{
				name: pageTitle
			}
		]
	}

	const handleSubmit = async (values: IChangelogForm, isDraft: boolean, currentTabKey: LANGUAGE, showConfirmationModal = true) => {
		setIsSubmitting(true)
		try {
			const mainFeatures: SubmitBody['mainFeatures'] = []
			const otherFeatures: Required<SubmitBody['otherFeatures']> = []

			const mergedLocalizationsDataAndFormData = mergeLocalizationsAndFormData(currentTabKey, localizationsData, formValues)

			if (!isDraft) {
				// validate counts of filled features only before publishing
				// draft can be saved without validation
				const changelogErrors = getChangelogErrorsReportData(mergedLocalizationsDataAndFormData)
				if (changelogErrors) {
					setErrorsReportData(changelogErrors)
					return
				}

				// confirm submit before publishing
				if (showConfirmationModal) {
					setSubmitModalData({
						handler: () => {
							handleSubmit(values, isDraft, currentTabKey, false)
							setSubmitModalData(null)
						}
					})
					return
				}
			}

			// transform local data into shape that accepts BE
			Object.keys(mergedLocalizationsDataAndFormData).forEach((lng) => {
				const language = lng as SubmitBodyLanguage

				// transform mainFeatures
				mergedLocalizationsDataAndFormData[language]?.mainFeatures.forEach((feature, index) => {
					if (!mainFeatures[index]) {
						mainFeatures[index] = []
					}

					if (feature.name) {
						mainFeatures[index]?.push({
							language,
							name: feature.name,
							description: feature.description || null
						})
					}
				})

				// transform otherFeatures
				const otherFeature = mergedLocalizationsDataAndFormData[language]?.otherFeatures.description
				if (otherFeature) {
					otherFeatures.push({
						language,
						value: transformOtherFeaturesForPreview(otherFeature).join(';')
					})
				}
			})

			let reqBody: SubmitBody = {
				platform: values.platform,
				version: values.version,
				isDraft,
				mainFeatures
			}

			if (otherFeatures.length) {
				reqBody = {
					...reqBody,
					otherFeatures
				}
			}

			// make requests
			if (changelogID) {
				await patchReq('/api/b2b/admin/changelogs/{changelogID}', { params: { path: { changelogID } }, reqBody })
				await fetchData(changelogID, currentTabKey)
			} else {
				const { data: postData } = await postReq('/api/b2b/admin/changelogs/', { params: {}, reqBody })
				if (postData.changelog?.id) {
					isFormInitialized.current = false
					navigate(t('paths:notifications/changelogs/{{changelogID}}', { changelogID: postData.changelog?.id }))
				} else {
					navigate(t('paths:notifications/changelogs'))
				}
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		} finally {
			setIsSubmitting(false)
		}
	}

	const onChangePlatform = (value: CHANGELOG_PLATFORM) => {
		const platformChangelog = changelogs.data?.changelogs.find((version) => version.platform === value)
		dispatch(change(FORM.CHANGELOG, 'version', platformChangelog?.version ? increaseVersion(platformChangelog.version) : ''))
	}

	const handleAddMainFeature = () => {
		const localizationsWithAddedEmptyMainFeature = Object.entries(cloneDeep(localizationsData)).reduce((acc, [lng, localization]) => {
			return {
				...acc,
				[lng as LANGUAGE]: {
					...localization,
					mainFeatures:
						localization.mainFeatures.length < CHANGELOG_MAIN_FEATURES_MAX_COUNT
							? [...localization.mainFeatures, { name: '', description: '' }]
							: localization.mainFeatures
				}
			}
		}, {} as IChangelogLocalizations)
		setLocalizationsData(localizationsWithAddedEmptyMainFeature)
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} backButtonPath={t('paths:index')} />
			</Row>

			<div className='content-body medium'>
				<Spin spinning={loading || !tabKey}>
					{tabKey && (
						<>
							<div className={'flex items-center w-full gap-2'}>
								<BellIcon className={'flex-shrink-0'} />
								<h3 className={'mb-0'}>{pageTitle}</h3>
								{changelogID && changelog.data?.changelog.status ? <div className={'ml-auto'}>{getChangelogStatusTag(changelog.data.changelog.status)}</div> : null}
							</div>
							<Divider className={'mb-6 mt-3'} />
							<ChangelogForm
								submitHandler={handleSubmit}
								onChangePlatform={changelogID ? undefined : onChangePlatform}
								onAddMainFeature={handleAddMainFeature}
								tabKey={tabKey}
								onTabChange={onTabChange}
								setIsWhatsNewModalOpen={setIsWhatsNewModalOpen}
								disabled={loading}
								isCreate={!changelogID}
								isPublished={changelog.data?.changelog.status === CHANGELOG_STATUS.PUBLISHED}
							/>
							{isWhatsNewModalOpen && (
								<WhatsNewModal
									title={
										<>
											{t('loc:Náhľad What’s New')} {formValues?.platform ? `(${CHANGELOG_PLATFORM_TRANSLATIONS()[formValues.platform]})` : undefined}
										</>
									}
									open={isWhatsNewModalOpen}
									onCancel={() => setIsWhatsNewModalOpen(false)}
									onContinue={() => setIsWhatsNewModalOpen(false)}
									buttonLabel={t('loc:Zavrieť náhľad')}
									initLanguage={tabKey}
									version={formValues?.version}
									content={getWhatsNewModalPreviewContent(tabKey, localizationsData, formValues)}
								/>
							)}
							{errorsReportData && <ErrorsReportModal open onCancel={() => setErrorsReportData(null)} errorReports={errorsReportData} />}
							{submitModalData && submitModalData.handler && formValues?.platform && (
								<ConfirmSubmitModal
									open
									onCancel={() => setSubmitModalData(null)}
									platform={formValues.platform}
									onSubmit={submitModalData.handler}
									isSubmitting={isSubmitting}
								/>
							)}
						</>
					)}
				</Spin>
			</div>
		</>
	)
}

export default compose(withPermissions())(ChangelogPage)
