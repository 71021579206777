/* eslint-disable import/no-cycle */
import { ILoadingAndFailure } from '../../types/interfaces'
import { RESET_STORE } from '../generalTypes'
import { IReviewsActions, IReviewsPayload, ISalonReviewPayload, ISalonReviewsPayload } from './reviewsActions'
import { REVIEWS, SALON_REVIEW, SALON_REVIEWS } from './reviewsTypes'

export const initState = {
	reviews: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IReviewsPayload & ILoadingAndFailure,
	salonReviews: {
		data: null,
		isLoading: false,
		isFailure: false
	} as ISalonReviewsPayload & ILoadingAndFailure,
	salonReview: {
		data: null,
		isLoading: false,
		isFailure: false
	} as ISalonReviewPayload & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: IReviewsActions) => {
	switch (action.type) {
		// Reviews
		case REVIEWS.REVIEWS_LOAD_START:
			return {
				...state,
				reviews: {
					...state.reviews,
					isLoading: true
				}
			}
		case REVIEWS.REVIEWS_LOAD_FAIL:
			return {
				...state,
				reviews: {
					...initState.reviews,
					isFailure: true
				}
			}
		case REVIEWS.REVIEWS_LOAD_DONE:
			return {
				...state,
				reviews: {
					...initState.reviews,
					data: action.payload.data
				}
			}
		// Salon Reviews
		case SALON_REVIEWS.SALON_REVIEWS_LOAD_START:
			return {
				...state,
				salonReviews: {
					...state.salonReviews,
					isLoading: true
				}
			}
		case SALON_REVIEWS.SALON_REVIEWS_LOAD_FAIL:
			return {
				...state,
				salonReviews: {
					...initState.salonReviews,
					isFailure: true
				}
			}
		case SALON_REVIEWS.SALON_REVIEWS_LOAD_DONE:
			return {
				...state,
				salonReviews: {
					...initState.salonReviews,
					data: action.payload.data
				}
			}
		// salon review
		case SALON_REVIEW.SALON_REVIEW_LOAD_START:
			return {
				...state,
				salonReview: {
					...state.salonReview,
					isLoading: true
				}
			}
		case SALON_REVIEW.SALON_REVIEW_LOAD_FAIL:
			return {
				...state,
				salonReview: {
					...initState.salonReview,
					isFailure: true
				}
			}
		case SALON_REVIEW.SALON_REVIEW_LOAD_DONE:
			return {
				...state,
				salonReview: {
					...initState.salonReview,
					data: action.payload.data
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
