import { z } from 'zod'
import { stringConstraint, emailConstraint, twoCharsConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH } from '../utils/enums'

// https://notino-admin.goodrequest.dev/api/doc/#/B2b-%3Eadmin/postApiB2BAdminEnumsContacts
// eslint-disable-next-line import/prefer-default-export
export const specialistSchema = z.object({
	email: emailConstraint.optional().nullable(),
	phonePrefixCountryCode: twoCharsConstraint,
	countryCode: twoCharsConstraint,
	phone: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_20, true)
})
