import React from 'react'
import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

// utils
import { NotificationConfirmModalData } from '../notificationsUtils'

type Props = {
	confirmModalData: NotificationConfirmModalData
	setConfirmModalData: (data: NotificationConfirmModalData | null) => any
	isLoading: boolean
}

const NotificationConfirmModal = (props: Props) => {
	const { confirmModalData, setConfirmModalData, isLoading } = props
	const { t } = useTranslation()

	return (
		<Modal
			open
			closable={false}
			footer={null}
			width={360}
			className={'noti-confirm-user-notifications-modal'}
			maskClosable
			onCancel={(e) => {
				e.stopPropagation()
				setConfirmModalData(null)
			}}
		>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
			<div className={'p-6'} onClick={(e) => e.stopPropagation()}>
				<div className={'text-center'}>
					<h3 className={'text-black text-base leading-5 mb-2'}>{t('loc:Ste si istý, že chcete vymazať všetky notifikácie?')}</h3>
					<p className={'text-xs text-notino-grayDark'}>{t('loc:Táto akcia je nevratná.')}</p>
				</div>
				<div className={'flex gap-4 mt-6'}>
					<Button
						type={'dashed'}
						size={'middle'}
						className={'noti-btn w-1/2'}
						htmlType={'button'}
						onClick={(e) => {
							e.stopPropagation()
							setConfirmModalData(null)
						}}
						disabled={isLoading}
					>
						{t('loc:Zrušiť')}
					</Button>

					<Button
						type={'primary'}
						size={'middle'}
						className={'noti-btn w-1/2'}
						htmlType={'button'}
						onClick={async (e) => {
							e.stopPropagation()
							await confirmModalData.handler()
							setConfirmModalData(null)
						}}
						disabled={isLoading}
					>
						{'Vymazať'}
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default React.memo(NotificationConfirmModal)
