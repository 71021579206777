import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import cx from 'classnames'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'

import EyeIcon from '../assets/icons/eye-icon.svg?react'
import EyeHiddenIcon from '../assets/icons/eye-hidden-icon.svg?react'
import { formFieldID } from '../utils/helper'

const { Item } = Form

type Props = WrappedFieldProps &
	InputProps &
	FormItemLabelProps & {
		hideHelp?: boolean
	}

const InputPasswordField = (props: Props) => {
	const {
		input,
		size,
		placeholder,
		label,
		required,
		type,
		prefix,
		disabled,
		style,
		meta: { form, error, touched },
		hideHelp,
		maxLength,
		readOnly,
		className,
		tooltip
	} = props

	// NOTE: internal state to temporary fix cursor jump: https://goodrequest.atlassian.net/browse/NOT-7327
	const [internalVal, setInternalVal] = useState('')

	useEffect(() => {
		setInternalVal(input.value)
	}, [input.value])

	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? e.target.value : null
			input.onChange(val)
			setInternalVal(val || '')
		},
		[input]
	)
	/*
	 * This logic (onBlur, onFocus) set 'dirty/pristine' state for Form incorrectly.
	 * Scenario:
	 * 1.Non required field
	 * 2.Focus field
	 * 3.Blur field
	 * 4.Form state is dirty without changes

	const onBlur = useCallback(
		async (e) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? e.target.value : null

			// NOTE: wait until redux-form "BLUR" action is finished
			await input.onBlur(val)
		},
		[input]
	)

	const onFocus = useCallback(
		async (e) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? e.target.value : null
			if (input.onFocus) {
				input.onFocus(val)
			}
		},
		[input]
	) */

	const renderToggleIcon = (visible: boolean) => {
		if (visible) {
			return <EyeIcon />
		}
		return <EyeHiddenIcon />
	}

	return (
		<Item
			label={label}
			required={required}
			style={style}
			className={cx(className, { 'form-item-disabled': disabled, readOnly })}
			help={hideHelp ? undefined : touched && error}
			validateStatus={error && touched ? 'error' : undefined}
			tooltip={tooltip}
		>
			<Input.Password
				{...input}
				id={formFieldID(form, input.name)}
				className={cx('noti-input-password')}
				onChange={onChange}
				// onBlur={onBlur}
				size={size || 'middle'}
				// onFocus={onFocus}
				value={internalVal}
				iconRender={renderToggleIcon}
				placeholder={placeholder}
				type={type || 'text'}
				prefix={prefix}
				disabled={disabled}
				maxLength={maxLength}
			/>
		</Item>
	)
}

export default React.memo(InputPasswordField)
