export enum RESERVATIONS {
	RESERVATIONS_LOAD_START = 'RESERVATIONS_LOAD_START',
	RESERVATIONS_LOAD_DONE = 'RESERVATIONS_LOAD_DONE',
	RESERVATIONS_LOAD_FAIL = 'RESERVATIONS_LOAD_FAIL'
}

export enum NOTINO_RESERVATIONS {
	NOTINO_RESERVATIONS_LOAD_START = 'NOTINO_RESERVATIONS_LOAD_START',
	NOTINO_RESERVATIONS_LOAD_DONE = 'NOTINO_RESERVATIONS_LOAD_DONE',
	NOTINO_RESERVATIONS_LOAD_FAIL = 'NOTINO_RESERVATIONS_LOAD_FAIL'
}

export enum PENDING_RESERVATIONS_COUNT {
	PENDING_RESERVATIONS_COUNT_LOAD_START = 'PENDING_RESERVATIONS_COUNT_LOAD_START',
	PENDING_RESERVATIONS_COUNT_LOAD_DONE = 'PENDING_RESERVATIONS_COUNT_LOAD_DONE',
	PENDING_RESERVATIONS_COUNT_LOAD_FAIL = 'PENDING_RESERVATIONS_COUNT_LOAD_FAIL'
}

export enum UPCOMING_CUSTOMER_RESERVATIONS_COUNT {
	UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_START = 'UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_START',
	UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_DONE = 'UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_DONE',
	UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_FAIL = 'UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_FAIL'
}

export enum UPCOMING_EMPLOYEE_RESERVATIONS_COUNT {
	UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_START = 'UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_START',
	UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_DONE = 'UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_DONE',
	UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_FAIL = 'UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_FAIL'
}
