import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getFormInitialValues, getFormValues, isValid } from 'redux-form'

// components
import UserDetail from './components/UserDetail'

// reducers
import { logOutUser, getCurrentUser } from '../../reducers/users/userActions'

// utils
import { FORM, PERMISSION } from '../../utils/enums'
import { RootState } from '../../reducers'
import { DATA_LAYER_EVENTS, GA_SCREEN_CLASS, GA_SCREEN_NAME } from '../../utils/dataLayerEnums'
import { getGaUserAccountFormChanges, pushEventToDataLayer, UserAccountFormChanges } from '../../utils/dataLayer'

// hooks
import usePushScreenInfoToDataLayer from '../../hooks/usePushScreenInfoToDataLayer'

// types
import { IUserAccountForm } from '../../types/interfaces'

const MyProfilePage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const authUser = useSelector((state: RootState) => state.user.authUser)
	const isFormValid = useSelector(isValid(FORM.USER_ACCOUNT))
	const formValues: Partial<IUserAccountForm> | undefined = useSelector((state: RootState) => getFormValues(FORM.USER_ACCOUNT)(state))
	const initialFormValues: Partial<IUserAccountForm> | undefined = useSelector((state: RootState) => getFormInitialValues(FORM.USER_ACCOUNT)(state))

	// Google Analytics initial events
	usePushScreenInfoToDataLayer(GA_SCREEN_NAME.PERSONAL_INFORMATION, GA_SCREEN_CLASS.PERSONAL_INFORMATION)

	const fetchData = useCallback(async () => {
		const { data } = await dispatch(getCurrentUser())

		if (!data?.id) {
			dispatch(logOutUser())
		}
	}, [dispatch])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	return (
		<UserDetail
			userData={authUser.data}
			deleteEntityName={t('loc:účet')}
			onDeleteSuccess={() => dispatch(logOutUser())}
			ignoreDeletePermissions
			submitPermissions={[PERMISSION.NOTINO, PERMISSION.PARTNER, PERMISSION.NOTINO_SUPER_ADMIN, PERMISSION.NOTINO_SUPER_ADMIN]}
			onPatchSuccess={fetchData}
			isLoadingUser={authUser.isLoading}
			onSubmit={() => {
				pushEventToDataLayer<UserAccountFormChanges & { isSuccess: boolean }>({
					event: DATA_LAYER_EVENTS.CLICK_BUTTON,
					button_name: 'save',
					screen_name: GA_SCREEN_NAME.PERSONAL_INFORMATION,
					isSuccess: isFormValid,
					...getGaUserAccountFormChanges(initialFormValues, formValues)
				})
			}}
		/>
	)
}

export default MyProfilePage
