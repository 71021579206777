export enum PHOTOALBUMS {
	PHOTOALBUMS_LOAD_START = 'PHOTOALBUMS_LOAD_START',
	PHOTOALBUMS_LOAD_DONE = 'PHOTOALBUMS_LOAD_DONE',
	PHOTOALBUMS_LOAD_FAIL = 'PHOTOALBUMS_LOAD_FAIL'
}

export enum PHOTOALBUM {
	PHOTOALBUM_LOAD_START = 'PHOTOALBUM_LOAD_START',
	PHOTOALBUM_LOAD_DONE = 'PHOTOALBUM_LOAD_DONE',
	PHOTOALBUM_LOAD_FAIL = 'PHOTOALBUM_LOAD_FAIL'
}
