/* eslint-disable import/no-cycle */
import { IResetStore } from '../generalTypes'
import { ThunkResult } from '../index'

// types
import { CATEGORIES, CATEGORY, CUSTOM_SERVICES } from './categoriesTypes'

// utils
import { getReq } from '../../utils/request'
import { GetUrls, ISelectOptionItem, RequestParams, RequestResponse } from '../../types/interfaces'
import { IGetCustomServicesQueryParams } from '../../types/schemaTypes'
import { CUSTOM_SERVICE_CHECKED_STATE } from '../../utils/enums'

export type ICategoriesActions = IResetStore | IGetCategories | IGetCategory | IGetCustomServices

interface IGetCategories {
	type: CATEGORIES
	payload: ICategoriesPayload
}

interface IGetCategory {
	type: CATEGORY
	payload: ICategoryPayload
}

interface IGetCustomServices {
	type: CUSTOM_SERVICES
	payload: ICustomServicesPayload
}

export interface ICategoryParameterValue {
	categoryParameterValueID: string
	name: string | null
}

export interface ICategoriesPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/enums/categories/']>['categories'] | null
	enumerationsOptions: ISelectOptionItem[]
}

export interface ICategoryPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/enums/categories/{categoryID}']>['category'] | null
	categoryParameterValues: ICategoryParameterValue[] | null
}

export type CustomServiceTableItem = {
	salon: {
		id: string
		name: string
		address: string | undefined
		countryCode: string | undefined
	}
	customService: {
		id: string
		name: string
		industries: { id: string; name: string; image: string | undefined }[]
		categories: { id: string; name: string }[]
		createdAt: string
		isChecked: boolean
	}
}

export interface ICustomServicesPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/services/custom']> | null
	tableData: CustomServiceTableItem[]
}

export const getCategories = (): ThunkResult<Promise<ICategoriesPayload>> => async (dispatch) => {
	let payload = {} as ICategoriesPayload

	try {
		dispatch({ type: CATEGORIES.CATEGORIES_LOAD_START })
		const { data } = await getReq('/api/b2b/admin/enums/categories/', { params: {}, reqBody: {} })

		const enumerationsOptions: ISelectOptionItem[] = data.categories.map((item) => ({
			key: `Cat_${item.id}`,
			label: item.name || item.id,
			value: item.id,
			extra: {
				image: item.image?.resizedImages?.thumbnail || item.image?.original
			}
		}))

		payload = { data: data?.categories, enumerationsOptions }
		dispatch({ type: CATEGORIES.CATEGORIES_LOAD_DONE, payload })
	} catch (err) {
		dispatch({ type: CATEGORIES.CATEGORIES_LOAD_FAIL })
		// eslint-disable-next-line no-console
		console.error(err)
	}

	return payload
}

export const getCategory =
	(categoryID: string): ThunkResult<Promise<ICategoryPayload>> =>
	async (dispatch) => {
		let payload = {} as ICategoryPayload

		try {
			dispatch({ type: CATEGORY.CATEGORY_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/enums/categories/{categoryID}', { params: { path: { categoryID } }, reqBody: {} })
			payload = {
				data: data?.category,
				categoryParameterValues:
					data?.category?.categoryParameter?.values?.map((parameterValue) => {
						return { categoryParameterValueID: parameterValue?.id, name: parameterValue?.value || null }
					}) || []
			}
			dispatch({ type: CATEGORY.CATEGORY_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: CATEGORY.CATEGORY_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

type IndustryNode = CustomServiceTableItem['customService']['industries'][0]

const findIndustry = (nodeList: NonNullable<ICategoriesPayload['data']>, childID: string): IndustryNode | undefined => {
	let topParent: IndustryNode | undefined

	const findParent = (nodes: NonNullable<ICategoriesPayload['data']>) => {
		nodes.forEach((node) => {
			if (node.children) {
				if (node.children.some((child) => child.id === childID)) {
					topParent = { id: node.id, image: node.image?.resizedImages.thumbnail || node.image?.original, name: node.name || node.id }
				}
			}
		})
	}

	findParent(nodeList)
	return topParent
}

type CustomServicesRequestQueryParams = NonNullable<RequestParams<GetUrls['/api/b2b/admin/services/custom']>['query']>

export const getCustomServicesRequestQueryParams = (params: IGetCustomServicesQueryParams): CustomServicesRequestQueryParams => {
	let isChecked: boolean | undefined

	if (params.checkedState === CUSTOM_SERVICE_CHECKED_STATE.CHECKED) {
		isChecked = true
	} else if (params.checkedState === CUSTOM_SERVICE_CHECKED_STATE.NOT_CHECKED) {
		isChecked = false
	}
	return {
		page: params.page,
		limit: params.limit,
		order: params.order,
		search: params.search || undefined,
		countryCode: params.countryCode || undefined,
		categoryIDs: params.categoryIDs || undefined,
		isChecked,
		serviceCreatedAtFrom: params.serviceCreatedAtFrom || undefined,
		serviceCreatedAtTo: params.serviceCreatedAtTo || undefined
	}
}

export const getCustomServices =
	(params: IGetCustomServicesQueryParams, categoriesData: ICategoriesPayload['data']): ThunkResult<Promise<ICustomServicesPayload>> =>
	async (dispatch) => {
		let payload = {} as ICustomServicesPayload

		try {
			dispatch({ type: CUSTOM_SERVICES.CUSTOM_SERVICES_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/services/custom', {
				params: {
					query: getCustomServicesRequestQueryParams(params)
				},
				reqBody: {}
			})

			const tableData: CustomServiceTableItem[] = []

			data.services.forEach((item) => {
				const tableItem: CustomServiceTableItem = {
					salon: {
						id: item.salon.id,
						name: item.salon.name,
						address: item.salon.address?.city && item.salon.address?.street ? `${item.salon.address.city}, ${item.salon.address.street}` : undefined,
						countryCode: item.salon.address?.countryCode
					},
					customService: {
						id: item.id,
						name: item.name,
						industries: [],
						categories: [],
						createdAt: item.createdAt,
						isChecked: !!item.checkedBy
					}
				}

				item.categories.forEach((serviceThirdLevelCategory) => {
					// service category
					const serviceSecondLevelCategory = serviceThirdLevelCategory.parent
					if (!tableItem.customService.categories.find((existingCategory) => existingCategory.id === serviceSecondLevelCategory.id)) {
						tableItem.customService.categories.push({ id: serviceSecondLevelCategory.id, name: serviceSecondLevelCategory.name || serviceSecondLevelCategory.id })
					}

					// service industry
					const industry = findIndustry(categoriesData || [], serviceSecondLevelCategory.id)

					if (industry && !tableItem.customService.industries.find((existingIndustry) => existingIndustry.id === industry?.id)) {
						tableItem.customService.industries.push({ id: industry.id, name: industry.name, image: industry.image })
					}
				})

				tableData.push(tableItem)
			})

			payload = { data, tableData }
			dispatch({ type: CUSTOM_SERVICES.CUSTOM_SERVICES_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: CUSTOM_SERVICES.CUSTOM_SERVICES_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
