import { Spin } from 'antd'
import React from 'react'

const GlobalLoading = () => {
	return (
		<div className={'suspense-loading-spinner'}>
			<Spin size='large' />
		</div>
	)
}

export default GlobalLoading
