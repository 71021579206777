import React from 'react'
import { BaseFieldsProps, WrappedFieldsProps } from 'redux-form'
import { Gutter } from 'antd/es/grid/row'
import cx from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { Col, Form, Row, TimePicker } from 'antd'
import { TimePickerProps } from 'antd/lib/time-picker'
import { get } from 'lodash'

// assets
import TimerIcon from '../assets/icons/clock-icon.svg?react'
import RemoveIcon from '../assets/icons/remove-select-icon.svg?react'

// utils
import { formFieldID } from '../utils/helper'
import { DEFAULT_TIME_FORMAT, DROPDOWN_POSITION, ROW_GUTTER_X_DEFAULT, TIME_FORMAT_12_HOUR } from '../utils/enums'
import { is12HourFormat } from '../utils/intl'
import { TimeRangeFieldOnConfirmFncArgs } from '../types/interfaces'

type Props = WrappedFieldsProps &
	TimePickerProps &
	BaseFieldsProps & {
		placeholders: string[]
		labels?: string[]
		timeFormat?: string
		itemClassName?: string
		hideHelp?: boolean
		layout: 'row' | 'col'
		customOnChange?: (data: TimeRangeFieldOnConfirmFncArgs) => void
		onConfirm?: (data: TimeRangeFieldOnConfirmFncArgs) => void
		gutter?: Gutter
	}

const { Item } = Form

const TimeRangeField = (props: Props) => {
	const {
		names,
		placeholders,
		labels,
		disabled,
		allowClear,
		minuteStep,
		getPopupContainer,
		required,
		size,
		itemClassName,
		timeFormat,
		hideHelp,
		suffixIcon,
		use12Hours = is12HourFormat(),
		layout = 'row',
		className,
		customOnChange,
		onOpenChange,
		onOk,
		onConfirm,
		gutter = ROW_GUTTER_X_DEFAULT
	} = props

	const defaultValueFormat = timeFormat || DEFAULT_TIME_FORMAT

	const allowClearWrap = () => {
		if (typeof allowClear === 'object' && allowClear.clearIcon) {
			return allowClear
		}

		if (allowClear) {
			return {
				clearIcon: <RemoveIcon className={'text-notino-black'} />
			}
		}

		return false
	}

	return (
		<Row gutter={gutter} className={className}>
			{names.map((name, index) => {
				const meta = get(props, `${name}.meta`) as any
				const input = get(props, `${name}.input`) as any

				const inputRef = React.createRef<React.ElementRef<typeof TimePicker>>()

				let pickerValue: Dayjs | undefined
				if (input.value) {
					pickerValue = dayjs(input.value, defaultValueFormat)
				}

				const getCustomOnChangeArgs = (valueWithSeconds: Dayjs) => {
					const value = valueWithSeconds.set('seconds', 0).set('milliseconds', 0)

					let currentTimeFrom = props[names[0]].input.value
					if (currentTimeFrom) {
						currentTimeFrom = dayjs(currentTimeFrom, defaultValueFormat).set('seconds', 0).set('milliseconds', 0)
					}

					let currentTimeTo = props[names[1]].input.value
					if (currentTimeTo) {
						currentTimeTo = dayjs(currentTimeTo, defaultValueFormat).set('seconds', 0).set('milliseconds', 0)
					}

					const newTimeFrom = index === 0 ? value : currentTimeFrom
					const newTimeTo = index === 1 ? value : currentTimeTo

					return { newTimeFrom, newTimeTo, currentTimeFrom, currentTimeTo, index }
				}

				const onChangeWrap = (valueWithSeconds: Dayjs) => {
					const value = valueWithSeconds.set('seconds', 0).set('milliseconds', 0)

					if (customOnChange) {
						customOnChange(getCustomOnChangeArgs(value))
						return
					}

					input.onChange(value.format(defaultValueFormat))
				}

				const onOkWrap = (valueWithSeconds: Dayjs) => {
					if (onOk) {
						onOk(valueWithSeconds)
					}

					const value = valueWithSeconds.set('seconds', 0).set('milliseconds', 0)

					if (onConfirm) {
						onConfirm(getCustomOnChangeArgs(value))
						return
					}

					// validate times => time from should always be earlier than time to
					let newValue = value.format(defaultValueFormat)
					const other = dayjs(get(props, `${names[index === 0 ? 1 : 0]}.input.value`) as any, defaultValueFormat)

					if (index === 0 && value.isSameOrAfter(other)) {
						newValue = other.subtract(1, 'minute').format(defaultValueFormat)
					} else if (index === 1 && value.isSameOrBefore(other)) {
						newValue = other.set('minutes', other.minute() + 1).format(defaultValueFormat)
					}

					input.onChange(newValue)
				}

				const onOpenChangeWrap = (open: boolean) => {
					if (onOpenChange) {
						onOpenChange(open)
					}

					if (!open) {
						// make the same validation than onOk when dropdown is closing
						onOkWrap(dayjs(input.value, DEFAULT_TIME_FORMAT))
					}
				}

				const onClear = (value: Dayjs | null) => {
					if (!value) {
						input.onChange(null)
					} else {
						onChangeWrap(value)
					}
				}

				return (
					<Col span={layout === 'row' ? 12 : 24} key={name}>
						<Item
							className={cx('w-full', itemClassName, { 'pb-4': layout === 'col' && index === 0 })}
							label={labels?.[index]}
							required={required}
							help={hideHelp ? undefined : meta.touched && meta.error}
							validateStatus={meta.touched && meta.error ? 'error' : undefined}
						>
							<TimePicker
								ref={inputRef}
								id={formFieldID(meta.form, input.name)}
								popupAlign={DROPDOWN_POSITION.BOTTOM_LEFT}
								// NOTE: workaround https://github.com/ant-design/ant-design/issues/21189
								onCalendarChange={(value) => {
									if (value) {
										onChangeWrap(Array.isArray(value) ? value[0] : value)
									}
								}}
								onChange={onClear}
								format={timeFormat || use12Hours ? TIME_FORMAT_12_HOUR : DEFAULT_TIME_FORMAT}
								value={pickerValue}
								className={'w-full noti-date-input noti-time-input'}
								popupClassName={'noti-time-dropdown'}
								size={size}
								suffixIcon={suffixIcon || <TimerIcon className={'text-notino-black'} />}
								placeholder={placeholders[index]}
								disabled={disabled}
								allowClear={allowClearWrap()}
								onOk={onOkWrap}
								showNow={false}
								minuteStep={minuteStep}
								use12Hours={use12Hours}
								getPopupContainer={getPopupContainer || ((node) => node)}
								onOpenChange={onOpenChangeWrap}
							/>
						</Item>
					</Col>
				)
			})}
		</Row>
	)
}

export default TimeRangeField
