import { uniq, map, get } from 'lodash'
import * as Sentry from '@sentry/react'
import Axios from 'axios'

// types
import { IResetStore } from '../generalTypes'
import { SELECTED_SALON, SALON_OPTIONS } from './selectedSalonTypes'
import { ISelectOptionItem, IPermissions, ICurrency, RequestResponse, GetUrls, PostUrls } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'

// utils
// eslint-disable-next-line import/no-cycle
import { getReq } from '../../utils/request'
import { DEFAULT_CURRENCY, PERMISSION } from '../../utils/enums'
import { checkPermissions } from '../../utils/Permissions'
import Navigator from '../../utils/navigation'

export type ISelectedSalonActions = IResetStore | IGetSelectedSalon | ISalonOptions

export interface IGetSelectedSalon {
	type: SELECTED_SALON
	payload: ISelectedSalonPayload
}

export interface ISalonOptions {
	type: SALON_OPTIONS
	payload: ISalonSelectionOptionsPayload
}

export type ISalonPayloadData = RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}']>['salon'] &
	IPermissions & {
		currency: ICurrency
	}

export interface ISelectedSalonPayload {
	data: ISalonPayloadData | null
}

interface ISalonSelectedOptionItem extends ISelectOptionItem {
	logo?: string
}

export interface ISalonSelectionOptionsPayload {
	data: ISalonSelectedOptionItem[] | null
}

export const selectSalon =
	(salonID: string | null): ThunkResult<Promise<ISelectedSalonPayload>> =>
	async (dispatch, getState) => {
		let payload = {} as ISelectedSalonPayload

		if (!salonID) {
			dispatch({ type: SELECTED_SALON.SELECTED_SALON_CLEAR })
			return payload
		}

		dispatch({ type: SELECTED_SALON.SELECTED_SALON_LOAD_START })

		const state = getState()

		try {
			const { data } = await getReq('/api/b2b/admin/salons/{salonID}', { params: { path: { salonID } }, reqBody: {}, customConfig: { allowAbort: true } })

			let salonCurrency: ICurrency = {} as ICurrency

			try {
				// obtain salon's currency
				if (data.salon.address) {
					// pick country code from salon address
					const { countryCode } = data.salon.address

					const countries = state.config.config.data?.rolloutCountries
					const currencies = state.config.config.data?.systemCurrencies

					// find country by code from enumeration values
					const country = countries?.find((item) => item.code?.toLowerCase() === countryCode?.toLowerCase())

					// find currency by currency code from country
					const currency = currencies?.find((item) => country?.currencyCode?.toLowerCase() === item.code?.toLowerCase())

					if (currency) {
						salonCurrency = {
							code: currency.code,
							symbol: currency.symbol
						}
					}
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error)
			}

			if (!salonCurrency) {
				salonCurrency = DEFAULT_CURRENCY as ICurrency
				Sentry.withScope((scope) => {
					scope.setTag('selectedSalon', 'error')
					Sentry.captureException(`Missing salon currency: salonID: ${data.salon.id}`)
				})
			}

			let permissions: PERMISSION[] = []

			const currentUser = state.user.authUser.data

			if (currentUser && currentUser.uniqPermissions) {
				permissions = currentUser.uniqPermissions

				const isNotinoUser = checkPermissions(currentUser.uniqPermissions, [PERMISSION.NOTINO])
				// Check permissions
				// Only NOTINO users or PARTNER with assigned salon can select salon
				if (!(isNotinoUser || currentUser.salons.find((salon) => salon.id === salonID))) {
					dispatch({ type: SELECTED_SALON.SELECTED_SALON_CLEAR })
					Navigator.navigate('/403')
					return payload
				}

				// Get permissions
				// Notino user doesn't require salon's permissions
				if (!isNotinoUser) {
					const salon = currentUser.salons.find((item) => item.id === salonID)
					if (salon && salon.role) {
						permissions = uniq(map(salon.role.permissions, 'name')) as any
					}
				}
			}

			payload = {
				data: {
					...data.salon,
					currency: salonCurrency,
					uniqPermissions: permissions
				}
			}
			dispatch({ type: SELECTED_SALON.SELECTED_SALON_LOAD_DONE, payload })
			// eslint-disable-next-line consistent-return
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)

			// standard error that was not caused by cancelled by refresh token
			if (!Axios.isCancel(error)) {
				dispatch({ type: SELECTED_SALON.SELECTED_SALON_CLEAR })
				Navigator.navigate('/404')
			}
		}

		return payload
	}

export const setSelectionOptions =
	(salons: RequestResponse<PostUrls['/api/b2b/admin/auth/login']>['user']['salons']): ThunkResult<void> =>
	(dispatch) => {
		const options = salons.map((salon) => ({
			key: salon.id,
			label: get(salon, 'name', salon.id.toString()),
			value: salon.id,
			logo: get(salon, 'logo.resizedImages.thumbnail')
		}))

		const payload: ISalonSelectionOptionsPayload = {
			data: options
		}

		dispatch({ type: SALON_OPTIONS.SALON_OPTIONS_UPDATE, payload })
	}
