import React, { useCallback, useEffect, useState } from 'react'
import { Button, Dropdown, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ItemType } from 'antd/es/menu/interface'

import cx from 'classnames'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router'
import i18next from 'i18next'

// assets
import BellIcon from '../../assets/icons/bell-icon.svg?react'

// utils
import { deleteReq, patchReq } from '../../utils/request'
import {
	CALENDAR_DATE_FORMAT,
	CALENDAR_EVENT_TYPE,
	CALENDAR_VIEW,
	DEFAULT_DATE_INIT_FORMAT,
	NOTIFICATION_EVENT_GROUP_TYPE,
	NOTIFICATION_EVENT_TYPE,
	NOTIFICATION_URL_RESOLVER_RESPONSE_TYPE,
	RESERVATIONS_STATE,
	RESERVATION_STATE
} from '../../utils/enums'
import { NOTIFICATIONS_DROPDOWN_WRAPPER_ID, NotificationConfirmModalData, formatNotificationsCount } from './notificationsUtils'
import { pushEventToDataLayer } from '../../utils/dataLayer'
import { DATA_LAYER_EVENTS, GA_CONTEXT_MENU_TYPE } from '../../utils/dataLayerEnums'

// types
import { RootState } from '../../reducers'
import { DeleteUrls, PatchUrls, RequestPayload } from '../../types/interfaces'

// redux
import {
	IUserNotificationsPayload,
	deleteUserNotification,
	getUserNotifications,
	markUserNotificationAsRead,
	sliceUserNotifications,
	updateUnreadNotificationsCount
} from '../../reducers/notifications/notificationsActions'
import { getCalendarEventDetail } from '../../reducers/calendar/calendarActions'

// components
import NotificationsHeader from './components/NotificationsHeader'
import Notification from './components/Notification'
import EmptyNotifications from './components/EmptyNotifications'
import NotificationConfirmModal from './components/NotificationConfirmModal'

// hooks
import { formatObjToQuery } from '../../hooks/useQueryParamsZod'

// schemas
import { ICalendarPageURLQueryParams, ISalonReservationsPageURLQueryParams } from '../../types/schemaTypes'

type ReservationLinkData = {
	getUrl: (date?: string) => string
	salonID: string
	reservationID: string
}

const getNotificationLink = (
	item: NonNullable<IUserNotificationsPayload['data']>['notifications'][0]
): { url: string | undefined | ReservationLinkData; isExternalUrl: boolean } => {
	const { externalLink, urlResolverResponse } = item

	let url: string | undefined | ReservationLinkData = externalLink?.url
	let isExternalUrl = !!externalLink?.url

	if (urlResolverResponse) {
		const { payload } = urlResolverResponse
		switch (urlResolverResponse.type) {
			case NOTIFICATION_URL_RESOLVER_RESPONSE_TYPE.RESERVATION_DETAIL: {
				if ('salonID' in payload && 'reservationID' in payload) {
					url = {
						getUrl: (date?: string) => {
							const redirectQuery = {
								view: CALENDAR_VIEW.DAY,
								sidebarView: CALENDAR_EVENT_TYPE.RESERVATION,
								eventId: payload.reservationID,
								date: dayjs(date).format(CALENDAR_DATE_FORMAT.QUERY)
							}
							return `${i18next.t('paths:salons')}/${payload.salonID}${i18next.t('paths:calendar')}${formatObjToQuery<ICalendarPageURLQueryParams>(redirectQuery)}`
						},
						reservationID: payload.reservationID,
						salonID: payload.salonID
					}
					isExternalUrl = false
				}
				break
			}
			case NOTIFICATION_URL_RESOLVER_RESPONSE_TYPE.DOCUMENT_DETAIL: {
				if ('documentID' in payload) {
					url = `${i18next.t('paths:my-documents')}/${payload.documentID}`
					isExternalUrl = false
				}
				break
			}
			case NOTIFICATION_URL_RESOLVER_RESPONSE_TYPE.OPENED_RESERVATIONS: {
				if ('salonID' in payload) {
					const openedReservationsQueryString = formatObjToQuery<ISalonReservationsPageURLQueryParams>({
						state: RESERVATIONS_STATE.ALL,
						reservationStates: [RESERVATION_STATE.APPROVED],
						dateTo: dayjs().format(DEFAULT_DATE_INIT_FORMAT)
					})

					url = `${i18next.t('paths:salons')}/${payload.salonID}${i18next.t('paths:salon-reservations')}${openedReservationsQueryString}`
					isExternalUrl = false
				}
				break
			}
			default:
				break
		}
	}

	return {
		url,
		isExternalUrl
	}
}

const Notifications = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const currentUser = useSelector((state: RootState) => state.user.authUser.data)
	const notifications = useSelector((state: RootState) => state.userNotifications.userNotifications)
	const googleAnalyticsScreenName = useSelector((state: RootState) => state.googleAnalytics.screenName)

	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const [onlyUnread, setOnlyUnread] = useState(false)
	const [notificationEventGroupType, setNotificationEventGroupType] = useState(NOTIFICATION_EVENT_GROUP_TYPE.RESERVATION)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [confirmModalData, setConfirmModalData] = useState<NotificationConfirmModalData | null>(null)
	const [isLoadingRedirectData, setIsLoadingRedirectData] = useState(false)

	const notificationsLength = notifications.data?.notifications.length || 0
	const emptyNotifications = !notificationsLength

	const userID = currentUser?.id

	const unreadTotalNotificationsCount = currentUser?.notificationCentre.totalUnreadNotificationsCount || 0
	const unreadReservationsNotificationsCount = currentUser?.notificationCentre.reservationUnreadNotificationsCount || 0
	const unreadOtherNotificationsCount = currentUser?.notificationCentre.otherUnreadNotificationsCount || 0

	const isLoading = notifications.isLoading || isSubmitting || isLoadingRedirectData

	useEffect(() => {
		if (!userID) {
			return
		}
		if (isDropdownOpen) {
			dispatch(getUserNotifications({ userID, page: 1, onlyUnread, notificationEventGroupType }, false))
			dispatch(updateUnreadNotificationsCount())
		} else {
			// staci ponechat v reduxe aspon zopar notifikacii, aby sa pri druhom otvoreni zasa nezobrazil empty state
			// ak by tam ostalo vela dat, tak to pri otvoreni potom trochu seka
			dispatch(sliceUserNotifications(10))
		}
	}, [dispatch, isDropdownOpen, userID, onlyUnread, notificationEventGroupType])

	useEffect(() => {
		const onScroll = (e: any) => {
			if (isLoading || !userID) {
				return
			}

			let hasMore = false
			let nextPage = 1

			if (notifications.data?.pagination) {
				hasMore = notificationsLength < notifications.data?.pagination.totalCount
				nextPage = (notifications.data?.pagination.page || 0) + 1
			}

			if (Math.ceil(e.target.scrollTop + e.target.offsetHeight) >= e.target.scrollHeight && hasMore) {
				dispatch(getUserNotifications({ userID, page: nextPage, onlyUnread, notificationEventGroupType }))
			}
		}
		const notificationsBody = document.querySelector('.notification-dropdown-body') as HTMLElement

		if (notificationsBody) {
			if (isDropdownOpen) {
				notificationsBody.addEventListener('scroll', onScroll)
			} else {
				notificationsBody.removeEventListener('scroll', onScroll)
			}
		}
		return () => notificationsBody?.removeEventListener('scroll', onScroll)
	}, [isDropdownOpen, isLoading, notifications.data?.pagination, notificationsLength, dispatch, userID, onlyUnread, notificationEventGroupType])

	const markNotificationAsRead = async (markAsRead: boolean, notificationID?: string) => {
		if (!userID) {
			return
		}
		try {
			setIsSubmitting(true)
			let requestBody: RequestPayload<PatchUrls['/api/b2b/admin/users/{userID}/notifications/mark-as-read']> = {
				markAsRead,
				notificationEventGroupType,
				markAll: !notificationID
			}

			if (notificationID) {
				requestBody = {
					...requestBody,
					notificationID
				}
			}

			// update notification BE data
			await patchReq('/api/b2b/admin/users/{userID}/notifications/mark-as-read', { params: { path: { userID } }, reqBody: requestBody })
			// update notification FE data
			if (onlyUnread) {
				if (notificationID) {
					// In case of onlyUnread mode only and when updating only one notification, we need to refetch the last page - this collection should contain existing notifications and one new notification.
					// A notifications with the same ID will be filtered out, and only this new notification will be added to the list of notifications that we maintain on the frontend (this logic is performed in the action).
					// If we didn't do this, this notification would be ignored because due to the data shift after marked as read, the request to the next page wouldn't include it.
					await dispatch(
						getUserNotifications({
							userID,
							page: notifications.data?.pagination.page,
							limit: notifications.data?.pagination.limit,
							onlyUnread: true,
							notificationEventGroupType
						})
					)
				} else {
					// all notifications are marked as read
					// fetch current unread notification, they should be empty
					await dispatch(getUserNotifications({ userID, page: 1, onlyUnread, notificationEventGroupType }, false))
				}
			}
			dispatch(markUserNotificationAsRead(markAsRead, onlyUnread, notificationID))
			// update unread notifications count
			dispatch(updateUnreadNotificationsCount())
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		} finally {
			setIsSubmitting(false)
		}
	}

	const deleteNotification = async (notificationID?: string) => {
		if (!userID) {
			return
		}
		setIsSubmitting(true)
		try {
			let requestBody: RequestPayload<DeleteUrls['/api/b2b/admin/users/{userID}/notifications/']> = {
				deleteAll: !notificationID,
				notificationEventGroupType
			}

			if (notificationID) {
				requestBody = {
					deleteAll: false,
					notificationID
				}
			}

			// delete notification from BE data
			await deleteReq('/api/b2b/admin/users/{userID}/notifications/', { params: { path: { userID } }, reqBody: requestBody })
			if (notificationID) {
				// delete notification from FE data
				dispatch(deleteUserNotification(notificationID))
				// The last fetched page will be refetched again - this collection should contain existing notifications and one new notification.
				// A notifications with the same ID will be filtered out, and only this new notification will be added to the list of notifications that we maintain on the frontend (this logic is performed in the action).
				// If we didn't do this, this notification would be ignored because due to the data shift after deletion, the request to the next page wouldn't include it.
				await dispatch(
					getUserNotifications({
						userID,
						page: notifications.data?.pagination.page,
						limit: notifications.data?.pagination.limit,
						onlyUnread,
						notificationEventGroupType
					})
				)
			} else {
				// fetch current notifications, they should be empty
				await dispatch(getUserNotifications({ userID, page: 1, onlyUnread, notificationEventGroupType }, false))
			}
			// update unread notifications count
			dispatch(updateUnreadNotificationsCount())
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		} finally {
			setIsSubmitting(false)
		}
	}

	const onChangeUnreadNotifications = useCallback(
		(unread: boolean) => {
			pushEventToDataLayer({
				event: DATA_LAYER_EVENTS.TOGGLE_SWITCH,
				checkbox_name: 'show_unread_notifications',
				screen_name: googleAnalyticsScreenName,
				checked_state: unread
			})

			const notificationsBody = document.querySelector('.notification-dropdown-body')

			if (notificationsBody) {
				notificationsBody.scrollTop = 0
			}
			setOnlyUnread(unread)
		},
		[googleAnalyticsScreenName]
	)

	const onChangeNotificationGroupType = (tabKey: string) => {
		pushEventToDataLayer({
			event: DATA_LAYER_EVENTS.SELECT_SEGMENT,
			screen_name: googleAnalyticsScreenName,
			segment_name: 'notifications_menu_type',
			selected_segment: tabKey === NOTIFICATION_EVENT_GROUP_TYPE.RESERVATION ? 'reservations' : 'other'
		})

		setNotificationEventGroupType(tabKey as NOTIFICATION_EVENT_GROUP_TYPE)
	}

	const getNotificationItems = (): ItemType[] => {
		const notificationsBodyItems: ItemType[] = []

		if (emptyNotifications) {
			notificationsBodyItems.push({
				key: 'notifications-body-empty',
				label: <EmptyNotifications />
			})
		} else {
			notifications.data?.notifications.forEach((item) => {
				const unread = !item.readAt
				const { url, isExternalUrl } = getNotificationLink(item)
				notificationsBodyItems.push({
					key: item.id,
					label: (
						<Notification
							id={item.id}
							title={item.title}
							message={item.message}
							notificationEventType={item.notificationEventType as NOTIFICATION_EVENT_TYPE}
							unread={unread}
							createdAt={item.createdAt}
							okActionProps={{
								onOk: () => {
									pushEventToDataLayer({
										event: DATA_LAYER_EVENTS.SELECT_CONTEXT_MENU_OPTION,
										context_menu_type: GA_CONTEXT_MENU_TYPE.SINGLE_NOTIFICATIONS_MENU,
										screen_name: googleAnalyticsScreenName,
										selected_option: 'mark_as_read'
									})

									markNotificationAsRead(unread, item.id)
								},
								okText: unread ? t('loc:Označiť ako prečítané') : t('loc:Označiť ako neprečítané')
							}}
							deleteActionProps={{
								onDelete: () => {
									pushEventToDataLayer({
										event: DATA_LAYER_EVENTS.SELECT_CONTEXT_MENU_OPTION,
										context_menu_type: GA_CONTEXT_MENU_TYPE.SINGLE_NOTIFICATIONS_MENU,
										screen_name: googleAnalyticsScreenName,
										selected_option: 'delete_notification'
									})

									deleteNotification(item.id)
								},
								deleteText: t('loc:Vymazať notifikáciu')
							}}
							isLoading={isLoading}
							onOpenChange={(open: boolean) => {
								if (open) {
									pushEventToDataLayer({
										event: DATA_LAYER_EVENTS.OPEN_CONTEXT_MENU,
										context_menu_type: GA_CONTEXT_MENU_TYPE.SINGLE_NOTIFICATIONS_MENU,
										screen_name: googleAnalyticsScreenName
									})
								}
							}}
							onNotificationClick={
								url
									? async () => {
											let redirectUrl
											if (typeof url === 'object') {
												const { getUrl, reservationID, salonID } = url
												let reservationDate: string | undefined
												try {
													setIsLoadingRedirectData(true)
													const { data } = await dispatch(getCalendarEventDetail(salonID, reservationID, false))
													reservationDate = data?.start.date
												} catch (error) {
													// eslint-disable-next-line no-console
													console.error(error)
												} finally {
													setIsLoadingRedirectData(false)
												}
												redirectUrl = getUrl(reservationDate)
											} else {
												redirectUrl = url
											}

											// NOTE: notifications dropdown will close after the click on the notification item
											// if you want to prevent closing the dropdown use e.stopPropagation(); e is passed as an argument to the onNotificationClick callback
											if (isExternalUrl) {
												window.open(redirectUrl)
											} else {
												navigate(redirectUrl)
											}
											if (unread) {
												markNotificationAsRead(true, item.id)
											}
										}
									: undefined
							}
						/>
					)
				})
			})
		}

		const unreadNotificationsOfCurrentEventGroupType =
			notificationEventGroupType === NOTIFICATION_EVENT_GROUP_TYPE.RESERVATION ? unreadReservationsNotificationsCount : unreadOtherNotificationsCount

		const menuItems: ItemType[] = [
			// notification dropdown header
			{
				key: 'notification-dropdown',
				className: 'notification-dropdown-header p-0 m-0',
				label: (
					<NotificationsHeader
						okActionProps={{
							onOk: () => {
								pushEventToDataLayer({
									event: DATA_LAYER_EVENTS.SELECT_CONTEXT_MENU_OPTION,
									context_menu_type: GA_CONTEXT_MENU_TYPE.NOTIFICATIONS_MENU,
									screen_name: googleAnalyticsScreenName,
									selected_option: 'mark_all_as_read'
								})

								markNotificationAsRead(!!unreadNotificationsOfCurrentEventGroupType)
							},
							okText: unreadNotificationsOfCurrentEventGroupType ? t('loc:Označiť všetky ako prečítané') : t('loc:Označiť všetky ako neprečítané')
						}}
						deleteActionProps={{
							onDelete: () => {
								pushEventToDataLayer({
									event: DATA_LAYER_EVENTS.SELECT_CONTEXT_MENU_OPTION,
									context_menu_type: GA_CONTEXT_MENU_TYPE.NOTIFICATIONS_MENU,
									screen_name: googleAnalyticsScreenName,
									selected_option: 'delete_all_notifications'
								})

								setConfirmModalData({ action: 'delete', handler: deleteNotification })
							}
						}}
						onlyUnread={onlyUnread}
						onChangeUnreadNotifications={onChangeUnreadNotifications}
						notificationEventGroupType={notificationEventGroupType}
						onChangeNotificationGroupType={onChangeNotificationGroupType}
						unreadTotalNotificationsCount={unreadTotalNotificationsCount}
						unreadReservationsNotificationsCount={unreadReservationsNotificationsCount}
						unreadOtherNotificationsCount={unreadOtherNotificationsCount}
						isLoading={isLoading}
						emptyNotifications={emptyNotifications}
						onOpenChange={(open: boolean) => {
							if (open) {
								pushEventToDataLayer({
									event: DATA_LAYER_EVENTS.OPEN_CONTEXT_MENU,
									context_menu_type: GA_CONTEXT_MENU_TYPE.NOTIFICATIONS_MENU,
									screen_name: googleAnalyticsScreenName
								})
							}
						}}
					/>
				)
			},
			// notification dropdown body
			{
				type: 'group',
				key: 'notification-body',
				className: 'notification-dropdown-body',
				style: { height: 'auto', maxHeight: 'calc(100vh - 250px)', overflowY: 'auto', overflowX: 'hidden', width: '100%' },
				children: notificationsBodyItems
			}
		]

		return menuItems
	}

	let unreadNotificationsBadgeClassNames = ''

	if (unreadTotalNotificationsCount < 10) {
		unreadNotificationsBadgeClassNames = 'w-4 h-4 right-0'
	} else {
		unreadNotificationsBadgeClassNames = 'h-4 px-1-5 -right-2'
	}

	const onOpenChange = (open: boolean) => {
		setIsDropdownOpen(open)
		if (open) {
			pushEventToDataLayer<{ count_of_unread_notifications: number }>({
				event: DATA_LAYER_EVENTS.CLICK_BUTTON,
				button_name: 'notifications_bell',
				screen_name: googleAnalyticsScreenName,
				count_of_unread_notifications: unreadTotalNotificationsCount
			})
		}
	}

	return (
		<>
			<Dropdown
				placement='bottomRight'
				trigger={['click']}
				overlayClassName={'notification-dropdown w-96'}
				getPopupContainer={() => document.querySelector('#noti-header') as HTMLElement}
				menu={{ items: getNotificationItems() }}
				onOpenChange={onOpenChange}
				dropdownRender={(originNode) => (
					<div className={'notifications-dropdown-wrapper bg-notino-white rounded'} id={NOTIFICATIONS_DROPDOWN_WRAPPER_ID}>
						<Spin spinning={isLoading}>{originNode}</Spin>
					</div>
				)}
				destroyPopupOnHide
			>
				<Button className='ml-4 flex-shrink-0 w-10 h-10 p-0 flex items-center justify-center border-0 bg-notino-grayLighter rounded-full relative'>
					{unreadTotalNotificationsCount ? (
						<span
							className={cx(
								'rounded-lg bg-notino-pink text-notino-white absolute top-0 text-xs flex items-center justify-center',
								unreadNotificationsBadgeClassNames
							)}
						>
							{formatNotificationsCount(unreadTotalNotificationsCount)}
						</span>
					) : null}
					<BellIcon />
				</Button>
			</Dropdown>

			{confirmModalData && <NotificationConfirmModal confirmModalData={confirmModalData} setConfirmModalData={setConfirmModalData} isLoading={isLoading} />}
		</>
	)
}

export default React.memo(Notifications)
