/* eslint-disable import/no-cycle */
import { RESET_STORE } from '../generalTypes'
import { IVouchersActions, IVouchersPayload } from './vouchersActions'
import { ILoadingAndFailure } from '../../types/interfaces'
import { VOUCHERS } from './vouchersTypes'

export const initState = {
	vouchers: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IVouchersPayload & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: IVouchersActions) => {
	switch (action.type) {
		// Vouchers
		case VOUCHERS.VOUCHERS_LOAD_START:
			return {
				...state,
				vouchers: {
					...state.vouchers,
					isLoading: true
				}
			}
		case VOUCHERS.VOUCHERS_LOAD_FAIL:
			return {
				...state,
				vouchers: {
					...initState.vouchers,
					isFailure: true
				}
			}
		case VOUCHERS.VOUCHERS_LOAD_DONE:
			return {
				...state,
				vouchers: {
					...initState.vouchers,
					data: action.payload.data
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
