import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router'

// utils
import { CHANGE_DEBOUNCE_TIME, CREATE_CHANGELOG_BUTTON_ID, FIELD_MODE, FORM, ROW_GUTTER_X_M, VALIDATION_MAX_LENGTH } from '../../../utils/enums'
import { CHANGELOG_PLATFORM_OPTIONS, getLinkWithEncodedBackUrl } from '../../../utils/helper'

// atoms
import SelectField from '../../../atoms/SelectField'
import InputField from '../../../atoms/InputField'

// assets
import PlusIcon from '../../../assets/icons/plus-icon.svg?react'

// types
import { IChangelogsFilter } from '../../../types/interfaces'

type ComponentProps = {}

type Props = InjectedFormProps<IChangelogsFilter, ComponentProps> & ComponentProps

const ChangelogsFilter = (props: Props) => {
	const { handleSubmit } = props
	const [t] = useTranslation()
	const navigate = useNavigate()

	return (
		<Form layout='horizontal' onSubmitCapture={handleSubmit} className={'pt-0'}>
			<Row gutter={ROW_GUTTER_X_M}>
				<Col flex={'auto'}>
					<Row gutter={ROW_GUTTER_X_M}>
						<Col span={12} xl={8}>
							<Field
								className={'h-10 p-0 m-0'}
								component={InputField}
								size={'large'}
								placeholder={t('loc:Hľadať podľa verzie')}
								name={'search'}
								fieldMode={FIELD_MODE.FILTER}
								search
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
							/>
						</Col>
						<Col span={12} xl={8}>
							<Field component={SelectField} name={'platform'} placeholder={t('loc:Aplikácia')} allowClear size={'large'} options={CHANGELOG_PLATFORM_OPTIONS()} />
						</Col>
					</Row>
				</Col>
				<Button
					id={CREATE_CHANGELOG_BUTTON_ID}
					onClick={() => {
						navigate(getLinkWithEncodedBackUrl(t('paths:notifications/changelogs/create')))
					}}
					type='primary'
					htmlType='button'
					className={'noti-btn'}
					icon={<PlusIcon />}
				>
					{t('loc:Vytvoriť upozornenie')}
				</Button>
			</Row>
		</Form>
	)
}

const form = reduxForm<IChangelogsFilter, ComponentProps>({
	form: FORM.CHANGELOGS_FILTER,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, CHANGE_DEBOUNCE_TIME),
	destroyOnUnmount: true
})(ChangelogsFilter)

export default form
