import React from 'react'
import { Select, Row, FormItemProps, SelectProps } from 'antd'
import i18next from 'i18next'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Field } from 'redux-form'

// utils
import { patchReq } from '../utils/request'
import { getLanguageCode } from '../utils/intl'
import { LANGUAGE, DEFAULT_LANGUAGE, LOCALES } from '../utils/enums'
import { optionRenderWithIcon } from '../utils/helper'

// hooks
import useMedia from '../hooks/useMedia'

// types
import { RootState } from '../reducers'
import { UserSettingsPatchBody } from '../types/interfaces'

// components
import FlagIcon from './FlagIcon'
import SelectField from '../atoms/SelectField'

// assets
import GlobeIcon from '../assets/icons/globe-icon.svg?react'
import { BROADCAST_CHANNEL_TYPE, BroadcastUserLanguageChangeMessage, appBroadcastChannel } from '../utils/broadcast'

// default language must be first
export const EMPTY_NAME_LOCALIZATIONS = Object.keys(LOCALES)
	.sort((a: string, b: string) => {
		if (a === DEFAULT_LANGUAGE) {
			return -1
		}
		return b === DEFAULT_LANGUAGE ? 1 : 0
	})
	.map((language) => ({ language }))

const { Option } = Select

type Props = {
	className?: string
	onDropdownVisibleChange?: SelectProps['onDropdownVisibleChange']
	onSelect?: SelectProps['onSelect']
}

// notify other opened tabs that user language has been changed
const postBroadcastChannelMessage = (value: LANGUAGE) => {
	const broadcastMessage: BroadcastUserLanguageChangeMessage = { type: BROADCAST_CHANNEL_TYPE.USER_LANGUAGE_CHANGE, payload: value }
	appBroadcastChannel.postMessage(broadcastMessage)
}

export const handleLanguageChange = async (
	value: LANGUAGE,
	currentUserID: string | undefined,
	onSuccess: () => void = () => window.document.location.reload(),
	onError?: () => void
) => {
	if (currentUserID) {
		try {
			const reqBody: UserSettingsPatchBody = {
				language: value
			}

			await patchReq('/api/b2b/admin/users/{userID}/settings', {
				params: { path: { userID: currentUserID } },
				reqBody,
				customConfig: { headers: { 'accept-language': value } }
			})
			await i18next.changeLanguage(value)

			postBroadcastChannelMessage(value)

			// NOTE: do not update user, because <WhatsNew /> modal would be called unexpectedly
			// dispatch(getCurrentUser())
			// user will be updated by onSuccess callback function that (by default) reloads the whole application
			onSuccess()
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)

			if (onError) {
				onError()
			}
		}
	} else {
		await i18next.changeLanguage(value)
		postBroadcastChannelMessage(value)
		onSuccess()
	}
}

export const languageOptions = Object.entries(LANGUAGE).map(([key, value]) => ({ label: key, value, icon: <FlagIcon language={value} className={'mr-2'} />, key: value }))

/**
 * use outside form
 */
const LanguagePicker = (props: Props) => {
	const { className, onDropdownVisibleChange, onSelect } = props

	const isSmallDevice = useMedia(['(max-width: 744px)'], [true], false)
	const currentUserData = useSelector((state: RootState) => state.user.authUser.data)

	return (
		<div className={`${className} language-picker-select ant-form-item`}>
			<Select
				defaultValue={getLanguageCode(i18next.resolvedLanguage)}
				onChange={(value: LANGUAGE) => handleLanguageChange(value, currentUserData?.id)}
				className={'noti-select-input'}
				popupClassName={'noti-select-dropdown z-30'}
				onDropdownVisibleChange={onDropdownVisibleChange}
				onSelect={onSelect}
			>
				{languageOptions?.map((option, index: number) => (
					<Option value={option.value} key={index}>
						<Row className={cx('items-center', { 'justify-center': isSmallDevice })}>
							{option.icon}
							{!isSmallDevice && option.label}
						</Row>
					</Option>
				))}
			</Select>
		</div>
	)
}

type LanguagePickerFieldProps = {
	name: string
	size?: SelectProps['size']
	onDropdownVisibleChange?: SelectProps['onDropdownVisibleChange']
	onSelect?: SelectProps['onSelect']
} & FormItemProps

/**
 * use inside form
 */
export const LanguagePickerField = (props: LanguagePickerFieldProps) => {
	const { name, label, className, size = 'large', onDropdownVisibleChange, onSelect, ...restProps } = props
	const [t] = useTranslation()

	return (
		<Field
			component={SelectField}
			optionRender={(itemData: any) => optionRenderWithIcon(itemData, <GlobeIcon />)}
			name={name}
			onDropdownVisibleChange={onDropdownVisibleChange}
			onSelect={onSelect}
			label={
				label || (
					<div>
						<strong className={'font-semibold text-sm'}>{t('loc:Jazyk aplikácie')}</strong>
						<br />
						<span className={'text-xs text-notino-grayMedium font-medium leading-3'}>
							{t(
								'loc:Vybraný jazyk zmení jazyk mobilnej aplikácie aj Admin aplikácie Notino partner. V tomto jazyku vám budú prichádzať aj všetky upozornenia vrátane sms a emailov.'
							)}
						</span>
					</div>
				)
			}
			placeholder={t('loc:Vyberte jazyk')}
			popupClassName={'z-50'}
			size={size}
			options={languageOptions}
			className={className}
			{...restProps}
		/>
	)
}

export default LanguagePicker
