import envConfig from '../config'

// utils
import { ERROR_MSG_CODE, GRECAPTCHA_ACTIONS } from './enums'
import { getReCaptchaErrorMessage, showNotifications } from './helper'

export const loadReCaptchaScript = () => {
	const script = document.createElement('script')
	script.src = `https://www.google.com/recaptcha/api.js?render=${envConfig.VITE_RECAPTCHA_SITE_KEY}`
	script.id = 'gRecaptcha'
	script.defer = true
	document.head.appendChild(script)
}

/**
 * Function that returns reCAPTCHA v3 token that you should send to backend for validation.
 *
 * @param {GRECAPTCHA_ACTIONS} action - The action for your reCAPTCHA. Actions might contain only alphanumeric characters, slashes, and underscores.
 * @returns {Promise<string>} The reCAPTCHA token.
 * @throws {Error} If an error occurs while generating the reCAPTCHA token.
 */
const reCaptchaVerify = async (action: GRECAPTCHA_ACTIONS): Promise<string> => {
	return new Promise<string>((resolve, reject) => {
		window.grecaptcha.ready(async () => {
			try {
				if (!envConfig.VITE_RECAPTCHA_SITE_KEY) {
					throw new Error('RECAPTCHA_SITE_KEY not provided')
				}

				const token = await window.grecaptcha.execute(envConfig.VITE_RECAPTCHA_SITE_KEY, { action })
				resolve(token)
			} catch (error) {
				showNotifications([getReCaptchaErrorMessage(ERROR_MSG_CODE.RECAPTCHA_TOKEN_NOT_PROVIDED, error)])
				reject(error)
			}
		})
	})
}

export default reCaptchaVerify
