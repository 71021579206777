/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'
import { notification } from 'antd'
import axios from 'axios'
import { ArgsProps } from 'antd/es/notification/interface'
import * as Sentry from '@sentry/react'

// utils
import { setIntervalImmediately } from '../utils/helper'

// env
import envConfig from '../config'

enum NOTIFICATION_KEY {
	OFFLINE = 'noti-offline-notification',
	BACK_ONLINE = 'noti-back-online-notification',
	SLOW_CONNECTION = 'noti-slow-connection-notification'
}

const INTERVAL = 1000 * 15 // 15 sec

let isErrorNotification = false
let isSlowConnectionNotification = false

let disabled = false
/**
 * If ENV variable CHECK_NETWORK_URL is set to 'false' then network check will be disabled. Otherwise it will be enabled and expect valid URL
 */
try {
	if (!JSON.parse(envConfig.VITE_CHECK_NETWORK_URL ?? '')) {
		disabled = true
	}
} catch (error) {
	/* empty */
}

const checkNetworkAxios = axios.create({
	baseURL: envConfig.VITE_CHECK_NETWORK_URL || '',
	headers: {
		Accept: 'application/json',
		'Access-Control-Allow-Origin': '*'
	},
	timeout: envConfig.VITE_CHECK_NETWORK_TIMEOUT_IN_SECONDS * 1000 // convert to seconds
})

const commonNotificationSettings: Omit<ArgsProps, 'message'> = {
	style: {
		width: 500
	},
	placement: 'bottom',
	duration: 0
}

const notificationOfflineConfig = (t: TFunction): ArgsProps => ({
	...commonNotificationSettings,
	key: NOTIFICATION_KEY.OFFLINE,
	className: 'noti-offline-notification',
	message: t('loc:Chyba pripojenia'),
	description: t('loc:Vyzerá to, že ste offline. Skontrolujte si prosím vaše internetové pripojenie.'),
	closeIcon: null
})

const notificationBackOnlineConfig = (t: TFunction): ArgsProps => ({
	...commonNotificationSettings,
	key: NOTIFICATION_KEY.BACK_ONLINE,
	className: 'noti-back-online-notification',
	message: t('loc:Pripojenie obnovené'),
	description: t('loc:Vitajte naspäť online.')
})

const notificationWarningConfig = (t: TFunction): ArgsProps => ({
	...commonNotificationSettings,
	key: NOTIFICATION_KEY.SLOW_CONNECTION,
	className: 'noti-slow-connection-notification',
	message: t('loc:Pomalé pripojenie'),
	description: t('loc:Detekovali sme pomalé internetové pripojenie. Aplikácia nemusí fungovať správne.'),
	closeIcon: null
})

/**
 * Periodically check if network status is Online/Offline/Slow every 15 seconds.
 */
const useNetworkCheck = () => {
	const [t] = useTranslation()

	useEffect(() => {
		if (disabled) {
			// eslint-disable-next-line no-console
			console.warn('Checking of network status is not enabled')
			return () => {}
		}

		let slowConnectionCount = 0
		let goodConnectionCount = 0

		const checkInterval = setIntervalImmediately(async () => {
			try {
				await checkNetworkAxios.get('')
				slowConnectionCount = 0

				// check if is visible SLOW connection notification
				if (isSlowConnectionNotification) {
					goodConnectionCount += 1
					// 3x success responses in a row -> dismiss SLOW connection notification
					if (goodConnectionCount > 2) {
						// destroy SLOW connection notification
						notification.destroy(NOTIFICATION_KEY.SLOW_CONNECTION)
						isSlowConnectionNotification = false
						// reset counters
						slowConnectionCount = 0
						goodConnectionCount = 0
					}
					// check if is visible OFFLINE notification
				} else if (isErrorNotification) {
					// destroy "offline" notification
					notification.destroy(NOTIFICATION_KEY.OFFLINE)
					isErrorNotification = false
					// show "back online" notification
					notification.success(notificationBackOnlineConfig(t))
				}
			} catch (error: any) {
				goodConnectionCount = 0

				// Offline detected
				if (error.code === 'ERR_NETWORK' && !error.response) {
					if (!isErrorNotification) {
						// hide other notifications if exist
						notification.destroy(NOTIFICATION_KEY.SLOW_CONNECTION)
						notification.destroy(NOTIFICATION_KEY.BACK_ONLINE)
						// OFFLINE notification has highest priority
						notification.error(notificationOfflineConfig(t))
						isErrorNotification = true
					}
					// TimeOut detected
				} else if (error.code === 'ECONNABORTED' && !error.response) {
					slowConnectionCount += 1
					// 3x TimeOut errors in a row
					if (slowConnectionCount > 2) {
						// hide other notifications if exist
						notification.destroy(NOTIFICATION_KEY.BACK_ONLINE)
						notification.destroy(NOTIFICATION_KEY.OFFLINE)
						isErrorNotification = false
						// show warning notification
						notification.warning(notificationWarningConfig(t))
						isSlowConnectionNotification = true
						// reset counters
						goodConnectionCount = 0
						slowConnectionCount = 0
					}
				} else {
					// Send any other error to Sentry
					Sentry.withScope((scope) => {
						scope.setTag('networkCheck', 'error')
						Sentry.captureException(error)
					})
				}
			}
		}, INTERVAL)

		return () => {
			clearInterval(checkInterval)
		}
	}, [t])

	return null
}

export default useNetworkCheck
