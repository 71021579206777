import React, { Suspense, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { PersistGate } from 'redux-persist/es/integration/react'
import { ConfigProvider } from 'antd'
import { StyleProvider } from '@ant-design/cssinjs'
import { Locale } from 'antd/lib/locale'
import dayjs from 'dayjs'
import { GoogleOAuthProvider } from '@react-oauth/google'
import 'antd/dist/reset.css'
import { z } from 'zod'

// env
import envConfig from './config'

// utils
import configureStore from './utils/configureStore'
import i18n from './utils/i18n'
import { ANTD_THEME_VARIABLES_OVERRIDE } from './utils/enums'
import { getLocale } from './utils/intl'
import { initAppStoreForGa } from './utils/dataLayer'
import { loadReCaptchaScript } from './utils/reCaptcha'

// atoms
import GlobalLoading from './atoms/GlobalLoading'

// hooks
import useTagManager from './hooks/useTagManager'
import useTabsCounter from './hooks/useTabsCounter'

// redux
import rootReducer from './reducers'

// routes
import AppRoutes from './routes/AppRoutes'

// schemas
import { defaultErrorMap } from './schemas/baseSchema'

const { store, persistor } = configureStore(rootReducer)

initAppStoreForGa(store)

const App = () => {
	const [antdLocale, setAntdLocale] = useState<Locale | undefined>(undefined)

	useEffect(() => {
		i18n.on('languageChanged', (language) => {
			const locale = getLocale(language)
			document.documentElement.setAttribute('lang', language)
			setAntdLocale(locale.antD)
			dayjs.locale(language)
			dayjs.updateLocale(language, {
				weekStart: 1
			})
		})
	}, [])

	// tabs counter (used by Sentry)
	useTabsCounter()

	useTagManager()

	z.setErrorMap(defaultErrorMap)

	useEffect(() => {
		loadReCaptchaScript()
	}, [])

	return (
		<Suspense fallback={<GlobalLoading />}>
			<I18nextProvider i18n={i18n}>
				<PersistGate loading={<GlobalLoading />} persistor={persistor}>
					<ConfigProvider
						locale={antdLocale}
						theme={{
							token: ANTD_THEME_VARIABLES_OVERRIDE
						}}
					>
						{/* eslint-disable-next-line no-underscore-dangle, no-console */}
						<GoogleOAuthProvider clientId={envConfig.VITE_GOOGLE_OAUTH_CLIENT_ID} onScriptLoadError={() => console.error('GoogleOAuth error')}>
							<Provider store={store}>
								<StyleProvider hashPriority={'low'}>
									<AppRoutes />
								</StyleProvider>
							</Provider>
						</GoogleOAuthProvider>
					</ConfigProvider>
				</PersistGate>
			</I18nextProvider>
		</Suspense>
	)
}
export default App
