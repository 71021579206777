import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// assets
import { useDispatch, useSelector } from 'react-redux'

// types
import { RootState } from '../reducers'

// redux
import { getUserChangelogs } from '../reducers/users/userActions'

// components
import WhatsNewModal from './WhatsNewModal'

// utils
import { patchReq } from '../utils/request'
import { pushEventToDataLayer } from '../utils/dataLayer'
import { DATA_LAYER_EVENTS } from '../utils/dataLayerEnums'

const WhatsNew = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const authUser = useSelector((state: RootState) => state.user.authUser)
	const userChangelogs = useSelector((state: RootState) => state.user.userChangelogs)

	const [isModalOpen, setIsModalOpen] = useState(true)

	useEffect(() => {
		// NOTE: zobrazime len ked ma uzivatel setnuty jazyk a ked ma aktivovany ucet
		// Ak ho nema setnuty, tak sa mu zobrazi najprv modal na nastavenie jazyka
		// Po tom co si nastavi jazyk, tak sa cela aplikacia reloadne a nasledne by sa mu mal zobrazit tento modal
		// Ak by sa aj v buducnosti zrusil reaload, tak mu tento modal vyskoci pri dalsom otvoreni aplikacie
		;(async () => {
			if (authUser.data?.id && authUser.data.language && authUser.data.activateAt) {
				await dispatch(getUserChangelogs(authUser.data.id))
				setIsModalOpen(true)
			}
		})()
	}, [dispatch, authUser.data?.id, authUser.data?.language, authUser.data?.activateAt])

	const handleCancelModal = async () => {
		if (userChangelogs.data?.changelog?.id && authUser.data?.id) {
			try {
				patchReq('/api/b2b/admin/users/{userID}/changelogs/{changelogID}/mark-as-seen', {
					params: { path: { userID: authUser.data.id, changelogID: userChangelogs.data.changelog.id } },
					reqBody: {}
				})
				setIsModalOpen(false)
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			}
		} else {
			setIsModalOpen(false)
			// eslint-disable-next-line no-console
			console.error('Could not mark notification as read')
		}
	}

	return userChangelogs?.data?.changelog && authUser.data && isModalOpen ? (
		<WhatsNewModal
			open={isModalOpen}
			onCancel={() => {
				pushEventToDataLayer({
					event: DATA_LAYER_EVENTS.CLICK_BUTTON,
					button_name: 'newsletter_close'
				})
				handleCancelModal()
			}}
			onContinue={() => {
				pushEventToDataLayer({
					event: DATA_LAYER_EVENTS.CLICK_BUTTON,
					button_name: 'newsletter_continue'
				})
				handleCancelModal()
			}}
			title={t('loc:Čo je nové v Notino Partner aplikácii?')}
			version={userChangelogs.data.changelog.version}
			content={{ mainFeatures: userChangelogs.data.changelog.mainFeatures, otherFeatures: { description: userChangelogs.data.changelog.otherFeatures } }}
			buttonLabel={t('loc:Pokračovať')}
		/>
	) : null
}

export default WhatsNew
