import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Collapse, Row, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Action, compose } from 'redux'
import { ColumnsType } from 'antd/lib/table'
import { useNavigate } from 'react-router'
import { destroy, initialize } from 'redux-form'
import cx from 'classnames'
import { ThunkDispatch } from 'redux-thunk'

// components
import i18next from 'i18next'
import CustomTable from '../../components/CustomTable'
import Breadcrumbs from '../../components/Breadcrumbs'
import FlagIcon from '../../components/FlagIcon'
import DocumentsForm from './components/DocumentsForm'
import CustomPagination from '../../components/CustomPagination'

// utils
import { ADMIN_PERMISSIONS, ASSET_TYPE, FORM, LANGUAGE } from '../../utils/enums'
import { postReq } from '../../utils/request'
import { withPermissions } from '../../utils/Permissions'
import { LocalizedDateTime, getLanguageCode, getLocale } from '../../utils/intl'
import { getExpandIcon, getRelativeTimeValue } from '../../utils/helper'

// reducers
import { getAssetTypes, getDocumentsGroupedByAssetTypes, IDocumentsGroupedByAssetTypesPayload, setDocumentsActiveKeys } from '../../reducers/documents/documentActions'
import { getCurrentUser } from '../../reducers/users/userActions'

// types
import { IBreadcrumbs, IDocumentForm, PostUrls, RequestPayload } from '../../types/interfaces'
import { RootState } from '../../reducers'
import { IDocumentsAssetTypesPageURLQueryParams } from '../../types/schemaTypes'

// assets
import UploadIcon from '../../assets/icons/upload-icon.svg?react'
import RefreshIcon from '../../assets/icons/refresh-icon.svg?react'

// hooks
import useQueryParams, { formatObjToQuery } from '../../hooks/useQueryParamsZod'

// schemas
import { documentsPageURLQueryParamsSchema } from '../../schemas/queryParams'

type DocumentAssetTypeItem = NonNullable<IDocumentsGroupedByAssetTypesPayload['data']>['assetTypes'][0]
type DocumentLanguageItem = DocumentAssetTypeItem['languages'][0]
type PostRequestDocumentBody = RequestPayload<PostUrls['/api/b2b/admin/documents/']>

type TableProps = {
	assetType: DocumentAssetTypeItem
	onBtnClick: () => void
}

const getTableColumns = (assetType: DocumentAssetTypeItem, onBtnClick: () => void, dispatch: ThunkDispatch<RootState, any, Action>): ColumnsType<DocumentLanguageItem> => [
	{
		title: i18next.t('loc:Jazyk'),
		dataIndex: 'languageCode',
		key: 'languageCode',
		width: '33.333%',
		ellipsis: true,
		render: (_value, record) => {
			const isEmpty = !record.document?.files.length
			return (
				<div className={cx('flex items-center gap-2 text-notino-black', { 'opacity-50': isEmpty })}>
					<FlagIcon language={getLanguageCode(record.languageCode.toLowerCase())} />
					{getLocale(record.languageCode).countryCode.toLowerCase()}
					{!isEmpty && <span className={'truncate text-xxs text-notino-grayDarker'}>{i18next.t('loc:pozrieť detail')}</span>}
				</div>
			)
		}
	},
	{
		title: i18next.t('loc:Dokument nahratý pred'),
		dataIndex: ['document', 'lastFileUpdatedAt'],
		key: 'createdAt',
		width: '33.333%',
		ellipsis: true,
		render: (_value, record) =>
			record.document?.lastFileUpdatedAt ? (
				<span
					className={
						'text-xxs leading-3 font-medium h-4 px-2 min-w-11 justify-center inline-flex items-center truncate rounded-full bg-notino-grayLight text-notino-gray-darker'
					}
				>
					{getRelativeTimeValue(record.document.lastFileUpdatedAt)}
				</span>
			) : null
	},
	{
		title: i18next.t('loc:Dátum poslednej aktualizácie'),
		dataIndex: ['document', 'lastFileUpdatedAt'],
		key: 'lastFileUpdatedAt',
		className: 'text-xs',
		width: '33.333%',
		ellipsis: true,
		render: (_value, record) => <LocalizedDateTime date={record.document?.lastFileUpdatedAt} ellipsis />
	},
	{
		fixed: 'right',
		width: '240px',
		render(_value, record) {
			const isEmpty = !record.document?.files.length
			return (
				<Button
					onClick={(e) => {
						e.stopPropagation()
						onBtnClick()
						dispatch(
							initialize(FORM.DOCUMENTS_FORM, {
								assetType: {
									key: assetType.key,
									value: assetType.key,
									label: assetType.name,
									extra: {
										mimeTypes: assetType.mimeTypes,
										fileType: assetType.fileType,
										maxFilesCount: assetType.maxFilesCount
									}
								},
								languageCode: record.languageCode,
								id: record.document?.id
							})
						)
					}}
					type='primary'
					htmlType='button'
					size={'small'}
					className={'noti-btn w-full'}
					icon={isEmpty ? <UploadIcon className={'w-4 h-4'} /> : <RefreshIcon className={'w-4 h-4'} />}
				>
					<span className={'pl-4'}>{isEmpty ? i18next.t('loc:Nahrať dokument') : i18next.t('loc:Aktualizovať dokument')}</span>
				</Button>
			)
		}
	}
]

const AssetTypeLanguagesTable = (props: TableProps) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { assetType, onBtnClick } = props

	return (
		<CustomTable<DocumentLanguageItem>
			className={'table-fixed'}
			wrapperClassName={'overflow-hidden noti-collapse-panel-table bordered'}
			columns={getTableColumns(assetType, onBtnClick, dispatch)}
			dataSource={assetType.languages}
			pagination={false}
			rowKey={'languageCode'}
			rowClassName={(record) => cx('h-10', { 'clickable-row': record.document?.files.length })}
			scroll={{ x: 800 }}
			onRow={(record) => ({
				onClick: () => {
					if (record.document?.files.length) {
						navigate({
							pathname: i18next.t('paths:documents/{{assetType}}', { assetType: assetType.key }),
							search: formatObjToQuery<IDocumentsAssetTypesPageURLQueryParams>({
								languageCode: record.languageCode
							})
						})
					}
				}
			})}
		/>
	)
}

const DocumentsPage = () => {
	const dispatch = useDispatch()
	const [t] = useTranslation()
	const documents = useSelector((state: RootState) => state.documents.documentsGroupedByAssetTypes)
	const [visible, setVisible] = useState(false)
	const isLoading = documents?.isLoading

	const [query, setQuery] = useQueryParams(documentsPageURLQueryParamsSchema, {
		page: 1,
		limit: 10
	})

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Prehľad dokumentov')
			}
		]
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === documents?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}

	const fetchData = useCallback(async () => {
		dispatch(getDocumentsGroupedByAssetTypes({ limit: query.limit, page: query.page }))
	}, [dispatch, query.limit, query.page])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	useEffect(() => {
		dispatch(getAssetTypes())
	}, [dispatch])

	const fileUploadSubmit = async (values: IDocumentForm) => {
		try {
			const fileIDs = values.files.map((item: { id: string }) => item.id)

			let reqBody: PostRequestDocumentBody = {
				languageCode: values.languageCode as LANGUAGE,
				fileIDs,
				message: (values.message as any) || null,
				assetType: values.assetType.value as ASSET_TYPE
			}

			if (values.createNotification && values.notificationTitle && values.notificationContent) {
				reqBody = {
					...reqBody,
					dynamicNotification: {
						title: values.notificationTitle,
						content: values.notificationContent
					}
				}
			}

			await postReq('/api/b2b/admin/documents/', { params: {}, reqBody })
			setVisible(false)
			dispatch(destroy(FORM.DOCUMENTS_FORM))
			await fetchData()
			// get current user to reload notifications in my profile menu
			dispatch(getCurrentUser())
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	return (
		<>
			<Spin spinning={isLoading}>
				<Row>
					<Breadcrumbs breadcrumbs={breadcrumbs} backButtonPath={t('paths:index')} />
				</Row>
				<h2 className={'text-2xl mb-4 mt-8'}>{t('loc:Prehľad dokumentov')}</h2>
				<div className={'content-body transparent-background'}>
					<div className={'noti-collapse-panel-table-wrapper'}>
						<Collapse
							bordered={false}
							activeKey={documents.activeKeys}
							onChange={(newKeys) => dispatch(setDocumentsActiveKeys(typeof newKeys === 'string' ? [newKeys] : newKeys))}
							expandIcon={(panelProps) => getExpandIcon(!!panelProps.isActive)}
							items={documents.data?.assetTypes?.map((assetType) => {
								return {
									key: assetType.key,
									id: assetType.key,
									className: 'panel panel-subpanel',
									label: <h4>{assetType.name}</h4>,
									children: <AssetTypeLanguagesTable key={assetType.key} onBtnClick={() => setVisible(true)} assetType={assetType} />
								}
							})}
						/>
					</div>
					<div className={'content-footer collapsed-tables-list-footer'} id={'content-footer-container'}>
						<CustomPagination
							pageSize={documents?.data?.pagination?.limit}
							total={documents?.data?.pagination?.totalCount}
							current={documents?.data?.pagination?.page}
							onChange={onChangePagination}
							disabled={documents?.isLoading}
							showSizeChanger={false}
						/>
					</div>
				</div>
			</Spin>
			<DocumentsForm visible={visible} setVisible={setVisible} onSubmit={fileUploadSubmit} />
		</>
	)
}
export default compose(withPermissions([...ADMIN_PERMISSIONS]))(DocumentsPage)
