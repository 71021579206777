// eslint-disable-next-line import/prefer-default-export
export enum DOCUMENTS {
	DOCUMENTS_LOAD_START = 'DOCUMENTS_LOAD_START',
	DOCUMENTS_LOAD_DONE = 'DOCUMENTS_LOAD_DONE',
	DOCUMENTS_LOAD_FAIL = 'DOCUMENTS_LOAD_FAIL'
}

export enum DOCUMENTS_BY_ASSET_TYPE {
	DOCUMENTS_BY_ASSET_TYPE_LOAD_START = 'DOCUMENTS_BY_ASSET_TYPE_LOAD_START',
	DOCUMENTS_BY_ASSET_TYPE_LOAD_DONE = 'DOCUMENTS_BY_ASSET_TYPE_LOAD_DONE',
	DOCUMENTS_BY_ASSET_TYPE_LOAD_FAIL = 'DOCUMENTS_BY_ASSET_TYPE_LOAD_FAIL'
}

export enum ASSET_TYPES {
	ASSET_TYPES_LOAD_START = 'ASSET_TYPES_LOAD_START',
	ASSET_TYPES_LOAD_DONE = 'ASSET_TYPES_LOAD_DONE',
	ASSET_TYPES_LOAD_FAIL = 'ASSET_TYPES_LOAD_FAIL'
}

export enum DOCUMENTS_GROUPED_BY_ASSET_TYPES {
	DOCUMENTS_GROUPED_BY_ASSET_TYPES_LOAD_START = 'DOCUMENTS_GROUPED_BY_ASSET_TYPES_LOAD_START',
	DOCUMENTS_GROUPED_BY_ASSET_TYPES_LOAD_DONE = 'DOCUMENTS_GROUPED_BY_ASSET_TYPES_LOAD_DONE',
	DOCUMENTS_GROUPED_BY_ASSET_TYPES_LOAD_FAIL = 'DOCUMENTS_GROUPED_BY_ASSET_TYPES_LOAD_FAIL'
}

export const SET_DOCUMENTS_ACTIVE_KEYS = 'SET_DOCUMENTS_ACTIVE_KEYS'
