import React, { useState } from 'react'
import { Field, Fields, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Row, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import cx from 'classnames'

// utils
import {
	OPEN_ASYNC_JOB_MODAL_BUTTON_ID,
	ASYNC_JOB_TYPE,
	CHANGE_DEBOUNCE_TIME,
	FIELD_MODE,
	FORM,
	PERMISSION,
	RESERVATION_PAYMENT_METHOD,
	RESERVATION_PAYMENT_METHODS,
	RESERVATION_SOURCE_TYPE,
	RESERVATION_SOURCE_TYPES,
	RESERVATION_STATE,
	RESERVATION_STATES,
	ROW_GUTTER_X_M,
	VALIDATION_MAX_LENGTH
} from '../../../utils/enums'
import {
	checkFiltersSizeWithoutSearch,
	formFieldID,
	optionRenderWithIcon,
	optionRenderWithImage,
	translateReservationSourceType,
	translateReservationPaymentMethod,
	translateReservationState
} from '../../../utils/helper'
import Permissions from '../../../utils/Permissions'

// atoms
import SelectField from '../../../atoms/SelectField'
import InputField from '../../../atoms/InputField'
import DateRangeField from '../../../atoms/DateRangeField'

// components
import Filters from '../../../components/Filters'
import AsyncJobsModal from '../../../components/AsyncJobsModal/AsyncJobsModal'

// types
import { RootState } from '../../../reducers'
import { INotinoReservationsFilter, ISelectOptionItem } from '../../../types/interfaces'

// assets
import GlobeIcon from '../../../assets/icons/globe-icon.svg?react'
import CategoryIcon from '../../../assets/icons/categories-icon.svg?react'
import FilesIcon from '../../../assets/icons/files-icon.svg?react'

// types
import { INotinoReservationsPageURLQueryParams } from '../../../types/schemaTypes'

type ComponentProps = {
	query: INotinoReservationsPageURLQueryParams
	loading: boolean
}

type Props = InjectedFormProps<INotinoReservationsFilter, ComponentProps> & ComponentProps

const NotinoReservationsFilter = (props: Props) => {
	const [t] = useTranslation()

	const { handleSubmit, query, loading } = props

	const config = useSelector((state: RootState) => state.config.config)
	const formValues: Partial<INotinoReservationsFilter> | undefined = useSelector((state: RootState) => getFormValues(FORM.NOTINO_RESERVATIONS_FILTER)(state))
	const categories = useSelector((state: RootState) => state.categories.categories)
	const totalCount = useSelector((state: RootState) => state.paginatedReservations.notinoReservations.data?.pagination.totalCount)

	const [reportModalVisible, setReportModalVisible] = useState(false)

	const RESERVATION_PAYMENT_METHOD_OPTIONS: ISelectOptionItem[] = RESERVATION_PAYMENT_METHODS.map((item) => ({
		key: item,
		value: item,
		label: translateReservationPaymentMethod(item as RESERVATION_PAYMENT_METHOD).text,
		icon: translateReservationPaymentMethod(item as RESERVATION_PAYMENT_METHOD).icon
	}))

	const RESERVATION_SOURCE_TYPE_OPTIONS: ISelectOptionItem[] = RESERVATION_SOURCE_TYPES.map((item) => ({
		key: item,
		value: item,
		label: translateReservationSourceType(item as RESERVATION_SOURCE_TYPE).text
	}))

	const RESERVATION_STATE_OPTIONS: ISelectOptionItem[] = RESERVATION_STATES.map((item) => ({
		key: item,
		value: item,
		label: translateReservationState(item as RESERVATION_STATE).text,
		icon: translateReservationState(item as RESERVATION_STATE).icon
	}))

	const showReportDisabledBtnTooltip = !totalCount
	const disabledReportButton = showReportDisabledBtnTooltip || loading

	return (
		<>
			<Form layout='horizontal' onSubmitCapture={handleSubmit} className={'pt-0'}>
				<Filters
					search={
						<Field
							className={'h-10'}
							component={InputField}
							size={'large'}
							placeholder={t('loc:Hľadať podľa názvu alebo ID')}
							name={'search'}
							fieldMode={FIELD_MODE.FILTER}
							search
							maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
						/>
					}
					activeFilters={checkFiltersSizeWithoutSearch(formValues)}
					form={FORM.NOTINO_RESERVATIONS_FILTER}
					customContent={
						<Permissions
							allowed={[PERMISSION.NOTINO]}
							render={(hasPermission, { openForbiddenModal }) => (
								<Tooltip title={showReportDisabledBtnTooltip ? t('loc:Report nie je možné vygenerovať z prázdneho zoznamu') : null}>
									{/* Workaround for disabled button inside tooltip: https://github.com/react-component/tooltip/issues/18 */}
									<span className={cx({ 'cursor-not-allowed': disabledReportButton })}>
										<Button
											onClick={() => {
												if (hasPermission) {
													setReportModalVisible(true)
												} else {
													openForbiddenModal()
												}
											}}
											type='primary'
											htmlType='button'
											className={cx('noti-btn', {
												'pointer-events-none': disabledReportButton
											})}
											icon={<FilesIcon />}
											disabled={disabledReportButton}
											id={formFieldID(FORM.NOTINO_RESERVATIONS_FILTER, OPEN_ASYNC_JOB_MODAL_BUTTON_ID(ASYNC_JOB_TYPE.EXPORT_RESERVATIONS))}
										>
											{t('loc:Generovať report')}
										</Button>
									</span>
								</Tooltip>
							)}
						/>
					}
				>
					<Row gutter={ROW_GUTTER_X_M}>
						<Col span={8}>
							<Fields
								label={t('loc:Dátum vytvorenia rezervácie')}
								names={['createdAtFrom', 'createdAtTo']}
								placeholders={[t('loc:od'), t('loc:do')]}
								component={DateRangeField}
								size={'large'}
							/>
						</Col>
						<Col span={8}>
							<Fields
								label={t('loc:Dátum plánovanej rezervácie')}
								names={['dateFrom', 'dateTo']}
								placeholders={[t('loc:od'), t('loc:do')]}
								component={DateRangeField}
								size={'large'}
							/>
						</Col>
						<Col span={8}>
							<Field
								component={SelectField}
								optionRender={(itemData: any) => optionRenderWithImage(itemData, <GlobeIcon />)}
								name={'countryCode'}
								placeholder={t('loc:Krajina')}
								className={'pb-0 mt-4'}
								allowClear
								size={'large'}
								filterOptions
								onDidMountSearch
								options={config.optionsRolloutCountries}
								loading={config.isLoading}
								disabled={config.isLoading}
							/>
						</Col>
						<Col span={8}>
							<Field
								component={SelectField}
								name={'categoryFirstLevelIDs'}
								mode={'multiple'}
								placeholder={t('loc:Odvetvie')}
								allowClear
								size={'large'}
								filterOptions
								onDidMountSearch
								optionRender={(itemData: any) => optionRenderWithImage(itemData, <CategoryIcon />)}
								options={categories?.enumerationsOptions}
								loading={categories?.isLoading}
								disabled={categories?.isLoading}
							/>
						</Col>
						<Col span={6}>
							<Field
								component={SelectField}
								optionRender={(itemData: any) => optionRenderWithIcon(itemData)}
								mode={'multiple'}
								name={'reservationStates'}
								placeholder={t('loc:Stav')}
								allowClear
								showSearch={false}
								size={'large'}
								options={RESERVATION_STATE_OPTIONS}
							/>
						</Col>
						<Col span={6}>
							<Field
								component={SelectField}
								mode={'multiple'}
								name={'reservationPaymentMethods'}
								placeholder={t('loc:Spôsob úhrady')}
								optionRender={(itemData: any) => optionRenderWithIcon(itemData)}
								allowClear
								showSearch={false}
								size={'large'}
								options={RESERVATION_PAYMENT_METHOD_OPTIONS}
							/>
						</Col>
						<Col span={4}>
							<Field
								component={SelectField}
								showSearch={false}
								name={'reservationCreateSourceType'}
								placeholder={t('loc:Vytvoril')}
								allowClear
								size={'large'}
								options={RESERVATION_SOURCE_TYPE_OPTIONS}
							/>
						</Col>
					</Row>
				</Filters>
				<AsyncJobsModal
					jobType={ASYNC_JOB_TYPE.EXPORT_RESERVATIONS}
					visible={reportModalVisible}
					setVisible={setReportModalVisible}
					query={query}
					sourceTypeOptions={RESERVATION_SOURCE_TYPE_OPTIONS}
					paymentMethodOptions={RESERVATION_PAYMENT_METHOD_OPTIONS}
					reservationStatesOptions={RESERVATION_STATE_OPTIONS}
					industriesOptions={categories?.enumerationsOptions || []}
					countriesOptions={config.optionsRolloutCountries}
				/>
			</Form>
		</>
	)
}

const form = reduxForm<INotinoReservationsFilter, ComponentProps>({
	form: FORM.NOTINO_RESERVATIONS_FILTER,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, CHANGE_DEBOUNCE_TIME),
	destroyOnUnmount: true
})(NotinoReservationsFilter)

export default form
