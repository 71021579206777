import React, { useEffect } from 'react'
import { DatePicker, Empty, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router'
import { compose } from 'redux'

// components
import CustomTable from '../../components/CustomTable'
import TabsComponent from '../../components/TabsComponent'
import ReservationsDashboardTab from './components/ReservationsDashboardTab'

// redux
import { RootState } from '../../reducers'
import { getNotinoDashboard } from '../../reducers/dashboard/dashboardActions'

// utils
import { DASHBOARD_TAB_KEYS, PERMISSION } from '../../utils/enums'
import { LineContentFunction, TimeStatsFilterFunction } from './dashboardUtils'
import { withPermissions } from '../../utils/Permissions'

// schema
import SalonsDashboardTab from './components/SalonsDashboardTab'

// dynamic imports
const LineChart = React.lazy(() => import('../../components/LineChart'))

const lineContent: LineContentFunction = (label, source, filter, columns) => {
	return (
		<div className='statistics-box py-4 px-6 md:py-8 md:px-12 mt-12'>
			<div className='flex justify-between w-full items-center gap-2'>
				<h4>{label}</h4>
				{filter}
			</div>
			<div className='mt-4'>
				<div className='h-40'>
					<Spin spinning={source.isLoading}>
						{source.isFailure ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={i18next.t('loc:Chyba')} /> : <LineChart source={source} />}
					</Spin>
				</div>
				<CustomTable
					className='mt-8'
					columns={columns}
					twoToneRows
					pagination={false}
					dataSource={source.data?.columns}
					rowKey={(record) => {
						return record.type
					}}
					scroll={{ x: 400 }}
				/>
			</div>
		</div>
	)
}

const now = dayjs()

const timeStatsFilter: TimeStatsFilterFunction = (handleChange, dateFormat, id, loading = false) => (
	<div id={id}>
		<DatePicker
			onChange={(date, dateString) => handleChange(date, typeof dateString === 'string' ? dateString : dateString[0])}
			picker={dateFormat ? 'month' : 'year'}
			size={'middle'}
			defaultValue={now}
			allowClear={false}
			format={dateFormat}
			disabled={loading}
			disabledDate={(date) => dayjs(date).year() < 2022}
			popupAlign={{ points: ['tr', 'br'] }}
			getPopupContainer={(node) => node.parentElement || document.body}
		/>
	</div>
)

type Props = {
	tabKey: DASHBOARD_TAB_KEYS
}

const NotinoDashboard = (props: Props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { tabKey } = props

	const { notino, salonsAnnualStats, salonsMonthStats, reservationsStats } = useSelector((state: RootState) => state.dashboard)
	const selectedCountry = useSelector((state: RootState) => state.selectedCountry.selectedCountry)

	useEffect(() => {
		dispatch(getNotinoDashboard(selectedCountry))
	}, [dispatch, selectedCountry])

	const onTabChange = (selectedTabKey: string) => {
		if (selectedTabKey === DASHBOARD_TAB_KEYS.SALONS_DASHBOARD) {
			navigate(t('paths:index'))
		}
		if (selectedTabKey === DASHBOARD_TAB_KEYS.RESERVATIONS_DASHBOARD) {
			navigate(t('paths:reservations-dashboard'))
		}
	}

	return (
		<TabsComponent
			className={'box-tab'}
			activeKey={tabKey}
			onChange={onTabChange}
			items={[
				{
					key: DASHBOARD_TAB_KEYS.SALONS_DASHBOARD,
					label: t('loc:Stav salónov'),
					children: (
						<SalonsDashboardTab
							timeStatsFilter={timeStatsFilter}
							lineContent={lineContent}
							notinoData={notino}
							salonsMonthStats={salonsMonthStats}
							salonsAnnualStats={salonsAnnualStats}
							selectedCountry={selectedCountry}
						/>
					)
				},
				{
					key: DASHBOARD_TAB_KEYS.RESERVATIONS_DASHBOARD,
					label: t('loc:Rezervačný systém'),
					children: (
						<ReservationsDashboardTab
							selectedCountry={selectedCountry}
							notinoData={notino}
							timeStatsFilter={timeStatsFilter}
							reservationsStatsData={reservationsStats}
							lineContent={lineContent}
						/>
					)
				}
			]}
		/>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO]))(NotinoDashboard)
