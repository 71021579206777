import React from 'react'
import { ModalProps } from 'antd'
import { Trans, useTranslation } from 'react-i18next'

// components
import ConfirmModal from '../../../atoms/ConfirmModal'

// utils
import { CHANGELOG_PLATFORM_TRANSLATIONS, formFieldID } from '../../../utils/helper'
import { CHANGELOG_PLATFORM, CONFIRM_SUBMIT_BUTTON_ID, FORM } from '../../../utils/enums'

export type ErrorReport = {
	mainFeaturesCount: number
	hasOtherFeatures: boolean
}

export type ConfirmSubmitModalProps = {
	open: boolean
	onCancel: ModalProps['onCancel']
	platform: CHANGELOG_PLATFORM
	onSubmit: Function
	isSubmitting?: boolean
}

const ConfirmSubmitModal = (props: ConfirmSubmitModalProps) => {
	const { open, onCancel, platform, isSubmitting, onSubmit } = props
	const [t] = useTranslation()

	return (
		<ConfirmModal
			open={open}
			onOk={() => onSubmit()}
			disabled={isSubmitting}
			onCancel={onCancel}
			maskClosable
			keyboard
			closable={false}
			title={t('loc:Publikovať What’s New')}
			okButtonProps={{ id: formFieldID(FORM.CHANGELOG, CONFIRM_SUBMIT_BUTTON_ID) }}
		>
			<p className={'text-notino-grayDark text-sm font-normal mb-0'}>
				<Trans
					defaults={t(
						'loc:Ste si istý, že chcete publikovať upozornenie pre <strong>{{ platform }}</strong>? Pred publikovaním prosím skontrolujte obsah upozornenia v náhľade.'
					)}
					components={{ strong: <strong className={'text-notino-black font-semibold'} /> }}
					values={{ platform: CHANGELOG_PLATFORM_TRANSLATIONS()[platform] }}
				/>
			</p>
		</ConfirmModal>
	)
}

export default ConfirmSubmitModal
