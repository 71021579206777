import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { useNavigate } from 'react-router-dom'
import { ColumnsType, TableProps } from 'antd/es/table'
import i18next from 'i18next'

// components
import CustomTable from '../../components/CustomTable'
import NotificationsFilter from './components/DynamicNotificationsFilter'

// utils
import { FORM } from '../../utils/enums'
import { LocalizedDateTime } from '../../utils/intl'
import { getStringifiedLanguages, normalizeDirectionKeys, setOrder } from '../../utils/helper'

// reducers
import { RootState } from '../../reducers'
import { getDynamicNotifications, IDynamicNotificationsPayload } from '../../reducers/dynamicNotifications/dynamicNotificationsActions'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// schema
import { dynamicNotificationsPageURLQueryParams } from '../../schemas/queryParams'

// assets
import BellIcon from '../../assets/icons/bell-icon.svg?react'

// types
import { IDynamicNotificationsFilter } from '../../types/interfaces'

type TableDataItem = NonNullable<IDynamicNotificationsPayload['data']>['dynamicNotifications'][0]

const DynamicNotificationsTab = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const dynamicNotifications = useSelector((state: RootState) => state.dynamicNotifications.dynamicNotifications)
	const navigate = useNavigate()

	const [query, setQuery] = useQueryParams(dynamicNotificationsPageURLQueryParams, {
		page: 1,
		order: 'createdAt:DESC'
	})

	useEffect(() => {
		dispatch(
			initialize(FORM.DYNAMIC_NOTIFICATIONS_FILTER, {
				language: query.language
			})
		)
		dispatch(
			getDynamicNotifications({
				language: query.language,
				page: query.page,
				order: query.order,
				limit: query.limit
			})
		)
	}, [dispatch, query.order, query.language, query.limit, query.page])

	const handleSubmit = (values: IDynamicNotificationsFilter) => {
		const newQuery = {
			...query,
			...values,
			page: 1
		}
		setQuery(newQuery)
	}

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}`
			const newQuery = {
				...query,
				limit: pagination.pageSize,
				page: pagination.current,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === dynamicNotifications?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}

	const columns: ColumnsType<TableDataItem> = [
		{
			title: <span />,
			key: 'icon',
			ellipsis: true,
			width: 40,
			className: 'flex items-center justify-center',
			render: () => {
				return <BellIcon className={'medium-icon'} />
			}
		},
		{
			title: t('loc:Názov'),
			dataIndex: 'titleLocalizations',
			key: 'titleLocalizations',
			ellipsis: true,
			width: '20%',
			render: (_value, record) => {
				const text = record.titleLocalizations?.find((localization) => localization.language === i18next.language)?.value

				return text || record.titleLocalizations[0].value
			}
		},
		{
			title: t('loc:Text upozornenia'),
			dataIndex: 'contentLocalizations',
			key: 'contentLocalizations',
			ellipsis: true,
			width: '40%',
			render: (_value, record) => {
				const text = record.contentLocalizations?.find((localization) => localization.language === i18next.language)?.value

				return (
					<Typography.Paragraph
						ellipsis={{
							rows: 1,
							tooltip: true
						}}
						className={'mb-0'}
					>
						{text || record.contentLocalizations[0].value}
					</Typography.Paragraph>
				)
			}
		},
		{
			title: t('loc:Jazyky'),
			dataIndex: 'sentInLanguages',
			key: 'sentInLanguages',
			ellipsis: true,
			width: '20%',
			render: (value, record) => {
				return getStringifiedLanguages(record.sentInLanguages)
			}
		},
		{
			title: t('loc:Dátum odoslania'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			width: '20%',
			sortOrder: setOrder(query.order, 'createdAt'),
			render: (_value, record) => <LocalizedDateTime date={record.createdAt} timeStyle={null} ellipsis />
		}
	]

	return (
		<>
			<NotificationsFilter onSubmit={handleSubmit} />
			<CustomTable<TableDataItem>
				className='table-fixed'
				columns={columns}
				rowKey={'id'}
				rowClassName={'clickable-row'}
				onChange={onChangeTable}
				loading={dynamicNotifications?.isLoading}
				dataSource={dynamicNotifications.data?.dynamicNotifications}
				onRow={(record) => ({
					onClick: () => {
						navigate({
							pathname: t('paths:notifications/{{dynamicNotificationID}}', { dynamicNotificationID: record.id })
						})
					}
				})}
				twoToneRows
				scroll={{ x: 800 }}
				useCustomPagination
				pagination={{
					pageSize: dynamicNotifications.data?.pagination.limit,
					total: dynamicNotifications.data?.pagination.totalCount,
					current: dynamicNotifications.data?.pagination.page,
					onChange: onChangePagination,
					disabled: dynamicNotifications.isLoading
				}}
			/>
		</>
	)
}

export default DynamicNotificationsTab
