// types
import { IResetStore } from '../generalTypes'

// utils
// eslint-disable-next-line import/no-cycle
import { getReq } from '../../utils/request'
import { getLanguageCode } from '../../utils/intl'
import { SUPPORT_CONTACTS, SUPPORT_CONTACT, SET_SUPPORT_CONTACT_OPTIONS } from './supportContactsTypes'
import { DEFAULT_LANGUAGE } from '../../utils/enums'
import i18n from '../../utils/i18n'

// types
import { GetUrls, ISelectOptionItem, RequestResponse, SupportContactSelectOptionItem } from '../../types/interfaces'
import { IGetSupportContactsQueryParams } from '../../types/schemaTypes'
import { ThunkResult } from '../index'

export type ISupportContactsActions = IResetStore | IGetSupportContacts | IGetSupportContact | IGetSupportContactsOptions

interface IGetSupportContacts {
	type: SUPPORT_CONTACTS
	payload: ISupportContactsPayload
}

export interface IGetSupportContact {
	type: SUPPORT_CONTACT
	payload: ISupportContactPayload
}

export interface IGetSupportContactsOptions {
	type: typeof SET_SUPPORT_CONTACT_OPTIONS
	payload: ISupportContactOptionsPayload
}

export interface ISupportContactsTableData {
	key: string
	supportContactID: string
	country: {
		name?: string
		flag?: string
		code: string
	}
	city: string
	street: string
	streetNumber: string
	zipCode: string
}

export interface ISupportContactsPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/enums/support-contacts/']> | null
	tableData?: ISupportContactsTableData[]
	options: ISelectOptionItem[]
}

export interface ISupportContactPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/enums/support-contacts/{supportContactID}']> | null
}

export interface ISupportContactOptionsPayload {
	options: SupportContactSelectOptionItem[]
}

export const getSupportContactsOptions =
	(currentLng = DEFAULT_LANGUAGE, data: RequestResponse<GetUrls['/api/b2b/admin/enums/support-contacts/']> | null): ThunkResult<Promise<ISupportContactOptionsPayload>> =>
	async (dispatch) => {
		let payload = {} as ISupportContactOptionsPayload

		const options: SupportContactSelectOptionItem[] =
			data?.supportContacts?.map((item) => {
				const countryTranslation = item.country.nameLocalizations.find((translation: any) => translation.language === currentLng)

				return {
					key: item.id,
					label: countryTranslation?.value || item.country.code,
					value: item.id,
					extra: {
						image: item.country.flag,
						countryCode: item.country.code
					}
				}
			}) || []

		payload = {
			options
		}

		dispatch({ type: SET_SUPPORT_CONTACT_OPTIONS, payload })

		return payload
	}

export const getSupportContacts =
	(queryParams?: IGetSupportContactsQueryParams): ThunkResult<Promise<ISupportContactsPayload>> =>
	async (dispatch) => {
		let payload = {} as ISupportContactsPayload
		try {
			dispatch({ type: SUPPORT_CONTACTS.SUPPORT_CONTACTS_START })
			const { data } = await getReq('/api/b2b/admin/enums/support-contacts/', { params: { query: { countryCode: queryParams?.countryCode || undefined } }, reqBody: {} })
			const tableData: ISupportContactsTableData[] = data.supportContacts.map((item) => {
				const tableItem = {
					key: item.id,
					supportContactID: item.id,
					country: {
						name: item.country.name,
						flag: item.country?.flag,
						code: item.country?.code
					},
					city: item.address?.city || '-',
					street: item.address?.street || '-',
					streetNumber: item.address?.streetNumber || '-',
					zipCode: item.address?.zipCode || '-'
				}
				return tableItem
			})

			const supportContactOptions = await dispatch(getSupportContactsOptions(getLanguageCode(i18n.language), data))

			payload = {
				data,
				tableData,
				options: supportContactOptions?.options || []
			}

			dispatch({ type: SUPPORT_CONTACTS.SUPPORT_CONTACTS_DONE, payload })
		} catch (err) {
			dispatch({ type: SUPPORT_CONTACTS.SUPPORT_CONTACTS_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getSupportContact =
	(supportContactID?: string): ThunkResult<Promise<ISupportContactPayload>> =>
	async (dispatch) => {
		let payload = {} as ISupportContactPayload

		if (!supportContactID) {
			dispatch({ type: SUPPORT_CONTACT.SUPPORT_CONTACT_DONE, payload })
			return payload
		}

		try {
			dispatch({ type: SUPPORT_CONTACT.SUPPORT_CONTACT_START })
			const { data } = await getReq('/api/b2b/admin/enums/support-contacts/{supportContactID}', { params: { path: { supportContactID } }, reqBody: {} })

			payload = {
				data
			}
			dispatch({ type: SUPPORT_CONTACT.SUPPORT_CONTACT_DONE, payload })
		} catch (err) {
			dispatch({ type: SUPPORT_CONTACT.SUPPORT_CONTACT_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
