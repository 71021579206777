import React from 'react'
import { useTranslation } from 'react-i18next'
import { WrappedFieldArrayProps } from 'redux-form'
import { Button, Form } from 'antd'
import { useSelector } from 'react-redux'
import cx from 'classnames'

// components
import DeleteButton from '../components/DeleteButton'
import PhoneWithPrefixField from '../components/PhoneWithPrefixField'

// assets
import PlusIcon from '../assets/icons/plus-icon.svg?react'

// utils
import { formFieldID, getPrefixCountryCode } from '../utils/helper'
import { ADD_PHONE_TO_ARRAY_BUTTON_ID, SALON_DETAIL_FORM_SECTION, SET_OF_COUNTRIES } from '../utils/enums'

// types
import { RootState } from '../reducers'
import { ISalonForm } from '../types/interfaces'

const { Item } = Form

type Props = WrappedFieldArrayProps<ISalonForm[SALON_DETAIL_FORM_SECTION.CONTACT_INFO_SECTION]['phones'][0]> & {
	required?: boolean
	requiredAtLeastOne?: boolean
	disabled?: boolean
	style?: React.CSSProperties
	// 0 = infinite count
	maxCount?: number
	toCheck?: {
		changed: boolean
		addedFilesIDs: string[]
	}
	countries?: SET_OF_COUNTRIES
}

const PhoneArrayField = (props: Props) => {
	const {
		fields,
		disabled,
		required,
		requiredAtLeastOne,
		style,
		maxCount = 5,
		toCheck,
		countries,
		meta: { form }
	} = props
	const [t] = useTranslation()

	const optionsPhonePrefixesAll = useSelector((state: RootState) => state.config.config.optionsPhonePrefixesAll)
	const phonePrefixCountryCode = getPrefixCountryCode(optionsPhonePrefixesAll)
	const { name } = fields

	const buttonAdd = (
		<Button
			onClick={() => fields.push({ phonePrefixCountryCode })}
			icon={<PlusIcon className={'text-notino-black small-icon'} />}
			className={'noti-btn mt-2'}
			type={'default'}
			size={'small'}
			disabled={disabled}
			id={formFieldID(form, ADD_PHONE_TO_ARRAY_BUTTON_ID(name))}
		>
			{t('loc:Pridať telefón')}
		</Button>
	)

	return (
		<Item label={t('loc:Telefónne čísla')} required={required || requiredAtLeastOne} style={style}>
			<div className={cx('flex flex-col gap-4 w-full noti-phone-array-field', { 'to-check-changes': toCheck?.changed })}>
				{fields.map((field, index: number) => {
					const fieldValue = fields.get(index)
					const toCheckChanges = Array.isArray(toCheck?.addedFilesIDs) ? !!toCheck?.addedFilesIDs.find((phoneId) => phoneId === fieldValue.id) : false

					return (
						<div key={index} className={'flex gap-2'}>
							<PhoneWithPrefixField
								placeholder={t('loc:Zadajte telefón')}
								prefixName={`${field}.phonePrefixCountryCode`}
								phoneName={`${field}.phone`}
								size={'large'}
								disabled={disabled}
								className={'m-0 pb-0 w-full gap-2 noti-phone-prefix-array'}
								fallbackDefaultValue={phonePrefixCountryCode}
								toCheck={toCheckChanges}
								countries={countries}
								required
							/>

							<DeleteButton
								className={'bg-red-100 mt-2'}
								onClick={() => fields.remove(index)}
								onlyIcon
								smallIcon
								noConfirm
								size={'small'}
								disabled={disabled || fields.length === 1}
							/>
						</div>
					)
				})}
			</div>
			{maxCount ? fields.length < maxCount && buttonAdd : buttonAdd}
		</Item>
	)
}

export default PhoneArrayField
