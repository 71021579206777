/* eslint-disable import/no-cycle */
import { RESET_STORE } from '../generalTypes'
import { INotificationsActions, IUserNotificationsPayload } from './notificationsActions'
import { ILoadingAndFailure } from '../../types/interfaces'
import { USER_NOTIFICATIONS, SLICE_USER_NOTIFICATIONS, DELETE_USER_NOTIFICATION, MARK_USER_NOTIFICATION_AS_READ } from './notificationsTypes'

export const initState = {
	userNotifications: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IUserNotificationsPayload & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: INotificationsActions) => {
	switch (action.type) {
		// user notifications
		case USER_NOTIFICATIONS.USER_NOTIFICATIONS_LOAD_START:
			return {
				...state,
				userNotifications: {
					...state.userNotifications,
					isLoading: true
				}
			}
		case USER_NOTIFICATIONS.USER_NOTIFICATIONS_LOAD_FAIL:
			return {
				...state,
				userNotifications: {
					...initState.userNotifications,
					isFailure: true
				}
			}
		case USER_NOTIFICATIONS.USER_NOTIFICATIONS_LOAD_DONE:
			return {
				...state,
				userNotifications: {
					...initState.userNotifications,
					data: action.payload.data
				}
			}
		// update user notifications
		case SLICE_USER_NOTIFICATIONS:
		case DELETE_USER_NOTIFICATION:
		case MARK_USER_NOTIFICATION_AS_READ:
			return {
				...state,
				userNotifications: {
					...initState.userNotifications,
					data: action.payload.data
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
