/* eslint-disable import/no-cycle */
import { map } from 'lodash'
import { ACTIVE_EMPLOYEES, DELETED_EMPLOYEES, EMPLOYEE, EMPLOYEES, EMPLOYEE_SHIFTS } from './employeesTypes'

// utils
import { getReq } from '../../utils/request'
import { getAssignedUserLabel } from '../../utils/helper'

// types
import { IResetStore } from '../generalTypes'
import { IEmployeePayload, IEmployeesPayload, IDeletedEmployeesPayload, IActiveEmployeesPayload, IEmployeeShiftsPayload } from '../../types/interfaces'
import { IGetEmployeeShiftsQueryParams, IGetEmployeesQueryParams } from '../../types/schemaTypes'
import { ThunkResult } from '../index'

export type IEmployeesActions = IResetStore | IGetEmployees | IGetEmployee | IGetDeletedEmployees | IGetActiveEmployees | IGetEmployeeShifts

interface IGetDeletedEmployees {
	type: DELETED_EMPLOYEES
	payload: IDeletedEmployeesPayload
}

interface IGetActiveEmployees {
	type: ACTIVE_EMPLOYEES
	payload: IActiveEmployeesPayload
}

interface IGetEmployees {
	type: EMPLOYEES
	payload: IEmployeesPayload
}

interface IGetEmployee {
	type: EMPLOYEE
	payload: IEmployeePayload
}

interface IGetEmployeeShifts {
	type: EMPLOYEE_SHIFTS
	payload: IEmployeeShiftsPayload
}

export const getEmployees =
	(queryParams: IGetEmployeesQueryParams, disableDeletedEmployees?: boolean): ThunkResult<Promise<IEmployeesPayload>> =>
	async (dispatch) => {
		let payload = {} as IEmployeesPayload
		try {
			dispatch({ type: EMPLOYEES.EMPLOYEES_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/employees/', {
				params: {
					query: {
						page: queryParams.page,
						limit: queryParams.limit,
						order: queryParams.order,
						search: queryParams.search || undefined,
						serviceID: queryParams.serviceID || undefined,
						salonID: queryParams.salonID || undefined,
						accountState: queryParams.accountState || undefined
					}
				},
				reqBody: {}
			})
			const employeesOptions = map(data.employees, (employee) => {
				return {
					label: getAssignedUserLabel({
						firstName: employee.firstName,
						lastName: employee.lastName,
						email: employee.email,
						id: employee.id
					}),
					disabled: !!(disableDeletedEmployees && employee.deletedAt),
					value: employee.id,
					key: `${employee.id}-key`
				}
			})

			const tableData = map(data.employees, (employee) => ({
				...employee,
				key: employee.id
			}))

			payload = {
				data,
				options: employeesOptions,
				tableData
			}

			dispatch({ type: EMPLOYEES.EMPLOYEES_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: EMPLOYEES.EMPLOYEES_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getDeletedEmployees =
	(queryParams: IGetEmployeesQueryParams): ThunkResult<Promise<IDeletedEmployeesPayload>> =>
	async (dispatch) => {
		let payload = {} as IDeletedEmployeesPayload
		try {
			dispatch({ type: DELETED_EMPLOYEES.DELETED_EMPLOYEES_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/employees/', {
				params: {
					query: {
						page: queryParams.page,
						limit: queryParams.limit,
						order: queryParams.order,
						search: queryParams.search || undefined,
						serviceID: queryParams.serviceID || undefined,
						salonID: queryParams.salonID || undefined,
						accountState: queryParams.accountState || undefined,
						deleted: true
					}
				},
				reqBody: {}
			})

			const tableData = map(data.employees, (employee) => ({
				...employee,
				key: employee.id
			}))

			payload = {
				data,
				tableData
			}

			dispatch({ type: DELETED_EMPLOYEES.DELETED_EMPLOYEES_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: DELETED_EMPLOYEES.DELETED_EMPLOYEES_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getActiveEmployees =
	(queryParams: IGetEmployeesQueryParams): ThunkResult<Promise<IActiveEmployeesPayload>> =>
	async (dispatch) => {
		let payload = {} as IActiveEmployeesPayload
		try {
			dispatch({ type: ACTIVE_EMPLOYEES.ACTIVE_EMPLOYEES_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/employees/', {
				params: {
					query: {
						page: queryParams.page,
						limit: queryParams.limit,
						order: queryParams.order,
						search: queryParams.search || undefined,
						serviceID: queryParams.serviceID || undefined,
						salonID: queryParams.salonID || undefined,
						accountState: queryParams.accountState || undefined,
						deleted: false
					}
				},
				reqBody: {}
			})

			const employeesOptions = map(data.employees, (employee) => {
				return {
					label: getAssignedUserLabel({
						firstName: employee.firstName,
						lastName: employee.lastName,
						email: employee.email,
						id: employee.id
					}),
					value: employee.id,
					key: `${employee.id}-key`
				}
			})

			const tableData = map(data.employees, (employee) => ({
				...employee,
				key: employee.id
			}))

			payload = {
				data,
				tableData,
				options: employeesOptions
			}

			dispatch({ type: ACTIVE_EMPLOYEES.ACTIVE_EMPLOYEES_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: ACTIVE_EMPLOYEES.ACTIVE_EMPLOYEES_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

// reorder of active employees
export const reorderEmployees =
	(reorderedTableData: IEmployeesPayload['tableData']): ThunkResult<void> =>
	async (dispatch) => {
		const payload = {
			tableData: reorderedTableData
		}
		dispatch({ type: EMPLOYEES.EMPLOYEES_REORDER, payload })
	}

export const getEmployee =
	(employeeID: string): ThunkResult<Promise<IEmployeePayload>> =>
	async (dispatch) => {
		let payload = {} as IEmployeePayload
		try {
			dispatch({ type: EMPLOYEE.EMPLOYEE_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/employees/{employeeID}', { params: { path: { employeeID } }, reqBody: {} })
			payload = {
				data
			}
			dispatch({ type: EMPLOYEE.EMPLOYEE_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: EMPLOYEE.EMPLOYEE_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getEmployeeShifts =
	(params: IGetEmployeeShiftsQueryParams): ThunkResult<Promise<IEmployeeShiftsPayload>> =>
	async (dispatch) => {
		let payload = {} as IEmployeeShiftsPayload
		try {
			const { employeeID, ...query } = params
			dispatch({ type: EMPLOYEE_SHIFTS.EMPLOYEE_SHIFTS_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/employees/{employeeID}/calendar-events/single-and-bulk-shifts', { params: { path: { employeeID }, query }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: EMPLOYEE_SHIFTS.EMPLOYEE_SHIFTS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: EMPLOYEE_SHIFTS.EMPLOYEE_SHIFTS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
