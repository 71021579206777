export const NOTIFICATIONS_DROPDOWN_WRAPPER_ID = 'user-notifications-dropdown-wrapper'

export const formatNotificationsCount = (count: number) => (count >= 100 ? '99+' : `${count}`)

export type ActionsDropdownProps = {
	okProps: {
		onOk: () => void
		okText?: string
		okDisabled?: boolean
	}
	deleteProps: {
		onDelete: () => void
		deleteText?: string
		deleteDisabled?: boolean
	}
	disabled?: boolean
	id?: string
	type?: 'primary' | 'secondary'
	onOpenChange?: (open: boolean) => void
}

export type NotificationConfirmModalData = { action?: 'delete' | 'ok'; handler: Function }
