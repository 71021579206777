/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import cx from 'classnames'

type Props = {
	className?: string
}

const ReCaptchaTermsAndConditions = (props: Props) => {
	const [t] = useTranslation()
	const { className } = props

	const privacyPolicyLink = t('loc:googlePrivacyPolicy')
	const termsOfServiceLink = t('loc:googleTermsOfService')

	return (
		<p className={cx(className, 'text-notino-grayDark text-xs leading-4 mb-0')}>
			<Trans
				defaults={t('loc:Táto stránka je chránená systémom reCAPTCHA a platia preň <a1>pravidlá ochrany súkromia</a1> a <a2>zmluvné podmienky</a2> spoločnosti Google.')}
				components={{
					a1: <a href={privacyPolicyLink} target='_blank' rel='noreferrer' className={'underline text-notino-grayDark'} />,
					a2: <a href={termsOfServiceLink} target='_blank' rel='noreferrer' className={'underline text-notino-grayDark'} />
				}}
			/>
		</p>
	)
}

export default ReCaptchaTermsAndConditions
