// types
import { IResetStore } from '../generalTypes'
import { GetUrls, ISearchable, RequestResponse } from '../../types/interfaces'
import { IGetDynamicNotificationsQueryParams } from '../../types/schemaTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'

// utils
import { getReq } from '../../utils/request'
import { DYNAMIC_NOTIFICATION, DYNAMIC_NOTIFICATIONS } from './dynamicNotificationsTypes'

export type IDynamicNotificationsActions = IResetStore | IGetDynamicNotifications | IGetDynamicNotification

interface IGetDynamicNotifications {
	type: DYNAMIC_NOTIFICATIONS
	payload: IDynamicNotificationsPayload
}

interface IGetDynamicNotification {
	type: DYNAMIC_NOTIFICATION
	payload: IDynamicNotificationPayload
}

export interface IDynamicNotificationsPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/dynamic-notifications/']>> {}
export interface IDynamicNotificationPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/dynamic-notifications/{dynamicNotificationID}']> | null
}

export const getDynamicNotifications =
	(queryParams?: IGetDynamicNotificationsQueryParams): ThunkResult<Promise<IDynamicNotificationsPayload>> =>
	async (dispatch) => {
		let payload = {} as IDynamicNotificationsPayload

		try {
			dispatch({ type: DYNAMIC_NOTIFICATIONS.DYNAMIC_NOTIFICATIONS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/dynamic-notifications/', {
				params: {
					query: {
						page: queryParams?.page,
						limit: queryParams?.limit,
						order: queryParams?.order,
						language: (queryParams?.language as any) || undefined
					}
				},
				reqBody: {}
			})

			payload = { data }
			dispatch({ type: DYNAMIC_NOTIFICATIONS.DYNAMIC_NOTIFICATIONS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: DYNAMIC_NOTIFICATIONS.DYNAMIC_NOTIFICATIONS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getDynamicNotification =
	(dynamicNotificationID: string): ThunkResult<Promise<IDynamicNotificationPayload>> =>
	async (dispatch) => {
		let payload = {} as IDynamicNotificationPayload
		try {
			dispatch({ type: DYNAMIC_NOTIFICATION.DYNAMIC_NOTIFICATION_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/dynamic-notifications/{dynamicNotificationID}', { params: { path: { dynamicNotificationID } }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: DYNAMIC_NOTIFICATION.DYNAMIC_NOTIFICATION_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: DYNAMIC_NOTIFICATION.DYNAMIC_NOTIFICATION_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
