import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { PAGE } from '../utils/enums'

type Props = {
	pageEnum?: PAGE
	label?: string
	className?: string
	size?: 'small' | 'normal'
}
// Sluzi na conditionane renderovanie badgu s textom New podla toho co je setnute v localStoragi
const NewBadge = (props: Props) => {
	const [t] = useTranslation()
	const { pageEnum, size = 'normal', label = t('loc:Nové'), className } = props
	const hideBadge = pageEnum ? localStorage.getItem(`hide-${pageEnum}-badge`) : false

	return (
		<span
			className={cx(className, 'bg-notino-pink text-white rounded-full leading-none flex-shrink-0', {
				hide: hideBadge === 'true',
				'text-[10px] px-1 py-0-5': size === 'small',
				'px-3 py-1 text-sm': size === 'normal'
			})}
		>
			{label}
		</span>
	)
}

export default NewBadge
