import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Row, Spin, TableProps, Tooltip, Image, Tag } from 'antd'
import { compose } from 'redux'
import { useNavigate } from 'react-router'
import { initialize } from 'redux-form'
import { ColumnsType } from 'antd/es/table'

// reducers
import { RootState } from '../../reducers'
import { CustomServiceTableItem, getCategories, getCustomServices } from '../../reducers/categories/categoriesActions'

// components
import CustomTable from '../../components/CustomTable'
import CustomServicesFilter from './components/CustomServicesFilter'
import PopoverList from '../../components/PopoverList'
import PopConfirmComponent from '../../components/PopConfirmComponent'

// assets
import IconCheck from '../../assets/icons/checker-icon.svg?react'

// utils
import { ROW_GUTTER_X_DEFAULT, PERMISSION, FORM, ROW_BUTTON_WITH_ID, CUSTOM_SERVICES_ORDER } from '../../utils/enums'
import { withPermissions } from '../../utils/Permissions'
import { LocalizedDateTime } from '../../utils/intl'
import { patchReq } from '../../utils/request'
import { getLinkWithEncodedBackUrl, normalizeDirectionKeys, setOrder } from '../../utils/helper'

// schemas
import { customServicesPageURLQueryParams } from '../../schemas/queryParams'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// types
import { ICustomServicesFilter } from '../../types/interfaces'

const CustomServicesPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const selectedCountry = useSelector((state: RootState) => state.selectedCountry.selectedCountry)
	const config = useSelector((state: RootState) => state.config.config)
	const categories = useSelector((state: RootState) => state.categories)
	const { customServices } = categories

	const [submitting, setSubmitting] = useState(false)

	const isLoading = categories.categories.isLoading || categories.customServices.isLoading || submitting

	const [query, setQuery] = useQueryParams(customServicesPageURLQueryParams, {
		page: 1,
		order: CUSTOM_SERVICES_ORDER.CREATED_AT_DESC
	})

	const countryCode = query.countryCode || selectedCountry

	const fetchData = useCallback(async () => {
		const { data } = await dispatch(getCategories())
		dispatch(
			getCustomServices(
				{
					page: query.page,
					limit: query.limit,
					order: query.order,
					search: query.search,
					countryCode,
					serviceCreatedAtFrom: query.serviceCreatedAtFrom,
					serviceCreatedAtTo: query.serviceCreatedAtTo,
					categoryIDs: query.categoryIDs,
					checkedState: query.checkedState
				},
				data
			)
		)

		const initData: ICustomServicesFilter = {
			countryCode,
			search: query.search,
			serviceCreatedAtFrom: query.serviceCreatedAtFrom,
			serviceCreatedAtTo: query.serviceCreatedAtTo,
			categoryIDs: query.categoryIDs,
			checkedState: query.checkedState
		}

		dispatch(initialize(FORM.CUSTOM_SERVICES_FILTER, initData))
	}, [dispatch, query.page, query.limit, query.order, query.search, countryCode, query.serviceCreatedAtFrom, query.serviceCreatedAtTo, query.categoryIDs, query.checkedState])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const onChangeTable: TableProps<CustomServiceTableItem>['onChange'] = (_pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}` as CUSTOM_SERVICES_ORDER
			const newQuery = {
				...query,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page
		}
		setQuery(newQuery)
	}

	const handleSubmitFilter = (values: ICustomServicesFilter) => {
		const newQuery = {
			...query,
			...values,
			page: 1
		}
		setQuery(newQuery)
	}

	const validateCustomService = async (serviceID: string) => {
		try {
			setSubmitting(true)
			await patchReq('/api/b2b/admin/services/custom/validate', { params: {}, reqBody: { serviceID } })
			fetchData()
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error.message)
		} finally {
			setSubmitting(false)
		}
	}

	const columns: ColumnsType<CustomServiceTableItem> = [
		{
			title: t('loc:Salón'),
			className: 'heading-4 text-left',
			children: [
				{
					title: t('loc:Názov salónu'),
					dataIndex: ['salon', 'name'],
					key: 'salonName',
					ellipsis: true,
					width: '15%'
				},
				{
					title: t('loc:Adresa salónu'),
					dataIndex: ['salon', 'address'],
					key: 'address',
					ellipsis: true,
					width: '15%',
					render: (value: CustomServiceTableItem['salon']['address']) => {
						return value || '-'
					}
				},
				{
					title: t('loc:Krajina salónu'),
					dataIndex: ['salon', 'address', 'countryCode'],
					key: 'country',
					ellipsis: true,
					width: '15%',
					render: (_value, record) => {
						const value = record.salon.countryCode
						const country = config.data?.rolloutCountries.find((rolloutCountry) => rolloutCountry.code === value)
						const flag = country?.flag

						if (!flag) {
							return value || '-'
						}

						return (
							<div className={'flex items-center gap-1'}>
								<img src={flag} width={24} height={24} alt={value} className={'flex-shrink-0'} /> {country.name || country.code}
							</div>
						)
					}
				}
			]
		},
		{
			title: t('loc:Služba'),
			className: 'heading-4 text-left',
			children: [
				{
					title: t('loc:Názov služby'),
					dataIndex: ['customService', 'name'],
					key: 'serviceName',
					ellipsis: true,
					width: '15%'
				},
				{
					title: t('loc:Odvetvia'),
					dataIndex: ['customService', 'industries'],
					key: 'industries',
					width: '15%',
					render: (value: CustomServiceTableItem['customService']['industries']) => {
						if (!value.length) {
							return '-'
						}

						return (
							<div className={'flex flex-wrap items-center'}>
								{value.map((industry) => {
									return (
										<Tooltip key={industry.id} title={industry.name}>
											<Image
												src={industry.image}
												loading='lazy'
												width={32}
												height={32}
												className='pr-0-5 pb-0-5 rounded'
												alt={industry.name}
												preview={false}
											/>
										</Tooltip>
									)
								})}
							</div>
						)
					}
				},
				{
					title: t('loc:Kategórie'),
					dataIndex: ['customService', 'categories'],
					key: 'categories',
					width: '15%',
					render: (value: CustomServiceTableItem['customService']['categories']) =>
						value.length ? <PopoverList elements={value.map((category) => ({ name: category.name || '-' }))} /> : '-'
				},
				{
					title: <span id={'sortby-createdAt'}>{t('loc:Vytvorené')}</span>,
					dataIndex: ['customService', 'createdAt'],
					key: 'createdAt',
					sorter: true,
					sortDirections: ['ascend', 'descend', 'ascend'],
					sortOrder: setOrder(query.order, 'createdAt'),
					width: '15%',
					render: (value: CustomServiceTableItem['customService']['createdAt']) => <LocalizedDateTime date={value} fallback={'-'} ellipsis />
				}
			]
		},
		{
			key: 'ctaButton',
			width: 150,
			children: [
				{
					title: t('loc:Označiť ako skontrolované'),
					dataIndex: ['customService', 'isChecked'],
					key: 'isChecked',
					fixed: customServices.data?.services.length ? 'right' : undefined,
					width: 150,
					render: (_value, record) => {
						if (record.customService.isChecked) {
							return (
								<div className={'flex items-center justify-center'}>
									<Tag className={'noti-tag bg-status-success'}>
										<span>{t('loc:Skontrolované')}</span>
									</Tag>
								</div>
							)
						}

						return (
							<PopConfirmComponent
								title={t('loc:Chcete túto službu označiť ako skontrolovanú?')}
								onConfirm={() => {
									validateCustomService(record.customService.id)
								}}
								okText={t('loc:Pokračovať')}
								placement={'topRight'}
								style={{ maxWidth: 500 }}
								allowedButton={
									<Button
										onClick={(e) => {
											e.stopPropagation()
										}}
										type={'primary'}
										icon={<IconCheck style={{ width: 12, height: 12 }} />}
										size={'small'}
										disabled={isLoading}
										className={'noti-btn m-regular w-full hover:shadow-none focus:shadow-none'}
										id={ROW_BUTTON_WITH_ID(record.customService.id)}
									>
										{t('loc:Skontrolovať')}
									</Button>
								}
							/>
						)
					}
				}
			]
		}
	]

	return (
		<Row gutter={ROW_GUTTER_X_DEFAULT}>
			<Col span={24}>
				<Spin spinning={isLoading}>
					<div className='content-body'>
						<CustomServicesFilter onSubmit={handleSubmitFilter} query={{ ...query, countryCode }} loading={isLoading} />
						<CustomTable
							className='table-fixed'
							onChange={onChangeTable}
							columns={columns || []}
							dataSource={customServices.tableData}
							scroll={{ x: 1500 }}
							rowKey={(record) => record.customService.id}
							rowClassName={'clickable-row'}
							twoToneRows
							useCustomPagination
							pagination={{
								pageSize: customServices?.data?.pagination?.limit,
								total: customServices?.data?.pagination?.totalCount,
								current: customServices?.data?.pagination?.page,
								onChange: onChangePagination,
								disabled: customServices?.isLoading
							}}
							onRow={(record) => ({
								onClick: () => {
									navigate(
										getLinkWithEncodedBackUrl(
											t('paths:salons/{{salonID}}/services-settings/{{serviceID}}', { salonID: record.salon.id, serviceID: record.customService.id })
										)
									)
								}
							})}
						/>
					</div>
				</Spin>
			</Col>
		</Row>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO]))(CustomServicesPage)
