/* eslint-disable import/no-cycle */
import { CALENDAR_EVENTS_KEYS, CALENDAR_MONTHLY_VIEW_EVENTS } from '../../utils/enums'
import { SET_DAY_EVENTS, EVENTS, EVENT_DETAIL, SET_IS_REFRESHING_EVENTS, EVENTS_MONTHLY_VIEW, SET_HIGHLIGHTED_CALENDAR_SHIFT_ID } from './calendarTypes'
import { RESET_STORE } from '../generalTypes'
import { ICalendarActions } from './calendarActions'
import { ICalendarEventsPayload, ILoadingAndFailure, ICalendarEventDetailPayload, ICalendarDayEvents, ICalendarMonthlyViewEventsPayload } from '../../types/interfaces'

export const initState = {
	[CALENDAR_EVENTS_KEYS.EVENTS]: {
		data: null,
		isLoading: false,
		isFailure: false
	} as ICalendarEventsPayload & ILoadingAndFailure,
	[CALENDAR_EVENTS_KEYS.RESERVATIONS]: {
		data: null,
		isLoading: false,
		isFailure: false
	} as ICalendarEventsPayload & ILoadingAndFailure,
	[CALENDAR_EVENTS_KEYS.SHIFTS_TIME_OFFS]: {
		data: null,
		isLoading: false,
		isFailure: false
	} as ICalendarEventsPayload & ILoadingAndFailure,
	[CALENDAR_MONTHLY_VIEW_EVENTS]: {
		data: null,
		isLoading: false,
		isFailure: false
	} as ICalendarMonthlyViewEventsPayload & ILoadingAndFailure,
	eventDetail: {
		data: null,
		isLoading: false,
		isFailure: false
	} as ICalendarEventDetailPayload & ILoadingAndFailure,
	dayEvents: {} as ICalendarDayEvents,
	isRefreshingEvents: false,
	highlightedCalendarShiftID: null as string | null
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: ICalendarActions) => {
	switch (action.type) {
		// Events
		case EVENTS.EVENTS_LOAD_START:
			return {
				...state,
				[action.enumType]: {
					...state[action.enumType],
					isLoading: true
				}
			}
		case EVENTS.EVENTS_LOAD_FAIL:
			return {
				...state,
				[action.enumType]: {
					...state[action.enumType],
					isFailure: true,
					isLoading: false
				}
			}
		case EVENTS.EVENTS_LOAD_DONE:
			return {
				...state,
				[action.enumType]: {
					...initState[action.enumType],
					data: action.payload.data
				}
			}
		// monthly view events
		case EVENTS_MONTHLY_VIEW.EVENTS_MONTHLY_VIEW_LOAD_START:
			return {
				...state,
				[CALENDAR_MONTHLY_VIEW_EVENTS]: {
					...state[CALENDAR_MONTHLY_VIEW_EVENTS],
					isLoading: true
				}
			}
		case EVENTS_MONTHLY_VIEW.EVENTS_MONTHLY_VIEW_LOAD_FAIL:
			return {
				...state,
				[CALENDAR_MONTHLY_VIEW_EVENTS]: {
					...state[CALENDAR_MONTHLY_VIEW_EVENTS],
					isFailure: true,
					isLoading: false
				}
			}
		case EVENTS_MONTHLY_VIEW.EVENTS_MONTHLY_VIEW_LOAD_DONE:
			return {
				...state,
				[CALENDAR_MONTHLY_VIEW_EVENTS]: {
					...initState[CALENDAR_MONTHLY_VIEW_EVENTS],
					data: action.payload.data
				}
			}
		// Event detail
		case EVENT_DETAIL.EVENT_DETAIL_LOAD_START:
			return {
				...state,
				eventDetail: {
					...state.eventDetail,
					isLoading: true
				}
			}
		case EVENT_DETAIL.EVENT_DETAIL_LOAD_FAIL:
			return {
				...state,
				eventDetail: {
					...initState.eventDetail,
					isFailure: true
				}
			}
		case EVENT_DETAIL.EVENT_DETAIL_LOAD_DONE:
			return {
				...state,
				eventDetail: {
					...initState.eventDetail,
					data: action.payload.data
				}
			}
		// set day events
		case SET_DAY_EVENTS:
			return {
				...state,
				dayEvents: action.payload
			}
		// clear events
		case EVENTS.EVENTS_CLEAR:
			return {
				...state,
				[action.enumType]: {
					...state[action.enumType],
					data: null
				}
			}
		// Refreshing events
		case SET_IS_REFRESHING_EVENTS:
			return {
				...state,
				isRefreshingEvents: action.payload
			}
		// Highlighted calendar Shift ID
		// this is used only in employee shifts planning view
		case SET_HIGHLIGHTED_CALENDAR_SHIFT_ID:
			return {
				...state,
				highlightedCalendarShiftID: action.payload
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
