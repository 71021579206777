import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Spin } from 'antd'
import { compose } from 'redux'

// reducers
import { RootState } from '../../reducers'
import { getCategories } from '../../reducers/categories/categoriesActions'
import { getCategoryParameters } from '../../reducers/categoryParams/categoryParamsActions'

// components
import CategoriesTree from './components/CategoriesTree'

// utils
import { ROW_GUTTER_X_DEFAULT, PERMISSION } from '../../utils/enums'
import { withPermissions } from '../../utils/Permissions'

const CategoriesPage = () => {
	const dispatch = useDispatch()

	const { isLoading } = useSelector((state: RootState) => state.categories.categories)

	useEffect(() => {
		dispatch(getCategories())
		dispatch(getCategoryParameters())
	}, [dispatch])

	return (
		<Row gutter={ROW_GUTTER_X_DEFAULT}>
			<Col span={24}>
				<div className='content-body'>
					<Spin spinning={isLoading}>
						<CategoriesTree />
					</Spin>
				</div>
			</Col>
		</Row>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO, PERMISSION.CATEGORY_EDIT]))(CategoriesPage)
