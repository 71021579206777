import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router'

// components
import { t } from 'i18next'
import NotinoDashboard from './NotinoDashboard'
import EmptySalons from '../../components/EmptySalons'
import PendingInvites from '../../components/PendingInvites'

// utils
import Permissions from '../../utils/Permissions'
import { DASHBOARD_TAB_KEYS, PERMISSION } from '../../utils/enums'

// types
import { RootState } from '../../reducers'

const HomePage = () => {
	const selectedSalonID = useSelector((state: RootState) => state.selectedSalon.selectedSalon.data?.id)
	const selectedSalonLoading = useSelector((state: RootState) => state.selectedSalon.selectedSalon.isLoading)
	const navigate = useNavigate()

	if (selectedSalonLoading) {
		return null
	}

	// if user is already working under salons view, redirect him to the selected salon dashboard - whenever his role is admin or partner
	// for example if he is working in two sessions in the browser
	if (selectedSalonID) {
		return <Navigate to={t('paths:salons/{{salonID}}/dashboard', { salonID: selectedSalonID })} />
	}

	return (
		<Permissions
			allowed={[PERMISSION.NOTINO]}
			render={(hasPermission) => {
				// current user logged in as admin
				if (hasPermission) {
					return <NotinoDashboard tabKey={DASHBOARD_TAB_KEYS.SALONS_DASHBOARD} />
				}

				// current user logged in as partner
				return (
					<>
						<PendingInvites
							onInviteAccept={(salonID: string) => {
								navigate(t('paths:salons/{{salonID}}/dashboard', { salonID }))
							}}
						/>
						<EmptySalons />
					</>
				)
			}}
		/>
	)
}

export default HomePage
