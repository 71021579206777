import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Form, Button } from 'antd'
import { useTranslation } from 'react-i18next'

// atoms
import InputField from '../../../atoms/InputField'

// utils
import { FORM, SKIP_BUTTON_ID, SUBMIT_BUTTON_ID } from '../../../utils/enums'
import { formFieldID, showErrorNotification } from '../../../utils/helper'

// types
import { ILoginAsPartnerForm } from '../../../types/interfaces'

// validation
import { validationLoginAsPartnerFn } from '../../../utils/formValidation'

type ComponentProps = {
	onSkipLoginAsPartner: () => void
}

type Props = InjectedFormProps<ILoginAsPartnerForm, ComponentProps> & ComponentProps

const LoginAsPartnerForm = (props: Props) => {
	const [t] = useTranslation()
	const { handleSubmit, submitting, onSkipLoginAsPartner } = props

	return (
		<Form layout={'vertical'} className={'form h-full w-full'} onSubmitCapture={handleSubmit}>
			<Field component={InputField} label={t('loc:E-mail partnera')} placeholder={t('loc:Zadajte e-mail partnera')} name={'email'} size={'large'} />
			<div className='mt-6'>
				<Button
					id={formFieldID(FORM.LOGIN, SUBMIT_BUTTON_ID)}
					type={'primary'}
					block
					className={'noti-btn m-regular mb-4'}
					htmlType={'submit'}
					disabled={submitting}
					loading={submitting}
				>
					{t('loc:Prihlásiť sa')}
				</Button>
				<Button
					id={formFieldID(FORM.LOGIN, SKIP_BUTTON_ID)}
					type={'dashed'}
					block
					className={'noti-btn m-regular mb-4'}
					htmlType={'button'}
					onClick={onSkipLoginAsPartner}
					disabled={submitting}
					loading={submitting}
				>
					{t('loc:Preskočiť')}
				</Button>
			</div>
		</Form>
	)
}

const form = reduxForm<ILoginAsPartnerForm, ComponentProps>({
	form: FORM.LOGIN_AS_PARTNER,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	onSubmitFail: showErrorNotification,
	validate: validationLoginAsPartnerFn
})(LoginAsPartnerForm)

export default form
