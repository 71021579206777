import { z } from 'zod'
import { emailConstraint, passwordConstraint, serializeValidationMessage } from './baseSchema'

export const createPasswordSchema = z
	.object({
		password: passwordConstraint,
		confirmPassword: z.string()
	})
	.refine(({ confirmPassword, password }) => confirmPassword === password, {
		message: serializeValidationMessage('loc:Heslá sa nezhodujú'),
		path: ['confirmPassword']
	})

export const forgotPasswordSchema = z.object({
	email: emailConstraint
})
