import React from 'react'
import { Outlet } from 'react-router'
import cx from 'classnames'
import { Content } from 'antd/es/layout/layout'

// utils
import PreventShowDeletedSalon from '../utils/PreventShowDeletedSalon'
import { MAIN_LAYOUT_CONTENT_CLASSES } from '../utils/enums'

// components
import MainLayoutSpin from './MainLayoutSpin'

type Props = {
	preventShowDeletedSalon?: boolean
	contentClassName?: string
	isLoadingSalon?: boolean
}

const MainLayoutContent = (props: Props) => {
	const { preventShowDeletedSalon, contentClassName = MAIN_LAYOUT_CONTENT_CLASSES.DEFAULT, isLoadingSalon } = props

	if (isLoadingSalon) {
		return (
			<Content className={cx(contentClassName, 'h-full')}>
				<MainLayoutSpin />
			</Content>
		)
	}

	const content = (
		<Content className={cx(contentClassName, 'h-full')}>
			<Outlet />
		</Content>
	)

	if (preventShowDeletedSalon) {
		return <PreventShowDeletedSalon>{content}</PreventShowDeletedSalon>
	}

	return content
}

export default MainLayoutContent
