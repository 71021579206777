import { z } from 'zod'
import { stringConstraint } from './baseSchema'
import { RESERVATION_QUICK_FEEDBACK_ANSWER, VALIDATION_MAX_LENGTH } from '../utils/enums'

// eslint-disable-next-line import/prefer-default-export
export const createReservationFeedbackSchema = z.object({
	rating: z.number(),
	reservationQuickFeedbackAnswers: z.nativeEnum(RESERVATION_QUICK_FEEDBACK_ANSWER).array().nullish(),
	text: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1500)
})
