import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

// utils
import { pushEventToDataLayer } from '../utils/dataLayer'
import { DATA_LAYER_EVENTS, DEFAULT_SCREEN_NAME, GA_SCREEN_CLASS, GA_SCREEN_NAME } from '../utils/dataLayerEnums'

// redux
import { setGaScreenName } from '../reducers/googleAnalytics/googleAnalyticsActions'

/**
 * push screen_view event to dataLayer on page (component) load
 * push screen_duration event to dataLayer on page (component) leave (or when user closes the tab / browser)
 */
const usePushScreenInfoToDataLayer = (screenName: GA_SCREEN_NAME, screenClass: GA_SCREEN_CLASS) => {
	const dispatch = useDispatch()

	useEffect(() => {
		pushEventToDataLayer({ event: DATA_LAYER_EVENTS.SCREEN_VIEW, screen_name: screenName, screen_class: screenClass })
	}, [screenName, screenClass])

	useEffect(() => {
		dispatch(setGaScreenName(screenName))

		const startTime = dayjs()

		const onPageLeave = (start: dayjs.Dayjs) => {
			pushEventToDataLayer({ event: DATA_LAYER_EVENTS.SCREEN_DURATION, screen_name: screenName, screen_time: dayjs().diff(start, 'ms') })
			// reset to default screen name in case the new page won't update redux with gaScreenName
			dispatch(setGaScreenName(DEFAULT_SCREEN_NAME))
		}

		// push to data layer on page refresh or tab close
		const onBeforeUnload = () => onPageLeave(startTime)

		window.addEventListener('beforeunload', onBeforeUnload)

		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload)
			// push to data layer on component unmount
			onPageLeave(startTime)
		}
	}, [screenName, dispatch])

	return null
}

export default usePushScreenInfoToDataLayer
