/* eslint-disable import/no-cycle */
// types
import { SMS_HISTORY, SMS_STATS, SMS_TIME_STATS } from './smsTypes'
import { IResetStore } from '../generalTypes'

// utils
import { getReq } from '../../utils/request'

// types
import { IGetSmsHistoryQueryParams } from '../../types/schemaTypes'
import { GetUrls, ISearchable, RequestResponse } from '../../types/interfaces'
import { ThunkResult } from '../index'

export type ISmsActions = IResetStore | IGetSmsStats | IGetSmsTimeStats | IGetSmsHistory

interface IGetSmsStats {
	type: SMS_STATS
	payload: ISmsStatsPayload
}

interface IGetSmsTimeStats {
	type: SMS_TIME_STATS
	payload: ISmsTimeStatsPayload
}

interface IGetSmsHistory {
	type: SMS_HISTORY
	payload: ISmsHistoryPayload
}

export interface ISmsStatsPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/notifications/sms/stats']> | null
}

export interface ISmsTimeStatsPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/notifications/sms/time-stats']> | null
}

export interface ISmsHistoryPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/notifications/sms/history']>> {}

export const getSmsStats =
	(salonID: string): ThunkResult<Promise<ISmsStatsPayload>> =>
	async (dispatch) => {
		let payload = {} as ISmsStatsPayload
		try {
			dispatch({ type: SMS_STATS.SMS_STATS_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/salons/{salonID}/notifications/sms/stats', { params: { path: { salonID } }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: SMS_STATS.SMS_STATS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SMS_STATS.SMS_STATS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}
		return payload
	}

export const getSmsTimeStatsForSalon =
	(salonID: string, year: number, month: number): ThunkResult<Promise<ISmsTimeStatsPayload>> =>
	async (dispatch) => {
		let payload = {} as ISmsTimeStatsPayload
		try {
			dispatch({ type: SMS_TIME_STATS.SMS_TIME_STATS_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/salons/{salonID}/notifications/sms/time-stats', { params: { path: { salonID }, query: { year, month } }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: SMS_TIME_STATS.SMS_TIME_STATS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SMS_TIME_STATS.SMS_TIME_STATS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}
		return payload
	}

export const getSmsTimeStatsForCountry =
	(countryCode: string, year: number, month: number): ThunkResult<Promise<ISmsTimeStatsPayload>> =>
	async (dispatch) => {
		let payload = {} as ISmsTimeStatsPayload
		try {
			dispatch({ type: SMS_TIME_STATS.SMS_TIME_STATS_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/notino-dashboard/sms-time-stats', { params: { query: { countryCode, year, month } }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: SMS_TIME_STATS.SMS_TIME_STATS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SMS_TIME_STATS.SMS_TIME_STATS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}
		return payload
	}

export const getSmsHistory =
	(queryParams: IGetSmsHistoryQueryParams): ThunkResult<Promise<ISmsHistoryPayload>> =>
	async (dispatch) => {
		let payload = {} as ISmsHistoryPayload
		try {
			const { salonID, ...query } = queryParams
			dispatch({ type: SMS_HISTORY.SMS_HISTORY_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/salons/{salonID}/notifications/sms/history', {
				params: {
					path: {
						salonID
					},
					query: {
						search: query.search,
						page: query.page,
						limit: query.limit,
						order: query.order,
						dateFrom: query.dateFrom,
						dateTo: query.dateTo
					}
				},
				reqBody: {}
			})

			payload = {
				data
			}

			dispatch({ type: SMS_HISTORY.SMS_HISTORY_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SMS_HISTORY.SMS_HISTORY_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
