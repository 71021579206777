import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

// utils
import { STRINGS } from '../utils/enums'

// assets
import PlusIcon from '../assets/icons/plus-icon.svg?react'

const EmptySalons = () => {
	const [t] = useTranslation()
	const navigate = useNavigate()

	return (
		<div className='flex justify-center items-center h-full my-10'>
			<div className='m-auto text-center'>
				<h1 className='text-5xl font-bold mb-4'>{t('loc:Začnite vytvorením salónu')}</h1>
				<Button onClick={() => navigate(t('paths:salons/create'))} type='primary' htmlType='button' className={'noti-btn'} icon={<PlusIcon />}>
					{STRINGS(t).addRecord(t('loc:salón'))}
				</Button>
			</div>
		</div>
	)
}

export default EmptySalons
