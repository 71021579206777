import React, { useState } from 'react'
import { Modal, ModalProps, Spin, Button, TabsProps, Divider, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

// assets
import CloseIcon from '../assets/icons/close-icon-modal.svg?react'

// utils
import { DEFAULT_LANGUAGE, LANGUAGE, LOCALES } from '../utils/enums'
import { isEnumValue } from '../utils/intl'

// components
import TabsComponent from './TabsComponent'

const { Paragraph } = Typography

type MainFeature = {
	name?: string | null
	description?: string | null
}

type OtherFeature = {
	description?: string[]
}

export type WhatsNewModalContent = {
	mainFeatures: (MainFeature | undefined)[]
	otherFeatures?: OtherFeature
}

export type WhatsNewModalProps = {
	title: React.ReactNode
	open: boolean
	onCancel: ModalProps['onCancel']
	onContinue: ModalProps['onCancel']
	loading?: boolean
	buttonLabel: string
	version: string | undefined
	initLanguage?: LANGUAGE
	content: WhatsNewModalContent | Record<LANGUAGE, WhatsNewModalContent>
}

type ModalContentProps = WhatsNewModalContent & { version?: string; isAdminView?: boolean }

const ModalContent = (props: ModalContentProps) => {
	const { mainFeatures, otherFeatures, version, isAdminView = false } = props
	const [t] = useTranslation()

	const filledMainFeatures = mainFeatures.filter((feature) => feature?.name || feature?.description)

	const otherFeaturesContent = () => {
		const title = <h2 className={'text-base text-bold text-notino-black font-semibold mb-0'}>{t('loc:Ďalšie úpravy vo verzii {{ version }}', { version })}:</h2>
		const list = (
			<ul className={'pl-4 mb-0'}>
				{otherFeatures?.description?.length
					? otherFeatures.description.map((value, index) => {
							return (
								<li key={index} className={'text-sm text-notino-grayDark font-normal'}>
									{value}
								</li>
							)
						})
					: null}
			</ul>
		)

		if (isAdminView) {
			return (
				<>
					<Divider className={'my-4'} />
					<div className={'flex flex-col gap-2'}>
						{title}
						{otherFeatures?.description?.length ? (
							list
						) : (
							<p className={'text-sm text-notino-grayDark font-normal mb-0'}>{t('loc:Pre tento jazyk nemáte pridané žiadne ďalšie funkcie.')}</p>
						)}
					</div>
				</>
			)
		}

		return otherFeatures?.description?.length ? (
			<>
				<Divider className={'my-4'} />
				<div className={'flex flex-col gap-2'}>
					{title}
					{list}
				</div>
			</>
		) : null
	}

	return (
		<>
			<div className={'flex flex-col gap-4'}>
				{filledMainFeatures?.length
					? filledMainFeatures.map((data, index) => {
							const { description, name } = data || {}
							return (
								<div key={index}>
									{name && (
										<h2 className={'text-base text-bold text-notino-black font-semibold mb-0'}>
											{name}
											<span
												className={`ml-1 text-xxs leading-3 font-semibold h-5 px-2 inline-flex items-center rounded-full bg-notino-pink text-notino-white uppercase flex-shrink-0`}
											>
												{t('loc:Nové')}
											</span>
										</h2>
									)}
									{description && (
										<Paragraph
											ellipsis={{
												rows: 3,
												expandable: true,
												symbol: t('loc:Zobraziť viac')
											}}
											className={'text-notino-grayDark text-sm mb-0 font-normal'}
										>
											{description}
										</Paragraph>
									)}
								</div>
							)
						})
					: null}
				{isAdminView && !filledMainFeatures?.length ? (
					<p className={'text-sm text-notino-grayDark font-normal mb-0'}>{t('loc:Pre tento jazyk nemáte pridané žiadne promované funkcie.')}</p>
				) : null}
			</div>
			{otherFeaturesContent()}
		</>
	)
}

const WhatsNewModal = (props: WhatsNewModalProps) => {
	const { open, onCancel, title, loading, buttonLabel, content, version, initLanguage = DEFAULT_LANGUAGE, onContinue } = props
	const [t] = useTranslation()

	const isAdminView = !('mainFeatures' in content)

	const [tabKey, setTabKey] = useState<LANGUAGE>(initLanguage)

	const tabContent: TabsProps['items'] = Object.values(LANGUAGE).map((lng) => ({
		key: lng,
		label: LOCALES[lng].countryCode.toUpperCase()
	}))

	const onTabChange = (newTabKey: string) => {
		if (isEnumValue(newTabKey, LANGUAGE)) {
			setTabKey(newTabKey)
		}
	}

	return (
		<Modal
			className='noti-changelog-modal'
			open={open}
			destroyOnClose
			footer={null}
			onCancel={onCancel}
			closeIcon={<CloseIcon />}
			width={480}
			maskClosable={isAdminView}
			keyboard={isAdminView}
		>
			<Spin spinning={!!loading}>
				<header className={cx('noti-changelog-modal-header flex flex-col gap-2', { 'pb-4': !isAdminView })}>
					<h1 className={'text-2xl font-bold text-notino-black max-w-72 mb-0'}>{title}</h1>
					{version && <span className={'text-sm text-notino-grayDarker font-normal'}>{t('loc:Verzia {{version}}', { version })}</span>}
					{isAdminView && (
						<TabsComponent activeKey={tabKey} onChange={onTabChange} items={tabContent} className={'noti-changelog-tabs noti-changelog-tabs-modal-preview'} />
					)}
				</header>
				<main className={'noti-changelog-modal-content'}>
					<>
						{isAdminView ? (
							<ModalContent mainFeatures={content[tabKey].mainFeatures} otherFeatures={content[tabKey].otherFeatures} version={version} isAdminView />
						) : (
							<ModalContent mainFeatures={content.mainFeatures} otherFeatures={content.otherFeatures} version={version} />
						)}
					</>
				</main>
				<footer className={'noti-changelog-modal-footer'}>
					<Button className='noti-btn' block size='large' type='primary' htmlType='button' onClick={onContinue}>
						{buttonLabel}
					</Button>
				</footer>
			</Spin>
		</Modal>
	)
}

export default WhatsNewModal
