/* eslint-disable import/no-cycle */
import { IResetStore } from '../generalTypes'

// types
import { NOTINO_DASHBOARD, RESERVATIONS_STATS, SALONS_ANNUAL_STATS, SALONS_MONTH_STATS, SALON_DASHBOARD } from './dashboardTypes'
import { ThunkResult } from '../index'

// utils
import { getReq } from '../../utils/request'
import { GetUrls, RequestResponse } from '../../types/interfaces'

export type IDashboardActions = IResetStore | IGetNotinoDashboard | IGetSalonsAnnualStats | IGetSalonsMonthlyStats | IGetReservationsStats | IGetSalonDashboard

interface IGetNotinoDashboard {
	type: NOTINO_DASHBOARD
	payload: INotinoDashboardPayload
}

interface IGetSalonDashboard {
	type: SALON_DASHBOARD
	payload: ISalonDashboardPayload
}

interface IGetReservationsStats {
	type: RESERVATIONS_STATS
	payload: IReservationStatsPayload
}

export type INotinoDashboard = RequestResponse<GetUrls['/api/b2b/admin/notino-dashboard/']>['counts']
export type ISalonsTimeStats = RequestResponse<GetUrls['/api/b2b/admin/notino-dashboard/salon-development-time-stats']>
export type IReservationsStats = RequestResponse<GetUrls['/api/b2b/admin/notino-dashboard/salon-reservations-time-stats']>

interface IGetReservationsStatsQueryParams {
	month?: number
	year: number
	countryCode?: string
}

export interface INotinoDashboardPayload {
	data: INotinoDashboard | null
}

export interface IReservationStatsPayload {
	data: IReservationsStats | null
}

interface IGetSalonsAnnualStats {
	type: SALONS_ANNUAL_STATS
	payload: ISalonsTimeStatsPayload
}

interface IGetSalonsMonthlyStats {
	type: SALONS_MONTH_STATS
	payload: ISalonsTimeStatsPayload
}

export interface ISalonsTimeStatsPayload {
	data: ISalonsTimeStats | null
}

export interface ISalonDashboardPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/dashboard']> | null
}

export const getNotinoDashboard =
	(countryCode?: string): ThunkResult<Promise<INotinoDashboardPayload>> =>
	async (dispatch) => {
		let payload = {} as INotinoDashboardPayload

		try {
			dispatch({ type: NOTINO_DASHBOARD.NOTINO_DASHBOARD_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/notino-dashboard/', {
				params: {
					query: { countryCode: countryCode || undefined }
				},
				reqBody: {}
			})
			payload = {
				data: data?.counts
			}

			dispatch({ type: NOTINO_DASHBOARD.NOTINO_DASHBOARD_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: NOTINO_DASHBOARD.NOTINO_DASHBOARD_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getReservationStats =
	(queryParams: IGetReservationsStatsQueryParams): ThunkResult<Promise<IReservationStatsPayload>> =>
	async (dispatch) => {
		let payload = {} as IReservationStatsPayload

		try {
			dispatch({ type: RESERVATIONS_STATS.RESERVATIONS_STATS_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/notino-dashboard/salon-reservations-time-stats', {
				params: { query: { month: queryParams.month ?? undefined, year: queryParams.year ?? undefined, countryCode: queryParams.countryCode ?? undefined } },
				reqBody: {}
			})
			payload = {
				data
			}

			dispatch({ type: RESERVATIONS_STATS.RESERVATIONS_STATS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: RESERVATIONS_STATS.RESERVATIONS_STATS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
const getSalonTimeStats = async (year: number, countryCode?: string, month?: number): Promise<ISalonsTimeStatsPayload> => {
	const { data } = await getReq('/api/b2b/admin/notino-dashboard/salon-development-time-stats', {
		params: { query: { month: month ?? undefined, year: year ?? undefined, countryCode: countryCode ?? undefined } },
		reqBody: {}
	})
	return { data }
}

export const getSalonsAnnualStats =
	(year: number, countryCode?: string): ThunkResult<Promise<ISalonsTimeStatsPayload>> =>
	async (dispatch) => {
		let payload = {} as ISalonsTimeStatsPayload

		try {
			dispatch({ type: SALONS_ANNUAL_STATS.SALONS_ANNUAL_STATS_LOAD_START })

			payload = await getSalonTimeStats(year, countryCode)

			dispatch({ type: SALONS_ANNUAL_STATS.SALONS_ANNUAL_STATS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SALONS_ANNUAL_STATS.SALONS_ANNUAL_STATS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getSalonsMonthStats =
	(year: number, month?: number, countryCode?: string): ThunkResult<Promise<ISalonsTimeStatsPayload>> =>
	async (dispatch) => {
		let payload = {} as ISalonsTimeStatsPayload

		try {
			dispatch({ type: SALONS_MONTH_STATS.SALONS_MONTH_STATS_LOAD_START })

			payload = await getSalonTimeStats(year, countryCode, month)

			dispatch({ type: SALONS_MONTH_STATS.SALONS_MONTH_STATS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SALONS_MONTH_STATS.SALONS_MONTH_STATS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getSalonDashboard =
	(salonID: string, employeeIDs?: string[]): ThunkResult<Promise<ISalonDashboardPayload>> =>
	async (dispatch) => {
		let payload = {} as ISalonDashboardPayload

		try {
			dispatch({ type: SALON_DASHBOARD.SALON_DASHBOARD_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/salons/{salonID}/dashboard', { params: { path: { salonID }, query: { employeeIDs } }, reqBody: {} })
			payload = {
				data
			}

			dispatch({ type: SALON_DASHBOARD.SALON_DASHBOARD_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SALON_DASHBOARD.SALON_DASHBOARD_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
