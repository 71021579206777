import React, { FC } from 'react'
import { Field, FieldArray, InjectedFormProps, reduxForm, submit } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Divider, Form, Button } from 'antd'
import cx from 'classnames'
import { useDispatch } from 'react-redux'

// utils
import { UPLOAD_IMG_CATEGORIES, URL_UPLOAD_FILE, FORM, STRINGS, DELETE_BUTTON_ID, SUBMIT_BUTTON_ID, VALIDATION_MAX_LENGTH, PERMISSION, UPLOAD } from '../../../utils/enums'
import { showErrorNotification, validationString, checkUploadingBeforeSubmit, formFieldID, getUploadLimitsMessage } from '../../../utils/helper'
import { withPromptUnsavedChanges } from '../../../utils/promptUnsavedChanges'
import Permissions from '../../../utils/Permissions'

// atoms
import InputField from '../../../atoms/InputField'
import ImgUploadField from '../../../atoms/ImgUploadField'

// components
import DeleteButton from '../../../components/DeleteButton'
import Localizations from '../../../components/Localizations'

// assets
import CloseIcon from '../../../assets/icons/close-icon.svg?react'
import EditIcon from '../../../assets/icons/edit-icon.svg?react'
import CreateIcon from '../../../assets/icons/plus-icon.svg?react'

// types
import { ILanguageForm } from '../../../types/interfaces'

// validation
import { validationLanguageFn } from '../../../utils/formValidation'

type ComponentProps = {
	languageID?: string
	closeForm: () => void
	onDelete: () => void
}

type Props = InjectedFormProps<ILanguageForm, ComponentProps> & ComponentProps

const fixLength255 = validationString(VALIDATION_MAX_LENGTH.LENGTH_255)

const editPermissions: PERMISSION[] = [PERMISSION.LANGUAGE_EDIT]

const LanguagesForm: FC<Props> = (props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const { handleSubmit, languageID, closeForm, onDelete, submitting, pristine } = props

	return (
		<Form layout={'vertical'} className={'w-full top-0 sticky overflow-hidden pt-1 px-6 pb-6 -mx-6'} onSubmitCapture={handleSubmit(checkUploadingBeforeSubmit)}>
			<div className={'h-full '}>
				<h3 className={'mb-0 mt-3 relative pr-7'}>
					{languageID ? t('loc:Upraviť jazyk') : t('loc:Vytvoriť jazyk')}
					<Button className='noti-close-form-btn absolute top-1 right-0' onClick={() => closeForm()}>
						<CloseIcon />
					</Button>
				</h3>
				<Divider className={'my-3'} />
				<FieldArray
					key='nameLocalizations'
					name='nameLocalizations'
					component={Localizations}
					placeholder={t('loc:Zadajte názov')}
					horizontal
					className={'flex-1 noti-languages-localizations'}
					ignoreFieldIndex={0} // do not render "0" field because it is rendered in mainField prop
					customValidate={fixLength255}
					noSpace
					mainField={
						<Field
							className='mb-0 pb-0'
							component={InputField}
							label={t('loc:Názov jazyka (EN)')}
							placeholder={t('loc:Zadajte názov')}
							key='nameLocalizations[0].value'
							name='nameLocalizations[0].value'
							required
							validate={fixLength255}
						/>
					}
				/>
				<Field
					component={ImgUploadField}
					name='image'
					label={t('loc:Vlajka')}
					signUrl={URL_UPLOAD_FILE}
					category={UPLOAD_IMG_CATEGORIES.LANGUAGE_IMAGE}
					maxFileSize={UPLOAD.LANGUAGE_FLAG.MAX_FILE_SIZE}
					accept={UPLOAD.LANGUAGE_FLAG.ACCEPT}
					labelDescription={getUploadLimitsMessage(UPLOAD.LANGUAGE_FLAG.ACCEPT, UPLOAD.LANGUAGE_FLAG.MAX_FILE_SIZE)}
					maxCount={UPLOAD.LANGUAGE_FLAG.MAX_COUNT}
				/>

				<div className={cx('flex w-full mt-6 gap-2 flex-wrap', { 'justify-between': languageID, 'justify-center': !languageID })}>
					{languageID && (
						<DeleteButton
							permissions={editPermissions}
							onConfirm={onDelete}
							entityName={''}
							id={formFieldID(FORM.LANGUAGES, DELETE_BUTTON_ID)}
							type={'default'}
							className='w-full xl:w-auto xl:min-w-40'
							getPopupContainer={() => document.getElementById('content-footer-container') || document.body}
						/>
					)}
					<Permissions
						allowed={editPermissions}
						render={(hasPermission, { openForbiddenModal }) => (
							<Button
								className={'noti-btn w-full xl:w-auto xl:min-w-40'}
								size='middle'
								type='primary'
								htmlType='submit'
								onClick={(e) => {
									e.preventDefault()
									if (hasPermission) {
										dispatch(submit(FORM.LANGUAGES))
									} else {
										openForbiddenModal()
									}
								}}
								disabled={submitting || pristine}
								loading={submitting}
								id={formFieldID(FORM.LANGUAGES, SUBMIT_BUTTON_ID)}
								icon={languageID ? <EditIcon /> : <CreateIcon />}
							>
								{languageID ? t('loc:Uložiť') : STRINGS(t).createRecord(t('loc:jazyk'))}
							</Button>
						)}
					/>
				</div>
			</div>
		</Form>
	)
}

const form = reduxForm<ILanguageForm, ComponentProps>({
	form: FORM.LANGUAGES,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	onSubmitFail: showErrorNotification,
	validate: validationLanguageFn
})(withPromptUnsavedChanges(LanguagesForm))

export default form
