import React from 'react'
import { Typography } from 'antd'
import cx from 'classnames'

// components
import ActionsDropdown from './ActionsDropdown'
import { ActionsDropdownProps } from '../notificationsUtils'
import { getRelativeTimeValue } from '../../../utils/helper'

// utils
import { NOTIFICATION_EVENT_TYPE } from '../../../utils/enums'

// assets
import CheckCircleIcon from '../../../assets/icons/check-icon-circle-icon.svg?react'
import RefreshIcon from '../../../assets/icons/refresh-icon.svg?react'
import ClockIcon from '../../../assets/icons/clock-icon.svg?react'
import CloseCircleIcon from '../../../assets/icons/close-circle-icon.svg?react'
import DocumentIcon from '../../../assets/icons/document-icon-2.svg?react'
import UnresolvedReservationIcon from '../../../assets/icons/unresolved-reservation-icon.svg?react'

const eventTypeIconClassName = 'text-notino-pink w-6 h-6 flex-shrink-0'

const NOTIFICATION_EVENT_TYPE_ICONS = {
	[NOTIFICATION_EVENT_TYPE.RESERVATION_CONFIRMED_EMPLOYEE]: <CheckCircleIcon className={eventTypeIconClassName} />,
	[NOTIFICATION_EVENT_TYPE.RESERVATION_CHANGED_EMPLOYEE]: <RefreshIcon className={eventTypeIconClassName} />,
	[NOTIFICATION_EVENT_TYPE.RESERVATION_CANCELLED_EMPLOYEE]: <CloseCircleIcon className={eventTypeIconClassName} />,
	[NOTIFICATION_EVENT_TYPE.RESERVATION_AWAITING_APPROVAL_EMPLOYEE]: <ClockIcon className={eventTypeIconClassName} />,
	[NOTIFICATION_EVENT_TYPE.DYNAMIC_NOTIFICATION]: <DocumentIcon className={eventTypeIconClassName} />,
	[NOTIFICATION_EVENT_TYPE.UNRESOLVED_RESERVATIONS]: <UnresolvedReservationIcon className={eventTypeIconClassName} />
}

type Props = {
	id: string
	title: string
	message: string
	unread: boolean
	createdAt: string
	notificationEventType: NOTIFICATION_EVENT_TYPE
	isLoading: boolean
	okActionProps: ActionsDropdownProps['okProps']
	deleteActionProps: ActionsDropdownProps['deleteProps']
	onOpenChange?: ActionsDropdownProps['onOpenChange']
	onNotificationClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<void>
}

const { Paragraph } = Typography

const Notification = (props: Props) => {
	const { id, title, message, notificationEventType, unread, createdAt, isLoading, okActionProps, deleteActionProps, onOpenChange, onNotificationClick } = props

	const eventTypeIcon = NOTIFICATION_EVENT_TYPE_ICONS[notificationEventType as NOTIFICATION_EVENT_TYPE] || <DocumentIcon className={eventTypeIconClassName} />

	const isClickable = !!onNotificationClick

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			className={cx('flex items-center p-4 ease-in transition-all duration-200 cursor-default', {
				'bg-notino-grayLighter': unread,
				'bg-notino-white': !unread,
				'hover:bg-notino-grayLight': unread && isClickable,
				'hover:bg-notino-grayLighter': !unread && isClickable,
				'cursor-pointer': isClickable
			})}
			onClick={(e) => {
				if (isClickable) {
					onNotificationClick(e)
				} else {
					e.stopPropagation()
				}
			}}
		>
			<div
				className={cx('w-11 h-11 rounded-full flex items-center justify-center mr-2 relative flex-shrink-0', {
					'bg-notino-grayLighter': !unread,
					'bg-notino-white': unread
				})}
			>
				{unread && <div style={{ top: 9, right: 1 }} className={'h-1 w-1 bg-notino-pink rounded-full mr-2 absolute'} />}
				{eventTypeIcon}
			</div>
			<div className={'flex flex-col justify-center flex-auto overflow-hidden max-w-100'}>
				<span className={cx('text-sm font-medium truncate inline-block', { 'text-notino-black': unread, 'text-notino-grayDark': !unread })}>{title}</span>
				<Paragraph
					ellipsis={{
						rows: 3,
						tooltip: true
					}}
					className={cx('text-xs mb-0', { 'text-notino-grayDark': unread, 'text-notino-grayMedium': !unread })}
				>
					{message}
				</Paragraph>
			</div>
			<div className={'flex flex-col justify-center items-end text-xs text-notino-grayDark'}>
				<span className={'mb-3'}>{getRelativeTimeValue(createdAt)}</span>
				<ActionsDropdown type={'secondary'} id={id} okProps={okActionProps} deleteProps={deleteActionProps} disabled={isLoading} onOpenChange={onOpenChange} />
			</div>
		</div>
	)
}

export default React.memo(Notification)
