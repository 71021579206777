import { isEmpty, map } from 'lodash'
import { IResetStore } from '../generalTypes'

// types
import { SALON, SALON_HISTORY, SALONS, SALONS_TO_CHECK, SALON_NON_CHECKED_CHANGES } from './salonsTypes'
import { GetUrls, ISearchable, RequestParams, RequestResponse } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IGetSalonsHistoryQueryParams, IGetSalonsQueryParams, IGetSalonsToCheckQueryParams, NonCheckedChanges } from '../../types/schemaTypes'

// utils
import { getReq } from '../../utils/request'
import {
	SALON_FILTER_OPENING_HOURS,
	SALON_FILTER_STATES,
	SALON_FILTER_RS,
	SALON_FILTER_RS_AVAILABLE_ONLINE,
	SALONS_TAB_KEYS,
	UPCOMING_RESERVATIONS,
	WITHOUT_ASSIGNED_USER
} from '../../utils/enums'
import { partialSafeParse } from '../../utils/zodUtils'

// schemas
import { nonCheckedChangesSchema } from '../../schemas/nonCheckedChanges'

export type ISalonsActions = IResetStore | IGetSalons | IGetSalon | IGetSalonHistory | IGetSalonNonCheckedChanges | IGetSalonsToCheck

interface IGetSalons {
	type: SALONS
	payload: ISalonsPayload
}

interface IGetSalonHistory {
	type: SALON_HISTORY
	payload: ISalonHistoryPayload
}

interface IGetSalon {
	type: SALON
	payload: ISalonPayload
}

export interface IGetSalonNonCheckedChanges {
	type: SALON_NON_CHECKED_CHANGES
	payload: ISalonNonCheckedChangesPayload
}

export interface ISalonPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}']> | null
}

export interface ISalonHistoryPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/history']> | null
}

export interface ISalonNonCheckedChangesPayload {
	data: (Omit<RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/non-checked-changes']>['salon'], 'changes'> & { changes: Partial<NonCheckedChanges> | null }) | null
}

interface IGetSalonsToCheck {
	type: SALONS_TO_CHECK
	payload: ISalonsToCheckPayload
}

export interface ISalonsPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/salons/']>> {}

export interface ISalonsToCheckPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/salons/to-check']>> {}

type GetSalonsRequestQueryParams = NonNullable<RequestParams<GetUrls['/api/b2b/admin/salons/']>['query']>

export const getSalonsRequestQueryParams = (queryParams: IGetSalonsQueryParams): GetSalonsRequestQueryParams => {
	let statuses: SALON_FILTER_STATES[] = []
	let hasSetOpeningHours
	let hasAvailableReservationSystem
	let enabledReservationsSetting
	let hasDaysWithAvailableReservationSlot
	let hasUpcomingReservations
	let assignedUserID

	if (queryParams.salonState === SALONS_TAB_KEYS.ACTIVE) {
		statuses = [SALON_FILTER_STATES.NOT_DELETED]
	}

	if (queryParams.salonState === SALONS_TAB_KEYS.DELETED) {
		statuses = [SALON_FILTER_STATES.DELETED]
	}

	if (!queryParams.statuses_all) {
		if (queryParams.statuses_published) {
			statuses.push(queryParams.statuses_published)
		}
		if (queryParams.statuses_changes) {
			statuses.push(queryParams.statuses_changes)
		}
	}

	if (queryParams.hasSetOpeningHours === SALON_FILTER_OPENING_HOURS.SET) {
		hasSetOpeningHours = true
	} else if (queryParams.hasSetOpeningHours === SALON_FILTER_OPENING_HOURS.NOT_SET) {
		hasSetOpeningHours = false
	}

	if (queryParams.enabledReservationsSetting === SALON_FILTER_RS.ENABLED) {
		enabledReservationsSetting = true
	} else if (queryParams.enabledReservationsSetting === SALON_FILTER_RS.NOT_ENABLED) {
		enabledReservationsSetting = false
	}

	if (queryParams.hasAvailableReservationSystem === SALON_FILTER_RS_AVAILABLE_ONLINE.AVAILABLE) {
		hasAvailableReservationSystem = true
	} else if (queryParams.hasAvailableReservationSystem === SALON_FILTER_RS_AVAILABLE_ONLINE.NOT_AVAILABLE) {
		hasAvailableReservationSystem = false
	} else if (queryParams.hasAvailableReservationSystem === SALON_FILTER_RS_AVAILABLE_ONLINE.HAS_DAYS_WITH_AVAILABLE_SLOT) {
		hasAvailableReservationSystem = true
		hasDaysWithAvailableReservationSlot = true
	}

	if (queryParams.hasUpcomingReservations === UPCOMING_RESERVATIONS.HAS_UPCOMING_RESERVATIONS) {
		hasUpcomingReservations = true
	} else if (queryParams.hasUpcomingReservations === UPCOMING_RESERVATIONS.DOESNT_HAVE_UPCOMING_RESERVATIONS) {
		hasUpcomingReservations = false
	}

	if (queryParams.assignedUserID === WITHOUT_ASSIGNED_USER) {
		assignedUserID = 'null'
	} else if (queryParams.assignedUserID) {
		assignedUserID = queryParams.assignedUserID
	}

	return {
		page: queryParams.page ?? undefined,
		limit: queryParams.limit ?? undefined,
		order: queryParams.order || undefined,
		search: queryParams.search || undefined,
		categoryFirstLevelIDs: queryParams.categoryFirstLevelIDs || undefined,
		categoryThirdLevelIDs: queryParams.categoryThirdLevelIDs || undefined,
		countryCode: queryParams.countryCode || undefined,
		lastUpdatedAtFrom: queryParams.lastUpdatedAtFrom || undefined,
		lastUpdatedAtTo: queryParams.lastUpdatedAtTo || undefined,
		createType: queryParams.createType || undefined,
		statuses: [...new Set(statuses)],
		hasSetOpeningHours,
		sourceType: queryParams.sourceType || undefined,
		premiumSourceUserType: queryParams.premiumSourceUserType || undefined,
		assignedUserID,
		enabledReservationsSetting,
		hasAvailableReservationSystem,
		walletAvailableBalanceFrom: queryParams.walletAvailableBalanceFrom || undefined,
		walletAvailableBalanceTo: queryParams.walletAvailableBalanceTo || undefined,
		hasDaysWithAvailableReservationSlot,
		hasUpcomingReservations
	}
}

export const getSalons =
	(queryParams: IGetSalonsQueryParams): ThunkResult<Promise<ISalonsPayload>> =>
	async (dispatch) => {
		let payload = {} as ISalonsPayload

		try {
			dispatch({ type: SALONS.SALONS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/salons/', { params: { query: getSalonsRequestQueryParams(queryParams) }, reqBody: {} })
			const salonsOptions = map(data.salons, (salon) => {
				return { label: salon.name || `${salon.id}`, value: salon.id, key: `${salon.id}-key` }
			})

			payload = {
				data,
				options: salonsOptions
			}

			dispatch({ type: SALONS.SALONS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SALONS.SALONS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const emptySalons = (): ThunkResult<Promise<void>> => async (dispatch) => {
	dispatch({ type: SALONS.SALONS_LOAD_DONE, payload: { data: null, options: [] } })
}

export const getSalon =
	(salonID: string): ThunkResult<Promise<ISalonPayload>> =>
	async (dispatch) => {
		let payload = {} as ISalonPayload
		try {
			dispatch({ type: SALON.SALON_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/salons/{salonID}', { params: { path: { salonID } }, reqBody: {} })
			payload = {
				data
			}
			dispatch({ type: SALON.SALON_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SALON.SALON_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const emptySalon = (): ThunkResult<Promise<void>> => async (dispatch) => {
	dispatch({ type: SALON.SALON_LOAD_DONE, payload: { data: null } })
}

export const getSalonHistory =
	(queryParams: IGetSalonsHistoryQueryParams): ThunkResult<Promise<ISalonHistoryPayload>> =>
	async (dispatch) => {
		let payload = {} as ISalonHistoryPayload
		try {
			const { salonID, ...query } = queryParams
			dispatch({ type: SALON_HISTORY.SALON_HISTORY_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/salons/{salonID}/history', { params: { path: { salonID }, query }, reqBody: {} })
			payload = {
				data
			}
			dispatch({ type: SALON_HISTORY.SALON_HISTORY_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SALON_HISTORY.SALON_HISTORY_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getSalonNonCheckedChanges =
	(salonID: string): ThunkResult<Promise<ISalonNonCheckedChangesPayload>> =>
	async (dispatch) => {
		let payload = {} as ISalonNonCheckedChangesPayload
		try {
			dispatch({ type: SALON_NON_CHECKED_CHANGES.SALON_NON_CHECKED_CHANGES_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/salons/{salonID}/non-checked-changes', { params: { path: { salonID } }, reqBody: {} })

			let payloadData: ISalonNonCheckedChangesPayload['data'] = {} as ISalonNonCheckedChangesPayload['data']

			if (!isEmpty(data.salon.changes)) {
				const parsedData = partialSafeParse(nonCheckedChangesSchema, data.salon.changes)

				payloadData = {
					...data.salon,
					changes: parsedData.validData
				}

				if (import.meta.env.DEV && !isEmpty(parsedData.invalidData)) {
					// eslint-disable-next-line no-console
					console.warn('Non checked changes invalid data:', parsedData.invalidData)
				}
			} else {
				payloadData = {
					...data.salon,
					changes: {}
				}
			}

			payload = {
				data: payloadData
			}

			dispatch({ type: SALON_NON_CHECKED_CHANGES.SALON_NON_CHECKED_CHANGES_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SALON_NON_CHECKED_CHANGES.SALON_NON_CHECKED_CHANGES_LOAD_FAIL })
		}
		return payload
	}

export const clearSalonNonCheckedChanges = (): ThunkResult<void> => (dispatch) => {
	dispatch({ type: SALON_NON_CHECKED_CHANGES.SALON_NON_CHECKED_CHANGES_CLEAR })
}

type GetSalonsToCheckRawQueryParams = NonNullable<RequestParams<GetUrls['/api/b2b/admin/salons/to-check']>['query']>

export const getSalonsToCheck =
	(queryParams: IGetSalonsToCheckQueryParams): ThunkResult<Promise<ISalonsToCheckPayload>> =>
	async (dispatch) => {
		let payload = {} as ISalonsToCheckPayload

		const statuses: GetSalonsToCheckRawQueryParams['statuses'] = [SALON_FILTER_STATES.NOT_DELETED]

		if (!queryParams.statuses_all) {
			if (queryParams.statuses_published) {
				statuses.push(queryParams.statuses_published)
			}
			if (queryParams.statuses_changes) {
				statuses.push(queryParams.statuses_changes)
			}
		}
		const editedQueryParams: GetSalonsToCheckRawQueryParams = {
			page: queryParams.page ?? undefined,
			limit: queryParams.limit ?? undefined,
			search: queryParams.search || undefined,
			countryCode: queryParams.countryCode || undefined,
			createType: queryParams.createType || undefined,
			statuses: [...new Set(statuses)],
			assignedUserID: queryParams.assignedUserID || undefined,
			order: queryParams.order
		}

		try {
			dispatch({ type: SALONS_TO_CHECK.SALONS_TO_CHECK_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/salons/to-check', { params: { query: editedQueryParams }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: SALONS_TO_CHECK.SALONS_TO_CHECK_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: SALONS_TO_CHECK.SALONS_TO_CHECK_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
