// eslint-disable-next-line import/prefer-default-export
export enum SMS_UNIT_PRICES_ACTUAL {
	SMS_UNIT_PRICES_ACTUAL_START = 'SMS_UNIT_PRICES_ACTUAL_START',
	SMS_UNIT_PRICES_ACTUAL_DONE = 'SMS_UNIT_PRICES_ACTUAL_DONE',
	SMS_UNIT_PRICES_ACTUAL_FAIL = 'SMS_UNIT_PRICES_ACTUAL_FAIL'
}

export enum SMS_UNIT_PRICES {
	SMS_UNIT_PRICES_START = 'SMS_UNIT_PRICES_START',
	SMS_UNIT_PRICES_DONE = 'SMS_UNIT_PRICES_DONE',
	SMS_UNIT_PRICES_FAIL = 'SMS_UNIT_PRICES_FAIL'
}

export enum SMS_UNIT_PRICE {
	SMS_UNIT_PRICE_START = 'SMS_UNIT_PRICE_START',
	SMS_UNIT_PRICE_DONE = 'SMS_UNIT_PRICE_DONE',
	SMS_UNIT_PRICE_FAIL = 'SMS_UNIT_PRICE_FAIL'
}
