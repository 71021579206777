export enum SELECTED_SALON {
	SELECTED_SALON_LOAD_START = 'SELECTED_SALON_LOAD_START',
	SELECTED_SALON_LOAD_DONE = 'SELECTED_SALON_LOAD_DONE',
	SELECTED_SALON_LOAD_FAIL = 'SELECTED_SALON_LOAD_FAIL',
	SELECTED_SALON_CLEAR = 'SELECTED_SALON_CLEAR'
}

export enum SALON_OPTIONS {
	SALON_OPTIONS_UPDATE = 'SALON_OPTIONS_UPDATE'
}

export enum SELECTED_SALON_ALLOW_NEXT_FETCH {
	SELECTED_SALON_ALLOW_NEXT_FETCH = 'SELECTED_SALON_ALLOW_NEXT_FETCH'
}
