import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router'

// utils
import { CHANGE_DEBOUNCE_TIME, CREATE_DYNAMIC_NOTIFICATION_BUTTON_ID, FORM, PERMISSION, ROW_GUTTER_X_M } from '../../../utils/enums'
import { getLinkWithEncodedBackUrl, optionRenderWithIcon } from '../../../utils/helper'

// atoms
import SelectField from '../../../atoms/SelectField'

// assets
import GlobeIcon from '../../../assets/icons/globe-icon.svg?react'
import PlusIcon from '../../../assets/icons/plus-icon.svg?react'

// utils
import { languageOptions } from '../../../components/LanguagePicker'
import Permissions from '../../../utils/Permissions'

// types
import { IDynamicNotificationsFilter } from '../../../types/interfaces'

type ComponentProps = {}

type Props = InjectedFormProps<IDynamicNotificationsFilter, ComponentProps> & ComponentProps

const DynamicNotificationsFilter = (props: Props) => {
	const { handleSubmit } = props
	const [t] = useTranslation()
	const navigate = useNavigate()

	return (
		<Form layout='horizontal' onSubmitCapture={handleSubmit} className={'pt-0'}>
			<Row gutter={ROW_GUTTER_X_M} justify={'space-between'}>
				<Col flex={'auto'} md={12} xl={8}>
					<Field
						component={SelectField}
						optionRender={(itemData: any) => optionRenderWithIcon(itemData, <GlobeIcon />)}
						name={'language'}
						placeholder={t('loc:Jazyk')}
						allowClear
						size={'large'}
						options={languageOptions}
					/>
				</Col>
				<Permissions
					allowed={[PERMISSION.DYNAMIC_NOTIFICATION_CREATE]}
					render={(hasPermission, { openForbiddenModal }) => (
						<Button
							id={CREATE_DYNAMIC_NOTIFICATION_BUTTON_ID}
							onClick={() => {
								if (hasPermission) {
									navigate(getLinkWithEncodedBackUrl(t('paths:notifications/create')))
								} else {
									openForbiddenModal()
								}
							}}
							type='primary'
							htmlType='button'
							className={'noti-btn'}
							icon={<PlusIcon />}
						>
							{t('loc:Vytvoriť upozornenie')}
						</Button>
					)}
				/>
			</Row>
		</Form>
	)
}

const form = reduxForm<IDynamicNotificationsFilter, ComponentProps>({
	form: FORM.DYNAMIC_NOTIFICATIONS_FILTER,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, CHANGE_DEBOUNCE_TIME),
	destroyOnUnmount: true
})(DynamicNotificationsFilter)

export default form
