import { OnboardingTooltipState } from '../../types/interfaces'
import { ONBOARDING_TOOLTIP_ID, ONBOARDING_TOOLTIP_GROUP } from '../../utils/enums'

// eslint-disable-next-line import/no-cycle
import { IOnboardingTooltipActions } from './onboardingTooltipsActions'
import { SET_ONBOARDING_TOOLTIP_ACTIVE_STATE } from './onboardingTooltipsTypes'

export interface IOnboardingTooltipStateData {
	data: Record<ONBOARDING_TOOLTIP_GROUP, OnboardingTooltipState[]>
}

export const initState: { onboardingTooltips: IOnboardingTooltipStateData; seenTooltipIDs: ONBOARDING_TOOLTIP_ID[] } = {
	onboardingTooltips: {
		data: {
			[ONBOARDING_TOOLTIP_GROUP.CALENDAR]: [
				{
					id: ONBOARDING_TOOLTIP_ID.CALENDAR_SERVICES_COLORS,
					isActive: false
				}
			],
			[ONBOARDING_TOOLTIP_GROUP.SERVICE_DETAIL]: [
				{
					id: ONBOARDING_TOOLTIP_ID.SERVICE_DETAIL_COLOR,
					isActive: false
				}
			],
			[ONBOARDING_TOOLTIP_GROUP.EMPLOYEE_DETAIL]: [
				{
					id: ONBOARDING_TOOLTIP_ID.EMPLOYEE_DETAIL_COLOR,
					isActive: false
				}
			]
		}
	},
	/**
	 * NOTE: only seenTooltipIDs are stored in localStorage, so the application works correctly, when onboardingTooltips data will change
	 */
	seenTooltipIDs: []
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: IOnboardingTooltipActions) => {
	switch (action.type) {
		// get async job state
		case SET_ONBOARDING_TOOLTIP_ACTIVE_STATE:
			return {
				...state,
				onboardingTooltips: {
					...state.onboardingTooltips,
					data: {
						...state.onboardingTooltips.data,
						[action.payload.group]: state.onboardingTooltips.data[action.payload.group].map((tooltip) =>
							tooltip.id === action.payload.id ? { ...tooltip, isActive: action.payload.isActive } : tooltip
						)
					}
				},
				seenTooltipIDs: !action.payload.isActive ? [...state.seenTooltipIDs, action.payload.id] : state.seenTooltipIDs
			}
		default:
			return state
	}
}
