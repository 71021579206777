import React from 'react'
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form'
import { Col, Form, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce, filter, isArray, isEmpty, isNil, size } from 'lodash'

// components
import { useSelector } from 'react-redux'
import Filters from '../../../../components/Filters'

// reducers
import { RootState } from '../../../../reducers'

// assets
import GlobeIcon from '../../../../assets/icons/globe-icon.svg?react'
import CategoryIcon from '../../../../assets/icons/categories-icon.svg?react'

// utils
import { CHANGE_DEBOUNCE_TIME, FIELD_MODE, FORM, ROW_GUTTER_X_M, VALIDATION_MAX_LENGTH } from '../../../../utils/enums'
import { optionRenderWithImage } from '../../../../utils/helper'
import { getCategoryThirdLevelIDsOptions } from '../salonUtils'

// atoms
import InputField from '../../../../atoms/InputField'
import SelectField from '../../../../atoms/SelectField'

// types
import { ISalonsDeletedFilter } from '../../../../types/interfaces'

type ComponentProps = {}

type Props = InjectedFormProps<ISalonsDeletedFilter, ComponentProps> & ComponentProps

export const checkSalonFiltersSize = (formValues: Partial<ISalonsDeletedFilter> | undefined) =>
	size(
		filter(formValues, (value, key) => {
			if (typeof value === 'boolean') {
				return value
			}
			if (isArray(value) && isEmpty(value)) {
				return false
			}
			return (!isNil(value) || !isEmpty(value)) && key !== 'search'
		})
	)

const SalonsFilterDeleted = (props: Props) => {
	const { handleSubmit } = props
	const [t] = useTranslation()

	const formValues: Partial<ISalonsDeletedFilter> | undefined = useSelector((state: RootState) => getFormValues(FORM.SALONS_FILTER_DELETED)(state))
	const categories = useSelector((state: RootState) => state.categories.categories)
	const config = useSelector((state: RootState) => state.config.config)

	return (
		<Form layout='horizontal' onSubmitCapture={handleSubmit} className={'pt-0'}>
			<Filters
				search={
					<Field
						className={'h-10 p-0 m-0'}
						component={InputField}
						size={'large'}
						placeholder={t('loc:Hľadať podľa názvu, adresy alebo ID')}
						name={'search'}
						fieldMode={FIELD_MODE.FILTER}
						search
						maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
					/>
				}
				activeFilters={checkSalonFiltersSize(formValues)}
				form={FORM.SALONS_FILTER_DELETED}
			>
				<Row gutter={ROW_GUTTER_X_M}>
					<Col span={4}>
						<Field
							component={SelectField}
							optionRender={(itemData: any) => optionRenderWithImage(itemData, <GlobeIcon />)}
							name={'countryCode'}
							placeholder={t('loc:Krajina')}
							allowClear
							size={'large'}
							options={config.optionsRolloutCountries}
							loading={config.isLoading}
							disabled={config.isLoading}
						/>
					</Col>
					<Col span={10}>
						<Field
							component={SelectField}
							placeholder={t('loc:Odvetvie')}
							name={'categoryFirstLevelIDs'}
							size={'large'}
							mode={'multiple'}
							showSearch
							loading={categories?.isLoading}
							disabled={categories?.isLoading}
							optionRender={(itemData: any) => optionRenderWithImage(itemData, <CategoryIcon />)}
							allowClear
							filterOption
							options={categories?.enumerationsOptions}
						/>
					</Col>
					<Col span={10}>
						<Field
							component={SelectField}
							placeholder={t('loc:Služby')}
							name={'categoryThirdLevelIDs'}
							mode={'multiple'}
							size={'large'}
							showSearch
							loading={categories?.isLoading}
							disabled={categories?.isLoading}
							allowClear
							filterOption
							options={getCategoryThirdLevelIDsOptions(categories.data)}
							optionRender={(itemData: any) => optionRenderWithImage(itemData, <CategoryIcon />)}
						/>
					</Col>
				</Row>
			</Filters>
		</Form>
	)
}

const form = reduxForm({
	form: FORM.SALONS_FILTER_DELETED,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, CHANGE_DEBOUNCE_TIME),
	destroyOnUnmount: true
})(SalonsFilterDeleted)

export default form
