import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

// dayjs
import dayjs from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import timezonePlugin from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import localeData from 'dayjs/plugin/localeData'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import updateLocale from 'dayjs/plugin/updateLocale'
import isToday from 'dayjs/plugin/isToday'

import App from './App'
// load theme styles with webpack
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// utils
import { detectBrowserType } from './utils/helper'
import { initAppBroadcastChannel } from './utils/broadcast'

// hooks
import { getNumberOfTabs } from './hooks/useTabsCounter'

// styles
import './styles/main.css'
import './styles/global.sass'

// env
import envConfig from './config'

// dayjs extended plugins
dayjs.extend(isBetween)
dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)
dayjs.extend(minMax)
dayjs.extend(relativeTime)
dayjs.extend(localeData)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(updateLocale)
dayjs.extend(isToday)

const OPEN_TABS_PER_SESSION_SENTRY_TAG = 'opened_tabs_per_session'

Sentry.init({
	release: envConfig.APP_VERSION,
	enabled: import.meta.env.PROD,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		})
	],
	// eslint-disable-next-line no-underscore-dangle
	environment: envConfig.VITE_SENTRY_ENV,
	// eslint-disable-next-line no-underscore-dangle
	dsn: envConfig.VITE_SENTRY_DSN,
	tracesSampleRate: 0.33, // 33% of transactions will be sent to Sentry
	beforeSend: (event) => {
		if (event.tags) {
			// eslint-disable-next-line no-param-reassign
			event.tags[OPEN_TABS_PER_SESSION_SENTRY_TAG] = getNumberOfTabs()
		}
		return event
	}
})

Sentry.setTag('browser_type', detectBrowserType())

initAppBroadcastChannel()

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(<App />)
