import React, { FC } from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { Form } from 'antd'
import { debounce } from 'lodash'

// utils
import { showErrorNotification } from '../../../utils/helper'
import { CHANGE_DEBOUNCE_TIME, FORM, LANGUAGE } from '../../../utils/enums'

// components
import { LanguagePickerField } from '../../../components/LanguagePicker'

// types
import { IAccountSettingsForm } from '../../../types/interfaces'

// validation
import { validationAccountSettingsFn } from '../../../utils/formValidation'
import { pushEventToDataLayer } from '../../../utils/dataLayer'
import { DATA_LAYER_EVENTS, GA_DROPDOWN_NAME, GA_SCREEN_NAME } from '../../../utils/dataLayerEnums'

type ComponentProps = {}

type Props = InjectedFormProps<IAccountSettingsForm, ComponentProps> & ComponentProps

const AccountSettingsForm: FC<Props> = (props) => {
	const { handleSubmit } = props

	return (
		<Form onSubmitCapture={handleSubmit} layout={'vertical'} className={'form'}>
			<LanguagePickerField
				name={'languageCode'}
				className={'pb-6 z-50'}
				onDropdownVisibleChange={(open: boolean) => {
					if (open) {
						pushEventToDataLayer({
							event: DATA_LAYER_EVENTS.OPEN_DROPDOWN,
							dropdown_name: GA_DROPDOWN_NAME.LANGUAGE_SELECTION,
							screen_name: GA_SCREEN_NAME.LANGUAGE_SETTINGS
						})
					}
				}}
				onSelect={(language: LANGUAGE) => {
					pushEventToDataLayer({
						event: DATA_LAYER_EVENTS.SELECT_DROPDOWN_MENU_OPTION,
						dropdown_name: GA_DROPDOWN_NAME.LANGUAGE_SELECTION,
						screen_name: GA_SCREEN_NAME.LANGUAGE_SETTINGS,
						selected_option: language
					})
				}}
			/>
		</Form>
	)
}

const form = reduxForm<IAccountSettingsForm, ComponentProps>({
	form: FORM.ACCOUNT_SETTINGS,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, CHANGE_DEBOUNCE_TIME),
	destroyOnUnmount: true,
	validate: validationAccountSettingsFn,
	onSubmitFail: showErrorNotification
})(AccountSettingsForm)

export default form
