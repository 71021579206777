import React from 'react'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Row, TabsProps } from 'antd'
import { useNavigate } from 'react-router-dom'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import TabsComponent from '../../components/TabsComponent'
import CustomServicesTab from './CustomServicesTab'
import CategoriesTab from './CategoriesTab'

// types
import { IBreadcrumbs } from '../../types/interfaces'

// utils
import { withPermissions } from '../../utils/Permissions'
import { CATEGORIES_TAB_KEYS, PERMISSION } from '../../utils/enums'

type Props = {
	tabKey: CATEGORIES_TAB_KEYS
}

const CategoriesPage = (props: Props) => {
	const [t] = useTranslation()
	const navigate = useNavigate()

	const { tabKey } = props

	const onTabChange = (newTabKey: string) => {
		if (newTabKey === CATEGORIES_TAB_KEYS.CATEGORIES) {
			navigate(t('paths:categories'))
		} else if (newTabKey === CATEGORIES_TAB_KEYS.CUSTOM_SERVICES) {
			navigate(t('paths:custom-services'))
		}
	}
	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam kategórií')
			}
		]
	}

	const tabContent: TabsProps['items'] = [
		{
			key: CATEGORIES_TAB_KEYS.CATEGORIES,
			label: t('loc:Kategórie'),
			children: <CategoriesTab />
		},
		{
			key: CATEGORIES_TAB_KEYS.CUSTOM_SERVICES,
			label: t('loc:Vlastné služby'),
			children: <CustomServicesTab />
		}
	]

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} backButtonPath={t('paths:index')} />
			</Row>

			<TabsComponent className={'box-tab'} activeKey={tabKey} onChange={onTabChange} items={tabContent} destroyInactiveTabPane />
		</>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO, PERMISSION.CATEGORY_EDIT]))(CategoriesPage)
