import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initialize } from 'redux-form'
import * as Sentry from '@sentry/react'

// components
import UserLanguageSelectionForm from './UserLanguageSelectionForm'

// utils
import { FORM } from '../utils/enums'
import { getLanguageCode } from '../utils/intl'
import { handleLanguageChange } from './LanguagePicker'

// redux
import { logOutUser } from '../reducers/users/userActions'

// types
import { IUserLanguageSelectionForm } from '../types/interfaces'
import { RootState } from '../reducers'

type Props = {
	setLoadingLanguage: (loading: boolean) => void
}

const LanguageInit = (props: Props) => {
	const { setLoadingLanguage } = props

	const dispatch = useDispatch()
	const [t] = useTranslation()

	const currentUser = useSelector((state: RootState) => state.user.authUser)

	const [languageModal, setLanguageModal] = useState<'default' | 'success' | null>(null)
	const [submitting, setSubmitting] = useState(false)

	const handleSubmitUserLanguage = async (values: IUserLanguageSelectionForm) => {
		setSubmitting(true)
		await handleLanguageChange(values.languageCode, values.userId, () => setLanguageModal('success'))
		setSubmitting(false)
	}

	const handleCloseLanguageModal = () => {
		// reload application on close to be sure all data use new language
		document.location.reload()
		setLanguageModal(null)
	}

	useEffect(() => {
		;(async () => {
			if (!currentUser.data?.id) {
				dispatch(logOutUser())
				return
			}

			// init user language
			if (currentUser.data.language) {
				await i18next.changeLanguage(currentUser.data.language)
			} else {
				// if user haven't selected language already, show him modal to select his language
				const initData: IUserLanguageSelectionForm = {
					userId: currentUser.data.id,
					languageCode: getLanguageCode(i18next.resolvedLanguage, i18next.language)
				}
				dispatch(initialize(FORM.USER_LANGUAGE_SELECTION, initData))
				setLanguageModal('default')
			}

			// init sentry
			Sentry.setUser({ id: currentUser.data.id })
			setLoadingLanguage(false)
		})()
		return () => {
			Sentry.setUser(null)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser.data?.id, currentUser.data?.language])

	return languageModal ? (
		<Modal
			width={500}
			open
			closable={false}
			maskClosable={false}
			title={languageModal === 'default' ? t('loc:Vyberte si jazyk') : t('loc:Jazyk aplikácie bol zmenený')}
			footer={null}
		>
			<Spin spinning={submitting}>
				{languageModal === 'default' ? (
					<UserLanguageSelectionForm onSubmit={handleSubmitUserLanguage} loading={submitting} />
				) : (
					<>
						<p className={'text-base mb-6'}>
							{t(
								'loc:Vybraný jazyk mobilnej aplikácie aj Admin aplikácie Notino partner bol zmenený. V tomto jazyku vám budú prichádzať aj všetky upozornenia vrátane sms a emailov.'
							)}
						</p>
						<Button
							className='noti-btn w-full'
							size='large'
							type='primary'
							htmlType='button'
							disabled={submitting}
							loading={submitting}
							onClick={handleCloseLanguageModal}
						>
							{t('loc:Rozumiem')}
						</Button>
					</>
				)}
			</Spin>
		</Modal>
	) : null
}

export default LanguageInit
