import React, { useEffect, FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Divider, Row, Spin } from 'antd'
import { compose } from 'redux'
import { useParams } from 'react-router-dom'
import { initialize } from 'redux-form'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import DynamicNotificationForm from './components/DynamicNotificationsForm'

// types
import { IBreadcrumbs, IDynamicNotificationsForm } from '../../types/interfaces'
import { RootState } from '../../reducers'

// utils
import { withPermissions } from '../../utils/Permissions'
import { DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE, FORM, PERMISSION } from '../../utils/enums'
import { getAssignedUserLabel } from '../../utils/helper'
import { LocalizedDateTime } from '../../utils/intl'

// hooks
import useBackUrl from '../../hooks/useBackUrl'

// assets
import BellIcon from '../../assets/icons/bell-icon.svg?react'

// redux
import { getDynamicNotification } from '../../reducers/dynamicNotifications/dynamicNotificationsActions'
import { IUserDocumentPayload, getUserDocument } from '../../reducers/users/userActions'

type Props = {}

const DynamicNotificationPage: FC<Props> = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const { dynamicNotificationID } = useParams<{ dynamicNotificationID?: string }>()

	const authUser = useSelector((state: RootState) => state.user.authUser)
	const dynamicNotification = useSelector((state: RootState) => state.dynamicNotifications.dynamicNotification)

	const [loadingDocuments, setLoadingDocuments] = useState(false)

	const [backUrl] = useBackUrl(t('paths:notifications'))

	useEffect(() => {
		;(async () => {
			if (dynamicNotificationID) {
				const { data } = await dispatch(getDynamicNotification(dynamicNotificationID))
				const initData: IDynamicNotificationsForm = {
					selectedLanguages: [],
					localizations: {}
				}

				data?.dynamicNotification.contentLocalizations.forEach((content) => {
					if (!initData.selectedLanguages.includes(content.language)) {
						initData.selectedLanguages.push(content.language)
					}
					initData.localizations[content.language] = {
						...(initData.localizations[content.language] || {}),
						content: content.value,
						notificationType: DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE.OTHER
					}
				})

				data?.dynamicNotification.titleLocalizations.forEach((title) => {
					if (!initData.selectedLanguages.includes(title.language)) {
						initData.selectedLanguages.push(title.language)
					}
					initData.localizations[title.language] = {
						...(initData.localizations[title.language] || {}),
						title: title.value,
						notificationType: DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE.OTHER
					}
				})

				const documentsByLanguagePromises: { language: string; request: Promise<IUserDocumentPayload> }[] = []

				data?.dynamicNotification.localizedNotificationData?.forEach((item) => {
					if (item.type === DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE.DOCUMENT) {
						if (authUser.data?.id && item.payload?.documentID) {
							documentsByLanguagePromises.push({ language: item.language, request: dispatch(getUserDocument(authUser.data.id, item.payload.documentID)) })
						} else {
							if (!initData.selectedLanguages.includes(item.language)) {
								initData.selectedLanguages.push(item.language)
							}
							initData.localizations[item.language] = {
								...(initData.localizations[item.language] || {}),
								document: [],
								notificationType: DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE.DOCUMENT
							}
						}
					} else {
						if (!initData.selectedLanguages.includes(item.language)) {
							initData.selectedLanguages.push(item.language)
						}
						initData.localizations[item.language] = {
							...(initData.localizations[item.language] || {}),
							externalLink: item.payload?.externalLink?.url,
							notificationType: DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE.OTHER
						}
					}
				})

				if (documentsByLanguagePromises.length) {
					try {
						setLoadingDocuments(true)
						const documentsData = await Promise.all(documentsByLanguagePromises.map((promise) => promise.request))

						data?.dynamicNotification.localizedNotificationData?.forEach((item) => {
							const documentDataIndex = documentsByLanguagePromises.findIndex((promise) => promise.language === item.language)
							const documentData = documentsData[documentDataIndex].data?.document

							if (!initData.selectedLanguages.includes(item.language)) {
								initData.selectedLanguages.push(item.language)
							}
							initData.localizations[item.language] = {
								...(initData.localizations[item.language] || {}),
								notificationType: DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE.DOCUMENT,
								document: documentData
									? documentData.files.map((file) => ({
											id: file.id,
											name: file.fileName,
											link: file.original
										}))
									: []
							}
						})
					} catch (e) {
						// eslint-disable-next-line no-console
						console.error(e)
					} finally {
						setLoadingDocuments(false)
					}
				}

				dispatch(initialize(FORM.DYNAMIC_NOTIFICATIONS, initData))
			}
		})()
	}, [dispatch, dynamicNotificationID, authUser.data?.id])

	// View
	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam upozornení'),
				link: backUrl
			},
			{
				name: t('loc:Detail upozornenia')
			}
		]
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} backButtonPath={t('paths:index')} />
			</Row>

			<div className='content-body medium'>
				<Spin spinning={dynamicNotification?.isLoading || loadingDocuments}>
					<div className={'flex items-center w-full'}>
						<BellIcon className={'mr-2 flex-shrink-0'} />
						<h3 className={'mb-0'}>{t('loc:Detail upozornenia')}</h3>
					</div>
					<Divider className={'mb-6 mt-3'} />
					<div className={'table-row'}>
						<span className={'table-cell pr-6 pb-3'}>
							<strong>{t('loc:Vytvorená používateľom')}:</strong>
						</span>
						<span className={'table-cell pr-6 pb-3'}>{getAssignedUserLabel(dynamicNotification.data?.dynamicNotification.creator)}</span>
					</div>
					<div className={'table-row'}>
						<span className={'table-cell pr-6'}>
							<strong>{t('loc:Vytvorená dňa')}:</strong>
						</span>
						<span className={'table-cell pr-6'}>
							<LocalizedDateTime date={dynamicNotification.data?.dynamicNotification.createdAt} timeStyle={null} />
						</span>
					</div>
					<Divider className={'my-4'} />
					<DynamicNotificationForm readOnly />
				</Spin>
			</div>
		</>
	)
}

export default compose(withPermissions([PERMISSION.DYNAMIC_NOTIFICATION_BROWSING, PERMISSION.DYNAMIC_NOTIFICATION_CREATE]))(DynamicNotificationPage)
