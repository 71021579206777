/* eslint-disable import/no-cycle */
import { RESET_STORE } from '../generalTypes'
import { ILoadingAndFailure, IEmployeePayload, IEmployeesPayload, IDeletedEmployeesPayload, IActiveEmployeesPayload, IEmployeeShiftsPayload } from '../../types/interfaces'
import { ACTIVE_EMPLOYEES, DELETED_EMPLOYEES, EMPLOYEE, EMPLOYEES, EMPLOYEE_SHIFTS } from './employeesTypes'
import { IEmployeesActions } from './employeesActions'

// eslint-disable-next-line import/prefer-default-export
export const initState = {
	employees: {
		data: null,
		options: [],
		tableData: [],
		isLoading: false,
		isFailure: false
	} as IEmployeesPayload & ILoadingAndFailure,
	deletedEmployees: {
		data: null,
		tableData: [],
		isLoading: false,
		isFailure: false
	} as IDeletedEmployeesPayload & ILoadingAndFailure,
	activeEmployees: {
		data: null,
		tableData: [],
		options: [],
		isLoading: false,
		isFailure: false
	} as IActiveEmployeesPayload & ILoadingAndFailure,
	employee: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IEmployeePayload & ILoadingAndFailure,
	employeeShifts: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IEmployeeShiftsPayload & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: IEmployeesActions) => {
	switch (action.type) {
		// Employees
		case EMPLOYEES.EMPLOYEES_LOAD_START:
			return {
				...state,
				employees: {
					...state.employees,
					isLoading: true
				}
			}
		case EMPLOYEES.EMPLOYEES_LOAD_FAIL:
			return {
				...state,
				employees: {
					...initState.employees,
					isFailure: true
				}
			}
		case EMPLOYEES.EMPLOYEES_LOAD_DONE:
			return {
				...state,
				employees: {
					...initState.employees,
					data: action.payload.data,
					tableData: action.payload.tableData,
					options: action.payload.options
				}
			}
		// Deleted Employees
		case DELETED_EMPLOYEES.DELETED_EMPLOYEES_LOAD_START:
			return {
				...state,
				deletedEmployees: {
					...state.deletedEmployees,
					isLoading: true
				}
			}
		case DELETED_EMPLOYEES.DELETED_EMPLOYEES_LOAD_FAIL:
			return {
				...state,
				deletedEmployees: {
					...initState.deletedEmployees,
					isFailure: true
				}
			}
		case DELETED_EMPLOYEES.DELETED_EMPLOYEES_LOAD_DONE:
			return {
				...state,
				deletedEmployees: {
					...initState.deletedEmployees,
					data: action.payload.data,
					tableData: action.payload.tableData,
					options: action.payload.options
				}
			}
		// Active Employees
		case ACTIVE_EMPLOYEES.ACTIVE_EMPLOYEES_LOAD_START:
			return {
				...state,
				activeEmployees: {
					...state.activeEmployees,
					isLoading: true
				}
			}
		case ACTIVE_EMPLOYEES.ACTIVE_EMPLOYEES_LOAD_FAIL:
			return {
				...state,
				activeEmployees: {
					...initState.activeEmployees,
					isFailure: true
				}
			}
		case ACTIVE_EMPLOYEES.ACTIVE_EMPLOYEES_LOAD_DONE:
			return {
				...state,
				activeEmployees: {
					...initState.activeEmployees,
					data: action.payload.data,
					tableData: action.payload.tableData,
					options: action.payload.options
				}
			}
		// Reorder
		case EMPLOYEES.EMPLOYEES_REORDER:
			return {
				...state,
				activeEmployees: {
					...state.activeEmployees,
					tableData: action.payload.tableData
				}
			}
		// Employee
		case EMPLOYEE.EMPLOYEE_LOAD_START:
			return {
				...state,
				employee: {
					...state.employee,
					isLoading: true
				}
			}
		case EMPLOYEE.EMPLOYEE_LOAD_FAIL:
			return {
				...state,
				employee: {
					...initState.employee,
					isFailure: true
				}
			}
		case EMPLOYEE.EMPLOYEE_LOAD_DONE:
			return {
				...state,
				employee: {
					...initState.employee,
					data: action.payload.data
				}
			}
		// Employee shifts
		case EMPLOYEE_SHIFTS.EMPLOYEE_SHIFTS_LOAD_START:
			return {
				...state,
				employeeShifts: {
					...state.employeeShifts,
					isLoading: true
				}
			}
		case EMPLOYEE_SHIFTS.EMPLOYEE_SHIFTS_LOAD_FAIL:
			return {
				...state,
				employeeShifts: {
					...initState.employeeShifts,
					isFailure: true
				}
			}
		case EMPLOYEE_SHIFTS.EMPLOYEE_SHIFTS_LOAD_DONE:
			return {
				...state,
				employeeShifts: {
					...initState.employeeShifts,
					data: action.payload.data
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
