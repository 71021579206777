import React from 'react'
import { Modal, ModalProps, Button } from 'antd'
import { useTranslation } from 'react-i18next'

// assets
import CloseIcon from '../../../assets/icons/close-icon-modal.svg?react'
import AlertTriangleIcon from '../../../assets/icons/alert-triangle-icon.svg?react'

// utils
import { LANGUAGE, LOCALES } from '../../../utils/enums'
import { isEnumValue } from '../../../utils/intl'

export type ErrorReport = {
	mainFeaturesCount: number
	hasOtherFeatures: boolean
}

export type ErrorsReportModalProps = {
	open: boolean
	onCancel: ModalProps['onCancel']
	errorReports: Record<LANGUAGE, ErrorReport>
}

const ErrorsReportModal = (props: ErrorsReportModalProps) => {
	const { open, onCancel, errorReports } = props
	const [t] = useTranslation()

	return (
		<Modal className='noti-changelog-modal' open={open} destroyOnClose footer={null} onCancel={onCancel} closeIcon={<CloseIcon />} width={480} maskClosable keyboard>
			<div className={'noti-changelog-modal-body'}>
				<header className={'noti-changelog-modal-header flex flex-col gap-2'}>
					<h1 className={'text-2xl font-bold text-notino-black max-w-72 mb-0 flex gap-2 items-center'}>
						<AlertTriangleIcon className={'flex-shrink-0'} />
						<span>{t('loc:Chyba validácie')}</span>
					</h1>
					<p className={'text-sm text-notino-grayDarker font-normal'}>
						{t('loc:Zadané údaje pre jednotlivé jazyky nie sú konzistentné. Upravte údaje a skúste to znova.')}
					</p>
				</header>
				<main className={'noti-changelog-modal-content flex flex-col gap-4'}>
					{Object.entries(errorReports).map(([lng, data]) => {
						return (
							<div key={lng}>
								<h2 className={'text-base text-bold text-notino-black font-semibold mb-0 uppercase'}>
									{isEnumValue(lng, LANGUAGE) ? LOCALES[lng].countryCode : lng}:
								</h2>
								<span className={'text-sm text-notino-grayDark font-normal'}>{`${t('loc:Počet hlavných funkcionalít')}: ${data.mainFeaturesCount}`}</span>
								<br />
								<span className={'text-sm text-notino-grayDark font-normal'}>
									{`${t('loc:Zadané ďalšie funkcionality')}: ${data.hasOtherFeatures ? t('loc:Áno') : t('loc:Nie')}`}
								</span>
							</div>
						)
					})}
				</main>
				<footer className={'noti-changelog-modal-footer'}>
					<Button className='noti-btn' block size='large' type='primary' htmlType='button' onClick={onCancel}>
						{t('loc:Zrušiť')}
					</Button>
				</footer>
			</div>
		</Modal>
	)
}

export default ErrorsReportModal
