/* eslint-disable import/no-cycle */
import { RESET_STORE } from '../generalTypes'
import { ILoadingAndFailure } from '../../types/interfaces'
import { CHANGELOG, CHANGELOGS } from './changelogsTypes'
import { IChangelogPayload, IChangelogsActions, IChangelogsPayload } from './changelogsActions'

export const initState = {
	changelogs: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IChangelogsPayload & ILoadingAndFailure,
	changelog: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IChangelogPayload & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: IChangelogsActions) => {
	switch (action.type) {
		case CHANGELOGS.CHANGELOGS_LOAD_START:
			return {
				...state,
				changelogs: {
					...state.changelogs,
					isLoading: true
				}
			}
		case CHANGELOGS.CHANGELOGS_LOAD_FAIL:
			return {
				...state,
				changelogs: {
					...initState.changelogs,
					isFailure: true
				}
			}
		case CHANGELOGS.CHANGELOGS_LOAD_DONE:
			return {
				...state,
				changelogs: {
					...initState.changelogs,
					data: action.payload.data
				}
			}
		case CHANGELOG.CHANGELOG_LOAD_START:
			return {
				...state,
				changelog: {
					...state.changelog,
					isLoading: true
				}
			}
		case CHANGELOG.CHANGELOG_LOAD_FAIL:
			return {
				...state,
				changelog: {
					...initState.changelog,
					isFailure: true
				}
			}
		case CHANGELOG.CHANGELOG_LOAD_DONE:
			return {
				...state,
				changelog: {
					...initState.changelog,
					data: action.payload.data
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
