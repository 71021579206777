import React, { useState } from 'react'
import { Field, Fields, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Row, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import cx from 'classnames'

// assets
import GlobeIcon from '../../../assets/icons/globe-icon.svg?react'
import CategoryIcon from '../../../assets/icons/categories-icon.svg?react'
import FilesIcon from '../../../assets/icons/files-icon.svg?react'

// components
import InputField from '../../../atoms/InputField'
import SelectField from '../../../atoms/SelectField'
import DateRangeField from '../../../atoms/DateRangeField'
import Filters from '../../../components/Filters'
import AsyncJobsModal from '../../../components/AsyncJobsModal/AsyncJobsModal'

// utils
import {
	FIELD_MODE,
	FORM,
	ROW_GUTTER_X_M,
	CHANGE_DEBOUNCE_TIME,
	CUSTOM_SERVICE_CHECKED_STATE,
	ASYNC_JOB_TYPE,
	VALIDATION_MAX_LENGTH,
	OPEN_ASYNC_JOB_MODAL_BUTTON_ID
} from '../../../utils/enums'
import { optionRenderWithImage, optionRenderWithTag, formFieldID, checkFiltersSize } from '../../../utils/helper'
import Permissions from '../../../utils/Permissions'

// types
import { RootState } from '../../../reducers'
import { ICustomServicesPageURLQueryParams } from '../../../types/schemaTypes'
import { ICustomServicesFilter, ISelectOptionItemWithChildren } from '../../../types/interfaces'
import { ICategoriesPayload } from '../../../reducers/categories/categoriesActions'

type ComponentProps = {
	query: ICustomServicesPageURLQueryParams
	loading: boolean
}

type Props = InjectedFormProps<ICustomServicesFilter, ComponentProps> & ComponentProps

const getSecondLevelCategoriesOptions = (categories: ICategoriesPayload['data']): ISelectOptionItemWithChildren[] => {
	return (
		categories?.map((industry) => {
			return {
				key: industry.id,
				label: industry.name || industry.id,
				children: industry.children.map((category) => {
					return {
						key: category.id,
						value: category.id,
						label: category.name || category.id,
						extra: {
							image: industry.image?.resizedImages.thumbnail || industry.image?.original
						}
					}
				})
			}
		}) || []
	)
}

const CustomServicesFilter = (props: Props) => {
	const { handleSubmit, query, loading } = props
	const [t] = useTranslation()

	const form = useSelector((state: RootState) => state.form?.[FORM.CUSTOM_SERVICES_FILTER])
	const categories = useSelector((state: RootState) => state.categories.categories)
	const config = useSelector((state: RootState) => state.config.config)
	const totalCount = useSelector((state: RootState) => state.categories.customServices.data?.pagination.totalCount)

	const [reportModalVisible, setReportModalVisible] = useState(false)

	const checkedOptions = [
		{ label: t('loc:Skontrolované'), value: CUSTOM_SERVICE_CHECKED_STATE.CHECKED, key: CUSTOM_SERVICE_CHECKED_STATE.CHECKED, tagClassName: 'bg-status-success' },
		{ label: t('loc:Neskontrolované'), value: CUSTOM_SERVICE_CHECKED_STATE.NOT_CHECKED, key: CUSTOM_SERVICE_CHECKED_STATE.NOT_CHECKED, tagClassName: '' }
	]

	const secondLevelCategoriesOptions = getSecondLevelCategoriesOptions(categories.data)

	const showReportDisabledBtnTooltip = !totalCount
	const disabledReportButton = showReportDisabledBtnTooltip || loading

	return (
		<>
			<Form layout='horizontal' onSubmitCapture={handleSubmit} className={'pt-0'}>
				<Filters
					customContent={
						<Permissions
							render={(hasPermission, { openForbiddenModal }) => (
								<Tooltip title={showReportDisabledBtnTooltip ? t('loc:Report nie je možné vygenerovať z prázdneho zoznamu') : null}>
									{/* Workaround for disabled button inside tooltip: https://github.com/react-component/tooltip/issues/18 */}
									<span className={cx({ 'cursor-not-allowed': disabledReportButton })}>
										<Button
											onClick={() => {
												if (hasPermission) {
													setReportModalVisible(true)
												} else {
													openForbiddenModal()
												}
											}}
											type='primary'
											htmlType='button'
											className={cx('noti-btn', {
												'pointer-events-none': disabledReportButton
											})}
											icon={<FilesIcon />}
											disabled={disabledReportButton}
											id={formFieldID(FORM.CUSTOM_SERVICES_FILTER, OPEN_ASYNC_JOB_MODAL_BUTTON_ID(ASYNC_JOB_TYPE.EXPORT_CUSTOM_SERVICES))}
										>
											{t('loc:Generovať report')}
										</Button>
									</span>
								</Tooltip>
							)}
						/>
					}
					search={
						<Field
							className={'h-10 p-0 m-0'}
							component={InputField}
							size={'large'}
							placeholder={t('loc:Hľadať podľa názvu salónu alebo ID')}
							name={'search'}
							fieldMode={FIELD_MODE.FILTER}
							search
							maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
						/>
					}
					activeFilters={checkFiltersSize(form?.values)}
					form={FORM.CUSTOM_SERVICES_FILTER}
					forceRender
				>
					<Row className={'items-center'} gutter={ROW_GUTTER_X_M}>
						<Col span={6}>
							<Field
								component={SelectField}
								optionRender={(itemData: any) => optionRenderWithImage(itemData, <GlobeIcon />)}
								name={'countryCode'}
								placeholder={t('loc:Krajina')}
								allowClear
								size={'large'}
								options={config.optionsRolloutCountries}
								loading={config.isLoading}
								disabled={config.isLoading}
							/>
						</Col>
						<Col span={6}>
							<Field
								component={SelectField}
								name={'checkedState'}
								placeholder={t('loc:Stav služby')}
								allowClear
								size={'large'}
								options={checkedOptions}
								optionRender={optionRenderWithTag}
							/>
						</Col>
						<Col span={12}>
							<Field
								component={SelectField}
								placeholder={t('loc:Kategórie')}
								name={'categoryIDs'}
								mode={'multiple'}
								size={'large'}
								showSearch
								loading={categories?.isLoading}
								optionRender={(itemData: any) => optionRenderWithImage(itemData, <CategoryIcon />)}
								allowClear
								filterOption
								options={secondLevelCategoriesOptions}
							/>
						</Col>
					</Row>
					<Row gutter={ROW_GUTTER_X_M}>
						<Col span={8}>
							<Fields
								label={t('loc:Dátum vytvorenia služby')}
								names={['serviceCreatedAtFrom', 'serviceCreatedAtTo']}
								placeholders={[t('loc:od'), t('loc:do')]}
								component={DateRangeField}
								size={'large'}
							/>
						</Col>
					</Row>
				</Filters>
			</Form>
			<AsyncJobsModal
				jobType={ASYNC_JOB_TYPE.EXPORT_CUSTOM_SERVICES}
				visible={reportModalVisible}
				setVisible={setReportModalVisible}
				query={query}
				countriesOptions={config.optionsRolloutCountries}
				checkedOptions={checkedOptions}
				secondLevelCategoriesOptions={secondLevelCategoriesOptions}
			/>
		</>
	)
}

const form = reduxForm({
	form: FORM.CUSTOM_SERVICES_FILTER,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, CHANGE_DEBOUNCE_TIME),
	destroyOnUnmount: true
})(CustomServicesFilter)

export default form
