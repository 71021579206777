export enum CATEGORIES {
	CATEGORIES_LOAD_START = 'CATEGORIES_LOAD_START',
	CATEGORIES_LOAD_DONE = 'CATEGORIES_LOAD_DONE',
	CATEGORIES_LOAD_FAIL = 'CATEGORIES_LOAD_FAIL'
}

export enum CATEGORY {
	CATEGORY_LOAD_START = 'CATEGORY_LOAD_START',
	CATEGORY_LOAD_DONE = 'CATEGORY_LOAD_DONE',
	CATEGORY_LOAD_FAIL = 'CATEGORY_LOAD_FAIL'
}

export enum CUSTOM_SERVICES {
	CUSTOM_SERVICES_LOAD_START = 'CUSTOM_SERVICES_LOAD_START',
	CUSTOM_SERVICES_LOAD_DONE = 'CUSTOM_SERVICES_LOAD_DONE',
	CUSTOM_SERVICES_LOAD_FAIL = 'CUSTOM_SERVICES_LOAD_FAIL'
}
