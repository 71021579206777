import React, { FC } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { reset } from 'redux-form'

// components
import ForgottenPasswordForm from './ForgottenPasswordForm'

// utils
import { postReq } from '../../utils/request'
import { FORM, GRECAPTCHA_ACTIONS } from '../../utils/enums'
import reCaptchaVerify from '../../utils/reCaptcha'

// assets
import CloseIcon from '../../assets/icons/close-icon-modal.svg?react'

// types
import { IForgotPasswordForm } from '../../types/interfaces'

type Props = {
	onClose: () => void
	visible: boolean
}

const ForgottenPasswordModal: FC<Props> = (props) => {
	const { onClose, visible } = props
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const hideModal = () => {
		dispatch(reset(FORM.FORGOT_PASSWORD))
		onClose()
	}

	const handleSubmit = async (values: IForgotPasswordForm) => {
		try {
			const reCaptchaToken = await reCaptchaVerify(GRECAPTCHA_ACTIONS.FORGOTTEN_PASSWORD_FORM)

			const reqBody = {
				email: values.email
			}

			await postReq('/api/b2b/admin/auth/forgot-password', {
				params: {},
				reqBody,
				customConfig: { headers: { 'recaptcha-token': reCaptchaToken } }
			})
			hideModal()
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	return (
		<Modal className='rounded-fields' title={t('loc:Zabudnuté heslo')} centered open={visible} footer={null} onCancel={hideModal} closeIcon={<CloseIcon />} width={394}>
			<ForgottenPasswordForm onSubmit={handleSubmit} />
		</Modal>
	)
}

export default ForgottenPasswordModal
