import { change } from 'redux-form'

// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { FORMS_WITH_COUNTRY_SELECT } from '../../utils/enums'
import { IResetStore } from '../generalTypes'
import SELECTED_COUNTRY from './selectedCountryTypes'

export type ISelectedCountryActions = IResetStore | ISelectedCountry

export interface ISelectedCountry {
	type: SELECTED_COUNTRY
	selectedCountry: string | undefined
}

export const setSelectedCountry =
	(countryCode?: string, changeFormCountryCode = true): ThunkResult<void> =>
	(dispatch, getStore) => {
		dispatch({ type: SELECTED_COUNTRY.SELECTED_COUNTRY_UPDATE, selectedCountry: countryCode })
		if (changeFormCountryCode) {
			const store = getStore()
			const activeForms = Object.keys(store.form)
			FORMS_WITH_COUNTRY_SELECT.forEach((formName) => {
				if (activeForms.includes(formName)) {
					dispatch(change(formName, 'countryCode', countryCode, true))
				}
			})
		}
	}
