export enum SUPPORT_CONTACTS {
	SUPPORT_CONTACTS_START = 'SUPPORT_CONTACTS_START',
	SUPPORT_CONTACTS_DONE = 'SUPPORT_CONTACTS_DONE',
	SUPPORT_CONTACTS_FAIL = 'SUPPORT_CONTACTS_FAIL'
}

export enum SUPPORT_CONTACT {
	SUPPORT_CONTACT_START = 'SUPPORT_CONTACT_START',
	SUPPORT_CONTACT_DONE = 'SUPPORT_CONTACT_DONE',
	SUPPORT_CONTACT_FAIL = 'SUPPORT_CONTACT_FAIL'
}

export const SET_SUPPORT_CONTACT_OPTIONS = 'SET_SUPPORT_CONTACT_OPTIONS'
