/* eslint-disable import/no-cycle */
import { createStore, compose, applyMiddleware, Reducer, Middleware } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { persistStore } from 'redux-persist'

import i18next from 'i18next'
import { UPLOAD_IN_PROGRESS_PROP, MSG_TYPE, HANDLE_CALENDAR_ACTIONS, HANDLE_CALENDAR_FORMS, CALENDAR_INIT_TIME, FORMS_WITH_COUNTRY_SELECT } from './enums'
import { showNotifications } from './helper'
import { addOrUpdateEvent } from '../reducers/virtualEvent/virtualEventActions'
import { setSelectedCountry } from '../reducers/selectedCountry/selectedCountryActions'
import { RootState } from '../reducers'

const RELEVANT_CALENDAR_ACTIONS = Object.keys(HANDLE_CALENDAR_ACTIONS)

/**
 * OnSubmit validate if UPLOAD_IN_PROGRESS_PROP is true -> indicates uploading
 * During upload will be submit action denied
 * UPLOAD_IN_PROGRESS_PROP must be set outside e.g. ImgUploadField
 */
const preventSubmitFormDuringUpload = (store: any) => (next: any) => (action: any) => {
	if (action?.type === '@@redux-form/SUBMIT') {
		const { form } = store.getState()
		const submittedForm = form[action.meta.form]

		if (submittedForm) {
			const { values } = submittedForm

			if (values && values[UPLOAD_IN_PROGRESS_PROP]) {
				const error = i18next.t('loc:Prebieha nahrávanie')
				showNotifications([{ type: MSG_TYPE.ERROR, message: error }])
				return
			}
		}
	}

	next(action)
}

/**
 * Middleware pre spracovanie form akcii HANDLE_CALENDAR_ACTIONS relevantnych pre kalendarove formulare.
 */
const handleCalendarFormsChanges: Middleware<{}, RootState> = (store) => (next) => (action) => {
	next(action)

	// check ci sa jedna o relevantny Calendar form (Reservation, Shift, Break, TimeOff)
	if (HANDLE_CALENDAR_FORMS.includes(action?.meta?.form)) {
		// eslint-disable-next-line no-restricted-syntax
		for (const actionType of RELEVANT_CALENDAR_ACTIONS) {
			// check ci sa vykonala relevantna akcia (INIT, CHANGE)
			if (action.type.endsWith(actionType)) {
				// NOTE: 600ms je cas, kym zacne existovat instancia CalendarAPI, aby vedela zobrazit data, ked sa inituje form
				setTimeout(() => store.dispatch(addOrUpdateEvent(actionType, action.meta.form)), CALENDAR_INIT_TIME)
				break
			}
		}
	}
}

/**
 * Middleware, ktory zaisti, ze ak sa v relevantnych filtroch FORMS_WITH_COUNTRY_SELECT prepne krajina, tak sa prepne aj v globalnom prepinaci v hlavicke
 */
const handleFiltersWithCountryFormsChanges: Middleware<{}, RootState> = (store) => (next) => (action) => {
	next(action)

	// check ci sa jedna o relevantny Filter form v ktorom sa prepina krajina
	if (FORMS_WITH_COUNTRY_SELECT.includes(action?.meta?.form)) {
		if (action?.type === '@@redux-form/SUBMIT') {
			const { form } = store.getState()
			const submittedForm = form[action.meta.form]

			if (submittedForm) {
				const { values } = submittedForm

				if (values && 'countryCode' in values) {
					store.dispatch(setSelectedCountry(values.countryCode, false))
				}
			}
		}
	}
}

/**
 * Middleware, ktory zaisti, ze ze ak sa v relevantnych filtroch FORMS_WITH_COUNTRY_SELECT vyinicializuje ina krajina ako v hlavicke, tak ju nasledne prepne aj v nej
 * Stane sa to vtedy, ked sa v novom tabe otvori URLcka, ktora ma setnuty query parameter pre countryCode a ten je iny ako predvolena krajina v hlavicke
 */
const handleFiltersWithCountryFormsInitialization: Middleware<{}, RootState> = (store) => (next) => (action) => {
	next(action)

	// check ci sa jedna o relevantny Filter form v ktorom sa prepina krajina
	if (FORMS_WITH_COUNTRY_SELECT.includes(action?.meta?.form)) {
		if (action?.type === '@@redux-form/INITIALIZE') {
			const { form, selectedCountry } = store.getState()
			const submittedForm = form[action.meta.form]

			if (submittedForm) {
				const { values } = submittedForm

				if (values && 'countryCode' in values) {
					const newCountryCode = values.countryCode
					if (newCountryCode !== selectedCountry) {
						store.dispatch(setSelectedCountry(values.countryCode, false))
					}
				}
			}
		}
	}
}

const loggerFilter = (getState: any, action: any) => {
	if (action.type.startsWith('persist')) {
		return false
	}
	if (action.type === '@@redux-form/REGISTER_FIELD') {
		return false
	}
	return action.type !== '@@redux-form/UNREGISTER_FIELD'
}

const configureStoreProd = (rootReducer: Reducer<RootState>) => {
	const middlewares = [
		// Add other middleware on this line...
		thunk,
		preventSubmitFormDuringUpload,
		handleCalendarFormsChanges,
		handleFiltersWithCountryFormsChanges,
		handleFiltersWithCountryFormsInitialization
	]

	const store = createStore(rootReducer, compose(applyMiddleware(...middlewares)))
	const persistor = persistStore(store)
	return { store, persistor }
}

const configureStoreDev = (rootReducer: Reducer<RootState>) => {
	const logger = createLogger({
		collapsed: true,
		duration: true,
		predicate: loggerFilter
	})

	const middlewares = [
		thunk,
		logger,
		preventSubmitFormDuringUpload,
		handleCalendarFormsChanges,
		handleFiltersWithCountryFormsChanges,
		handleFiltersWithCountryFormsInitialization
	]

	const composeEnhancers =
		typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
					trace: true,
					traceLimit: 25
				})
			: compose

	const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))
	const persistor = persistStore(store)

	return {
		store,
		persistor
	}
}

const configureStore = (rootReducer: Reducer) => (import.meta.env.PROD ? configureStoreProd(rootReducer) : configureStoreDev(rootReducer))

export default configureStore
