export enum SYSTEM_ROLES {
	SYSTEM_ROLES_LOAD_START = 'SYSTEM_ROLES_LOAD_START',
	SYSTEM_ROLES_LOAD_DONE = 'SYSTEM_ROLES_LOAD_DONE',
	SYSTEM_ROLES_LOAD_FAIL = 'SYSTEM_ROLES_LOAD_FAIL'
}

export enum SALON_ROLES {
	SALON_ROLES_LOAD_START = 'SALON_ROLES_LOAD_START',
	SALON_ROLES_LOAD_DONE = 'SALON_ROLES_LOAD_DONE',
	SALON_ROLES_LOAD_FAIL = 'SALON_ROLES_LOAD_FAIL'
}
