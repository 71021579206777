/* eslint-disable import/no-cycle */
import { IResetStore } from '../generalTypes'

// types
import { COSMETICS } from './cosmeticsTypes'
import { ISelectOptionItem, ISearchable, RequestResponse, GetUrls } from '../../types/interfaces'
import { IGetCosmeticsQueryParams } from '../../types/schemaTypes'
import { ThunkResult } from '../index'

// utils
import { getReq } from '../../utils/request'

export type ICosmeticsActions = IResetStore | IGetCosmetics

interface IGetCosmetics {
	type: COSMETICS
	payload: ICosmeticsPayload
}

export interface ICosmeticsPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/enums/cosmetics/']>> {}

export const getCosmetics =
	(queryParams?: IGetCosmeticsQueryParams): ThunkResult<Promise<ICosmeticsPayload>> =>
	async (dispatch) => {
		let payload = {} as ICosmeticsPayload

		try {
			dispatch({ type: COSMETICS.COSMETICS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/enums/cosmetics/', {
				params: {
					query: {
						page: queryParams?.page,
						limit: queryParams?.limit,
						search: queryParams?.search
					}
				},
				reqBody: {}
			})
			const options: ISelectOptionItem[] = data.cosmetics.map((cosmetic) => ({
				key: `Cosmetic_${cosmetic.id}`,
				label: cosmetic.name,
				value: cosmetic.id,
				extra: {
					image: cosmetic.image?.resizedImages?.thumbnail || cosmetic.image?.original
				}
			}))

			payload = { data, options }
			dispatch({ type: COSMETICS.COSMETICS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: COSMETICS.COSMETICS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
