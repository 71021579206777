import React from 'react'

type Props = {
	title: string
	voucherCode: string
}

const Voucher = (props: Props) => {
	const { voucherCode, title } = props

	return (
		<div className={'bg-notino-grayLighter px-6 py-4 rounded-lg h-[fit-content]'}>
			<h5 className={'text-base font-bold text-black block mb-0'}>{title}</h5>
			<div className={'flex flex-col gap-4'}>
				<p className={'text-sm text-notino-black italic mb-0 block'}>{voucherCode}</p>
			</div>
		</div>
	)
}

export default Voucher
