// eslint-disable-next-line import/prefer-default-export
export enum CUSTOMERS {
	CUSTOMERS_LOAD_START = 'CUSTOMERS_LOAD_START',
	CUSTOMERS_LOAD_DONE = 'CUSTOMERS_LOAD_DONE',
	CUSTOMERS_LOAD_FAIL = 'CUSTOMERS_LOAD_FAIL'
}

export enum CUSTOMER {
	CUSTOMER_LOAD_START = 'CUSTOMER_LOAD_START',
	CUSTOMER_LOAD_DONE = 'CUSTOMER_LOAD_DONE',
	CUSTOMER_LOAD_FAIL = 'CUSTOMER_LOAD_FAIL'
}
