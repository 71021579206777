// types
import { SET_GA_SCREEN_NAME } from './googleAnalyticsTypes'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'

// utils
import { GA_SCREEN_NAME } from '../../utils/dataLayerEnums'

export type IGoogleAnalyticsActions = IResetStore | ISetGaScreenName

interface ISetGaScreenName {
	type: typeof SET_GA_SCREEN_NAME
	payload: GA_SCREEN_NAME
}

export const setGaScreenName =
	(screenName: GA_SCREEN_NAME): ThunkResult<void> =>
	(dispatch) => {
		dispatch({ type: SET_GA_SCREEN_NAME, payload: screenName })
	}
