export enum USER_NOTIFICATIONS {
	USER_NOTIFICATIONS_LOAD_START = 'USER_NOTIFICATIONS_LOAD_START',
	USER_NOTIFICATIONS_LOAD_DONE = 'USER_NOTIFICATIONS_LOAD_DONE',
	USER_NOTIFICATIONS_LOAD_FAIL = 'USER_NOTIFICATIONS_LOAD_FAIL'
}

export const SLICE_USER_NOTIFICATIONS = 'SLICE_USER_NOTIFICATIONS'

export const DELETE_USER_NOTIFICATION = 'DELETE_USER_NOTIFICATION'

export const MARK_USER_NOTIFICATION_AS_READ = 'MARK_USER_NOTIFICATION_AS_READ'

export enum USER_DOCUMENTS {
	USER_DOCUMENTS_LOAD_START = 'USER_DOCUMENTS_LOAD_START',
	USER_DOCUMENTS_LOAD_DONE = 'USER_DOCUMENTS_LOAD_DONE',
	USER_DOCUMENTS_LOAD_FAIL = 'USER_DOCUMENTS_LOAD_FAIL'
}
