export enum DYNAMIC_NOTIFICATIONS {
	DYNAMIC_NOTIFICATIONS_LOAD_START = 'DYNAMIC_NOTIFICATIONS_LOAD_START',
	DYNAMIC_NOTIFICATIONS_LOAD_DONE = 'DYNAMIC_NOTIFICATIONS_LOAD_DONE',
	DYNAMIC_NOTIFICATIONS_LOAD_FAIL = 'DYNAMIC_NOTIFICATIONS_LOAD_FAIL'
}

export enum DYNAMIC_NOTIFICATION {
	DYNAMIC_NOTIFICATION_LOAD_START = 'DYNAMIC_NOTIFICATION_LOAD_START',
	DYNAMIC_NOTIFICATION_LOAD_DONE = 'DYNAMIC_NOTIFICATION_LOAD_DONE',
	DYNAMIC_NOTIFICATION_LOAD_FAIL = 'DYNAMIC_NOTIFICATION_LOAD_FAIL'
}
