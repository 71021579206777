import React, { FC } from 'react'
import { Field, InjectedFormProps, reduxForm, FieldArray, isDirty, submit } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button, Col, Divider, Form, Row, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'

// assets
import { ButtonProps } from 'antd/lib/button'
import CloseIcon from '../../../assets/icons/close-icon.svg?react'
import EditIcon from '../../../assets/icons/edit-icon.svg?react'
import CreateIcon from '../../../assets/icons/plus-icon.svg?react'

// atoms
import InputField from '../../../atoms/InputField'
import ImgUploadField from '../../../atoms/ImgUploadField'
import SelectField from '../../../atoms/SelectField'
import TextareaField from '../../../atoms/TextareaField'

// components
import Localizations from '../../../components/Localizations'
import DeleteButton from '../../../components/DeleteButton'
import PopConfirmComponent from '../../../components/PopConfirmComponent'

// utils
import { validationString, checkUploadingBeforeSubmit, formFieldID, getUploadLimitsMessage } from '../../../utils/helper'
import { DELETE_BUTTON_ID, FORM, PERMISSION, STRINGS, SUBMIT_BUTTON_ID, UPLOAD, UPLOAD_IMG_CATEGORIES, URL_UPLOAD_FILE, VALIDATION_MAX_LENGTH } from '../../../utils/enums'
import Permissions from '../../../utils/Permissions'
import { withPromptUnsavedChanges } from '../../../utils/promptUnsavedChanges'

// reducers
import { RootState } from '../../../reducers'

// types
import { ICategoryForm } from '../../../types/interfaces'

// validation
import { validationCategoryFn } from '../../../utils/formValidation'

type ComponentProps = {
	deleteCategory: Function
	createCategory: (rootParentId: string, parentId: string, parentTitle: string, childrenLength: number, level: number) => void
	closeCategoryForm: (e?: React.MouseEvent<HTMLElement> | undefined) => void
}

const fixLength100 = validationString(VALIDATION_MAX_LENGTH.LENGTH_100)
const fixLength1500 = validationString(VALIDATION_MAX_LENGTH.LENGTH_1500)

type Props = InjectedFormProps<ICategoryForm, ComponentProps> & ComponentProps

const permissions: PERMISSION[] = [PERMISSION.CATEGORY_EDIT]

const CategoryForm: FC<Props> = (props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const { handleSubmit, submitting, deleteCategory, createCategory, closeCategoryForm, pristine } = props

	const values = useSelector((state: RootState) => state.form[FORM.CATEGORY]?.values)
	const categoriesParameters = useSelector((state: RootState) => state.categoryParams.parameters)
	const category = useSelector((state: RootState) => state.categories.category)
	const isFormDirty = useSelector(isDirty(FORM.CATEGORY))

	const renderFormTitle = () => {
		if (values?.id) {
			return t('loc:Upraviť kategóriu')
		}
		if (values?.parentTitle) {
			return `${t('loc:Vytvoriť podkategóriu pre')} - ${values?.parentTitle}`
		}
		return t('loc:Vytvoriť kategóriu')
	}

	const documentFooter = document.getElementById('content-footer-container') || document.body

	const renderCreatSubcategoryButton = (hasPermissions: boolean, openForbiddenModal: () => void) => {
		const btnProps: ButtonProps = {
			id: `${FORM.CATEGORY}-create-subcategory-button`,
			className: 'noti-btn m-regular w-full 2xl:w-auto max-w-full min-w-0',
			size: 'middle',
			type: 'dashed',
			icon: <CreateIcon />,
			children: t('loc:Vytvoriť podkategóriu')
		}

		const onBtnClick = () => {
			if (hasPermissions) {
				createCategory(values?.parentId, values?.id, values?.name, values?.childrenLength, (values?.level ?? 0) + 1)
			} else {
				openForbiddenModal()
			}
		}

		if (isFormDirty) {
			return (
				<PopConfirmComponent
					placement={'left'}
					title={t('loc:Máte neuložené zmeny vo formulári. Želáte si pokračovať ďalej?')}
					onConfirm={onBtnClick}
					okText={t('loc:Pokračovať')}
					getPopupContainer={() => documentFooter}
					allowedButton={<Button {...btnProps} />}
				/>
			)
		}
		return <Button {...btnProps} onClick={onBtnClick} />
	}

	return (
		<Form layout={'vertical'} className={'w-full top-0 sticky overflow-hidden pt-1 px-6 pb-6 -mx-6'} onSubmitCapture={handleSubmit(checkUploadingBeforeSubmit)}>
			<Spin wrapperClassName={'w-full'} spinning={category.isLoading || categoriesParameters.isLoading}>
				<Col className={'flex'}>
					<Row className={'w-full h-full block'} justify='center'>
						<h3 className={'mb-0 mt-3 relative pr-7'}>
							{renderFormTitle()}
							{isFormDirty ? (
								<PopConfirmComponent
									placement={'left'}
									title={t('loc:Máte neuložené zmeny vo formulári. Želáte si pokračovať ďalej?')}
									onConfirm={closeCategoryForm}
									okText={t('loc:Pokračovať')}
									getPopupContainer={() => documentFooter}
									allowedButton={
										<Button className='noti-close-form-btn absolute top-1 right-0'>
											<CloseIcon />
										</Button>
									}
								/>
							) : (
								<Button className='noti-close-form-btn absolute top-1 right-0' onClick={closeCategoryForm}>
									<CloseIcon />
								</Button>
							)}
						</h3>
						<Divider className={'mb-3 mt-3'} />
						<Row className={'w-full'}>
							<FieldArray
								key='nameLocalizations'
								name='nameLocalizations'
								className='w-full'
								component={Localizations}
								placeholder={t('loc:Zadajte názov')}
								horizontal
								ignoreFieldIndex={0} // do not render "0" field because it is rendered in mainField prop
								customValidate={fixLength100}
								mainField={
									<Field
										className='mb-0 pb-0'
										component={InputField}
										label={t('loc:Názov kategórie (EN)')}
										placeholder={t('loc:Zadajte názov')}
										key='nameLocalizations[0].value'
										name='nameLocalizations[0].value'
										required
										validate={fixLength100}
									/>
								}
								noSpace
							/>
							{values?.level === 2 ? (
								<>
									<Field
										className={'w-full mb-2'}
										component={SelectField}
										options={categoriesParameters.enumerationsOptions}
										label={t('loc:Parameter')}
										placeholder={t('loc:Vyberte parameter')}
										name={'categoryParameterID'}
										loading={categoriesParameters.isLoading}
										allowClear
										labelInValue
									/>
									<FieldArray
										key='descriptionLocalizations'
										name='descriptionLocalizations'
										component={Localizations}
										placeholder={t('loc:Zadajte názov')}
										horizontal
										fieldComponent={TextareaField}
										ignoreFieldIndex={0} // do not render "0" field because it is rendered in mainField prop
										customValidate={fixLength1500}
										customRows={4}
										className={'w-full'}
										mainField={
											<Field
												className='mb-0 pb-0'
												component={TextareaField}
												label={t('loc:Popis kategórie (EN)')}
												placeholder={t('loc:Zadajte popis')}
												key='descriptionLocalizations[0].value'
												name='descriptionLocalizations[0].value'
												maxLength={VALIDATION_MAX_LENGTH.LENGTH_1500}
												rows={4}
											/>
										}
										noSpace
									/>
								</>
							) : undefined}
						</Row>
						{values?.level === 0 ? (
							<Row className={'mb-4'}>
								<Field
									component={ImgUploadField}
									name='image'
									label={t('loc:Obrázok')}
									signUrl={URL_UPLOAD_FILE}
									category={UPLOAD_IMG_CATEGORIES.CATEGORY_IMAGE}
									required
									maxFileSize={UPLOAD.CATEGORY_IMAGE.MAX_FILE_SIZE}
									accept={UPLOAD.CATEGORY_IMAGE.ACCEPT}
									tooltip={getUploadLimitsMessage(UPLOAD.CATEGORY_IMAGE.ACCEPT, UPLOAD.CATEGORY_IMAGE.MAX_FILE_SIZE)}
									maxCount={UPLOAD.CATEGORY_IMAGE.MAX_COUNT}
								/>
								<Field
									component={ImgUploadField}
									name='icon'
									label={t('loc:Ikona')}
									signUrl={URL_UPLOAD_FILE}
									category={UPLOAD_IMG_CATEGORIES.CATEGORY_ICON}
									required
									maxFileSize={UPLOAD.CATEGORY_ICON.MAX_FILE_SIZE}
									accept={UPLOAD.CATEGORY_ICON.ACCEPT}
									tooltip={getUploadLimitsMessage(UPLOAD.CATEGORY_ICON.ACCEPT, UPLOAD.CATEGORY_ICON.MAX_FILE_SIZE)}
									maxCount={UPLOAD.CATEGORY_ICON.MAX_COUNT}
								/>
							</Row>
						) : undefined}

						<Permissions
							allowed={permissions}
							render={(hasPermission, { openForbiddenModal }) => (
								<div className={cx('flex flex-wrap gap-2 mt-6', { 'justify-center': !values?.id, 'justify-between': values?.id })}>
									{values?.id && !values?.deletedAt && (
										<DeleteButton
											permissions={permissions}
											onConfirm={() => deleteCategory(values?.id, false)}
											entityName={''}
											className={'w-full 2xl:w-auto max-w-full min-w-0'}
											type={'default'}
											getPopupContainer={() => document.getElementById('content-footer-container') || document.body}
											id={formFieldID(FORM.CATEGORY, DELETE_BUTTON_ID)}
										/>
									)}

									<div className='flex gap-2 flex-wrap w-full 2xl:w-auto'>
										{values?.id && values?.level < 2 && !values?.deletedAt ? renderCreatSubcategoryButton(hasPermission, openForbiddenModal) : undefined}
										{!values?.deletedAt ? (
											<Button
												className={'noti-btn w-full 2xl:w-auto max-w-full min-w-0'}
												size='middle'
												type='primary'
												htmlType='submit'
												onClick={(e) => {
													e.preventDefault()
													if (hasPermission) {
														dispatch(submit(FORM.CATEGORY))
													} else {
														openForbiddenModal()
													}
												}}
												disabled={submitting || pristine}
												loading={submitting}
												icon={values?.id ? <EditIcon /> : <CreateIcon />}
												id={formFieldID(FORM.CATEGORY, SUBMIT_BUTTON_ID)}
											>
												{values?.id ? t('loc:Uložiť') : STRINGS(t).createRecord(t('loc:kategóriu'))}
											</Button>
										) : undefined}
									</div>
								</div>
							)}
						/>
					</Row>
				</Col>
			</Spin>
		</Form>
	)
}

const form = reduxForm<ICategoryForm, ComponentProps>({
	form: FORM.CATEGORY,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validationCategoryFn
})(withPromptUnsavedChanges(CategoryForm))

export default form
