export enum SPECIALIST_CONTACTS {
	SPECIALIST_CONTACTS_START = 'SPECIALIST_CONTACTS_START',
	SPECIALIST_CONTACTS_DONE = 'SPECIALIST_CONTACTS_DONE',
	SPECIALIST_CONTACTS_FAIL = 'SPECIALIST_CONTACTS_FAIL'
}

export enum SPECIALIST_CONTACT {
	SPECIALIST_CONTACT_START = 'SPECIALIST_CONTACT_START',
	SPECIALIST_CONTACT_DONE = 'SPECIALIST_CONTACT_DONE',
	SPECIALIST_CONTACT_FAIL = 'SPECIALIST_CONTACT_FAIL'
}
