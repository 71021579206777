import { z } from 'zod'
import { emailConstraint, imageConstraint, stringConstraint, twoCharsConstraint, uuidConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH } from '../utils/enums'

// https://notino-admin.goodrequest.dev/api/doc/#/B2b-%3Eadmin/patchApiB2BAdminUsersUserId
export const editUserSchema = z.object({
	firstName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100),
	lastName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100),
	phonePrefixCountryCode: twoCharsConstraint,
	phone: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_20, true),
	avatar: imageConstraint.array().max(1).nullish(),
	assignedCountryCode: twoCharsConstraint.optional()
})

// https://notino-admin.goodrequest.dev/api/doc/#/B2b-%3Eadmin/postApiB2BAdminUsers
export const createUserSchema = z.object({
	email: emailConstraint,
	phonePrefixCountryCode: twoCharsConstraint,
	phone: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_20, true),
	roleID: uuidConstraint,
	assignedCountryCode: twoCharsConstraint.optional()
})
