import { PostUrls, RequestResponse } from '../types/interfaces'
import { LANGUAGE } from './enums'

export const APP_BROADCAST_CHANNEL_NAME = 'NOTINO_ADMIN_APP_BROADCAST_CHANNEL'

export const appBroadcastChannel = new BroadcastChannel(APP_BROADCAST_CHANNEL_NAME)

export const initAppBroadcastChannel = () => appBroadcastChannel

export enum BROADCAST_CHANNEL_TYPE {
	REFRESH_TOKEN = 'REFRESH_TOKEN',
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
	USER_LANGUAGE_CHANGE = 'USER_LANGUAGE_CHANGE'
}

type UserData = RequestResponse<PostUrls['/api/b2b/admin/auth/login']>['user'] | null

export type LoginMessageData = {
	user: UserData
}

export type LogoutMessageData = {
	user: UserData
}

export type BroadcastLoginMessage = {
	type: BROADCAST_CHANNEL_TYPE.LOGIN
	payload: LoginMessageData
}

export type BroadcastLogoutMessage = {
	type: BROADCAST_CHANNEL_TYPE.LOGOUT
	payload: LogoutMessageData
}

export type BroadcastRefreshTokenMessage = {
	type: BROADCAST_CHANNEL_TYPE.REFRESH_TOKEN
	payload: number
}

export type BroadcastUserLanguageChangeMessage = {
	type: BROADCAST_CHANNEL_TYPE.USER_LANGUAGE_CHANGE
	payload: LANGUAGE
}

export type BroadcastPayload = BroadcastLoginMessage | BroadcastLogoutMessage | BroadcastRefreshTokenMessage | BroadcastUserLanguageChangeMessage

export type BroadcastMessageEvent = MessageEvent<BroadcastPayload>
