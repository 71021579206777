import React, { useEffect } from 'react'
import { Modal } from 'antd'
import { reset, initialize } from 'redux-form'
import { useDispatch } from 'react-redux'

// components
import OpenHoursNoteForm from './OpenHoursNoteForm'

// utils
import { patchReq } from '../../utils/request'
import { FORM } from '../../utils/enums'

// types
import { GetUrls, IOpenHoursNoteForm, RequestResponse } from '../../types/interfaces'

// assets
import CloseIcon from '../../assets/icons/close-icon-modal.svg?react'

type Props = {
	visible?: boolean
	onClose?: () => void
	title?: string
	salonID: string
	openingHoursNote?: RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}']>['salon']['openingHoursNote']
}

const OpenHoursNoteModal = (props: Props) => {
	const { visible, onClose = () => {}, title, salonID, openingHoursNote } = props
	const dispatch = useDispatch()

	useEffect(() => {
		if (!visible) return // init form only if the modal is opened

		let initData: any

		if (openingHoursNote) {
			initData = {
				openingHoursNote: openingHoursNote.note
			}
		}
		dispatch(initialize(FORM.OPEN_HOURS_NOTE, initData || {}))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible])

	const hideModal = () => {
		dispatch(reset(FORM.OPEN_HOURS_NOTE))
		onClose()
	}

	const handleSubmit = async (values: IOpenHoursNoteForm) => {
		try {
			const reqBody = {
				openingHoursNote: {
					note: values?.openingHoursNote
				}
			}

			await patchReq('/api/b2b/admin/salons/{salonID}/open-hours-note', { params: { path: { salonID } }, reqBody })
			hideModal()
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		}
	}

	return (
		<Modal key={`${visible}`} title={title} open={visible} onCancel={hideModal} footer={null} closeIcon={<CloseIcon />}>
			<OpenHoursNoteForm onSubmit={handleSubmit} />
		</Modal>
	)
}

export default OpenHoursNoteModal
