import { useEffect } from 'react'
import * as CookieConsent from 'vanilla-cookieconsent'
import { CookieConsentConfig } from 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

// utils
import { gtag } from '../utils/dataLayer'
import { COOKIE_CATEGORY } from '../utils/enums'

// env
import envConfig from '../config'

const cookieUpdate: CookieConsentConfig['onConsent'] = ({ cookie }) => {
	gtag('consent', 'update', {
		ad_storage: cookie.categories.includes(COOKIE_CATEGORY.MARKETING) ? 'granted' : 'denied',
		analytics_storage: cookie.categories.includes(COOKIE_CATEGORY.ANALYTICS) ? 'granted' : 'denied'
	})
}

const TRANSLATIONS_KEY = 'TRANSLATIONS_KEY'

const getCookieConfig = (t: TFunction): CookieConsentConfig => ({
	autoClearCookies: true,
	revision: 1,
	cookie: {
		name: 'cc_cookie',
		expiresAfterDays: 365
	},
	disablePageInteraction: true,
	categories: {
		[COOKIE_CATEGORY.NECESSARY]: {
			enabled: true,
			readOnly: true
		},
		[COOKIE_CATEGORY.FUNCTIONAL]: {
			enabled: true,
			readOnly: true
		},
		[COOKIE_CATEGORY.ANALYTICS]: {
			enabled: true,
			readOnly: false
		},
		[COOKIE_CATEGORY.MARKETING]: {
			enabled: true,
			readOnly: false
		}
	},
	onConsent: ({ cookie }) => cookieUpdate({ cookie }),
	onChange: ({ cookie }) => cookieUpdate({ cookie }),
	guiOptions: {
		consentModal: {
			layout: 'bar', // box,cloud,bar
			position: 'bottom center', // bottom,middle,top + left,right,center,
			equalWeightButtons: true
		},
		preferencesModal: {
			layout: 'box', // box, bar
			position: 'left' // right,left (available only if bar layout selected)
		}
	},
	language: {
		// NOTE: we only need to provide translations keys and the translation function will take care of translating (we don't need to provide translations for all languages as state in the documentation)
		// this solution works, because whenever the app language is change, the whole window is reloaded
		default: TRANSLATIONS_KEY,
		translations: {
			[TRANSLATIONS_KEY]: {
				consentModal: {
					title: t('loc:Rýchlo a jednoducho o cookies'),
					description: t(
						'loc:Na tomto webe používame súbory cookies, ktoré zlepšujú váš užívateľský komfort, vyhodnocujú používanie webu a presnejšie cielia reklamu na služby, ktoré vás zaujímajú. Môžete ich prijať, odmietnuť alebo si ľubovoľne nastaviť jednotlivé kategórie. S výnimkou potrebných cookies, ktoré sú nevyhnutné, aby tieto stránky správne fungovali. Podrobnosti sa dozviete v <a href="{{ link }}" target="_blank">Zásadách spracovania osobných údajov</a>.',
						{ link: t('paths:privacy-policy') }
					),
					acceptAllBtn: t('loc:Prijať všetko'),
					acceptNecessaryBtn: t('loc:Odmietnuť'),
					showPreferencesBtn: t('loc:Nastavenie')
				},
				preferencesModal: {
					title: t('loc:Nastavenie cookies'),
					acceptAllBtn: t('loc:Prijať všetko'),
					acceptNecessaryBtn: undefined,
					savePreferencesBtn: t('loc:Uložiť možnosti'),
					closeIconLabel: 'Zavrieť',
					sections: [
						{
							title: t('loc:Nevyhnutné cookies'),
							description: t(
								'loc:Tieto cookies sú nevyhnutné pre správne fungovanie našej webovej stránky. Bez týchto cookies by webová stránka nefungovala správne.'
							),
							// this field will generate a toggle linked to the 'necessary' category
							linkedCategory: COOKIE_CATEGORY.NECESSARY
						},
						{
							title: t('loc:Funkčné cookies'),
							description: t(
								'loc:Tieto cookies vás osobne neidentifikujú, ale sú vašou aj našou predĺženou pamäťou. Vďaka nim si náš web zapamätá vaše preferencie a vlastne všetko, čo ste si prezerali. A to vrátane vašej geografickej polohy, ktorá je pre nás jasným signálom pri výbere jazyka prezentácie. To všetko dohromady nám umožní množstvo vylepšení, lepšiu prezentáciu a hlavne cielenú ponuku presne na mieru vášmu vkusu.'
							),
							linkedCategory: COOKIE_CATEGORY.FUNCTIONAL
						},
						{
							title: t('loc:Analytické cookies'),
							description: t(
								'loc:Tieto cookies sú usilovnými zberačmi údajov o tom, k čomu naše webové stránky používate, čo navštevujete najčastejšie a čo ste si prezerali. Práve vďaka tomu ich môžeme optimalizovať a zjednodušiť. Všetky získané údaje sú anonymné a vás osobne neidentifikujú.'
							),
							linkedCategory: COOKIE_CATEGORY.ANALYTICS
						},
						{
							title: t('loc:Marketingové a retargetové cookies'),
							description: t(
								'loc:Tieto cookies zaznamenávajú vašu aktivitu na našom webe – navštívené stránky a odkazy, na ktoré ste klikli. Cieľom je presnejšie zacielenie reklamy, aby sme vám ponúkali hlavne to, čo vás najviac zaujíma. Slúži tiež na obmedzenie počtu zobrazení reklamy a na meranie účinnosti reklamných kampaní.'
							),
							linkedCategory: COOKIE_CATEGORY.MARKETING
						}
					]
				}
			}
		}
	}
})

const useCookieConsentBar = () => {
	const { t } = useTranslation()

	useEffect(() => {
		// eslint-disable-next-line no-underscore-dangle
		if (envConfig.VITE_NOTINO_GTM_ID) {
			CookieConsent.run(getCookieConfig(t))
		}
	}, [t])
}

export default useCookieConsentBar
