import { z } from 'zod'

import { isEmpty, isNil } from 'lodash'
import { serializeValidationMessage, stringConstraint, uuidConstraint } from './baseSchema'
import { PARAMETER_TYPE, VALIDATION_MAX_LENGTH } from '../utils/enums'
import { hexColorRegex } from '../utils/regex'

export const arePriceAndDurationDataEmpty = (data?: z.infer<typeof priceAndDurationSchema>) => {
	let emptyPrice = isNil(data?.priceFrom) || Number.isNaN(data?.priceFrom)
	let emptyDuration = isNil(data?.durationFrom) || Number.isNaN(data?.durationFrom)

	if (data?.variableDuration) {
		emptyDuration = emptyDuration && (isNil(data?.durationTo) || Number.isNaN(data?.durationTo))
	}

	if (data?.variablePrice) {
		emptyPrice = emptyPrice && (isNil(data?.priceTo) || Number.isNaN(data?.priceTo))
	}

	return emptyPrice && emptyDuration
}

const validatePriceAndDurationData = (value: z.infer<typeof priceAndDurationSchema>, ctx: z.RefinementCtx, paths: any[] = [], validateDuration = true) => {
	let isError = false

	if (validateDuration) {
		if (isNil(value.durationFrom)) {
			isError = true
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Toto pole je povinné'),
				path: [...paths, 'durationFrom']
			})
		}
		if (value.variableDuration && isNil(value.durationTo)) {
			isError = true
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Toto pole je povinné'),
				path: [...paths, 'durationTo']
			})
		}

		if (value.variableDuration && !isNil(value.durationTo) && (value.durationFrom || 0) > value.durationTo) {
			isError = true
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Chybný rozsah'),
				path: [...paths, 'durationFrom']
			})

			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Chybný rozsah'),
				path: [...paths, 'durationTo']
			})
		}
	}

	if (isNil(value.priceFrom)) {
		isError = true
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: serializeValidationMessage('loc:Toto pole je povinné'),
			path: [...paths, 'priceFrom']
		})
	}

	if (value.variablePrice && isNil(value.priceTo)) {
		isError = true
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: serializeValidationMessage('loc:Toto pole je povinné'),
			path: [...paths, 'priceTo']
		})
	}

	if (value.variablePrice && !isNil(value.priceTo) && (value.priceFrom || 0) > value.priceTo) {
		isError = true
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: serializeValidationMessage('loc:Chybný rozsah'),
			path: [...paths, 'priceFrom']
		})

		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: serializeValidationMessage('loc:Chybný rozsah'),
			path: [...paths, 'priceTo']
		})
	}

	return isError
}

export const priceAndDurationSchema = z.object({
	durationFrom: z.number().nullish(),
	durationTo: z.number().nullish(),
	variableDuration: z.boolean().optional(),
	priceFrom: z.number().nullish(),
	priceTo: z.number().finite().nullish(),
	variablePrice: z.boolean().optional()
})

export const employeeServiceCategoryParameter = z.object({
	employeePriceAndDurationData: priceAndDurationSchema.optional(),
	hasOverriddenPricesAndDurationData: z.boolean().optional()
})

export const employeeServiceSchema = z
	.object({
		useCategoryParameter: z.boolean().optional(),
		employeePriceAndDurationData: priceAndDurationSchema.optional(),
		hasOverriddenPricesAndDurationData: z.boolean().optional(),
		serviceCategoryParameter: employeeServiceCategoryParameter.array().optional(),
		serviceCategoryParameterType: z.nativeEnum(PARAMETER_TYPE).optional()
	})
	.superRefine((values, ctx) => {
		const priceAndDurationData = values?.employeePriceAndDurationData

		if (!values?.useCategoryParameter) {
			if (priceAndDurationData && !arePriceAndDurationDataEmpty(priceAndDurationData)) {
				validatePriceAndDurationData(priceAndDurationData, ctx, ['employeePriceAndDurationData'])
			}
		} else {
			const areAllEmpty = !values.serviceCategoryParameter?.some((parameterValue) => !arePriceAndDurationDataEmpty(parameterValue.employeePriceAndDurationData))
			const validateDuration = values.serviceCategoryParameterType !== PARAMETER_TYPE.TIME

			if (!areAllEmpty) {
				let serviceCategoryParameterError = false
				values.serviceCategoryParameter?.forEach((parameterValue, parameterValueIndex) => {
					if (parameterValue.employeePriceAndDurationData) {
						const isPriceAndDurationDataError = validatePriceAndDurationData(
							parameterValue.employeePriceAndDurationData,
							ctx,
							['serviceCategoryParameter', parameterValueIndex, 'employeePriceAndDurationData'],
							validateDuration
						)

						// show error msg if there is at least one error
						if (!serviceCategoryParameterError) {
							serviceCategoryParameterError = isPriceAndDurationDataError
						}

						if (isPriceAndDurationDataError) {
							ctx.addIssue({
								code: z.ZodIssueCode.custom,
								message: serializeValidationMessage('loc:Zadaná hodnota nie je správna'),
								path: ['serviceCategoryParameter', parameterValueIndex, 'error']
							})
						}
					}
				})

				if (serviceCategoryParameterError) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: serializeValidationMessage('loc:Je potrebné vyplniť povinné údaje pre všetky hodnoty parametra'),
						path: ['serviceCategoryParameter', '_error']
					})
				}
			}
		}
	})

export const parameterValueSchema = priceAndDurationSchema.extend({
	id: uuidConstraint,
	useParameter: z.boolean()
})

export const serviceBaseSchema = priceAndDurationSchema
	.extend({
		serviceName: z.string().nullish(),
		useCategoryParameter: z.boolean(),
		selectedEmployeeIDs: uuidConstraint.array().nullish(),
		settings: z.object({
			enabledB2cReservations: z.boolean(),
			autoApproveReservations: z.boolean()
		}),
		serviceCategoryParameter: parameterValueSchema.array().nullish(),
		serviceCategoryParameterType: z.nativeEnum(PARAMETER_TYPE).optional(),
		descriptionLocalizations: z.object({
			use: z.boolean(),
			defaultLanguage: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1500, false),
			enLanguage: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1500, false)
		}),
		color: z.string().regex(hexColorRegex)
	})
	.superRefine((val, ctx) => {
		if (val.descriptionLocalizations.use && !val.descriptionLocalizations.defaultLanguage) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Toto pole je povinné'),
				path: ['descriptionLocalizations', 'defaultLanguage']
			})
		}

		if (!val.useCategoryParameter) {
			validatePriceAndDurationData(val, ctx)
		} else {
			const validateDuration = val.serviceCategoryParameterType !== PARAMETER_TYPE.TIME

			if (val.useCategoryParameter && isEmpty(val.serviceCategoryParameter?.filter((value) => value.useParameter))) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Musíte zvoliť a nastaviť aspoň jednu hodnotu parametra!'),
					path: ['serviceCategoryParameter', '_error']
				})
			}

			val.serviceCategoryParameter?.forEach((parameter, index) => {
				if (parameter.useParameter) {
					const isPriceAndDurationDataError = validatePriceAndDurationData(parameter, ctx, ['serviceCategoryParameter', index], validateDuration)

					if (isPriceAndDurationDataError) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							message: serializeValidationMessage('loc:Zadaná hodnota nie je správna'),
							path: ['serviceCategoryParameter', index, 'error']
						})
					}
				}
			})
		}
	})

export const serviceSchema = serviceBaseSchema

export const serviceCustomSchema = z
	.object({
		serviceName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255, true),
		industryIDs: z.array(uuidConstraint),
		categoryIDs: z.array(uuidConstraint),
		categoryParameterID: z
			.object({
				label: z.string(),
				value: uuidConstraint
			})
			.nullish()
	})
	.and(serviceBaseSchema)
