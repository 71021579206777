import React, { FC } from 'react'
import { change, destroy, Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button, Form, Modal, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

// utils
import { formFieldID, optionRenderWithIcon, validationRequired, checkUploadingBeforeSubmit, getMimeTypeName } from '../../../utils/helper'
import {
	DOCUMENT_ASSET_TYPE_PREFIX,
	FILE_FILTER_DATA_TYPE,
	FORM,
	SUBMIT_BUTTON_ID,
	UPLOAD,
	UPLOAD_IMG_CATEGORIES,
	URL_UPLOAD_FILE,
	VALIDATION_MAX_LENGTH
} from '../../../utils/enums'

// components
import { languageOptions } from '../../../components/LanguagePicker'
import ImgUploadField from '../../../atoms/ImgUploadField'

// atoms
import SelectField from '../../../atoms/SelectField'
import TextareaField from '../../../atoms/TextareaField'
import SwitchField from '../../../atoms/SwitchField'
import InputField from '../../../atoms/InputField'

// assets
import CloseIcon from '../../../assets/icons/close-icon-modal.svg?react'
import GlobeIcon from '../../../assets/icons/globe-icon.svg?react'
import UploadIcon from '../../../assets/icons/upload-icon.svg?react'

// reducers
import { RootState } from '../../../reducers'

// types
import { DocumentAssetTypeSelectOptionItem, IDocumentForm } from '../../../types/interfaces'

// validation
import { validationDocumentFn } from '../../../utils/formValidation'

type ComponentProps = {
	visible: boolean
	setVisible: React.Dispatch<React.SetStateAction<any>>
	disabledForm?: boolean
}

type Props = InjectedFormProps<IDocumentForm, ComponentProps> & ComponentProps

const shouldShowOptionToGenerateNotification = (assetTypePrefix: string | undefined, fileType: FILE_FILTER_DATA_TYPE | undefined) => {
	return !!assetTypePrefix?.startsWith(DOCUMENT_ASSET_TYPE_PREFIX.B2B) && fileType === FILE_FILTER_DATA_TYPE.DOC
}

const DocumentsForm: FC<Props> = (props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const { handleSubmit, submitting, disabledForm, pristine, visible, setVisible } = props
	const assetTypes = useSelector((state: RootState) => state.documents.assetTypes)
	const formValues: Partial<IDocumentForm> | undefined = useSelector((state: RootState) => getFormValues(FORM.DOCUMENTS_FORM)(state))
	const mimeType = getMimeTypeName(formValues?.assetType?.extra?.mimeTypes, formValues?.assetType?.extra?.fileType)
	const maxFilesCount = formValues?.assetType?.extra?.maxFilesCount || UPLOAD.MAX_COUNT

	const showOptionToGenerateNotificationForSelectedAssetType = shouldShowOptionToGenerateNotification(formValues?.assetType?.value, formValues?.assetType?.extra?.fileType)

	return (
		<Modal
			className='rounded-fields'
			title={formValues?.id ? t('loc:Aktualizovať dokument') : t('loc:Nahrať dokument')}
			centered
			open={visible}
			destroyOnClose
			footer={null}
			onCancel={() => {
				dispatch(destroy(FORM.DOCUMENTS_FORM))
				setVisible(false)
			}}
			closeIcon={<CloseIcon />}
			width={500}
			maskClosable={false}
			keyboard={false}
		>
			<Spin spinning={submitting}>
				<Form onSubmitCapture={handleSubmit(checkUploadingBeforeSubmit)} layout={'vertical'} className={'form'}>
					<div className={'mb-6'}>
						<Field
							component={SelectField}
							name={'assetType'}
							label={t('loc:Vyberte typ dokumentu')}
							placeholder={t('loc:Typ dokumentu')}
							size={'large'}
							required
							labelInValue
							options={assetTypes?.options}
							loading={assetTypes?.isLoading}
							disabled={formValues?.id || assetTypes?.isLoading}
							onSelect={(newValueOption: DocumentAssetTypeSelectOptionItem) => {
								// NOTE: clear form data if the new options doesn't support to generate document notification
								const showOptionToGenerateNotificationForNewAssetType = shouldShowOptionToGenerateNotification(
									typeof newValueOption.value === 'string' ? newValueOption.value : undefined,
									newValueOption.extra?.fileType
								)
								if (showOptionToGenerateNotificationForSelectedAssetType && !showOptionToGenerateNotificationForNewAssetType) {
									dispatch(change(FORM.DOCUMENTS_FORM, 'createNotification', null))
									dispatch(change(FORM.DOCUMENTS_FORM, 'notificationTitle', null))
									dispatch(change(FORM.DOCUMENTS_FORM, 'notificationContent', null))
								}
							}}
						/>
						<Field
							component={SelectField}
							optionRender={(itemData: any) => optionRenderWithIcon(itemData, <GlobeIcon />)}
							name={'languageCode'}
							label={t('loc:Vyberte jazyk')}
							placeholder={t('loc:Jazyk')}
							size={'large'}
							required
							disabled={formValues?.id}
							options={languageOptions}
						/>

						<Field
							className={'m-0 p-0'}
							uploaderClassName={'overflow-x-auto'}
							component={ImgUploadField}
							accept={mimeType?.formattedMimeTypes || 'application/pdf'}
							name={'files'}
							label={t('loc:Vyberte súbor vo formáte {{ formats }}', { formats: mimeType?.formattedNames || '.pdf' })}
							signUrl={URL_UPLOAD_FILE}
							disabled={submitting || !formValues?.assetType}
							multiple={maxFilesCount > 1}
							maxCount={maxFilesCount}
							required
							validate={validationRequired}
							category={mimeType?.fileType === FILE_FILTER_DATA_TYPE.DOC ? UPLOAD_IMG_CATEGORIES.ASSET_DOC_TYPE : UPLOAD_IMG_CATEGORIES.ASSET_IMAGE_TYPE}
						/>

						{/* // Message field nebude pre dokumenty typu IMAGE dostupný */}
						{formValues?.assetType?.extra?.fileType === FILE_FILTER_DATA_TYPE.IMAGE ? undefined : (
							<Field
								name={'message'}
								label={t('loc:Sprievodná správa')}
								placeholder={t('loc:Zadajte sprievodnú správu')}
								className={'pb-0 mt-4'}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
								showLettersCount
								component={TextareaField}
							/>
						)}
					</div>
					{showOptionToGenerateNotificationForSelectedAssetType && (
						<div className={'mb-4'}>
							<Field
								className={'mb-0 pb-2'}
								component={SwitchField}
								label={t('loc:Vytvoriť notifikáciu o aktualizovaní dokumentu')}
								name={'createNotification'}
								size={'middle'}
							/>
							{formValues?.createNotification && (
								<>
									<Field
										component={InputField}
										label={t('loc:Názov upozornenia')}
										name={'notificationTitle'}
										size={'large'}
										placeholder={t('loc:Napíšte názov upozornenia')}
										maxLength={VALIDATION_MAX_LENGTH.LENGTH_50}
										required
									/>
									<Field
										component={TextareaField}
										label={t('loc:Text upozornenia')}
										placeholder={t('loc:Napíšte text upozornenia')}
										maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
										showLettersCount
										name={'notificationContent'}
										size={'large'}
										rows={4}
										required
									/>
								</>
							)}
						</div>
					)}
					<Button
						id={formFieldID(FORM.DOCUMENTS_FORM, SUBMIT_BUTTON_ID)}
						className='noti-btn w-full'
						size='large'
						type='primary'
						htmlType='submit'
						disabled={disabledForm || submitting || pristine}
						loading={submitting}
						icon={<UploadIcon />}
					>
						{t('loc:Nahrať')}
					</Button>
				</Form>
			</Spin>
		</Modal>
	)
}

const form = reduxForm<IDocumentForm, ComponentProps>({
	form: FORM.DOCUMENTS_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validationDocumentFn
})(DocumentsForm)

export default form
