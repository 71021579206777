import React, { FC } from 'react'
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button, Form } from 'antd'
import { useSelector } from 'react-redux'

// utils
import { showErrorNotification } from '../../../utils/helper'
import { ASYNC_JOB_TYPE, FORM, SUBMIT_ASYNC_JOB_BUTTON_ID } from '../../../utils/enums'

// types
import { IImportSalonsForm } from '../../../types/interfaces'
import { RootState } from '../../../reducers'

// components
import FileUploadField from '../../../atoms/FileUploadField'

// validation
import { validationImportSalonsFn } from '../../../utils/formValidation'

type ComponentProps = {}

type Props = InjectedFormProps<IImportSalonsForm, ComponentProps> & ComponentProps

const ImportSalonsForm: FC<Props> = (props) => {
	const [t] = useTranslation()

	const { handleSubmit, submitting } = props

	const formValues: Partial<IImportSalonsForm> = useSelector((state: RootState) => getFormValues(FORM.IMPORT_SALONS)(state))

	const disabledButton = submitting || !formValues?.file

	return (
		<Form onSubmitCapture={handleSubmit} layout={'vertical'} className={'form'}>
			<Field
				component={FileUploadField}
				name={'file'}
				label={t('loc:Vyberte súbor vo formáte {{ formats }}', { formats: '.xlsx' })}
				accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'}
				maxCount={1}
				type={'file'}
				disabled={submitting}
				manualUpload
				required
			/>
			<Button
				id={SUBMIT_ASYNC_JOB_BUTTON_ID(ASYNC_JOB_TYPE.IMPORT_SALONS)}
				className={`noti-btn sticky mt-4 bottom-6 shadow-[0px_8px_24px_rgba(255,255,255,0.84),_0px_4px_14px_rgba(255,255,255,0.84),_0px_2px_4px_rgba(255,255,255,0.84)] ${!disabledButton ? `hover:shadow-[0px_8px_24px_rgba(0,0,0,0.24),_0px_4px_14px_rgba(0,0,0,0.14),_0px_2px_4px_rgba(0,0,0,0.16)]` : ''}`}
				block
				size='large'
				type='primary'
				htmlType='submit'
				disabled={disabledButton}
				loading={submitting}
			>
				{t('loc:Importovať')}
			</Button>
		</Form>
	)
}

const form = reduxForm<IImportSalonsForm, ComponentProps>({
	form: FORM.IMPORT_SALONS,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	onSubmitFail: showErrorNotification,
	validate: validationImportSalonsFn
})(ImportSalonsForm)

export default form
