// types
import { ASSET_TYPES, DOCUMENTS_BY_ASSET_TYPE, DOCUMENTS, SET_DOCUMENTS_ACTIVE_KEYS, DOCUMENTS_GROUPED_BY_ASSET_TYPES } from './documentTypes'
import { IResetStore } from '../generalTypes'
import { DocumentsActiveKeys, GetUrls, ISearchable, ISelectOptionItem, RequestResponse } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IDocumentsAssetTypesRequestQueryParams, IGetDocumentsGroupedByAssetTypesQueryParams, IGetDocumentsQueryParams } from '../../types/schemaTypes'

// utils
import { getReq } from '../../utils/request'
import { LANGUAGE } from '../../utils/enums'

export type IDocumentsActions = IResetStore | IGetDocuments | IGetDocumentsByAssetType | IGetAssetTypes | IGetDocumentsGroupedByAssetTypes | ISetDocumentsActiveKeys

interface IGetDocuments {
	type: DOCUMENTS
	payload: IDocumentsPayload
}

interface IGetAssetTypes {
	type: ASSET_TYPES
	payload: IAssetTypesPayload
}

interface IGetDocumentsByAssetType {
	type: DOCUMENTS_BY_ASSET_TYPE
	payload: IDocumentsByAssetTypePayload
}

interface ISetDocumentsActiveKeys {
	type: typeof SET_DOCUMENTS_ACTIVE_KEYS
	payload: DocumentsActiveKeys
}

interface IGetDocumentsGroupedByAssetTypes {
	type: DOCUMENTS_GROUPED_BY_ASSET_TYPES
	payload: IDocumentsGroupedByAssetTypesPayload
}

export interface IAssetTypesPayload {
	options?: ISelectOptionItem[]
	data: RequestResponse<GetUrls['/api/b2b/admin/enums/asset-types/']> | null
}

export interface IDocumentsGroupedByAssetTypesPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/documents/asset-types']>> {
	activeKeys: DocumentsActiveKeys
}

export interface IDocumentsByAssetTypePayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/documents/asset-types/{assetType}']>> {}

export interface IDocumentsPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/documents/']>> {}

const languages = Object.values(LANGUAGE)

export const getDocuments =
	(queryParams: IGetDocumentsQueryParams): ThunkResult<Promise<IDocumentsPayload>> =>
	async (dispatch) => {
		let payload = {} as IDocumentsPayload

		try {
			dispatch({ type: DOCUMENTS.DOCUMENTS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/documents/', {
				params: {
					query: {
						page: queryParams.page,
						limit: queryParams.limit,
						languageCode: queryParams.languageCode,
						assetTypes: queryParams.assetTypes
					}
				},
				reqBody: {}
			})

			payload = {
				data
			}

			dispatch({ type: DOCUMENTS.DOCUMENTS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: DOCUMENTS.DOCUMENTS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getDocumentsGroupedByAssetTypes =
	(queryParams: IGetDocumentsGroupedByAssetTypesQueryParams): ThunkResult<Promise<IDocumentsGroupedByAssetTypesPayload>> =>
	async (dispatch, getState) => {
		let payload = {} as IDocumentsGroupedByAssetTypesPayload

		const selectedKeys = getState().documents.documentsGroupedByAssetTypes.activeKeys

		try {
			dispatch({ type: DOCUMENTS_GROUPED_BY_ASSET_TYPES.DOCUMENTS_GROUPED_BY_ASSET_TYPES_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/documents/asset-types', { params: { query: queryParams }, reqBody: {} })

			// filter unsupported language from the response
			const mappedData = data.assetTypes.map((assetType) => ({
				...assetType,
				languages: assetType.languages.filter((language) => languages.includes(language.languageCode as LANGUAGE))
			}))

			let activeKeys: string[] = []

			if (selectedKeys?.length) {
				activeKeys = selectedKeys
			} else if (data?.assetTypes[0].key) {
				activeKeys = [data.assetTypes[0].key]
			}

			payload = { data: { ...data, assetTypes: mappedData }, activeKeys }
			dispatch({ type: DOCUMENTS_GROUPED_BY_ASSET_TYPES.DOCUMENTS_GROUPED_BY_ASSET_TYPES_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: DOCUMENTS_GROUPED_BY_ASSET_TYPES.DOCUMENTS_GROUPED_BY_ASSET_TYPES_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getDocumentsByAssetType =
	(queryParams: IDocumentsAssetTypesRequestQueryParams): ThunkResult<Promise<IDocumentsByAssetTypePayload>> =>
	async (dispatch) => {
		let payload = {} as IDocumentsByAssetTypePayload
		const { assetType, ...query } = queryParams
		try {
			dispatch({ type: DOCUMENTS_BY_ASSET_TYPE.DOCUMENTS_BY_ASSET_TYPE_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/documents/asset-types/{assetType}', {
				params: {
					path: { assetType },
					query
				},
				reqBody: {}
			})

			payload = {
				data
			}

			dispatch({ type: DOCUMENTS_BY_ASSET_TYPE.DOCUMENTS_BY_ASSET_TYPE_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: DOCUMENTS_BY_ASSET_TYPE.DOCUMENTS_BY_ASSET_TYPE_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getAssetTypes = (): ThunkResult<Promise<IAssetTypesPayload>> => async (dispatch) => {
	let payload = {} as IAssetTypesPayload

	try {
		dispatch({ type: ASSET_TYPES.ASSET_TYPES_LOAD_START })
		const { data } = await getReq('/api/b2b/admin/enums/asset-types/', { params: {}, reqBody: {} })
		const options: ISelectOptionItem[] = data.assetTypes.map((assetType) => ({
			key: assetType.key,
			label: assetType.name || assetType.key,
			value: assetType.key,
			extra: {
				mimeTypes: assetType.mimeTypes,
				fileType: assetType.fileType,
				maxFilesCount: assetType.maxFilesCount
			}
		}))

		payload = { data, options }
		dispatch({ type: ASSET_TYPES.ASSET_TYPES_LOAD_DONE, payload })
	} catch (err) {
		dispatch({ type: ASSET_TYPES.ASSET_TYPES_LOAD_FAIL })
		// eslint-disable-next-line no-console
		console.error(err)
	}

	return payload
}

export const setDocumentsActiveKeys =
	(newActiveKeys: DocumentsActiveKeys): ThunkResult<DocumentsActiveKeys> =>
	(dispatch) => {
		dispatch({ type: SET_DOCUMENTS_ACTIVE_KEYS, payload: newActiveKeys })
		return newActiveKeys
	}
