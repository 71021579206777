// types
import { IResetStore } from '../generalTypes'
import { GetUrls, ISearchable, RequestResponse } from '../../types/interfaces'
import { IGetChangelogsQueryParams } from '../../types/schemaTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'

// utils
import { getReq } from '../../utils/request'
import { CHANGELOGS, CHANGELOG } from './changelogsTypes'

export type IChangelogsActions = IResetStore | IGetChangelogs | IGetChangelog

interface IGetChangelogs {
	type: CHANGELOGS
	payload: IChangelogsPayload
}

interface IGetChangelog {
	type: CHANGELOG
	payload: IChangelogPayload
}

export interface IChangelogsPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/changelogs/']>> {}
export interface IChangelogPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/changelogs/{changelogID}']> | null
}

export const getChangelogs =
	(queryParams?: IGetChangelogsQueryParams): ThunkResult<Promise<IChangelogsPayload>> =>
	async (dispatch) => {
		let payload = {} as IChangelogsPayload

		try {
			dispatch({ type: CHANGELOGS.CHANGELOGS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/changelogs/', {
				params: {
					query: {
						search: queryParams?.search,
						limit: queryParams?.limit,
						page: queryParams?.page,
						order: queryParams?.order,
						platform: queryParams?.platform || undefined
					}
				},
				reqBody: {}
			})

			payload = { data }
			dispatch({ type: CHANGELOGS.CHANGELOGS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: CHANGELOGS.CHANGELOGS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getChangelog =
	(changelogID: string): ThunkResult<Promise<IChangelogPayload>> =>
	async (dispatch) => {
		let payload = {} as IChangelogPayload
		try {
			dispatch({ type: CHANGELOG.CHANGELOG_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/changelogs/{changelogID}', { params: { path: { changelogID } }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: CHANGELOG.CHANGELOG_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: CHANGELOG.CHANGELOG_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
