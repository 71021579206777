import React from 'react'
import { ConfigProvider, DatePicker, DatePickerProps, Form } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import cx from 'classnames'
import { Fields, WrappedFieldsProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'

// assets
import DateSuffixIcon from '../assets/icons/date-suffix-icon.svg?react'
import RemoveIcon from '../assets/icons/remove-select-icon.svg?react'

// enums
import { DEFAULT_DATE_INIT_FORMAT } from '../utils/enums'

// helpers

// helpers
import { formFieldID } from '../utils/helper'

type Props = Fields &
	WrappedFieldsProps &
	InputProps &
	FormItemLabelProps &
	Omit<DatePickerProps, 'clearIcon' | 'onOpenChange' | 'onCalendarChange'> & {
		disablePast?: boolean // for disable startDate from past
		disableStartDayEnd?: boolean
		labels?: React.ReactNode[]
		customLabel?: React.ReactNode
		onOpenChange?: (open: boolean, index: 0 | 1) => void
		onCalendarChange: (value: dayjs.Dayjs | undefined, index: 0 | 1) => void
		formats?: [string?, string?]
		placeholders?: [string?, string?]
	}

const { Item } = Form

const DateRangeField = (props: Props) => {
	const {
		names,
		labels,
		formats,
		renderExtraFooter,
		required,
		placeholders,
		disabled,
		disableStartDayEnd,
		disablePast,
		suffixIcon,
		label,
		customLabel,
		size,
		allowClear,
		getPopupContainer,
		onOpenChange,
		onCalendarChange
	} = props
	const disabledStartDate = (startValue: Dayjs | null) => {
		const endValue = props[names[1]].input.value
		const yesterday = dayjs().subtract(1, 'day')
		if (disablePast && startValue?.isBefore(yesterday)) {
			// disable past
			return true
		}
		if (startValue?.isAfter(endValue)) {
			// disable StartValue after EndValue
			return true
		}
		return !!(disableStartDayEnd && (startValue?.isAfter(endValue) || startValue?.isSame(endValue)))
	}

	const disabledEndDate = (endValue: Dayjs | null) => {
		const startValue = props[names[0]].input.value
		if (disablePast && endValue?.isBefore(dayjs())) {
			// disable past
			return true
		}
		if (endValue?.isBefore(startValue)) {
			// disable EndValue before StartValue
			return true
		}
		return !!(disableStartDayEnd && (endValue?.isBefore(startValue) || endValue?.isSame(startValue)))
	}

	const { locale } = React.useContext(ConfigProvider.ConfigContext)

	return (
		<div>
			{customLabel || <div className={'text-notino-black font-semibold text-xs'}>{label}</div>}
			<div className={'flex'}>
				{names.map((name, index) => {
					const { input, meta } = props[name] || {}
					const { touched, error, form } = meta || {}
					let value
					const format = formats?.[index] || locale?.DatePicker?.lang?.dateFormat || DEFAULT_DATE_INIT_FORMAT
					if (input.value && dayjs(input.value).isValid()) {
						value = dayjs(input.value)
					}

					const allowClearWrap =
						!required || allowClear
							? {
									clearIcon: <RemoveIcon />
								}
							: false

					let disabledDate
					if (index === 0) {
						disabledDate = disabledStartDate
					} else {
						disabledDate = disabledEndDate
					}
					return (
						<div id={formFieldID(form, name)} className={'w-1/2 mr-1'}>
							<Item
								label={labels ? labels[index] : undefined}
								required={required}
								help={touched && error}
								validateStatus={error && touched ? 'error' : undefined}
								key={index}
							>
								<DatePicker
									value={value}
									format={format}
									className={cx('w-full noti-date-input', { 'allow-clear': allowClearWrap })}
									onChange={(val) => {
										if (val) {
											input.onChange(val.format(DEFAULT_DATE_INIT_FORMAT))
										} else {
											input.onChange(null)
										}
									}}
									onOpenChange={(open) => {
										if (onOpenChange) {
											onOpenChange(open, index === 0 ? 0 : 1)
										}
									}}
									onCalendarChange={(val) => {
										if (onCalendarChange) {
											onCalendarChange(Array.isArray(val) ? val[0] : val, index === 0 ? 0 : 1)
										}
									}}
									size={size}
									getPopupContainer={getPopupContainer || ((node) => node)}
									placeholder={placeholders?.[index]}
									disabledDate={disabledDate}
									disabled={disabled}
									renderExtraFooter={renderExtraFooter}
									showNow={!(index === 1 && disableStartDayEnd === true)}
									suffixIcon={suffixIcon || <DateSuffixIcon />}
									allowClear={allowClearWrap}
								/>
							</Item>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default DateRangeField
