import { z } from 'zod'

import { serializeValidationMessage, stringConstraint, twoCharsConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH } from '../utils/enums'

// eslint-disable-next-line import/prefer-default-export
export const documentSchema = z
	.object({
		message: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255),
		languageCode: twoCharsConstraint,
		assetType: z.object({
			key: z.string(),
			value: z.string(),
			label: z.string()
		}),
		createNotification: z.boolean().nullish(),
		notificationTitle: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_50),
		notificationContent: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255)
	})
	.superRefine((val, ctx) => {
		if (val.createNotification) {
			if (!val.notificationTitle) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['notificationTitle']
				})
			}

			if (!val.notificationContent) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['notificationContent']
				})
			}
		}
	})
