import React from 'react'
import { Alert, Button, Progress } from 'antd'
import { ProgressProps } from 'antd/lib'
import { useTranslation } from 'react-i18next'

// assets
import UploadIcon from '../../../assets/icons/upload-icon.svg?react'
import CheckIcon from '../../../assets/icons/check-icon-circle-icon.svg?react'

// types
import { AsyncJob } from '../../../types/interfaces'

// utils
import { ASYNC_JOB_MODAL_GENERATE_MORE_BTN_ID, ASYNC_JOB_STATE, ASYNC_JOB_TYPE } from '../../../utils/enums'
import { LocalizedDateTime } from '../../../utils/intl'

type Props = {
	onRequestAgain: () => void
	successMessage?: string
	description?: string
	buttonText?: string
	buttonIcon?: React.ReactNode
	asyncJobs: AsyncJob[]
	jobType: ASYNC_JOB_TYPE
}

const getProgressBarProps = (jobState: ASYNC_JOB_STATE): ProgressProps => {
	const barProps: ProgressProps = {
		trailColor: '#F5F5F5',
		strokeColor: '#000'
	}

	switch (jobState) {
		case ASYNC_JOB_STATE.COMPLETED:
			return {
				...barProps,
				strokeColor: '#008700',
				status: 'success'
			}
		case ASYNC_JOB_STATE.FAILED:
			return {
				...barProps,
				strokeColor: '#D21414',
				status: 'exception'
			}
		case ASYNC_JOB_STATE.IN_PROGRESS:
			return {
				...barProps,
				status: 'active'
			}
		default:
			return barProps
	}
}

const SuccessView = (props: Props) => {
	const [t] = useTranslation()

	const {
		onRequestAgain,
		successMessage = t('loc:Požiadavka na spracovanie dát bola úspešne vytvorená.'),
		description = t('loc:Po spracovaní vám na vašu e-mailovú adresu zašleme súhrnnú správu o importe.'),
		buttonIcon = <UploadIcon />,
		buttonText = t('loc:Importovať ďalšie'),
		asyncJobs,
		jobType
	} = props

	return (
		<div className={'data-upload-success flex flex-col'}>
			<Alert
				message={successMessage}
				type={'success'}
				className={'noti-alert success-request-alert mb-4'}
				showIcon
				icon={<CheckIcon className={'medium-icon text-notino-success'} />}
			/>
			<p className={'mb-4'}>{description}</p>

			{asyncJobs.length ? (
				<>
					<strong className={'mb-4'}>{t('loc:Prebiehajúce požiadavky')}</strong>
					<ul className={'flex flex-col gap-2 mb-4'}>
						{asyncJobs.map((job) => {
							return (
								<li key={job.jobID}>
									<span className={'text-sm'}>
										<LocalizedDateTime date={job.startDateTime} />
									</span>
									{job.jobState === ASYNC_JOB_STATE.COULD_NOT_VERIFY ? (
										<p className={'text-xs mt-1 mb-0 text-danger'}>{t('loc:Aktuálny stav požiadavky sa nepodarilo zistiť. Počkajte na e-mail o výsledku.')}</p>
									) : (
										<Progress percent={job.progressPercentage} {...getProgressBarProps(job.jobState)} />
									)}
								</li>
							)
						})}
					</ul>
				</>
			) : null}

			<Button
				id={ASYNC_JOB_MODAL_GENERATE_MORE_BTN_ID(jobType)}
				onClick={onRequestAgain}
				type='primary'
				htmlType='button'
				className={'noti-btn self-center'}
				icon={buttonIcon}
			>
				{buttonText}
			</Button>
		</div>
	)
}

export default SuccessView
