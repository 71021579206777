import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Header } from 'antd/lib/layout/layout'
import { Button, Result, Row, Select, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'

// components
import { languageOptions } from '../../components/LanguagePicker'

// assets
import Logo from '../../assets/images/notino-logo-no-description.png'

// types
import { RootState } from '../../reducers'

// utils
import { ASSET_TYPE, LANGUAGE } from '../../utils/enums'
import { getLanguageCode } from '../../utils/intl'

// redux
import { getDocuments } from '../../reducers/documents/documentActions'

const { Option } = Select

interface Props {
	assetType: ASSET_TYPE
}

const getDocumentTitle = (assetType: ASSET_TYPE) => {
	switch (assetType) {
		case ASSET_TYPE.B2B_PRIVACY_POLICY:
			return i18next.t('loc:Zásady spracovania osobných údajov')
		case ASSET_TYPE.B2B_APP_TERMS_CONDITIONS:
			return i18next.t('loc:Podmienky používania')
		default:
			throw new Error('Unsupported asset type')
	}
}

const LegacyDocumentPage = (props: Props) => {
	const { assetType } = props
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const documents = useSelector((state: RootState) => state.documents.documents)

	const [language, setLanguage] = useState<LANGUAGE>()

	useEffect(() => {
		setLanguage(getLanguageCode(i18next.resolvedLanguage, i18next.language))
	}, [])

	useEffect(() => {
		if (language) {
			dispatch(getDocuments({ page: 1, limit: 1, assetTypes: [assetType], languageCode: language }))
		}
	}, [dispatch, assetType, language])

	const document = documents.data?.documents[0]?.files[0]

	const getContent = () => {
		if (documents.isLoading) {
			return <Spin className={'w-full h-screen pt-32'} />
		}

		if (document) {
			return (
				<object data={`${document.original}#scrollbar=0&toolbar=0&statusbar=0&messages=0&navpanes=0`} width='100%' className={'w-full h-full'}>
					<div className={'flex flex-col items-center text-center w-full mt-48 p-4'}>
						<h1 className={'text-lg'}>{getDocumentTitle(assetType)}</h1>
						<p className={'max-w-md'}>
							{t('loc:PDF dokument nie je možné zobraziť. Žiadny problém, kliknutím na tlačidlo nižšie si môžete dokument stiahnuť do vášho zariadenia')}
						</p>
						<Button
							type={'primary'}
							className={'noti-btn no-underline'}
							href={document.original}
							target={'_blank'}
							rel={'noopener noreferrer'}
							download={document.fileName}
						>
							{t('loc:Stiahnuť dokument')}
						</Button>
					</div>
				</object>
			)
		}

		return <Result status='404' title='404' />
	}

	return (
		<div className={'w-full'}>
			<Header className='shadow-md bg-notino-white sticky top-0 px-4 items-center z-20 w-full h-16 flex justify-between'>
				<a href={t('paths:index')}>
					<img src={Logo} alt={t('loc:Naspäť na hlavnú stránku Notino Partner')} />
				</a>
				<div className={'pb-0 h-auto flex items-center language-picker-select ant-form-item'}>
					<Select
						value={language}
						onChange={(value: LANGUAGE) => setLanguage(value)}
						className={'noti-select-input'}
						popupClassName={'noti-select-dropdown z-30'}
						disabled={documents.isLoading}
					>
						{languageOptions?.map((option) => (
							<Option value={option.value} key={option.key}>
								<Row className={'justify-center'}>{option.icon}</Row>
							</Option>
						))}
					</Select>
				</div>
			</Header>

			<main className={'bg-notino-grayLighter z-10 fixed top-16 bottom-0 left-0 right-0 overflow-hidden'}>{getContent()}</main>
		</div>
	)
}

export default LegacyDocumentPage
