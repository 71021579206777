import React from 'react'
import { Outlet, RouteProps } from 'react-router-dom'

const BaseRoute = <T extends { layout?: React.ComponentType<any> } & RouteProps>(props: T) => {
	const { layout: Layout, ...rest } = props

	if (Layout) {
		return (
			<Layout {...rest}>
				<Outlet />
			</Layout>
		)
	}

	return <Outlet />
}

export default BaseRoute
