import React, { FC, useMemo } from 'react'
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Form, FormProps } from 'antd'
import { useSelector } from 'react-redux'

// utils
import { DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE, FORM, VALIDATION_MAX_LENGTH } from '../../../utils/enums'
import { showErrorNotification } from '../../../utils/helper'
import { getLanguageCode, getLocale } from '../../../utils/intl'
import { languageOptions } from '../../../components/LanguagePicker'

// assets
import AttachIcon from '../../../assets/icons/attach-icon.svg?react'

// atoms
import InputField from '../../../atoms/InputField'
import TextareaField from '../../../atoms/TextareaField'

// components
import FlagIcon from '../../../components/FlagIcon'

// types
import { RootState } from '../../../reducers'

// types
import { IDynamicNotificationsForm } from '../../../types/interfaces'

// validation
import { validationDynamicNotificationsFn } from '../../../utils/formValidation'

type ComponentProps = FormProps & {
	readOnly?: boolean
}

type Props = InjectedFormProps<IDynamicNotificationsForm, ComponentProps> & ComponentProps

const DynamicNotificationForm: FC<Props> = (props) => {
	const [t] = useTranslation()
	const { handleSubmit, readOnly } = props
	const formValues: Partial<IDynamicNotificationsForm> = useSelector((state: RootState) => getFormValues(FORM.DYNAMIC_NOTIFICATIONS)(state))

	const selectedLanguages = useMemo(() => {
		return formValues?.selectedLanguages?.sort((a, b) => {
			const aIndex = languageOptions.findIndex((option) => option.value === a)
			const bIndex = languageOptions.findIndex((option) => option.value === b)
			return aIndex - bIndex
		})
	}, [formValues?.selectedLanguages])

	return (
		<Form layout={'vertical'} className={'form flex flex-col gap-6'} onSubmitCapture={handleSubmit}>
			{selectedLanguages?.map((lng) => {
				const lang = getLanguageCode(lng)
				const countryCode = getLocale(lng).countryCode.toUpperCase()
				const localizationData = formValues?.localizations ? formValues.localizations[lang] : undefined

				return lng === lang ? (
					<div key={lng}>
						<h4 className={'inline-flex items-center gap-2 text-lg leading-6 text-notino-black mb-4'}>
							<FlagIcon language={lang} className={'flex-shrink-0'} /> {countryCode || lang}
						</h4>
						<div className={'bg-notino-grayLighter rounded-2xl p-4 flex flex-col gap-4'}>
							<Field
								component={InputField}
								label={t('loc:Názov upozornenia')}
								name={`localizations.${lang}.title`}
								size={'large'}
								placeholder={t('loc:Napíšte názov upozornenia')}
								disabled={readOnly}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_50}
								className={'pb-0'}
								required
							/>
							<Field
								component={TextareaField}
								label={t('loc:Text upozornenia')}
								name={`localizations.${lang}.content`}
								size={'large'}
								placeholder={t('loc:Napíšte text upozornenia')}
								className={'pb-0'}
								disabled={readOnly}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
								showLettersCount
								required
								rows={4}
							/>
							{(() => {
								if (localizationData?.notificationType === DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE.DOCUMENT && localizationData.document?.length) {
									return (
										<div>
											<span className={'ant-form-item-label'}>{t('loc:Sprievodný dokument')}</span>
											<br />
											{localizationData.document?.map((file) => {
												return (
													<a
														key={file.id}
														href={file.link}
														target='_blank'
														rel='noreferrer'
														className={'text-notino-pink hover:text-notino-black inline-flex items-center'}
													>
														<AttachIcon className={'flex-shrink-0'} />
														{file.name}
													</a>
												)
											})}
										</div>
									)
								}

								if (localizationData?.notificationType === DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE.OTHER) {
									return (
										<Field
											component={InputField}
											label={t('loc:Sprievodný odkaz')}
											name={`localizations.${lang}.externalLink`}
											size={'large'}
											placeholder={t('loc:Zadajte sprievodnú URL adresu pre toto upozornenie')}
											maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
											className={'pb-0'}
											disabled={readOnly}
										/>
									)
								}

								return null
							})()}
						</div>
					</div>
				) : null
			})}
		</Form>
	)
}

const form = reduxForm<IDynamicNotificationsForm, ComponentProps>({
	form: FORM.DYNAMIC_NOTIFICATIONS,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	onSubmitFail: showErrorNotification,
	validate: validationDynamicNotificationsFn
})(DynamicNotificationForm)

export default form
