// eslint-disable-next-line import/prefer-default-export
export enum EMPLOYEES {
	EMPLOYEES_LOAD_START = 'EMPLOYEES_LOAD_START',
	EMPLOYEES_LOAD_DONE = 'EMPLOYEES_LOAD_DONE',
	EMPLOYEES_LOAD_FAIL = 'EMPLOYEES_LOAD_FAIL',
	EMPLOYEES_REORDER = 'EMPLOYEES_REORDER'
}
export enum DELETED_EMPLOYEES {
	DELETED_EMPLOYEES_LOAD_START = 'DELETED_EMPLOYEES_LOAD_START',
	DELETED_EMPLOYEES_LOAD_DONE = 'DELETED_EMPLOYEES_LOAD_DONE',
	DELETED_EMPLOYEES_LOAD_FAIL = 'DELETED_EMPLOYEES_LOAD_FAIL'
}
export enum ACTIVE_EMPLOYEES {
	ACTIVE_EMPLOYEES_LOAD_START = 'ACTIVE_EMPLOYEES_LOAD_START',
	ACTIVE_EMPLOYEES_LOAD_DONE = 'ACTIVE_EMPLOYEES_LOAD_DONE',
	ACTIVE_EMPLOYEES_LOAD_FAIL = 'ACTIVE_EMPLOYEES_LOAD_FAIL'
}
export enum EMPLOYEE {
	EMPLOYEE_LOAD_START = 'EMPLOYEE_LOAD_START',
	EMPLOYEE_LOAD_DONE = 'EMPLOYEE_LOAD_DONE',
	EMPLOYEE_LOAD_FAIL = 'EMPLOYEE_LOAD_FAIL'
}
export enum EMPLOYEE_SHIFTS {
	EMPLOYEE_SHIFTS_LOAD_START = 'EMPLOYEE_SHIFTS_LOAD_START',
	EMPLOYEE_SHIFTS_LOAD_DONE = 'EMPLOYEE_SHIFTS_LOAD_DONE',
	EMPLOYEE_SHIFTS_LOAD_FAIL = 'EMPLOYEE_SHIFTS_LOAD_FAIL'
}
