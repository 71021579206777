// types
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'

// utils
import { getReq } from '../../utils/request'
import { SMS_UNIT_PRICE, SMS_UNIT_PRICES, SMS_UNIT_PRICES_ACTUAL } from './smsUnitPricesTypes'

// types
import { GetUrls, ISearchable, RequestResponse } from '../../types/interfaces'
import { IGetSmsUnitPricesQueryParams } from '../../types/schemaTypes'

export type ISmsUnitPricesActions = IResetStore | IGetSmsUnitPricesActual | IGetSmsUnitPrices | IGetSmsUnitPrice

interface IGetSmsUnitPricesActual {
	type: SMS_UNIT_PRICES_ACTUAL
	payload: ISmsUnitPricesActualPayload
}

interface IGetSmsUnitPrices {
	type: SMS_UNIT_PRICES
	payload: ISmsUnitPricesPayload
}

interface IGetSmsUnitPrice {
	type: SMS_UNIT_PRICE
	payload: ISmsUnitPricePayload
}

export interface ISmsUnitPricesActualPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/enums/sms-unit-prices/actual']>['actualSmsUnitPricesPerCountry'] | null
}

export interface ISmsUnitPricePayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/enums/sms-unit-prices/{smsUnitPriceID}']>['smsUnitPrice'] | null
}

export interface ISmsUnitPricesPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/enums/sms-unit-prices/']>> {}

export const getSmsUnitPricesActual = (): ThunkResult<Promise<ISmsUnitPricesActualPayload>> => async (dispatch) => {
	let payload = {} as ISmsUnitPricesActualPayload
	try {
		dispatch({ type: SMS_UNIT_PRICES_ACTUAL.SMS_UNIT_PRICES_ACTUAL_START })
		const { data } = await getReq('/api/b2b/admin/enums/sms-unit-prices/actual', { params: {}, reqBody: {} })

		payload = {
			data: data.actualSmsUnitPricesPerCountry
		}

		dispatch({ type: SMS_UNIT_PRICES_ACTUAL.SMS_UNIT_PRICES_ACTUAL_DONE, payload })
	} catch (err) {
		dispatch({ type: SMS_UNIT_PRICES_ACTUAL.SMS_UNIT_PRICES_ACTUAL_FAIL })
		// eslint-disable-next-line no-console
		console.error(err)
	}

	return payload
}

export const getSmsUnitPrices =
	(queryParams: IGetSmsUnitPricesQueryParams): ThunkResult<Promise<ISmsUnitPricesPayload>> =>
	async (dispatch) => {
		let payload = {} as ISmsUnitPricesPayload
		try {
			dispatch({ type: SMS_UNIT_PRICES.SMS_UNIT_PRICES_START })
			const { data } = await getReq('/api/b2b/admin/enums/sms-unit-prices/', {
				params: { query: { countryCode: queryParams.countryCode, limit: queryParams.limit, page: queryParams.page, order: queryParams.order } },
				reqBody: {}
			})

			payload = {
				data
			}

			dispatch({ type: SMS_UNIT_PRICES.SMS_UNIT_PRICES_DONE, payload })
		} catch (err) {
			dispatch({ type: SMS_UNIT_PRICES.SMS_UNIT_PRICES_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getSmsUnitPrice =
	(smsUnitPriceID: string): ThunkResult<Promise<ISmsUnitPricePayload>> =>
	async (dispatch) => {
		let payload = {} as ISmsUnitPricePayload
		try {
			dispatch({ type: SMS_UNIT_PRICE.SMS_UNIT_PRICE_START })
			const { data } = await getReq('/api/b2b/admin/enums/sms-unit-prices/{smsUnitPriceID}', { params: { path: { smsUnitPriceID } }, reqBody: {} })

			payload = {
				data: data.smsUnitPrice
			}

			dispatch({ type: SMS_UNIT_PRICE.SMS_UNIT_PRICE_DONE, payload })
		} catch (err) {
			dispatch({ type: SMS_UNIT_PRICE.SMS_UNIT_PRICE_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
