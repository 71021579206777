import { z } from 'zod'
import { isValidIBAN } from 'ibantools'
import { emailConstraint, serializeValidationMessage, stringConstraint, twoCharsConstraint } from './baseSchema'
import { BILLING_INFO_FORM_SECTION, VALIDATION_MAX_LENGTH } from '../utils/enums'
import { removeWhiteSpacesRegex } from '../utils/regex'

// https://notino-admin.goodrequest.dev/api/doc/?urls.primaryName=v2.2.9#/B2b-%3Eadmin/patchApiB2BAdminSalonsSalonIdInvoice
// eslint-disable-next-line import/prefer-default-export
export const billingSchema = z.object({
	[BILLING_INFO_FORM_SECTION.CONTACT_PERSON_SECTION]: z.object({
		firstName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100),
		lastName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100),
		email: emailConstraint.nullish(),
		phonePrefixCountryCode: twoCharsConstraint.optional(),
		phone: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_20)
	}),
	[BILLING_INFO_FORM_SECTION.BANK_DETAILS_SECTION]: z.object({
		iban: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255).refine(
			(val) => {
				if (val) {
					return isValidIBAN(val.replace(removeWhiteSpacesRegex, ''))
				}
				return true
			},
			{
				message: serializeValidationMessage('loc:IBAN kód nie je platný')
			}
		),
		contactPersonDisagreeMarketing: z.boolean().optional()
	}),
	[BILLING_INFO_FORM_SECTION.CORPORATE_INFORMATION_SECTION]: z.object({
		businessID: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_20),
		taxID: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_20),
		vatID: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_20),
		companyName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255)
	}),
	[BILLING_INFO_FORM_SECTION.BILLING_ADDRESS_SECTION]: z.object({
		countryCode: twoCharsConstraint.nullish(),
		zipCode: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_10),
		city: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100),
		street: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100),
		streetNumber: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_10)
	})
})
