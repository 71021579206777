import React, { useMemo } from 'react'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import { LANGUAGE } from '../utils/enums'
import { RootState } from '../reducers'

type Props = {
	language: LANGUAGE
	className?: string
	size?: 24
}

const FlagIcon = ({ language, className, size = 24 }: Props) => {
	const config = useSelector((state: RootState) => state.config.config)

	const flags = useMemo(
		() =>
			config.data?.systemLanguages.reduce(
				(acc, country) => {
					const code = country.code.toLowerCase()
					return {
						...acc,
						[code]: country.flag || ''
					}
				},
				{} as Record<string, string>
			) || {},
		[config.data?.systemLanguages]
	)

	const flag = flags[language]

	return (
		<div className={cx(className, 'flag-icon items-center flex-shrink-0')}>
			{flag ? (
				<img src={flag} width={size} height={size} alt={''} className={'object-scale-down object-center'} />
			) : (
				<div className={'bg-notino-grayLight'} style={{ width: size, height: 0.667 * size }} />
			)}
		</div>
	)
}

export default FlagIcon
