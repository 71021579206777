import { z } from 'zod'

import { uuidConstraint } from './baseSchema'

// https://notino-admin.goodrequest.dev/api/doc/?urls.primaryName=v2.2.9#/B2b-%3Eadmin/patchApiB2BAdminEmployeesEmployeeIdRole
export const employeeRoleSchema = z.object({
	roleID: uuidConstraint
})

export const userRoleSchema = z.object({
	roleID: uuidConstraint
})
