import React, { useMemo } from 'react'
import { DatePicker, Empty, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'
import cx from 'classnames'
import { ColumnsType } from 'antd/es/table'

// types
import { ILoadingAndFailure, TimeStats } from '../../types/interfaces'

// utils
import { MONTH_NAME_YEAR_FORMAT, SMS_TIME_STATS_DATE_PICKER_ID } from '../../utils/enums'
import { SMS_SENT_STATS_COLOR, transformSmsDataToStatsData } from '../../pages/HomePage/dashboardUtils'

// redux
import { ISmsTimeStatsPayload } from '../../reducers/sms/smsActions'

// components
import CustomTable from '../CustomTable'
import { LocalizedPrice } from '../../utils/intl'

// 	dynamic imports
const LineChart = React.lazy(() => import('../LineChart'))

type Props = {
	selectedDate: Dayjs
	onPickerChange: (date: Dayjs | null) => void
	onPickerOpenChange?: (open: boolean) => void
	countryPicker?: React.ReactNode
	className?: string
	title?: React.ReactNode
	smsTimeStats: ISmsTimeStatsPayload & ILoadingAndFailure
	loading?: boolean
}

const SmsTimeStats = (props: Props) => {
	const { onPickerChange, onPickerOpenChange, selectedDate, className, title, smsTimeStats, countryPicker, loading = false } = props
	const [t] = useTranslation()

	const source: TimeStats = useMemo(() => {
		return transformSmsDataToStatsData(smsTimeStats.data, smsTimeStats.isLoading, smsTimeStats.isFailure, selectedDate)
	}, [selectedDate, smsTimeStats.data, smsTimeStats.isLoading, smsTimeStats.isFailure])

	const columns = (labels: string[] = [], futureBreak = 0): ColumnsType<any> => [
		{
			key: 'type',
			dataIndex: 'type',
			render: () => {
				return (
					<div className={'flex flex-1 items-center'}>
						<div className='h-2-5 w-2-5 rounded-full mr-1 stats-circle' style={{ backgroundColor: SMS_SENT_STATS_COLOR, flex: '0 0 auto' }} />
						<span className='xs-bold'>{t('loc:Odoslané SMS')}</span>
					</div>
				)
			}
		},
		...labels.map((label: string, index: number) => {
			return {
				key: index,
				dataIndex: index,
				className: cx({ 'future-divider': futureBreak - 0.5 === index }), // 0.5 is delta for display devider between columns
				title: <span className={cx('xs-semibold', { 'text-notino-gray': futureBreak <= index })}>{label}</span>,
				render: (value: number) => <span className={cx('xs-regular', { 'text-notino-gray': futureBreak <= index })}>{value}</span>
			}
		})
	]

	return (
		<div className={className}>
			<div className={'flex justify-between items-center mb-6'}>
				{title || <h2 className={'mb-0'}>{t('loc:Prehľad')}</h2>}
				<div className={'flex items-center gap-4'}>
					{countryPicker}
					<div id={SMS_TIME_STATS_DATE_PICKER_ID}>
						<DatePicker
							onChange={onPickerChange}
							disabled={smsTimeStats.isLoading || loading}
							picker={'month'}
							size={'middle'}
							defaultValue={dayjs()}
							allowClear={false}
							format={MONTH_NAME_YEAR_FORMAT}
							popupAlign={{ points: ['tr', 'br'] }}
							disabledDate={(date) => dayjs(date).year() < 2022}
							onOpenChange={onPickerOpenChange}
							getPopupContainer={() => document.querySelector('#root') || document.body}
						/>
					</div>
				</div>
			</div>
			<div className={'flex gap-4 mb-6'}>
				<div className={'p-4 rounded shadow-lg bg-notino-white w-1/3'}>
					<span className={'text-notino-grayDark text-sm block mb-2'}>{t('loc:Spotreba kreditu v období')}</span>
					<span className={'text-notino-black text-base font-bold'}>
						<LocalizedPrice
							price={smsTimeStats.data?.totalSpentTransactionAmount}
							currencyCode={smsTimeStats.data?.totalSpentTransactionAmount.currency}
							fallback={'-'}
						/>
					</span>
				</div>
				<div className={'p-4 rounded shadow-lg bg-notino-white w-1/3'}>
					<span className={'text-notino-grayDark text-sm block mb-2'}>{t('loc:Počet odoslaných SMS v období')}</span>
					<span className={'text-notino-black text-base font-bold'}>{smsTimeStats.data?.totalSentSmsCount ?? '0'}</span>
				</div>
				<div className={'p-4 rounded shadow-lg bg-notino-white w-1/3'}>
					<span className={'text-notino-grayDark text-sm block mb-2'}>{t('loc:Cena SMS v období')}</span>
					<span className={'text-notino-black text-base font-bold'}>
						<LocalizedPrice
							price={smsTimeStats.data?.currentSmsUnitPrice?.amount}
							currencyCode={smsTimeStats.data?.currentSmsUnitPrice?.amount.currency}
							fallback={'-'}
						/>
					</span>
				</div>
			</div>

			<div className='statistics-box py-4 px-6 md:py-8 md:px-12'>
				<Spin spinning={smsTimeStats.isLoading || loading}>
					<div className='flex flex-wrap justify-between w-full'>
						<h4>{t('loc:Vývoj odoslaných SMS')}</h4>
					</div>
					<div className='mt-4'>
						<div className='h-40'>
							{smsTimeStats.isFailure ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('loc:Chyba')} /> : <LineChart source={source} />}
						</div>
						<CustomTable
							className='mt-8'
							columns={columns(source.data?.labels, source?.data?.breakIndex)}
							twoToneRows
							pagination={false}
							dataSource={source.data?.columns}
							rowKey={(record) => record.type}
							scroll={{ x: 400 }}
						/>
					</div>
				</Spin>
			</div>
		</div>
	)
}

export default SmsTimeStats
