export enum EVENTS {
	EVENTS_LOAD_START = 'EVENTS_LOAD_START',
	EVENTS_LOAD_DONE = 'EVENTS_LOAD_DONE',
	EVENTS_LOAD_FAIL = 'EVENTS_LOAD_FAIL',
	EVENTS_CLEAR = 'EVENTS_CLEAR'
}

export enum EVENTS_MONTHLY_VIEW {
	EVENTS_MONTHLY_VIEW_LOAD_START = 'EVENTS_COUNTS_AND_DURATIONS_LOAD_START',
	EVENTS_MONTHLY_VIEW_LOAD_DONE = 'EVENTS_COUNTS_AND_DURATIONS_LOAD_DONE',
	EVENTS_MONTHLY_VIEW_LOAD_FAIL = 'EVENTS_COUNTS_AND_DURATIONS_LOAD_FAIL',
	EVENTS_MONTHLY_VIEW_CLEAR = 'EVENTS_MONTHLY_VIEW_CLEAR'
}

export enum EVENT_DETAIL {
	EVENT_DETAIL_LOAD_START = 'EVENT_DETAIL_LOAD_START',
	EVENT_DETAIL_LOAD_DONE = 'EVENT_DETAIL_LOAD_DONE',
	EVENT_DETAIL_LOAD_FAIL = 'EVENT_DETAIL_LOAD_FAIL'
}

export const SET_IS_REFRESHING_EVENTS = 'SET_IS_REFRESHING_EVENTS'

export const SET_DAY_EVENTS = 'SET_DAY_EVENTS'

export const SET_HIGHLIGHTED_CALENDAR_SHIFT_ID = 'SET_HIGHLIGHTED_CALENDAR_SHIFT_ID'
