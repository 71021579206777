import React, { useEffect, useRef, useState } from 'react'
import { reset } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Modal, ModalProps, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosResponse } from 'axios'
import { RcFile } from 'antd/es/upload'

// utils
import { FORM, ASYNC_JOB_STATE, ASYNC_JOB_PROGRESS_INTERVAL, ASYNC_JOB_TYPE } from '../../utils/enums'
import { isEnumValue } from '../../utils/intl'
import { getReq, postReq } from '../../utils/request'

// assets
import CloseIcon from '../../assets/icons/close-icon-modal.svg?react'
import FilesIcon from '../../assets/icons/files-icon.svg?react'

// components
import SuccessView from './components/SuccessView'
import SalonsReportForm, { SalonsReportRequestQueryParams } from './components/SalonsReport'
import ReservationsReportsAdmin, { ReservationsReportRequestAdminQueryParams } from './components/ReservationsReportAdmin'
import ReservationsReportsSalon, { ReservationsReportRequestSalonQueryParams } from './components/ReservationsReportSalon'
import CustomServicesReport, { CustomServicesReportRequestQueryParams } from './components/CustomServicesReport'
import ImportCustomersForm from './components/ImportCustomersForm'
import ImportReservationsForm from './components/ImportReservationsForm'
import ImportSalonsForm from './components/ImportSalonsForm'

// types
import {
	AsyncJob,
	GetUrls,
	IImportCustomersForm,
	IImportReservationsForm,
	IImportSalonsForm,
	ISelectOptionItem,
	ISelectOptionItemWithChildren,
	PostUrls,
	RequestResponse,
	SelectOptionItemWithGaName
} from '../../types/interfaces'
import { RootState } from '../../reducers'
import {
	ICustomServicesPageURLQueryParams,
	INotinoReservationsPageURLQueryParams,
	ISalonReservationsPageURLQueryParams,
	ISalonsActivePageURLQueryParams
} from '../../types/schemaTypes'

// redux
import { clearAsyncJob, getAsyncJobState } from '../../reducers/asyncJobs/asyncJobsActions'

type CommonProps = {
	visible: boolean
	setVisible: (visible: boolean) => void
	disabled?: boolean
}

type SalonsReportProps = {
	jobType: ASYNC_JOB_TYPE.EXPORT_SALONS_REPORT
	query: ISalonsActivePageURLQueryParams
	publishedSalonOptions: ISelectOptionItem[]
	salonChangesOptions: ISelectOptionItem[]
	salonCreateTypesOptions: ISelectOptionItem[]
	countriesOptions: ISelectOptionItem[]
	industriesOptions: ISelectOptionItem[]
	servicesOptions: ISelectOptionItemWithChildren[]
	sourceOptions: ISelectOptionItem[]
	premiumSourceOptions: ISelectOptionItem[]
	rsOptions: ISelectOptionItem[]
	rsAvailableOnlineOptions: ISelectOptionItem[]
	upcomingReservationsOptions: ISelectOptionItem[]
	openingHoursOptions: ISelectOptionItem[]
}

type PartnerReservationsReportProps = {
	jobType: ASYNC_JOB_TYPE.EXPORT_SALON_RESERVATIONS
	salonID: string
	query: ISalonReservationsPageURLQueryParams
	sourceTypeOptions: SelectOptionItemWithGaName[]
	paymentMethodOptions: SelectOptionItemWithGaName[]
	reservationStatesOptions: SelectOptionItemWithGaName[]
	employeesOptions: ISelectOptionItem[]
	categoriesOptions: ISelectOptionItem[]
}

type AdminReservationsReportProps = {
	jobType: ASYNC_JOB_TYPE.EXPORT_RESERVATIONS
	query: INotinoReservationsPageURLQueryParams
	sourceTypeOptions: ISelectOptionItem[]
	paymentMethodOptions: ISelectOptionItem[]
	reservationStatesOptions: ISelectOptionItem[]
	industriesOptions: ISelectOptionItem[]
	countriesOptions: ISelectOptionItem[]
}

type CustomServicesReportProps = {
	jobType: ASYNC_JOB_TYPE.EXPORT_CUSTOM_SERVICES
	query: ICustomServicesPageURLQueryParams
	secondLevelCategoriesOptions: ISelectOptionItemWithChildren[]
	checkedOptions: ISelectOptionItem[]
	countriesOptions: ISelectOptionItem[]
}

type ImportSalonsProps = {
	jobType: ASYNC_JOB_TYPE.IMPORT_SALONS
}

type ImportReservationsProps = {
	jobType: ASYNC_JOB_TYPE.IMPORT_CALENDAR_EVENTS
	salonID: string
}

type ImportCustomersProps = {
	jobType: ASYNC_JOB_TYPE.IMPORT_CUSTOMERS
	salonID: string
	onUploaderClick?: () => void
	onDownloadTemplateClick?: () => void
	onSubmit?: () => void
}

type Props = CommonProps &
	(
		| SalonsReportProps
		| AdminReservationsReportProps
		| PartnerReservationsReportProps
		| CustomServicesReportProps
		| ImportSalonsProps
		| ImportReservationsProps
		| ImportCustomersProps
	)

type SalonsExportRequest = RequestResponse<GetUrls['/api/b2b/admin/reports/salons']>
type AdminReservationsExportRequest = RequestResponse<GetUrls['/api/b2b/admin/calendar-events/reservations/export']>
type SalonReservationsExportRequest = RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/calendar-events/reservations/export']>
type CustomServicesExportRequest = RequestResponse<GetUrls['/api/b2b/admin/services/custom/export']>
type ImportCustomersRequest = RequestResponse<PostUrls['/api/b2b/admin/imports/salons/{salonID}/customers']>
type ImportReservationsRequest = RequestResponse<PostUrls['/api/b2b/admin/imports/salons/{salonID}/calendar-events']>
type ImportSalonsRequest = RequestResponse<PostUrls['/api/b2b/admin/imports/salons']>

type RelevantResponse =
	| SalonsExportRequest
	| AdminReservationsExportRequest
	| SalonReservationsExportRequest
	| CustomServicesExportRequest
	| ImportCustomersRequest
	| ImportReservationsRequest
	| ImportSalonsRequest

type RelevantForm = FORM.IMPORT_CUSTOMERS | FORM.IMPORT_RESERVATIONS | FORM.IMPORT_SALONS

const MODAL_WIDTH = {
	IMPORT: 394,
	REPORT: 500
}

enum MODAL_VIEW {
	DEFAULT = 'DEFAULT',
	SUCCESS = 'SUCCESS'
}

const AsyncJobsModal = (props: Props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const { jobType, visible, setVisible } = props

	const asyncJobs = useSelector((state: RootState) => state.asyncJobs.asyncJobs)
	const relevantAsyncJobs = asyncJobs.data.filter((job) => job.jobType === jobType)
	const config = useSelector((state: RootState) => state.config.config)

	const initModalView = relevantAsyncJobs.length ? MODAL_VIEW.SUCCESS : MODAL_VIEW.DEFAULT

	const [modalView, setModalView] = useState<MODAL_VIEW>(initModalView)
	const [isSubmitting, setIsSubmitting] = useState(false)

	const intervalRef = useRef<NodeJS.Timeout | null>(null)
	const isInit = useRef(true)
	const initModalViewRef = useRef<MODAL_VIEW>(initModalView)

	useEffect(() => {
		if (isInit.current) {
			/**
			 * We must set the modal view not only the first time it is opened, but also each time the user opens it again
			 */
			setModalView(initModalView)
			initModalViewRef.current = initModalView
			isInit.current = false
		}

		const clearExistingInterval = () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current)
				intervalRef.current = null
			}
		}

		const fetchJobStates = () => {
			const promises: Promise<AsyncJob>[] = []

			relevantAsyncJobs.forEach((job) => {
				if (job.jobState === ASYNC_JOB_STATE.IN_PROGRESS || job.jobState === ASYNC_JOB_STATE.COULD_NOT_VERIFY) {
					promises.push(dispatch(getAsyncJobState(job.jobID, job.jobType)))
				}
			})

			Promise.all(promises)
		}

		if (visible) {
			if (modalView === MODAL_VIEW.SUCCESS && !intervalRef.current) {
				intervalRef.current = setInterval(fetchJobStates, ASYNC_JOB_PROGRESS_INTERVAL)
			} else {
				clearExistingInterval()
			}
		} else {
			clearExistingInterval()
			isInit.current = true
			initModalViewRef.current = initModalView
		}

		return () => clearExistingInterval()
	}, [relevantAsyncJobs, initModalView, dispatch, modalView, visible])

	const getSubmitRequest = async (request: Promise<AxiosResponse<RelevantResponse>>) => {
		setIsSubmitting(true)
		try {
			const { data } = await request

			if (isEnumValue(data.job.queue, ASYNC_JOB_TYPE)) {
				await dispatch(getAsyncJobState(data.job.id, data.job.queue))
			}
			setModalView(MODAL_VIEW.SUCCESS)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		} finally {
			setIsSubmitting(false)
		}
	}

	const clearFinishedJobs = () => {
		;[...relevantAsyncJobs].forEach((job) => {
			if (job.jobState === ASYNC_JOB_STATE.COMPLETED || job.jobState === ASYNC_JOB_STATE.FAILED || job.jobState === ASYNC_JOB_STATE.COULD_NOT_VERIFY) {
				dispatch(clearAsyncJob(job.jobID, job.jobType))
			}
		}, [])
	}

	const handleResetView = (form?: RelevantForm) => {
		setModalView(MODAL_VIEW.DEFAULT)
		initModalViewRef.current = MODAL_VIEW.DEFAULT
		if (form) {
			dispatch(reset(form))
		}
		clearFinishedJobs()
	}

	const handleCloseModal = () => {
		setVisible(false)
	}

	const getAsyncJobData = (): {
		defaultViewContent: React.ReactNode
		successViewContent: React.ReactNode
		title: string
		modalProps: ModalProps
	} => {
		const showGenerateMoreButton = initModalViewRef.current === MODAL_VIEW.SUCCESS && relevantAsyncJobs.length

		const reportsSuccessViewCommonProps = {
			onRequestAgain: showGenerateMoreButton ? handleResetView : handleCloseModal,
			buttonText: showGenerateMoreButton ? t('loc:Generovať ďalší') : t('loc:Zavrieť dialóg'),
			buttonIcon: showGenerateMoreButton ? <FilesIcon /> : <CloseIcon />,
			asyncJobs: relevantAsyncJobs
		}

		switch (jobType) {
			case ASYNC_JOB_TYPE.EXPORT_SALONS_REPORT: {
				const {
					query,
					publishedSalonOptions,
					salonChangesOptions,
					salonCreateTypesOptions,
					countriesOptions,
					industriesOptions,
					servicesOptions,
					sourceOptions,
					premiumSourceOptions,
					rsOptions,
					rsAvailableOnlineOptions,
					upcomingReservationsOptions,
					openingHoursOptions
				} = props
				const handleSubmit = (requestQuery: SalonsReportRequestQueryParams) =>
					getSubmitRequest(
						getReq('/api/b2b/admin/reports/salons', {
							params: { query: requestQuery },
							reqBody: {}
						})
					)

				return {
					title: t('loc:Generovať report salónov'),
					defaultViewContent: (
						<SalonsReportForm
							visible={visible}
							handleSubmit={handleSubmit}
							submitting={isSubmitting}
							query={query}
							publishedSalonOptions={publishedSalonOptions}
							salonChangesOptions={salonChangesOptions}
							salonCreateTypesOptions={salonCreateTypesOptions}
							countriesOptions={countriesOptions}
							industriesOptions={industriesOptions}
							servicesOptions={servicesOptions}
							sourceOptions={sourceOptions}
							premiumSourceOptions={premiumSourceOptions}
							rsOptions={rsOptions}
							rsAvailableOnlineOptions={rsAvailableOnlineOptions}
							upcomingReservationsOptions={upcomingReservationsOptions}
							openingHoursOptions={openingHoursOptions}
						/>
					),
					successViewContent: (
						<SuccessView
							{...reportsSuccessViewCommonProps}
							jobType={ASYNC_JOB_TYPE.EXPORT_SALONS_REPORT}
							description={t('loc:Po úspešnom spracovaní vám na vašu e-mailovú adresu zašleme report salónov vo formáte .xlxs')}
						/>
					),
					modalProps: {
						width: MODAL_WIDTH.REPORT
					}
				}
			}
			case ASYNC_JOB_TYPE.EXPORT_RESERVATIONS: {
				const { query, industriesOptions, paymentMethodOptions, reservationStatesOptions, sourceTypeOptions, countriesOptions } = props

				const handleSubmit = (requestQuery: ReservationsReportRequestAdminQueryParams) => {
					getSubmitRequest(
						getReq('/api/b2b/admin/calendar-events/reservations/export', {
							params: {
								query: requestQuery
							},
							reqBody: {}
						})
					)
				}

				return {
					title: t('loc:Generovať report rezervácií'),
					defaultViewContent: (
						<ReservationsReportsAdmin
							handleSubmit={handleSubmit}
							submitting={isSubmitting}
							query={query}
							industriesOptions={industriesOptions}
							paymentMethodOptions={paymentMethodOptions}
							reservationStatesOptions={reservationStatesOptions}
							sourceTypeOptions={sourceTypeOptions}
							countriesOptions={countriesOptions}
						/>
					),
					successViewContent: (
						<SuccessView
							{...reportsSuccessViewCommonProps}
							jobType={ASYNC_JOB_TYPE.EXPORT_RESERVATIONS}
							description={t('loc:Po úspešnom spracovaní vám na vašu e-mailovú adresu zašleme report rezervácií vo formáte .xlxs')}
						/>
					),
					modalProps: {
						width: MODAL_WIDTH.REPORT
					}
				}
			}
			case ASYNC_JOB_TYPE.EXPORT_SALON_RESERVATIONS: {
				const { categoriesOptions, paymentMethodOptions, reservationStatesOptions, sourceTypeOptions, employeesOptions, salonID, query } = props

				const handleSubmit = (requestQuery: ReservationsReportRequestSalonQueryParams) => {
					getSubmitRequest(
						getReq('/api/b2b/admin/salons/{salonID}/calendar-events/reservations/export', {
							params: {
								path: {
									salonID
								},
								query: requestQuery
							},
							reqBody: {}
						})
					)
				}

				return {
					title: t('loc:Generovať report rezervácií'),
					defaultViewContent: (
						<ReservationsReportsSalon
							visible={visible}
							salonID={salonID}
							handleSubmit={handleSubmit}
							submitting={isSubmitting}
							query={query}
							categoriesOptions={categoriesOptions}
							paymentMethodOptions={paymentMethodOptions}
							reservationStatesOptions={reservationStatesOptions}
							employeesOptions={employeesOptions}
							sourceTypeOptions={sourceTypeOptions}
						/>
					),
					successViewContent: (
						<SuccessView
							{...reportsSuccessViewCommonProps}
							jobType={ASYNC_JOB_TYPE.EXPORT_SALON_RESERVATIONS}
							description={t('loc:Po úspešnom spracovaní vám na vašu e-mailovú adresu zašleme report rezervácií vo formáte .xlxs')}
						/>
					),
					modalProps: {
						width: MODAL_WIDTH.REPORT
					}
				}
			}
			case ASYNC_JOB_TYPE.EXPORT_CUSTOM_SERVICES: {
				const { query, checkedOptions, countriesOptions, secondLevelCategoriesOptions } = props
				const handleSubmit = (requestQuery: CustomServicesReportRequestQueryParams) =>
					getSubmitRequest(
						getReq('/api/b2b/admin/services/custom/export', {
							params: { query: requestQuery },
							reqBody: {}
						})
					)

				return {
					title: t('loc:Generovať report vlastných služieb'),
					defaultViewContent: (
						<CustomServicesReport
							handleSubmit={handleSubmit}
							submitting={isSubmitting}
							query={query}
							countriesOptions={countriesOptions}
							secondLevelCategoriesOptions={secondLevelCategoriesOptions}
							checkedOptions={checkedOptions}
						/>
					),
					successViewContent: (
						<SuccessView
							{...reportsSuccessViewCommonProps}
							jobType={ASYNC_JOB_TYPE.EXPORT_CUSTOM_SERVICES}
							description={t('loc:Po úspešnom spracovaní vám na vašu e-mailovú adresu zašleme report vlastných služieb vo formáte .xlxs')}
						/>
					),
					modalProps: {
						width: MODAL_WIDTH.REPORT
					}
				}
			}
			case ASYNC_JOB_TYPE.IMPORT_SALONS: {
				const handleSubmit = (values: IImportSalonsForm) => {
					const formData = new FormData()

					if (values.file[0]) {
						formData.append('file', values.file[0] as RcFile)
					}
					getSubmitRequest(
						postReq('/api/b2b/admin/imports/salons', {
							params: {},
							reqBody: formData as any,
							customConfig: {
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							}
						})
					)
				}

				return {
					title: t('loc:Importovať salóny'),
					defaultViewContent: <ImportSalonsForm onSubmit={handleSubmit} />,
					successViewContent: (
						<SuccessView jobType={ASYNC_JOB_TYPE.IMPORT_SALONS} onRequestAgain={() => handleResetView(FORM.IMPORT_SALONS)} asyncJobs={relevantAsyncJobs} />
					),
					modalProps: {
						width: MODAL_WIDTH.IMPORT
					}
				}
			}
			case ASYNC_JOB_TYPE.IMPORT_CUSTOMERS: {
				const { salonID, onDownloadTemplateClick, onUploaderClick, onSubmit } = props

				const handleSubmit = (values: IImportCustomersForm) => {
					if (onSubmit) {
						onSubmit()
					}

					const formData = new FormData()

					if (values.file[0]) {
						formData.append('file', values.file[0] as RcFile)
					}

					getSubmitRequest(
						postReq('/api/b2b/admin/imports/salons/{salonID}/customers', {
							params: { path: { salonID } },
							reqBody: formData as any,
							customConfig: {
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							}
						})
					)
				}

				return {
					title: t('loc:Importovať zákazníkov'),
					defaultViewContent: (
						<ImportCustomersForm onSubmit={handleSubmit} config={config} onDownloadTemplateClick={onDownloadTemplateClick} onUploaderClick={onUploaderClick} />
					),
					successViewContent: (
						<SuccessView jobType={ASYNC_JOB_TYPE.IMPORT_CUSTOMERS} onRequestAgain={() => handleResetView(FORM.IMPORT_CUSTOMERS)} asyncJobs={relevantAsyncJobs} />
					),
					modalProps: {
						width: MODAL_WIDTH.IMPORT
					}
				}
			}
			case ASYNC_JOB_TYPE.IMPORT_CALENDAR_EVENTS: {
				const { salonID } = props

				const handleSubmit = (values: IImportReservationsForm) => {
					const formData = new FormData()

					if (values.file[0]) {
						formData.append('file', values.file[0] as RcFile)
					}

					getSubmitRequest(
						postReq('/api/b2b/admin/imports/salons/{salonID}/calendar-events', {
							params: { path: { salonID } },
							reqBody: formData as any,
							customConfig: {
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							}
						})
					)
				}

				return {
					title: t('loc:Importovať rezervácie'),
					defaultViewContent: <ImportReservationsForm onSubmit={handleSubmit} config={config} />,
					successViewContent: (
						<SuccessView
							jobType={ASYNC_JOB_TYPE.IMPORT_CALENDAR_EVENTS}
							onRequestAgain={() => handleResetView(FORM.IMPORT_RESERVATIONS)}
							asyncJobs={relevantAsyncJobs}
						/>
					),
					modalProps: {
						width: MODAL_WIDTH.IMPORT
					}
				}
			}
			default:
				throw new Error('Unsupported async job type')
		}
	}

	const { defaultViewContent, successViewContent, title, modalProps } = getAsyncJobData()

	return (
		<Modal
			title={title}
			centered
			open={visible}
			destroyOnClose
			footer={null}
			onCancel={handleCloseModal}
			afterClose={clearFinishedJobs}
			closeIcon={<CloseIcon />}
			maskClosable={false}
			keyboard={false}
			style={{ margin: 16 }}
			{...modalProps}
		>
			<Spin spinning={isSubmitting || config.isLoading}>{modalView === MODAL_VIEW.SUCCESS ? successViewContent : defaultViewContent}</Spin>
		</Modal>
	)
}

export default AsyncJobsModal
