import React, { useCallback, useRef, useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import cx from 'classnames'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { trimStart, trim } from 'lodash'
import { FIELD_MODE } from '../utils/enums'
import { formFieldID } from '../utils/helper'
import SearchIcon from '../assets/icons/search-icon.svg?react'
import RemoveIcon from '../assets/icons/remove-select-icon.svg?react'

const { Item } = Form

type Props = WrappedFieldProps &
	InputProps &
	FormItemLabelProps & {
		hideHelp?: boolean
		rounded?: boolean
		fieldMode?: FIELD_MODE
		focused?: boolean
		toCheck?: boolean
	}

const InputField = (props: Props) => {
	const {
		input,
		size,
		placeholder,
		label,
		required,
		type,
		prefix,
		disabled,
		style,
		meta: { form, error, touched },
		hideHelp,
		maxLength,
		fieldMode = FIELD_MODE.INPUT,
		readOnly,
		className,
		allowClear,
		suffix,
		addonBefore,
		focused,
		toCheck
	} = props

	const inputRef = useRef<any>(null)

	// NOTE: internal state to temporary fix cursor jump: https://goodrequest.atlassian.net/browse/NOT-7327
	const [internalVal, setInternalVal] = useState('')

	useEffect(() => {
		setInternalVal(input.value)
	}, [input.value])

	useEffect(() => {
		if (inputRef.current && focused) {
			inputRef.current.focus()
		}
	}, [focused])

	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? trimStart(e.target.value) : null

			input.onChange(val)
			setInternalVal(val || '')
		},
		[input]
	)

	const onBlur = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			// NOTE: prevent to have "" empty string as empty value
			const val = e.target.value ? trim(e.target.value) : undefined

			// NOTE: wait until redux-form "BLUR" action is finished
			await input.onBlur(val)
			setInternalVal(val || '')
		},
		[input]
	)

	const onFocus = useCallback(
		async (e: React.FocusEvent<HTMLInputElement>) => {
			if (input.onFocus) {
				// NOTE: prevent to have "" empty string as empty value
				const val: any = e.target.value ? e.target.value : undefined
				input.onFocus(val)
			}
		},
		[input]
	)

	const suffixWrap = () => {
		if ((allowClear || fieldMode === FIELD_MODE.FILTER) && input.value) {
			return typeof allowClear === 'object' && allowClear.clearIcon ? (
				allowClear.clearIcon
			) : (
				<RemoveIcon
					onClick={() => {
						input.onChange('')
						setInternalVal('')
					}}
					className={'text-notino-black cursor-pointer'}
				/>
			)
		}

		return suffix
	}

	return (
		<Item
			label={label}
			required={required}
			style={style}
			className={cx(className, { 'form-item-disabled': disabled, readOnly })}
			help={hideHelp ? undefined : touched && error}
			validateStatus={error && touched ? 'error' : undefined}
		>
			<div className={cx('input-inner-wrapper', { 'to-check-changes': toCheck })}>
				<Input
					{...input}
					id={formFieldID(form, input.name)}
					className={cx('noti-input', { 'noti-input-filter': fieldMode === FIELD_MODE.FILTER })}
					onChange={onChange}
					onBlur={onBlur}
					addonBefore={addonBefore}
					size={size || 'middle'}
					onFocus={onFocus}
					value={internalVal}
					placeholder={placeholder}
					type={type || 'text'}
					// Ak je filter cez RemoveIcon zmazat string (ant ma pre input aj allowClear ale neda sa mu zmenit ikona tak ako napr v selecte preto to je takto robene)
					suffix={suffixWrap()}
					prefix={fieldMode === FIELD_MODE.FILTER ? <SearchIcon className={'medium-icon'} /> : prefix}
					disabled={disabled}
					maxLength={maxLength}
					ref={inputRef}
				/>
			</div>
		</Item>
	)
}

export default React.memo(InputField)
