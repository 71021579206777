import React from 'react'
import { useTranslation } from 'react-i18next'

// components
import SwitchField from '../../../atoms/SwitchField'
import TabsComponent from '../../TabsComponent'

// utils
import { NOTIFICATION_EVENT_GROUP_TYPE } from '../../../utils/enums'

import ActionsDropdown from './ActionsDropdown'
import { ActionsDropdownProps, formatNotificationsCount } from '../notificationsUtils'

type Props = {
	onlyUnread: boolean
	onChangeUnreadNotifications: (unread: boolean) => void
	notificationEventGroupType: NOTIFICATION_EVENT_GROUP_TYPE
	onChangeNotificationGroupType: (tabKey: string) => void
	isLoading: boolean
	emptyNotifications: boolean
	unreadTotalNotificationsCount: number
	unreadReservationsNotificationsCount: number
	unreadOtherNotificationsCount: number
	okActionProps: ActionsDropdownProps['okProps']
	deleteActionProps: ActionsDropdownProps['deleteProps']
	onOpenChange?: ActionsDropdownProps['onOpenChange']
}

const NotificationsHeader = (props: Props) => {
	const { t } = useTranslation()
	const {
		onlyUnread,
		onChangeUnreadNotifications,
		notificationEventGroupType,
		onChangeNotificationGroupType,
		isLoading,
		emptyNotifications,
		unreadTotalNotificationsCount,
		unreadReservationsNotificationsCount,
		unreadOtherNotificationsCount,
		okActionProps,
		deleteActionProps,
		onOpenChange
	} = props
	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<div onClick={(e) => e.stopPropagation()}>
			<div className={'flex items-center py-3 px-4 border-0 border-b border-solid border-notino-grayLight'}>
				<span className={'text-notino-black text-base font-semibold mr-auto'}>
					{t('loc:Notifikácie')} {unreadTotalNotificationsCount ? `(${formatNotificationsCount(unreadTotalNotificationsCount)})` : null}
				</span>

				<SwitchField
					label={t('loc:Zobraziť iba neprečítané')}
					size={'small'}
					className={'p-0 mr-2 noti-show-unread-notif-switch'}
					input={
						{
							value: onlyUnread,
							onChange: onChangeUnreadNotifications
						} as any
					}
					meta={{} as any}
					disabled={isLoading}
				/>

				<ActionsDropdown okProps={okActionProps} deleteProps={deleteActionProps} disabled={emptyNotifications || isLoading} onOpenChange={onOpenChange} />
			</div>
			<TabsComponent
				className={'user-notifications-tabs'}
				onChange={onChangeNotificationGroupType}
				activeKey={notificationEventGroupType}
				animated={false}
				items={[
					{
						key: NOTIFICATION_EVENT_GROUP_TYPE.RESERVATION,
						tabKey: NOTIFICATION_EVENT_GROUP_TYPE.RESERVATION,
						label: t('loc:Rezervácie ({{ unreadCount }})', { unreadCount: formatNotificationsCount(unreadReservationsNotificationsCount) }),
						disabled: isLoading
					},
					{
						key: NOTIFICATION_EVENT_GROUP_TYPE.OTHER,
						tabKey: NOTIFICATION_EVENT_GROUP_TYPE.OTHER,
						label: t('loc:Ostatné ({{ unreadCount }})', { unreadCount: formatNotificationsCount(unreadOtherNotificationsCount) }),
						disabled: isLoading
					}
				]}
			/>
		</div>
	)
}

export default React.memo(NotificationsHeader)
