import React, { CSSProperties, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { autofill, change, WrappedFieldProps } from 'redux-form'
import { isEmpty } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Checkbox, Form, Image, Popconfirm, Tooltip as AntdTooltip, Upload, UploadProps, TooltipProps as AntdTooltipProps } from 'antd'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import { UploadChangeParam } from 'antd/lib/upload'
import { FormItemProps } from 'antd/lib/form/FormItem'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import cx from 'classnames'
import { DndContext, DragEndEvent, MouseSensor, closestCenter, useDroppable, useSensor, useSensors } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { SortableContext, rectSortingStrategy, useSortable, arrayMove } from '@dnd-kit/sortable'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'

// utils
import { uploadFiles } from '../utils/request'
import { formFieldID, formatImgFormValues, getMaxSizeNotifMessage, showNotifications, splitArrayByCondition } from '../utils/helper'
import { UPLOAD_IN_PROGRESS_PROP, MSG_TYPE, STRINGS, UPLOAD_IMG_CATEGORIES } from '../utils/enums'

// types
import { CustomRequestOptions, ImgUploadFieldValueType, FileUploadParam, SignUrlType, UploadFieldValueType } from '../types/interfaces'

// assets
import UploadIcon from '../assets/icons/upload-icon.svg?react'
import EyeIcon from '../assets/icons/eye-icon.svg?react'
import RemoveIcon from '../assets/icons/remove-select-icon.svg?react'
import DownloadIcon from '../assets/icons/download-icon.svg?react'
import PdfIcon from '../assets/icons/pdf-icon.svg?react'
import CrownIcon from '../assets/icons/crown-icon.svg?react'
import LockIcon from '../assets/icons/lock-icon.svg?react'
import InfoIcon from '../assets/icons/info-icon.svg?react'

const { Item } = Form

type Props = WrappedFieldProps &
	FormItemProps &
	UploadProps & {
		category: UPLOAD_IMG_CATEGORIES
		pathToFolder: string
		// /** Max file size in Bytes */
		maxFileSize: number
		// endpoint which returns signed url for image upload
		signUrl: SignUrlType
		className?: CSSProperties
		uploaderClassName?: string
		draggable?: boolean
		hasCoverPhoto?: boolean
		isCoverPhotoDisabled?: boolean
		hasRawPermissions?: boolean
		toCheck?: {
			changed: boolean
			addedFilesIDs: string[]
		}
		renderDescription?: (file: UploadFile, disabled?: boolean) => React.ReactNode
		labelDescription?: React.ReactNode
		onUploaderClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
		onPreviewImage?: (file: ImgUploadFieldValueType) => void
	}

const isFilePDF = (fileUrl: string | undefined | null): string | undefined => {
	if (!fileUrl) {
		return undefined
	}

	return fileUrl.endsWith('.pdf') ? 'application/pdf' : undefined
}

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = (error) => reject(error)
	})

type TooltipProps = Omit<AntdTooltipProps, 'title'> & {
	title: React.ReactNode
	description: React.ReactNode
} & PropsWithChildren

const Tooltip = (props: TooltipProps) => {
	const { title, description, children, ...restProps } = props

	return (
		<AntdTooltip
			color={'#fff'}
			overlayInnerStyle={{ padding: 0, width: 320, borderRadius: 4 }}
			title={
				<div className={'relative p-3'}>
					<div className={'text-sm leading-4 font-semibold text-black mb-1 mr-4'}>{title}</div>
					<div className={'text-sm leading-4 text-notino-grayDark font-normal'}>{description}</div>
				</div>
			}
			{...restProps}
		>
			{children}
		</AntdTooltip>
	)
}

type GalleryImageProps = {
	file: UploadFile
	actions: { download: () => void; preview: () => void; remove: () => void }
	hasCoverPhoto: boolean
	isCoverPhoto?: boolean
	isCoverPhotoLocked?: boolean
	isCoverPhotoDisabled?: boolean
	toCheck?: {
		changed: boolean
		addedFilesIDs: string[]
	}
	disabled?: boolean
	draggable?: boolean
	renderDescription?: (file: UploadFile, disabled?: boolean) => React.ReactNode
}

const GalleryImage = (props: GalleryImageProps) => {
	const [t] = useTranslation()

	const {
		file,
		actions: { preview, remove },
		toCheck,
		hasCoverPhoto,
		isCoverPhoto,
		isCoverPhotoLocked,
		isCoverPhotoDisabled,
		disabled,
		draggable,
		renderDescription
	} = props

	const toCheckChanges = Array.isArray(toCheck?.addedFilesIDs) ? !!toCheck?.addedFilesIDs.find((changedFileId) => changedFileId === file.uid) : false

	const disabledCoverPhoto = hasCoverPhoto && isCoverPhoto && isCoverPhotoLocked && isCoverPhotoDisabled

	const disableDragAndDrop = disabled || disabledCoverPhoto

	const { attributes, listeners, setNodeRef, transform, isDragging, setActivatorNodeRef } = useSortable({
		id: file.uid,
		disabled: disableDragAndDrop
	})

	const { setNodeRef: setDroppableRef } = useDroppable({
		id: file.uid,
		disabled: disableDragAndDrop
	})

	const style: React.CSSProperties = {
		transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
		...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
	}

	const image = (
		<>
			<div
				className={cx('ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card p-0', {
					'to-check-changes': toCheckChanges,
					'ant-upload-list-item-hover': isDragging,
					'ant-upload-list-item-locked': disabledCoverPhoto,
					'ant-upload-list-item-is-cover': isCoverPhoto
				})}
				ref={(el) => {
					if (draggable) {
						setActivatorNodeRef(el)
						setDroppableRef(el)
					}
				}}
				{...(draggable ? listeners : {})}
				{...(draggable ? attributes : {})}
			>
				<div className={'ant-upload-list-item-info flex items-center justify-center overflow-hidden bg-notino-white'}>
					{file.type === 'application/pdf' || !!isFilePDF(file.url) ? (
						<div className={'flex items-center justify-center h-full max-w-full'}>
							<PdfIcon className={'flex-shrink-0'} />
							<span className={'line-clamp-4'}>{file.name}</span>
						</div>
					) : (
						<Image src={file.thumbUrl || file.url} alt={file.name} fallback={file.url} className='ant-upload-list-item-image' />
					)}
				</div>
				{disabledCoverPhoto && (
					<div
						className={
							'ant-upload-list-item-locked-inner-wrapper w-full h-full flex flex-col gap-1 items-center justify-center text-white text-sm font-medium absolute top-0 left-0 z-20'
						}
					>
						<LockIcon className={'w-8 h-8 flex-shrink-0'} />
						<span>{t('loc:Zamknuté')}</span>
					</div>
				)}
				<div className={'ant-upload-list-item-actions w-full h-full z-20'}>
					<div className={'w-full flex items-center h-full'}>
						{!disabledCoverPhoto && (
							<Popconfirm
								placement={'top'}
								title={STRINGS(t).areYouSureDelete(t('loc:súbor'))}
								okButtonProps={{
									type: 'default',
									className: 'noti-btn'
								}}
								cancelButtonProps={{
									type: 'primary',
									className: 'noti-btn'
								}}
								okText={t('loc:Zmazať')}
								onConfirm={remove}
								cancelText={t('loc:Zrušiť')}
								disabled={disabled}
							>
								<button
									title='Remove file'
									type='button'
									className='noti-remove-img-button ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn flex items-center justify-center fixed top-1 right-1 z-50 p-0 border-none bg-transparent'
								>
									<span role='img' aria-label='delete' tabIndex={-1} className={cx('anticon anticon-delete w-full h-full', { 'cursor-not-allowed': disabled })}>
										<RemoveIcon className='remove-icon-image' width={18} />
									</span>
								</button>
							</Popconfirm>
						)}
						<Button
							type={'link'}
							htmlType={'button'}
							className={cx('flex items-center justify-center m-0 p-0 w-full h-full', {
								'cursor-move': draggable && !(disabled || disabledCoverPhoto),
								'cursor-pointer': disabled || disabledCoverPhoto
							})}
							onClick={preview}
							target='_blank'
							rel='noopener noreferrer'
							title='Preview file'
							disabled={disabled}
						>
							<span role='img' aria-label='eye' className='anticon anticon-eye w-6 text-white cursor-pointer'>
								<EyeIcon width={24} />
							</span>
						</Button>
					</div>
				</div>
				{isCoverPhoto && (
					<div className={'w-6 h-6 bg-notino-white rounded-full flex items-center justify-center text-black absolute left-1 top-1 z-10'}>
						<CrownIcon className={'w-4 h-4'} />
					</div>
				)}
			</div>
		</>
	)

	const imageWithTooltip = disabledCoverPhoto ? (
		<Tooltip
			title={t('loc:Zamknutá fotka')}
			description={t('loc:Táto fotografia bola nastavená ako titulná a uzamknutá Notino administrátorom. To znamená, že s ním nemôžete manipulovať.')}
		>
			{image}
		</Tooltip>
	) : (
		image
	)

	const imageWithDescription = (
		<>
			{imageWithTooltip}
			{renderDescription && renderDescription(file, disabled)}
		</>
	)

	return draggable ? (
		<div ref={setNodeRef} className={'upload-draggable-list-item w-full h-full'} style={style}>
			{imageWithDescription}
		</div>
	) : (
		imageWithDescription
	)
}

/**
 * Umoznuje nahrat obrazky na podpisanu url
 */
const ImgUploadField = (props: Props) => {
	const {
		label,
		input,
		required,
		meta: { form, error, touched },
		accept = 'image/jpeg,image/png',
		maxFileSize,
		disabled,
		signUrl,
		multiple,
		maxCount,
		category,
		className = '',
		uploaderClassName = '',
		draggable = false,
		hasCoverPhoto = false,
		isCoverPhotoDisabled = false,
		tooltip,
		hasRawPermissions = false,
		toCheck,
		renderDescription,
		labelDescription,
		onUploaderClick,
		onPreviewImage
	} = props

	const value: ImgUploadFieldValueType[] = useMemo(() => input.value || [], [input.value])

	const [t] = useTranslation()
	const dispatch = useDispatch()

	const imagesUrls = useRef<FileUploadParam>({})
	const [previewUrl, setPreviewUrl] = useState<UploadFieldValueType | null>(null)
	const [images, setImages] = useState<UploadFieldValueType[]>([])
	const [previewImgIndex, setPreviewImgIndex] = useState<number>(0)

	const coverPhoto = value.find((file) => file.isCover)
	const isCoverPhotoLocked = !!coverPhoto?.isLocked

	useEffect(() => {
		if (!isEmpty(value)) {
			setImages(
				value.filter((file) => {
					if ('type' in file) {
						return file.type !== 'application/pdf'
					}
					return !isFilePDF(file.url)
				})
			)
		}
	}, [value])

	const onChange = async (info: UploadChangeParam<UploadFile>) => {
		let values: UploadFieldValueType[] = info.fileList || []

		if (info.file.status === 'error') {
			showNotifications([{ type: MSG_TYPE.ERROR, message: info.file.error.message }])
			values.pop()
		}
		if (info.file.status === 'done' || info.file.status === 'removed') {
			values = formatImgFormValues(info.fileList, imagesUrls.current)
			const splitted = splitArrayByCondition(values, (item) => item.type !== 'application/pdf')

			setImages(splitted[0])
		}
		if (info.file.status === 'uploading') {
			values = info.fileList
		}

		if (hasCoverPhoto) {
			values = values.map((image, index) => {
				// only first photo can be locked and have attribute isLocked
				if (index === 0) {
					return { ...image, isLocked: isCoverPhotoLocked, isCover: true }
				}
				return { ...image, isCover: false }
			})
		}

		input.onChange(values)

		if (info.file.status !== 'uploading') {
			// uploading process finished -> remove UPLOAD_IN_PROGRESS_PROP from bodyForm
			dispatch(autofill(form, UPLOAD_IN_PROGRESS_PROP, undefined))
		}
	}

	const transformDownloadUrl = () => {
		const originalUrl = images[previewImgIndex]?.url
		if (hasRawPermissions) {
			// Extract the file name and file extension from the original URL
			const fileName = originalUrl?.substring(originalUrl.lastIndexOf('/') + 1)
			const fileExtension = fileName?.substring(fileName.lastIndexOf('.') + 1)
			// Append 'raw' to the file name before the file extension
			const transformedUrl = originalUrl?.replace(`.${fileExtension}`, `-raw.${fileExtension}`)
			return transformedUrl
		}
		return originalUrl
	}

	const showUploadList = {
		showRemoveIcon: true,
		showPreviewIcon: true
	}

	const onDragEnd = ({ active, over }: DragEndEvent) => {
		if (active.id && over?.id) {
			let newIndex: number | undefined
			let oldIndex: number | undefined

			for (let i = 0; i < value.length; i += 1) {
				if (newIndex !== undefined && oldIndex !== undefined) {
					break
				}
				if (value[i].uid === active.id) {
					oldIndex = i
				}
				if (value[i].uid === over.id) {
					newIndex = i
				}
			}

			if (oldIndex !== undefined && newIndex !== undefined) {
				let newData = arrayMove([...value], oldIndex, newIndex)

				if (hasCoverPhoto) {
					// set new cover photo if it is dropped on the first position
					newData = newData.map((image, index) => {
						if (index === 0) {
							return { ...image, isCover: true, isLocked: isCoverPhotoLocked }
						}
						return { ...image, isCover: false }
					})
				}

				input.onChange(newData)
			}
		}
	}

	const onPreview = async (file: ImgUploadFieldValueType) => {
		if (onPreviewImage) {
			onPreviewImage(file)
		}
		if (!file.url) {
			if ('originFileObj' in file && file.originFileObj) {
				try {
					const url = await getBase64(file.originFileObj)
					setPreviewUrl({ ...file, url })
				} catch {
					showNotifications([{ type: MSG_TYPE.ERROR, message: t('loc:Náhľad nie je možné zobraziť') }])
				}
				return
			}
			showNotifications([{ type: MSG_TYPE.ERROR, message: t('loc:Náhľad nie je možné zobraziť') }])
			return
		}

		if (('type' in file && file.type === 'application/pdf') || isFilePDF(file.url)) {
			window.open(file.url)
			return
		}
		setPreviewUrl(file)
	}

	const onLockCoverPhotoChange = (checkedValue: CheckboxChangeEvent) => {
		const newValue = value.map((image, index) => {
			// only first photo can be locked
			if (index === 0) {
				return { ...image, isLocked: checkedValue.target.checked }
			}
			return { ...image }
		})
		input.onChange(newValue)
	}

	const { setNodeRef } = useDroppable({
		id: 'parent-droppable'
	})

	const mouseSensor = useSensor(MouseSensor, {
		activationConstraint: {
			distance: 10 // Enable sort function when dragging at least 10px
		}
	})
	const sensors = useSensors(mouseSensor)

	const uploader = (
		<Upload
			ref={setNodeRef}
			id={formFieldID(form, input.name)}
			className={cx(uploaderClassName, '-mb-2 min-h-[114px] min-w-[114px]', {
				'draggable-upload': draggable,
				'to-check-changes': toCheck?.changed,
				'upload-with-description': Boolean(renderDescription)
			})}
			customRequest={(options: CustomRequestOptions) => {
				dispatch(change(form, UPLOAD_IN_PROGRESS_PROP, true))
				uploadFiles(options, signUrl, category, imagesUrls)
			}}
			accept={accept}
			disabled={disabled}
			onChange={onChange}
			listType='picture-card'
			multiple={multiple}
			itemRender={(_originNode, file, _currFileList, actions) => {
				const isCoverPhoto = coverPhoto?.uid === file.uid

				return (
					<GalleryImage
						file={file}
						actions={actions}
						hasCoverPhoto={hasCoverPhoto}
						isCoverPhoto={isCoverPhoto}
						isCoverPhotoLocked={isCoverPhoto && isCoverPhotoLocked}
						isCoverPhotoDisabled={isCoverPhotoDisabled}
						toCheck={toCheck}
						draggable={draggable}
						disabled={disabled}
						renderDescription={renderDescription}
					/>
				)
			}}
			fileList={value as UploadFile[]}
			onPreview={onPreview}
			maxCount={maxCount}
			showUploadList={showUploadList}
			beforeUpload={(file, fileList) => {
				const acceptTypes = accept.split(',')
				if (!acceptTypes.includes(file.type)) {
					showNotifications([
						{
							type: MSG_TYPE.ERROR,
							message: (
								<Trans
									defaults={t('loc:Súbor <strong>{{fileName}}</strong> musí byť jeden z podporovaných typov {{accept}}')}
									components={{ strong: <strong /> }}
									values={{ fileName: file.name, accept: acceptTypes.join(', ') }}
								/>
							)
						}
					])
					return Upload.LIST_IGNORE
				}

				if (file.size >= maxFileSize) {
					const messages = [getMaxSizeNotifMessage(maxFileSize, file.name)]
					showNotifications(messages)
					return Upload.LIST_IGNORE
				}

				if (maxCount !== undefined && fileList.length > maxCount) {
					const { uid: uidCurrent } = file
					const { uid: uidLast } = fileList[fileList.length - 1]
					if (uidCurrent === uidLast) showNotifications([{ type: MSG_TYPE.ERROR, message: t('loc:Nahrajte maximálne {{maxCount}} súborov', { maxCount }) }])
					return Upload.LIST_IGNORE
				}
				return true
			}}
		>
			{((maxCount !== undefined && value.length < maxCount) || maxCount === undefined) && (
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
				<div onClick={onUploaderClick}>
					<UploadIcon className={`text-xl mx-auto upload-icon ${touched && error ? 'text-red-600' : 'text-gray-600'}`} />
					<div className={`upload-input text-sm ${touched && error ? 'text-red-600' : 'text-gray-600'}`}>{t('loc:Nahrať')}</div>
				</div>
			)}
		</Upload>
	)

	return (
		<>
			<Item
				className={`${className ?? 'w-full'} noti-upload-item`}
				label={label}
				required={required}
				help={touched && error ? error : undefined}
				validateStatus={touched && error ? 'error' : undefined}
				tooltip={tooltip}
			>
				{labelDescription && <div className={'text-notino-grayDark mb-1'}>{labelDescription}</div>}
				{draggable ? (
					<DndContext onDragEnd={onDragEnd} modifiers={[restrictToWindowEdges]} collisionDetection={closestCenter} sensors={sensors}>
						<SortableContext items={value.map((image) => image.uid)} strategy={rectSortingStrategy}>
							{uploader}
						</SortableContext>
					</DndContext>
				) : (
					uploader
				)}
			</Item>
			{hasCoverPhoto && !isCoverPhotoDisabled && (
				<div className={'flex items-center'}>
					<Checkbox onChange={onLockCoverPhotoChange} checked={!!coverPhoto?.isLocked} disabled={disabled || !value.length}>
						{t('loc:Zamknúť titulnú fotku')}
					</Checkbox>
					<Tooltip
						title={t('loc:Uzamknutie titulnej fotky')}
						description={t('loc:Titulnú fotografiu (prvá fotografia v zozname) môžete uzamknúť, aby ju salón nemohol zmeniť.')}
					>
						<InfoIcon className={'text-notino-pink w-4 h-4 flex-shrink-0'} />
					</Tooltip>
				</div>
			)}
			{!!previewUrl && (
				<>
					<div className={cx('download', { hidden: !previewUrl, fixed: previewUrl })}>
						<Button
							className={'w-full h-full m-0 p-0'}
							href={`${transformDownloadUrl()}?response-content-disposition=attachment`}
							target='_blank'
							rel='noopener noreferrer'
							type={'link'}
							htmlType={'button'}
							title={t('loc:Stiahnuť súbor')}
							download
						>
							<span role='img' aria-label='download' className='w-full h-full flex items-center justify-center'>
								<DownloadIcon width={24} />
							</span>
						</Button>
					</div>
					<div className={'hidden'}>
						<Image.PreviewGroup
							preview={{
								visible: (() => {
									if (!previewUrl) {
										return false
									}
									if ('type' in previewUrl) {
										return previewUrl.type !== 'application/pdf'
									}
									return !isFilePDF(previewUrl?.url)
								})(),
								onVisibleChange: () => setPreviewUrl(null),
								current: images?.findIndex((image) => image?.url === previewUrl?.url),
								countRender: (current: number, total: number) => {
									// NOTE: Antd pre readable format indexuje current od 1 a nie 0
									setPreviewImgIndex(current - 1)
									return `${current}/${total}`
								},
								onChange: (newCurrent: number) => {
									const newPreviewUrl = images[newCurrent]
									if (newPreviewUrl) {
										setPreviewUrl(newPreviewUrl)
									}
								}
							}}
						>
							{images.map((image) => (
								<Image key={image.uid} src={image.url || undefined} fallback={image.thumbUrl || undefined} />
							))}
						</Image.PreviewGroup>
					</div>
				</>
			)}
		</>
	)
}

export default React.memo(ImgUploadField)
