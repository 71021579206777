import { ILoadingAndFailure } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { IPhotoalbumsActions, ISalonPhotoalbumPayload, ISalonPhotoalbumsPayload } from './photoalbumActions'
import { PHOTOALBUM, PHOTOALBUMS } from './photoalbumTypes'

export const initState = {
	photoalbums: {
		data: null,
		isLoading: false,
		isFailure: false
	} as ISalonPhotoalbumsPayload & ILoadingAndFailure,
	photoalbum: {
		data: null,
		isLoading: false,
		isFailure: false
	} as ISalonPhotoalbumPayload & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: IPhotoalbumsActions) => {
	switch (action.type) {
		// Photoalbums
		case PHOTOALBUMS.PHOTOALBUMS_LOAD_START:
			return {
				...state,
				photoalbums: {
					...state.photoalbums,
					isLoading: true
				}
			}
		case PHOTOALBUMS.PHOTOALBUMS_LOAD_FAIL:
			return {
				...state,
				photoalbums: {
					...initState.photoalbums,
					isFailure: true
				}
			}
		case PHOTOALBUMS.PHOTOALBUMS_LOAD_DONE:
			return {
				...state,
				photoalbums: {
					...initState.photoalbums,
					data: action.payload.data
				}
			}

		// Photoalbum
		case PHOTOALBUM.PHOTOALBUM_LOAD_START:
			return {
				...state,
				photoalbum: {
					...state.photoalbum,
					isLoading: true
				}
			}

		case PHOTOALBUM.PHOTOALBUM_LOAD_FAIL:
			return {
				...state,
				photoalbum: {
					...initState.photoalbum,
					isFailure: true
				}
			}

		case PHOTOALBUM.PHOTOALBUM_LOAD_DONE:
			return {
				...state,
				photoalbum: {
					...initState.photoalbum,
					data: action.payload.data
				}
			}

		default:
			return state
	}
}
