import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Spin, Rate, Progress, Button, Dropdown, TableProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { compose } from 'redux'
import { isNil } from 'lodash'
import cx from 'classnames'
import { ColumnsType } from 'antd/es/table'

// components
import CustomTable from '../../components/CustomTable'
import Breadcrumbs from '../../components/Breadcrumbs'
import ReviewsFilter from './components/ReviewsFilter'
import DeleteButton from '../../components/DeleteButton'
import TabsComponent from '../../components/TabsComponent'

// utils
import { ADMIN_PERMISSIONS, REVIEWS_BUTTON_IDS, FORM, PERMISSION, REVIEWS_TAB_KEYS, REVIEW_VERIFICATION_STATUS, ROW_GUTTER_X_DEFAULT } from '../../utils/enums'
import { normalizeDirectionKeys, setOrder } from '../../utils/helper'
import { deleteReq, patchReq } from '../../utils/request'
import Permissions, { withPermissions } from '../../utils/Permissions'
import { LocalizedDateTime } from '../../utils/intl'

// reducers
import { RootState } from '../../reducers'
import { IReviewsPayload, getReviews } from '../../reducers/reviews/reviewsActions'

// types
import { IBreadcrumbs, IReviewFilterForm } from '../../types/interfaces'

// assets
import EyeoffIcon from '../../assets/icons/eye-hidden-icon.svg?react'
import EyeIcon from '../../assets/icons/eye-icon.svg?react'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// schema
import { reviewsPageURLQueryParams } from '../../schemas/queryParams'

type TableDataItem = NonNullable<IReviewsPayload['data']>['reviews'][0]

const ReviewsPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const selectedCountry = useSelector((state: RootState) => state.selectedCountry.selectedCountry)

	const [query, setQuery] = useQueryParams(reviewsPageURLQueryParams, {
		page: 1,
		order: 'toxicityScore:DESC',
		reviewState: REVIEWS_TAB_KEYS.PUBLISHED
	})

	const countryCode = selectedCountry || query.salonCountryCode

	const reviews = useSelector((state: RootState) => state.reviews.reviews)

	const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([])
	const [isSubmitting, setIsSubmitting] = useState(false)

	const isLoading = isSubmitting || reviews?.isLoading

	const fetchReviews = useCallback(async () => {
		const { data } = await dispatch(
			getReviews({
				page: query.page,
				limit: query.limit,
				order: query.order,
				search: query.search,
				deleted: query.reviewState === REVIEWS_TAB_KEYS.DELETED,
				verificationStatus: query.verificationStatus as REVIEW_VERIFICATION_STATUS,
				toxicityScoreFrom: query.toxicityScoreFrom,
				toxicityScoreTo: query.toxicityScoreTo,
				salonCountryCode: countryCode
			})
		)
		if (data) {
			setExpandedRowKeys(data.reviews.map((review) => review.id))
		}
	}, [dispatch, query.page, query.limit, query.order, query.search, query.reviewState, query.verificationStatus, query.toxicityScoreFrom, query.toxicityScoreTo, countryCode])

	useEffect(() => {
		dispatch(
			initialize(FORM.REVIEWS_FILTER, {
				search: query.search,
				verificationStatus: query.verificationStatus as REVIEW_VERIFICATION_STATUS,
				toxicityScoreFrom: query.toxicityScoreFrom,
				toxicityScoreTo: query.toxicityScoreTo,
				countryCode
			})
		)
		fetchReviews()
	}, [dispatch, query.search, fetchReviews, query.toxicityScoreFrom, query.toxicityScoreTo, query.verificationStatus, countryCode])

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (_pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}`
			const newQuery = {
				...query,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === reviews?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}

	const handleSubmit = (values: IReviewFilterForm) => {
		const newQuery = {
			...query,
			...values,
			toxicityScoreFrom: values.toxicityScoreFrom ?? undefined,
			toxicityScoreTo: values.toxicityScoreTo ?? undefined,
			page: 1
		}
		setQuery(newQuery)
	}

	const onTabChange = (selectedTabKey: string) => {
		setQuery({ ...query, page: 1, reviewState: selectedTabKey as REVIEWS_TAB_KEYS })
	}

	const deleteReview = async (reviewID: string) => {
		if (isSubmitting) {
			return
		}
		try {
			setIsSubmitting(true)
			await deleteReq('/api/b2b/admin/reviews/{reviewID}', { params: { path: { reviewID } }, reqBody: {} })
			fetchReviews()
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			setIsSubmitting(false)
		}
	}

	const changeVerificationStatus = async (reviewID: string, verificationStatus: REVIEW_VERIFICATION_STATUS) => {
		if (isSubmitting) {
			return
		}
		try {
			setIsSubmitting(true)
			await patchReq('/api/b2b/admin/reviews/{reviewID}/verification', { params: { path: { reviewID } }, reqBody: { verificationStatus } })
			fetchReviews()
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			setIsSubmitting(false)
		}
	}

	const getColumns = () => {
		const columns: ColumnsType<TableDataItem> = [
			{
				title: t('loc:Autor recenzie'),
				dataIndex: 'reviewerName',
				key: 'reviewerName',
				ellipsis: true,
				width: '20%',
				render: (value: TableDataItem['reviewerName']) => value || '-'
			},
			{
				title: t('loc:Dátum a čas vytvorenia'),
				dataIndex: 'createdAt',
				key: 'createdAt',
				ellipsis: true,
				width: '20%',
				render: (value: TableDataItem['createdAt']) => <LocalizedDateTime date={value} fallback={''} />
			},
			{
				title: t('loc:Salón'),
				dataIndex: 'salon',
				key: 'salon',
				ellipsis: true,
				width: '20%',
				render: (value: TableDataItem['salon']) => {
					return value.name
				}
			},
			{
				title: t('loc:Recenzovaný kolega'),
				dataIndex: 'calendarEvent',
				key: 'calendarEvent',
				ellipsis: true,
				width: '20%',
				render: (value: TableDataItem['calendarEvent']) => value?.employee?.fullName || '-'
			},
			{
				title: t('loc:Hodnotenie'),
				dataIndex: 'rating',
				key: 'rating',
				ellipsis: true,
				width: 170,
				render: (value: TableDataItem['rating']) => <Rate disabled defaultValue={value} count={5} />
			},
			{
				title: t('loc:Toxicita'),
				dataIndex: 'toxicityScore',
				key: 'toxicityScore',
				ellipsis: true,
				width: 170,
				sorter: true,
				sortDirections: ['ascend', 'descend', 'ascend'],
				sortOrder: setOrder(query.order, 'toxicityScore'),
				render: (value: TableDataItem['toxicityScore']) =>
					!isNil(value) ? (
						<Row wrap={false}>
							<span className={'flex-shrink-0 mr-1 w-13'}>{value ? `${value}%` : ''}</span>
							<Progress percent={value} showInfo={false} strokeColor={'#000'} />
						</Row>
					) : null
			}
		]

		if (query.reviewState === REVIEWS_TAB_KEYS.PUBLISHED) {
			columns.push({
				key: 'actions',
				ellipsis: true,
				fixed: 'right',
				width: 190,
				render: (_value, record) => {
					const disabledShowReview = record?.verificationStatus === REVIEW_VERIFICATION_STATUS.VISIBLE_IN_B2C
					const disabledHideReview = record?.verificationStatus === REVIEW_VERIFICATION_STATUS.HIDDEN_IN_B2C
					const showReviewText = record?.verificationStatus === REVIEW_VERIFICATION_STATUS.NOT_VERIFIED ? t('loc:Akceptovať text') : t('loc:Publikovať text')
					const dropdownItemClassName = 'p-2 min-w-0 h-9 w-full whitespace-nowrap'

					return (
						<div className={'flex justify-center items-center gap-2 p-1'}>
							<Permissions
								allowed={[...ADMIN_PERMISSIONS, PERMISSION.REVIEW_VERIFY]}
								render={(hasPermission, { openForbiddenModal }) => (
									<Dropdown
										key={'footer-checkout-dropdown'}
										menu={{
											className: `${REVIEWS_BUTTON_IDS.MODERATE_DROPDOWN_CLASSNAME(record.id)} shadow-md max-w-xs min-w-52 mt-1 p-2 flex flex-col gap-2`,
											items: [
												{
													key: 'visible_in_b2c',
													label: showReviewText,
													icon: <EyeIcon />,
													className: cx(dropdownItemClassName, {
														[REVIEWS_BUTTON_IDS.MODERATE_ACCEPT_MESSAGE(record.id)]:
															record?.verificationStatus === REVIEW_VERIFICATION_STATUS.NOT_VERIFIED,
														[REVIEWS_BUTTON_IDS.MODERATE_PUBLISH_MESSAGE(record.id)]:
															record?.verificationStatus !== REVIEW_VERIFICATION_STATUS.NOT_VERIFIED
													}),
													disabled: disabledShowReview,
													onClick: (menuInfo) => {
														if (!hasPermission) {
															menuInfo.domEvent.preventDefault()
															openForbiddenModal()
														} else {
															changeVerificationStatus(record.id, REVIEW_VERIFICATION_STATUS.VISIBLE_IN_B2C)
														}
													}
												},
												{
													key: 'hidden_in_b2c',
													label: t('loc:Skryť text'),
													icon: <EyeoffIcon />,
													className: cx(dropdownItemClassName, [REVIEWS_BUTTON_IDS.MODERATE_HIDE_MESSAGE(record.id)]),
													disabled: disabledHideReview,
													onClick: (menuInfo) => {
														if (!hasPermission) {
															menuInfo.domEvent.preventDefault()
															openForbiddenModal()
														} else {
															changeVerificationStatus(record.id, REVIEW_VERIFICATION_STATUS.HIDDEN_IN_B2C)
														}
													}
												}
											]
										}}
										placement='bottomRight'
										trigger={['click']}
									>
										<Button
											type={'primary'}
											htmlType={'button'}
											size={'middle'}
											className={'noti-btn h-8 w-32 hover:shadow-none'}
											onClick={(e) => e.preventDefault()}
											id={REVIEWS_BUTTON_IDS.SHOW_MODERATE_DROPDOWN(record.id)}
										>
											{t('loc:Moderovať')}
										</Button>
									</Dropdown>
								)}
							/>
							<Permissions
								allowed={[...ADMIN_PERMISSIONS, PERMISSION.REVIEW_DELETE]}
								render={(hasPermission, { openForbiddenModal }) => (
									<DeleteButton
										onConfirm={(e) => {
											if (!hasPermission) {
												e?.preventDefault()
												openForbiddenModal()
											} else {
												deleteReview(record.id)
											}
										}}
										smallIcon
										type={'default'}
										entityName={t('loc:recenziu')}
										onlyIcon
										id={REVIEWS_BUTTON_IDS.DELETE_REVIEW_BUTTON(record.id)}
									/>
								)}
							/>
						</div>
					)
				}
			})
		}

		return columns
	}

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam recenzií')
			}
		]
	}

	const tabContent = [
		{
			key: REVIEWS_TAB_KEYS.PUBLISHED,
			tabKey: REVIEWS_TAB_KEYS.PUBLISHED,
			label: t('loc:Publikované')
		},
		{
			key: REVIEWS_TAB_KEYS.DELETED,
			tabKey: REVIEWS_TAB_KEYS.DELETED,
			label: t('loc:Vymazané')
		}
	]

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} backButtonPath={t('paths:index')} />
			</Row>
			<TabsComponent className={'box-tab'} activeKey={query.reviewState} onChange={onTabChange} items={tabContent} destroyInactiveTabPane />
			<Row gutter={ROW_GUTTER_X_DEFAULT}>
				<Col span={24}>
					<div className='content-body'>
						<Spin spinning={isLoading}>
							<ReviewsFilter onSubmit={handleSubmit} />
							<CustomTable
								className='table-fixed table-expandable'
								onChange={onChangeTable}
								columns={getColumns()}
								dataSource={reviews?.data?.reviews}
								rowKey={'id'}
								scroll={{ x: 1000 }}
								twoToneRows
								onRow={(record) => ({
									className: record.verificationStatus === REVIEW_VERIFICATION_STATUS.NOT_VERIFIED ? 'noti-table-row-warning' : undefined
								})}
								expandable={{
									expandedRowRender: (record) => {
										return (
											<p
												className={cx('pb-1 pl-4 whitespace-pre-wrap flex-1', {
													'text-notino-gray': record.verificationStatus === REVIEW_VERIFICATION_STATUS.HIDDEN_IN_B2C
												})}
											>
												<i>{record.reviewMessage || '-'}</i>
											</p>
										)
									},
									showExpandColumn: false,
									defaultExpandAllRows: true,
									expandedRowKeys
								}}
								useCustomPagination
								pagination={{
									pageSize: reviews?.data?.pagination?.limit,
									total: reviews?.data?.pagination?.totalCount,
									current: reviews?.data?.pagination?.page,
									onChange: onChangePagination,
									disabled: reviews?.isLoading
								}}
							/>
						</Spin>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default compose(withPermissions([...ADMIN_PERMISSIONS, PERMISSION.REVIEW_READ]))(ReviewsPage)
