import React, { useState, ReactNode, PropsWithChildren } from 'react'
import { Collapse, Button, Badge, Row, Col } from 'antd'
import FilterIcon from '../assets/icons/filter-filled-icon.svg?react'
import { FILTER_BUTTON_ID, FORM, ROW_GUTTER_X_DEFAULT } from '../utils/enums'
import { formFieldID } from '../utils/helper'

type Props = {
	activeFilters?: number
	search?: ReactNode
	customContent?: ReactNode | JSX.Element
	customSearchContent?: ReactNode
	disableFilter?: boolean
	form?: FORM
	forceRender?: boolean
	onOpenChange?: (open: boolean) => void
} & PropsWithChildren

const Filters = (props: Props) => {
	const { children, activeFilters, search, customContent, customSearchContent, disableFilter, form, forceRender = false, onOpenChange } = props
	const [visible, setVisible] = useState<undefined | string>(undefined)

	const onClick = () => {
		const activeKey = visible === '1' ? undefined : '1'
		setVisible(activeKey)
		if (onOpenChange) {
			onOpenChange(!!activeKey)
		}
	}

	return (search || customSearchContent) && (customContent || children) ? (
		<Collapse
			collapsible={'disabled'}
			activeKey={visible}
			ghost
			className='ghost-filters'
			items={[
				{
					key: '1',
					showArrow: false,
					forceRender,
					label: (
						<Row className={'mb-4'} justify={'space-between'} gutter={ROW_GUTTER_X_DEFAULT}>
							<Col span={8}>
								{search && search}
								{!search && customSearchContent}
							</Col>
							<Col span={16}>
								<Row gutter={ROW_GUTTER_X_DEFAULT} justify={'end'} align={'middle'}>
									{children && (
										<Col>
											<Badge count={activeFilters} className={'mr-1'} style={{ top: '8px', right: '10px', background: '#DC0069' }}>
												<Button
													id={formFieldID(form, FILTER_BUTTON_ID)}
													onClick={onClick}
													htmlType='button'
													type='link'
													className={'noti-filter-button w-full h-full px-1 flex items-center'}
													disabled={disableFilter}
													icon={<FilterIcon className={'text-gray-600 hover:text-gray-900'} />}
												/>
											</Badge>
										</Col>
									)}
									<Col>{customContent}</Col>
								</Row>
							</Col>
						</Row>
					),
					children
				}
			]}
		/>
	) : (
		<div>
			{/* // Ak exsituje len search */}
			<Col span={24} lg={12} xl={8}>
				{search || customSearchContent}
			</Col>
			{/* // Ak exsituje len children - search a children tu nikdy nebudu naraz, pretoze podmienka vyssie to nedovoli */}
			{children}
		</div>
	)
}

export default Filters
