import { z } from 'zod'

const stringValueSchema = z.object({
	changed: z.boolean(),
	new: z.string().nullish(),
	old: z.string().nullish()
})

export const stringArrayValueSchema = z.object({
	changed: z.boolean(),
	new: z.string().array().nullish(),
	old: z.string().array().nullish()
})

const numberValueSchema = z.object({
	changed: z.boolean(),
	new: z.number().nullish(),
	old: z.number().nullish()
})

const opnenigHoursNoteValueSchema = z.object({
	changed: z.boolean(),
	new: z
		.object({
			note: z.string().nullish()
		})
		.nullish(),
	old: z
		.object({
			note: z.string().nullish()
		})
		.nullish()
})

export const nonCheckedChangesSchema = z.object({
	'salonData.name': stringValueSchema,
	'salonData.aboutUsFirst': stringValueSchema,
	'salonData.openingHoursNote': opnenigHoursNoteValueSchema,
	'salonData.parkingNote': stringValueSchema,
	'salonData.locationNote': stringValueSchema,
	'salonData.email': stringValueSchema,
	'salonData.socialLinkFB': stringValueSchema,
	'salonData.socialLinkInstagram': stringValueSchema,
	'salonData.socialLinkWebPage': stringValueSchema,
	'salonData.socialLinkYoutube': stringValueSchema,
	'salonData.socialLinkTikTok': stringValueSchema,
	'salonData.socialLinkPinterest': stringValueSchema,
	'salonData.otherPaymentMethods': stringValueSchema,
	'salonData.addressID': stringValueSchema,
	'salonData.logoID': stringValueSchema,
	'salonData->address.zipCode': stringValueSchema,
	'salonData->address.city': stringValueSchema,
	'salonData->address.street': stringValueSchema,
	'salonData->address.streetNumber': stringValueSchema,
	'salonData->address.latitude': numberValueSchema,
	'salonData->address.longitude': numberValueSchema,
	'salonData->address.countryCode': stringValueSchema,
	'salonData->images.id': stringArrayValueSchema,
	'salonData->pricelists.id': stringArrayValueSchema,
	'salonData->phones.id': stringArrayValueSchema
})
