export enum WALLET {
	WALLET_LOAD_START = 'WALLET_LOAD_START',
	WALLET_LOAD_DONE = 'WALLET_LOAD_DONE',
	WALLET_LOAD_FAIL = 'WALLET_LOAD_FAIL'
}

export enum WALLET_TRANSACTIONS {
	WALLET_TRANSACTIONS_LOAD_START = 'WALLET_TRANSACTIONS_LOAD_START',
	WALLET_TRANSACTIONS_LOAD_DONE = 'WALLET_TRANSACTIONS_LOAD_DONE',
	WALLET_TRANSACTIONS_LOAD_FAIL = 'WALLET_TRANSACTIONS_LOAD_FAIL'
}
