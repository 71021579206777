/* eslint-disable import/no-cycle */
import { DateSpanApi, EventApi, BusinessHoursInput } from '@fullcalendar/core'
import dayjs, { Dayjs } from 'dayjs'
import i18next from 'i18next'
import { uniqueId, startsWith, isEmpty, isNil, flatten } from 'lodash'
import Scroll from 'react-scroll'
import { notification } from 'antd'
import * as Sentry from '@sentry/react'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

// types
import { change } from 'redux-form'
import {
	CalendarEvent,
	ICalendarEventsPayload,
	ICalendarEventCardData,
	IEventExtendedProps,
	IResourceEmployee,
	IWeekViewResourceExtendedProps,
	IDayViewResourceExtendedProps,
	RawOpeningHours,
	DisabledNotificationsArray,
	ICalendarMonthlyViewEventsPayload,
	ICalendarMonthlyViewEventsCardData,
	CalendarEmployee,
	RsNotificationType,
	CalendarTimeRangeChangeFncArgs,
	ICalendarEventForm,
	ICalendarReservationFormServiceOption,
	ServiceType,
	EmployeeService,
	ICalendarReservationFormServiceGroupOption,
	RequestResponse,
	GetUrls
} from '../../types/interfaces'

// utils
import {
	CALENDAR_COMMON_SETTINGS,
	CALENDAR_DATE_FORMAT,
	CALENDAR_DAY_EVENTS_SHOWN,
	CALENDAR_DISABLED_NOTIFICATION_TYPE,
	CALENDAR_EVENTS_KEYS,
	CALENDAR_EVENT_DISPLAY_TYPE,
	CALENDAR_EVENT_TYPE,
	CALENDAR_VIEW,
	CANCEL_TOKEN_MESSAGES,
	DAY,
	DEFAULT_TIME_FORMAT,
	EXCLUDED_SMS_NOTIFICATIONS,
	MONTHLY_VIEW_EVENTS_CANCEL_TOKEN_KEYS,
	NEW_ID_PREFIX,
	PARAMETERS_UNIT_TYPES,
	PARAMETERS_VALUE_TYPES,
	RS_NOTIFICATION_CHANNEL
} from '../../utils/enums'
import { CALENDAR_COLORS, getAssignedUserLabel, getDateTime, SHORTCUT_DAYS_OPTIONS } from '../../utils/helper'
import { abortControllers } from '../../utils/request'
import { formatDateTimeByLocale, formatDateTimeRangeByLocale, isEnumValue } from '../../utils/intl'

// redux
import { getCalendarEventsCancelTokenKey, getCalendarMonthlyViewCancelTokenKey } from '../../reducers/calendar/calendarActions'
import { RootState } from '../../reducers'
import { getCategoryParameter } from '../../reducers/categoryParams/categoryParamsActions'
import { getEmployee } from '../../reducers/employees/employeesActions'

const stringifyNumber = (value: number, numberOfPlaces = 4) => {
	const stringValue = String(value) // Convert the number to a string
	const paddingLength = numberOfPlaces - stringValue.length // Calculate the number of zeros needed for padding

	if (paddingLength <= 0) {
		return stringValue // No padding needed if the number of places is smaller or equal to the length of the string
	}

	const paddingZeros = '0'.repeat(paddingLength) // Create a string of zeros for padding
	return paddingZeros + stringValue // Concatenate the padding zeros with the string value
}

/**
 * zrusi prebiehajuci request - pouzivame pre zrusenie background loadu pri urcitych akciach, napr. pri zaciatku resizovania/dnd eventu alebo pred zavolanim updatu dat na BE
 */
export const cancelEventsRequestOnDemand = () => {
	const GET_RESERVATIONS_CANCEL_TOKEN_KEY = getCalendarEventsCancelTokenKey(CALENDAR_EVENTS_KEYS.RESERVATIONS)
	const GET_SHIFTS_TIME_OFFS_CANCEL_TOKEN_KEY = getCalendarEventsCancelTokenKey(CALENDAR_EVENTS_KEYS.SHIFTS_TIME_OFFS)
	const GET_RESERVATIONS_MONTHLY_VIEW_CANCEL_TOKEN_KEY = getCalendarMonthlyViewCancelTokenKey(MONTHLY_VIEW_EVENTS_CANCEL_TOKEN_KEYS.RESERVATIONS)
	const GET_TIME_OFFS_MONTHLY_VIEW_CANCEL_TOKEN_KEY = getCalendarMonthlyViewCancelTokenKey(MONTHLY_VIEW_EVENTS_CANCEL_TOKEN_KEYS.TIME_OFFS)

	if (abortControllers.httpGet.has(GET_SHIFTS_TIME_OFFS_CANCEL_TOKEN_KEY)) {
		abortControllers.httpGet.get(GET_SHIFTS_TIME_OFFS_CANCEL_TOKEN_KEY)?.abort(CANCEL_TOKEN_MESSAGES.CANCELED_ON_DEMAND)
	}
	if (abortControllers.httpGet.has(GET_RESERVATIONS_CANCEL_TOKEN_KEY)) {
		abortControllers.httpGet.get(GET_RESERVATIONS_CANCEL_TOKEN_KEY)?.abort(CANCEL_TOKEN_MESSAGES.CANCELED_ON_DEMAND)
	}
	if (abortControllers.httpGet.has(GET_RESERVATIONS_MONTHLY_VIEW_CANCEL_TOKEN_KEY)) {
		abortControllers.httpGet.get(GET_RESERVATIONS_MONTHLY_VIEW_CANCEL_TOKEN_KEY)?.abort(CANCEL_TOKEN_MESSAGES.CANCELED_ON_DEMAND)
	}
	if (abortControllers.httpGet.has(GET_TIME_OFFS_MONTHLY_VIEW_CANCEL_TOKEN_KEY)) {
		abortControllers.httpGet.get(GET_TIME_OFFS_MONTHLY_VIEW_CANCEL_TOKEN_KEY)?.abort(CANCEL_TOKEN_MESSAGES.CANCELED_ON_DEMAND)
	}
}

export const getCalendarMonthFullRangeDates = (selectedMonthFirstDay: string | dayjs.Dayjs, format: string | false = CALENDAR_DATE_FORMAT.QUERY) => {
	// v mesacnom view je potrebne vyplnit cely kalendar - 7 x 6 buniek (od PO - NE) = 42
	const queryParamsStart = dayjs(selectedMonthFirstDay).startOf('week')
	const queryParamsEnd = dayjs(queryParamsStart).add(41, 'days')
	return {
		queryParamsStart: format ? queryParamsStart.format(format) : queryParamsStart,
		queryParamsEnd: format ? queryParamsEnd.format(CALENDAR_DATE_FORMAT.QUERY) : queryParamsEnd
	}
}

interface ICompareAndSortDayEventsData {
	start: string
	end: string
	id: string
	employeeId: string
	eventType: CALENDAR_EVENT_TYPE
	orderIndex: number
}

const CALENDAR_EVENT_TYPES_ORDER = {
	[CALENDAR_EVENT_TYPE.RESERVATION]: 0,
	[CALENDAR_EVENT_TYPE.EMPLOYEE_SHIFT]: 1,
	[CALENDAR_EVENT_TYPE.EMPLOYEE_TIME_OFF]: 2,
	[CALENDAR_EVENT_TYPE.EMPLOYEE_BREAK]: 3
}

export const compareAndSortDayEvents = (dataA: ICompareAndSortDayEventsData, dataB: ICompareAndSortDayEventsData) => {
	const { start: aStart, end: aEnd, id: aId, employeeId: aEmployeeId, eventType: aEventType, orderIndex: aOrderIndex } = dataA
	const { start: bStart, end: bEnd, id: bId, employeeId: bEmployeeId, eventType: bEventType, orderIndex: bOrderIndex } = dataB

	// najprv sa zobrazi novo vytvarany virtualny event
	if (aId.startsWith(NEW_ID_PREFIX) || bId.startsWith(NEW_ID_PREFIX)) {
		return -1
	}
	// potom sa porovnava podla employee id a orderIndex
	if (aEmployeeId === bEmployeeId) {
		const aEventTypeOrder = CALENDAR_EVENT_TYPES_ORDER[aEventType]
		const bEventTypeOrder = CALENDAR_EVENT_TYPES_ORDER[bEventType]
		// potom sa porovnava podla event typu
		if (aEventTypeOrder === bEventTypeOrder) {
			// potom sa porovnava podla zaciatku - skorsi event ide najprv
			if (dayjs(aStart).isBefore(bStart)) {
				return -1
			}
			// ak je rovnaky zaciatok - potom sa porovnava podla konca - dlhsi event ide najprv
			if (dayjs(aStart).isSame(bStart)) {
				// ak je aj koniec rovnaky tak podla id eventu
				if (dayjs(aEnd).isSame(bEnd)) {
					return aId > bId ? -1 : 1
				}
				if (dayjs(aEnd).isAfter(dayjs(bEnd))) {
					return -1
				}
				return 1
			}
		} else {
			return aEventTypeOrder - bEventTypeOrder
		}
	} else {
		return aOrderIndex - bOrderIndex
	}

	return 0
}

export const compareCountsAndDurationsEvents = (aOrderIndex: number, bOrderIndex: number) => aOrderIndex - bOrderIndex

export const eventAllow = (dropInfo: DateSpanApi, movingEvent: EventApi | null, calendarView?: CALENDAR_VIEW) => {
	const extendedProps: IEventExtendedProps | undefined = movingEvent?.extendedProps
	const { eventData } = extendedProps || {}

	if (eventData?.eventType === CALENDAR_EVENT_TYPE.RESERVATION || startsWith(movingEvent?.id, NEW_ID_PREFIX) || calendarView === CALENDAR_VIEW.MONTH) {
		return true
	}

	const resourceExtendedProps = dropInfo?.resource?.extendedProps as IWeekViewResourceExtendedProps | IDayViewResourceExtendedProps

	const resourceEmployeeId = resourceExtendedProps?.employee?.id
	const eventEmployeeId = eventData?.employee?.id

	return resourceEmployeeId === eventEmployeeId
}

/*
 * monthViewFull = true;
 * prida datumy aj z konca predosleho a zaciatku nasledujuceho mesiaca (do konca tyzdna + dalsi tyzden, tak to zobrazuje FC), aby sa vyplnilo cele mesacne view
 * monthViewFull = false;
 * vrati klasicky zaciatok a koniec mesiaca
 */

export const getSelectedDateRange = (view: CALENDAR_VIEW, selectedDate: string, monthViewFull = false, format: string | false = CALENDAR_DATE_FORMAT.QUERY) => {
	let result = {
		view,
		start: dayjs(selectedDate).startOf('day'),
		end: dayjs(selectedDate).endOf('day'),
		selectedMonth: {
			month: dayjs(selectedDate).month(),
			year: dayjs(selectedDate).year()
		}
	}

	switch (view) {
		case CALENDAR_VIEW.MONTH: {
			const start = dayjs(selectedDate).startOf('month')
			const end = dayjs(selectedDate).endOf('month')
			result = {
				...result,
				selectedMonth: {
					month: start.month(),
					year: start.year()
				}
			}
			if (monthViewFull) {
				const { queryParamsStart, queryParamsEnd } = getCalendarMonthFullRangeDates(start, false)
				result = {
					...result,
					start: queryParamsStart as dayjs.Dayjs,
					end: queryParamsEnd as dayjs.Dayjs
				}
			} else {
				result = {
					...result,
					view,
					start,
					end
				}
			}
			break
		}
		case CALENDAR_VIEW.WEEK: {
			result = {
				...result,
				view,
				start: dayjs(selectedDate).startOf('week'),
				end: dayjs(selectedDate).endOf('week')
			}
			break
		}
		default:
			break
	}

	return {
		view: result.view,
		start: format ? result.start.format(format) : result.start.toISOString(),
		end: format ? result.end.format(format) : result.end.toISOString(),
		selectedMonth: result.selectedMonth
	}
}

export const isDateInRange = (start: string, end: string, date: string | dayjs.Dayjs) => dayjs(date).isSameOrAfter(start) && dayjs(date).isSameOrBefore(end)

export const parseTimeFromMinutes = (minutes: number) => {
	const days = Math.floor(minutes / 1440)
	const hoursLeft = minutes % 1440
	const hours = Math.floor(hoursLeft / 60)
	const min = hoursLeft % 60

	return `${days ? `${days}${'d'} ${hours}h` : ''} ${!days && hours ? `${hours}${'h'}` : ''} ${min ? `${min}${'m'}` : ''}`.trim()
}

const timeTextOptions = { dateStyle: null, fallback: '-' }

export const getTimeText = (start: string | Date | null, end: string | Date | null, onlyStart = false) => {
	if (onlyStart) {
		return formatDateTimeByLocale(start, timeTextOptions)
	}

	return formatDateTimeRangeByLocale(start, end, timeTextOptions)
}

export const getTimeScrollId = (hour: number) => dayjs().startOf('day').add(Math.floor(hour), 'hour').format('HH:mm:ss')

type ResourceMap = {
	[key: string]: number
}

export const scrollToSelectedDate = (scrollId: string, options?: Object) => {
	// scroll ID je datum v tvare YYYY-MM-DD
	Scroll.scroller.scrollTo(scrollId, {
		containerId: 'nc-calendar-week-wrapper',
		offset: -25, // - hlavicka
		...(options || {})
	})
}

const isEntityNotified = (disabledNotificationSource: DisabledNotificationsArray[0]) => {
	let notificationChannelsLength = Object.keys(RS_NOTIFICATION_CHANNEL).length

	// not all notification event types get SMS notification
	if (EXCLUDED_SMS_NOTIFICATIONS.includes(disabledNotificationSource?.eventType as RsNotificationType)) {
		notificationChannelsLength -= 1
	}

	// when array length is equal to notificationChannelsLength it means all notifications are disabled for entity
	if (disabledNotificationSource?.channels?.length === notificationChannelsLength) {
		return false
	}

	return true
}

/**
 * @param baseNotificationText base notification text
 * @param disabledNotificationTypes array of disabled notification types to check if they are included in disabled notications types source
 * @param disabledNotificationsSource source of disabled notifications types
 * @return string
 *
 *  Return base notification text including information whether employee, customer, both or none of them will be notified
 *
 */
export const getConfirmModalText = (
	baseNotificationText: string,
	disabledNotificationTypesToCheck: CALENDAR_DISABLED_NOTIFICATION_TYPE[],
	disabledNotificationsSource?: DisabledNotificationsArray,
	ignoreCustomerNotification = false,
	ignoreEmployeeNotification = false
) => {
	let isCustomerNotified = !ignoreCustomerNotification
	let isEmployeeNotified = !ignoreEmployeeNotification

	if (!ignoreCustomerNotification || !ignoreEmployeeNotification) {
		disabledNotificationTypesToCheck.forEach((notificationToCheck) => {
			const disabledNotificationSource = disabledNotificationsSource?.find((notificationSource) => notificationSource?.eventType === notificationToCheck)

			if (disabledNotificationSource) {
				if (disabledNotificationSource.eventType?.endsWith('CUSTOMER')) {
					isCustomerNotified = isEntityNotified(disabledNotificationSource)
				}

				if (disabledNotificationSource.eventType?.endsWith('EMPLOYEE')) {
					isEmployeeNotified = isEntityNotified(disabledNotificationSource)
				}
			}
		})
	}

	if (isCustomerNotified && isEmployeeNotified) {
		return i18next.t('loc:{{baseNotificationText}} Zamestnanec aj zákazník dostanú notifikáciu.', { baseNotificationText })
	}

	const notificationText = (entity: string) => i18next.t('loc:{{baseNotificationText}} {{entity}} dostane notifikáciu.', { entity, baseNotificationText })

	if (isCustomerNotified) {
		return notificationText(i18next.t('loc:Zákazník'))
	}

	if (isEmployeeNotified) {
		return notificationText(i18next.t('loc:Zamestnanec'))
	}

	return baseNotificationText
}

export const getWeekDays = (selectedDate: string) => {
	const monday = dayjs(selectedDate).startOf('week')
	const weekDays = []
	for (let i = 0; i < 7; i += 1) {
		weekDays.push(monday.add(i, 'days').format(CALENDAR_DATE_FORMAT.QUERY))
	}
	return weekDays
}

export const getWeekViewSelectedDate = (weekDays: string[]) => {
	// vráti buď dnešok (ak sa nachádza vo zvolenom týždni) alebo prvý deň zo zvoleného týždňa
	const today = dayjs().startOf('day')
	return weekDays.some((day) => dayjs(day).startOf('day').isSame(today)) ? today.format(CALENDAR_DATE_FORMAT.QUERY) : weekDays[0]
}

export const getSelectedDateForCalendar = (view: CALENDAR_VIEW, selectedDate: string) => {
	switch (view) {
		case CALENDAR_VIEW.MONTH: {
			// realne sice nebude sediet selectedDate v kalendari s datumom v query parameteri
			// ale kalendaru je jedno aky ma nastaveny den v mesacnom view, podstatny je mesiac
			// takze kvoli optimalizaciam, aby sa zbytocne neprerendrovaval kalendar vzdy ked sa zmeni datum v ramci mesiaca, tak sa vezme jeho zaciatok
			return dayjs(selectedDate).startOf('month').format(CALENDAR_DATE_FORMAT.QUERY)
		}
		case CALENDAR_VIEW.WEEK: {
			/**
			 * aj ked sa jedna o tyzdenne view, realne sa pouziva denne view, ktore je pozgrupovane tak, ze posobi ako tyzdenne
			 * je potrebne skontrolovat, ci sa vramci novo nastaveneho tyzdnoveho rangu nachadza dnesok
			 * ak ano, je potrebne ho nastavit ako aktualny den do Fullcalendara, aby sa ukazal now indicator, ak nie, tak sa nastavi ako aktualny datum prvy den zo zvoleneho tyzdna
			 * tym, ze sa nastavi bud dnesok alebo prvy den z tyzdna, sa zamedzi zbytocnym prerendrovaniam Fullcalendara, ktore su hlavne v tyzdennom view, kde moze byt dost vela eventov, narocne
			 */
			const weekDays = getWeekDays(selectedDate)
			return getWeekViewSelectedDate(weekDays)
		}
		case CALENDAR_VIEW.DAY:
		default:
			return selectedDate
	}
}

const createAllDayInverseEventFromResourceMap = (resourcesMap: ResourceMap, selectedDate: string) => {
	return Object.entries({ ...resourcesMap }).reduce((acc, [key, value]) => {
		if (!value) {
			return [
				...acc,
				{
					id: uniqueId((Math.random() * Math.random()).toString()),
					resourceId: key,
					start: dayjs(selectedDate).startOf('day').toISOString(),
					end: dayjs(selectedDate).startOf('day').add(1, 'seconds').toISOString(),
					allDay: false,
					employee: key,
					display: 'inverse-background'
				}
			]
		}
		return acc
	}, [] as any[])
}

// ak je dlzka bg eventu mensia ako min dielik v kalendari (u nas 15 minut), tak ho to vytvorime ako 15 minutovy, lebo to vyzera divne potom
const getBgEventEnd = (start: string, end: string) =>
	dayjs(end).diff(start, 'minutes') < CALENDAR_COMMON_SETTINGS.EVENT_MIN_DURATION ? dayjs(start).add(CALENDAR_COMMON_SETTINGS.EVENT_MIN_DURATION, 'minutes').toISOString() : end

const createEmployeeResourceData = (employee: CalendarEvent['employee'], isTimeOff: boolean, description?: string): IResourceEmployee => {
	return {
		id: employee.id,
		name: getAssignedUserLabel({
			id: employee.id,
			firstName: employee.firstName,
			lastName: employee?.lastName,
			email: employee.email || employee.inviteEmail
		}),
		image: employee.image.resizedImages.thumbnail,
		description,
		isTimeOff,
		isDeleted: employee.isDeleted
	}
}

const createBaseEvent = (event: CalendarEvent, resourceId: string, start: string, end: string): ICalendarEventCardData => {
	const baseEvent = {
		id: event.id,
		resourceId,
		start,
		end,
		allDay: false,
		eventData: {
			...(event || {})
		}
	}
	return baseEvent
}

/**
 * Daily view helpers
 */
export const composeDayViewEvents = (
	selectedDate: string,
	reservations: ICalendarEventsPayload['data'],
	shiftsTimeOffs: ICalendarEventsPayload['data'],
	employees: CalendarEmployee[]
) => {
	const composedEvents: any[] = []
	// resources mapa, pre trackovanie, ci zamestnanec ma zmenu alebo dovolenku v dany den
	const resourcesMap = employees?.reduce((resources, employee) => {
		return {
			...resources,
			[employee.id]: 0
		}
	}, {} as ResourceMap)

	const events = [...(reservations || []), ...(shiftsTimeOffs || [])]

	events?.forEach((event) => {
		const employeeID = event.employee?.id

		const start = event.startDateTime
		const end = event.endDateTime

		if (employeeID && dayjs(start).isBefore(end)) {
			const calendarEvent = createBaseEvent(event, employeeID, start, end)
			const bgEventEnd = getBgEventEnd(start, end)

			switch (calendarEvent.eventData.eventType) {
				case CALENDAR_EVENT_TYPE.EMPLOYEE_SHIFT:
					composedEvents.push({
						...calendarEvent,
						end: bgEventEnd,
						groupId: 'availability-not-set',
						display: CALENDAR_EVENT_DISPLAY_TYPE.INVERSE_BACKGROUND
					})
					resourcesMap[employeeID] += 1
					break
				case CALENDAR_EVENT_TYPE.EMPLOYEE_BREAK:
				case CALENDAR_EVENT_TYPE.EMPLOYEE_TIME_OFF:
				case CALENDAR_EVENT_TYPE.RESERVATION:
					composedEvents.push({
						...calendarEvent
					})
					break
				default:
					break
			}
		}
	})

	// ak zamestnanec nema ziadnu zmenu v dany den
	// tak vytvorime "fake" 'inverse-background' event, ktory zasrafuje pozadie pre cely den
	const allDayInverseEvents = createAllDayInverseEventFromResourceMap(resourcesMap, selectedDate)

	return [...composedEvents, ...allDayInverseEvents]
}

export const composeDayViewResources = (shiftsTimeOffs: ICalendarEventsPayload['data'], employees: CalendarEmployee[]) => {
	return employees.map((employee) => {
		const employeeShifts: any[] = []
		const employeeTimeOff: any[] = []

		shiftsTimeOffs?.forEach((event) => {
			if (event.employee?.id === employee.id) {
				if (event.eventType === CALENDAR_EVENT_TYPE.EMPLOYEE_SHIFT) {
					employeeShifts.push(event)
				} else if (event.eventType === CALENDAR_EVENT_TYPE.EMPLOYEE_TIME_OFF) {
					employeeTimeOff.push(event)
				}
			}
		})

		let description: string | undefined = i18next.t('loc:Nenastavená zmena')

		if (employeeShifts.length) {
			const employeeWorkingHours = employeeShifts.reduce(
				(result, cv, i) => {
					const startCv = cv.startDateTime
					const endCv = cv.endDateTime

					if (i === 0) {
						return {
							start: startCv,
							end: endCv
						}
					}

					const startResult = result.start
					const endResult = result.end

					return {
						start: dayjs(startCv).isBefore(dayjs(startResult)) ? startCv : startResult,
						end: dayjs(endCv).isAfter(dayjs(endResult)) ? endCv : endResult
					}
				},
				{} as { start: string; end: string }
			)
			description = formatDateTimeRangeByLocale(employeeWorkingHours.start, employeeWorkingHours.end, { dateStyle: null, fallback: '-' })
		} else if (employeeTimeOff.length && !employeeShifts.length) {
			description = i18next.t('loc:Voľno')
		}

		return {
			id: employee.id,
			employee: createEmployeeResourceData(employee, !!employeeTimeOff.length, description),
			title: stringifyNumber(employee.orderIndex) // used for ordering
		}
	})
}

/**
 * Weekly view helpers
 */
export const getWeekDayResourceID = (employeeID: string, weekDay: string) => `${weekDay}_${employeeID}`

interface EmployeeWeekResource {
	id: string
	name: string
	image: string
	isTimeOff: boolean
	employee: CalendarEmployee
}

type WeekDayResource = { id: string; day: string; employee: EmployeeWeekResource }

/**
 * Returns e.g.
	const weekDayResources = [
		{ id: `employeeID1_mondayDate`, day: mondayDate, employee: employee1Data },
		{ id: `employeeID2_mondayDate`, day: mondayDate, employee: employee2Data },
		{ id: `employeeID1_tuesdayDate`, day: tuesdayDate, employee: employee1Data },
		{ id: `employeeID2_tuesdayDate`, day: tuesdayDate, employee: employee2Data }
]
*/

export const composeWeekResources = (weekDays: string[], shiftsTimeOffs: ICalendarEventsPayload['data'], employees: CalendarEmployee[]): WeekDayResource[] => {
	return weekDays.reduce((resources, weekDay) => {
		const timeOffsWeekDay = shiftsTimeOffs?.filter((event) => dayjs(event.start.date).isSame(dayjs(weekDay)) && event.eventType === CALENDAR_EVENT_TYPE.EMPLOYEE_TIME_OFF)

		const weekDayEmployees = employees.map((employee) => {
			return {
				id: getWeekDayResourceID(employee.id, weekDay),
				day: weekDay,
				employee: createEmployeeResourceData(employee, !!timeOffsWeekDay?.filter((timeOff) => timeOff.employee?.id === employee.id).length),
				title: stringifyNumber(employee.orderIndex) // used for ordering
			}
		})
		return [...resources, ...weekDayEmployees]
	}, [] as any[])
}

export const composeWeekViewEvents = (
	selectedDate: string,
	weekDays: string[],
	reservations: ICalendarEventsPayload['data'],
	shiftsTimeOffs: ICalendarEventsPayload['data'],
	employees: CalendarEmployee[],
	showShiftsAsRegularEvents = false
) => {
	const composedEvents: any[] = []

	// resources mapa, pre trackovanie, ci zamestnanec ma zmenu alebo dovolenku v dany den
	const resourcesMap = weekDays?.reduce((resources, weekDay) => {
		return {
			...resources,
			...employees.reduce((resourcesDay, employee) => {
				return {
					...resourcesDay,
					[getWeekDayResourceID(employee.id, weekDay)]: 0
				}
			}, {} as ResourceMap)
		}
	}, {} as ResourceMap)
	const events = [...(reservations || []), ...(shiftsTimeOffs || [])]

	events?.forEach((event) => {
		const startOriginal = event.startDateTime
		const start = getDateTime(selectedDate, event.start.time)
		const end = getDateTime(selectedDate, event.end.time)

		const employeeID = event.employee?.id
		const weekDayIndex = weekDays.findIndex((weekDay) => dayjs(weekDay).startOf('day').isSame(dayjs(startOriginal).startOf('day')))

		if (employeeID && dayjs(start).isBefore(end) && weekDayIndex >= 0) {
			const resourceId = getWeekDayResourceID(employeeID, weekDays[weekDayIndex])
			const calendarEvent = createBaseEvent(event, resourceId, start, end)
			const bgEventEnd = getBgEventEnd(start, end)

			switch (calendarEvent.eventData.eventType) {
				case CALENDAR_EVENT_TYPE.EMPLOYEE_SHIFT:
					composedEvents.push({
						...calendarEvent,
						end: bgEventEnd,
						groupId: 'availability-not-set',
						display: CALENDAR_EVENT_DISPLAY_TYPE.INVERSE_BACKGROUND
					})
					resourcesMap[resourceId] += 1

					if (showShiftsAsRegularEvents) {
						composedEvents.push({
							...calendarEvent
						})
					}

					break
				case CALENDAR_EVENT_TYPE.EMPLOYEE_TIME_OFF:
				case CALENDAR_EVENT_TYPE.EMPLOYEE_BREAK:
				case CALENDAR_EVENT_TYPE.RESERVATION:
					composedEvents.push({
						...calendarEvent
					})
					break
				default:
					break
			}
		}
	})

	// ak zamestnanec nema ziadnu zmenu v dany den
	// tak vytvorime "fake" 'inverse-background' event, ktory zasrafuje pozadie pre cely den
	const allDayInverseEvents = createAllDayInverseEventFromResourceMap(resourcesMap, selectedDate)

	return [...composedEvents, ...allDayInverseEvents]
}

/**
 * Monthly view helpers
 */
type DayMap = {
	[key in DAY]: number
}

export const DAY_MAP: DayMap = {
	[DAY.SUNDAY]: 0,
	[DAY.MONDAY]: 1,
	[DAY.TUESDAY]: 2,
	[DAY.WEDNESDAY]: 3,
	[DAY.THURSDAY]: 4,
	[DAY.FRIDAY]: 5,
	[DAY.SATURDAY]: 6
}

export type OpeningHoursMap = {
	[key: number]: boolean
}

export const getOpeningHoursMap = (openingHours: RawOpeningHours) => {
	let map: OpeningHoursMap = {
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
		6: false,
		0: false
	}

	openingHours?.forEach((day) => {
		if (day.state || !isEmpty(day.timeRanges)) {
			map = {
				...map,
				[DAY_MAP[day.day as DAY]]: true
			}
		}
	})

	return map
}

export const getBusinessHours = (openingHoursMap: OpeningHoursMap): BusinessHoursInput => {
	return {
		daysOfWeek: Object.entries(openingHoursMap).reduce((acc, [key, value]) => {
			if (value) {
				return [Number(key), ...acc]
			}
			return acc
		}, [] as number[])
	}
}

export const composeMonthViewEvents = (events: ICalendarEventsPayload['data']) => {
	const composedEvents: any[] = []

	events?.forEach((event) => {
		const employeeID = event.employee?.id
		const start = event.startDateTime
		const end = event.endDateTime

		if (employeeID && dayjs(start).isBefore(end)) {
			composedEvents.push(createBaseEvent(event, employeeID, start, end))
		}
	})

	return composedEvents
}

export const getCountsAndDurationsCalendarEventId = (day: string, employeeId: string) => `${day}_${employeeId}`

export const composeMonthCountsAndDurations = (monthlyViewEvents: ICalendarMonthlyViewEventsPayload['data']) => {
	const composedEvents = Object.entries(monthlyViewEvents || {}).reduce((acc, [day, dayEmployees]) => {
		const dayEvents: ICalendarMonthlyViewEventsCardData[] = []
		dayEmployees.forEach((dayEmployee) => {
			dayEvents.push({
				id: dayEmployee.id,
				start: dayjs(day).startOf('day').toISOString(),
				end: dayjs(day).endOf('day').subtract(1, 'hours').toISOString(),
				allDay: false,
				eventData: dayEmployee
			})
		})
		/**
		 * pre kazdy den potrebujeme poslat do kolenadra len take mnozstvo eventov, ktore je v nom realne vidiet
		 * zvysne sa potom zobrazia v popoveri
		 */
		const sortedAndSlicedDayEvents = dayEvents
			.sort((a, b) => compareCountsAndDurationsEvents(a.eventData.employee.orderIndex, b.eventData.employee.orderIndex))
			.slice(0, CALENDAR_DAY_EVENTS_SHOWN)
		return [...acc, ...sortedAndSlicedDayEvents]
	}, [] as ICalendarMonthlyViewEventsCardData[])

	return composedEvents
}

export const validateReservationAndShowNotification = ({ serviceId, customerId }: { serviceId?: string | number; customerId?: string | number }) => {
	const errors: { message: string; description: string }[] = []

	if (!serviceId) {
		errors.push({
			message: i18next.t('loc:Služba nie je zadaná'),
			description: i18next.t('loc:Nie je možné editovať rezerváciu bez zadanej služby')
		})
	}

	if (!customerId) {
		errors.push({
			message: i18next.t('loc:Zákazník nie je zadaný'),
			description: i18next.t('loc:Nie je možné editovať rezerváciu bez zadaného zákazníka')
		})
	}

	if (!isEmpty(errors)) {
		errors.forEach((error) => notification.error(error))
	}

	return errors
}

export const onCalendarEventTimeRangeChange = ({
	newTimeFrom,
	newTimeTo,
	currentTimeFrom,
	currentTimeTo,
	index,
	timeRangeDifferenceInMinutes,
	dispatch,
	formName
}: CalendarTimeRangeChangeFncArgs) => {
	// change of the start time
	if (index === 0 && newTimeFrom) {
		// maximum allowed start time is 23:58
		const normalizedNewTimeFrom = newTimeFrom.isBefore(newTimeFrom.set('hour', 23).set('minute', 59)) ? newTimeFrom : newTimeFrom.set('hour', 23).set('minute', 58)

		const minutesToAdd = timeRangeDifferenceInMinutes || 1

		if (currentTimeFrom && currentTimeTo) {
			// start time and end to has already been set
			let normalizedNewTimeTo: Dayjs = currentTimeTo
			normalizedNewTimeTo = normalizedNewTimeFrom.add(minutesToAdd, 'minute')

			if (!normalizedNewTimeTo.isSameOrBefore(currentTimeFrom.set('hour', 23).set('minute', 59))) {
				normalizedNewTimeTo = currentTimeFrom.set('hour', 23).set('minute', 59)
			}

			dispatch(change(formName, 'timeTo', dayjs(normalizedNewTimeTo).format(DEFAULT_TIME_FORMAT)))
		} else if (currentTimeTo && normalizedNewTimeFrom.isSameOrBefore(currentTimeTo)) {
			// only end time has already been set
			let normalizedNewTimeTo: Dayjs = currentTimeTo
			normalizedNewTimeTo = normalizedNewTimeFrom.add(minutesToAdd, 'minute')

			if (!normalizedNewTimeTo.isSameOrBefore(normalizedNewTimeFrom.set('hour', 23).set('minute', 59))) {
				normalizedNewTimeTo = normalizedNewTimeFrom.set('hour', 23).set('minute', 59)
			}

			dispatch(change(formName, 'timeTo', dayjs(normalizedNewTimeTo).format(DEFAULT_TIME_FORMAT)))
		}

		dispatch(change(formName, 'timeFrom', dayjs(normalizedNewTimeFrom).format(DEFAULT_TIME_FORMAT)))
	}
	// change of the end time
	if (index === 1 && newTimeTo) {
		// minimum allowed end time is 00:01
		const normalizedNewTimeTo = newTimeTo.isAfter(newTimeTo.startOf('day').add(1, 'minute')) ? newTimeTo : newTimeTo.startOf('day').add(1, 'minute')

		if (currentTimeFrom && newTimeTo) {
			let normalizedNewTimeFrom: Dayjs = currentTimeFrom

			if (normalizedNewTimeTo.isSameOrBefore(currentTimeFrom)) {
				normalizedNewTimeFrom = normalizedNewTimeTo.subtract(1, 'minute')
				dispatch(change(formName, 'timeFrom', dayjs(normalizedNewTimeFrom).format(DEFAULT_TIME_FORMAT)))
			}

			dispatch(change(formName, 'timeRangeDifferenceInMinutes', normalizedNewTimeTo.diff(normalizedNewTimeFrom, 'minute')))
		}

		dispatch(change(formName, 'timeTo', dayjs(normalizedNewTimeTo).format(DEFAULT_TIME_FORMAT)))
	}
}

export const normalizeCalendarEventTimeRange = (timeFrom: string, timeRangeDifferenceInMinutes: number) => {
	const timeFromDayJs = dayjs(timeFrom, DEFAULT_TIME_FORMAT)
	const endOfTheDay = timeFromDayJs.set('hour', 23).set('minutes', 59)

	let normalizedTimeTo: Dayjs = dayjs(timeFromDayJs).add(timeRangeDifferenceInMinutes, 'minute')

	if (normalizedTimeTo.isSameOrAfter(endOfTheDay)) {
		normalizedTimeTo = endOfTheDay
	}

	return {
		timeTo: normalizedTimeTo.format(DEFAULT_TIME_FORMAT)
	}
}

type DurationData = Omit<ServiceType['rangePriceAndDurationData'], 'priceFrom' | 'priceTo'>

const getDurationData = async (
	dispatch: ThunkDispatch<RootState, any, Action>,
	priceAndDurationData?: ServiceType['priceAndDurationData'],
	useCategoryParameter?: boolean,
	serviceCategoryParameter?: ServiceType['serviceCategoryParameter']
) => {
	let durationData = {} as DurationData

	try {
		const durationDataToCheck = {} as DurationData
		if (!isNil(priceAndDurationData?.durationFrom)) {
			durationDataToCheck.durationFrom = priceAndDurationData.durationFrom
		}
		if (!isNil(priceAndDurationData?.durationTo)) {
			durationDataToCheck.durationTo = priceAndDurationData.durationTo
		}

		if (useCategoryParameter && serviceCategoryParameter && !isEmpty(serviceCategoryParameter.values)) {
			let parameterDurationData = {} as DurationData

			if (serviceCategoryParameter.valueType === PARAMETERS_VALUE_TYPES.TIME) {
				const parameterValues = await dispatch(getCategoryParameter(serviceCategoryParameter.id))
				if (serviceCategoryParameter.unitType !== PARAMETERS_UNIT_TYPES.MINUTES) {
					// NOTE: if the new parameter unit type is added, values must be converted to minutes to get correct results
					// eslint-disable-next-line no-console
					Sentry.captureMessage('New time parameter unit type added! Values must be converted to minutes to get correct results. Please update the code.')
				} else {
					parameterDurationData = serviceCategoryParameter.values.reduce((duration, cv) => {
						const foundValue = parameterValues.data?.values.find((value) => value.id === cv.categoryParameterValueID)

						if (!foundValue) {
							// NOTE: no need to throw an error and fail the application, it's just a nice to have feature
							// the sentry log is enough for this case
							Sentry.captureMessage(`Expected to found category parameter value for salon service category parameter value with id ${cv.id}`)
						}

						let newDurationData = { ...duration }
						const durationTo = !isNil(foundValue?.value) && !Number.isNaN(foundValue.value) ? Number(foundValue.value) : 0

						if (durationTo && durationTo > (duration.durationTo || 0)) {
							newDurationData = {
								...newDurationData,
								durationTo
							}
						}
						return newDurationData
					}, {} as DurationData)
				}
			} else {
				parameterDurationData = serviceCategoryParameter.values.reduce((duration, cv) => {
					let newDurationData = { ...duration }
					const durationTo = cv.priceAndDurationData.durationTo || cv.priceAndDurationData.durationFrom || 0

					if (durationTo && durationTo > (duration.durationTo || 0)) {
						newDurationData = {
							...newDurationData,
							durationTo
						}
					}
					return newDurationData
				}, {} as DurationData)
			}

			if (!isEmpty(parameterDurationData)) {
				durationData = {
					durationTo: parameterDurationData.durationTo
				}
			}
		} else if (!isEmpty(durationDataToCheck)) {
			const durationTo = durationDataToCheck?.durationTo || durationDataToCheck?.durationFrom || 0
			durationData = {
				durationTo
			}
		}
	} catch {
		return durationData
	}

	return durationData
}

const getCategoryById = (category: any, serviceCategoryID?: string): EmployeeService | null => {
	let result = null
	if (category?.category?.id === serviceCategoryID) {
		return category
	}
	if (category?.children) {
		// eslint-disable-next-line no-return-assign
		category.children.some((node: any) => (result = getCategoryById(node, serviceCategoryID)))
	}
	return result
}

export const getReservationTime = async (args: {
	dispatch: ThunkDispatch<RootState, any, Action>
	service: ICalendarReservationFormServiceOption | undefined
	employeeId: string | undefined
	timeFrom: string
}) => {
	const { dispatch, service, employeeId, timeFrom } = args
	let durationData: DurationData = {}
	let timeRangeDifferenceInMinutes: number | undefined
	let timeTo: string | undefined

	if (employeeId) {
		try {
			const { data: employeeData } = await dispatch(getEmployee(employeeId))
			const employeeCategory = getCategoryById(
				{
					children: employeeData?.employee?.categories
				},
				service?.extra?.categoryId
			)

			// check if employee has overridden duration data of selected service
			if (employeeCategory) {
				const employeeDurationData = await getDurationData(
					dispatch,
					employeeCategory.priceAndDurationData,
					employeeCategory.useCategoryParameter,
					employeeCategory.serviceCategoryParameter
				)
				if (!isEmpty(employeeDurationData)) {
					durationData = {
						durationTo: employeeDurationData.durationTo
					}
				}
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	// if employee doesn't have overridden duration data, check duration data of selected service
	if (isEmpty(durationData)) {
		const serviceDurationData = await getDurationData(
			dispatch,
			service?.extra?.priceAndDurationData,
			service?.extra?.useCategoryParameter,
			service?.extra?.serviceCategoryParameter
		)
		if (!isEmpty(serviceDurationData)) {
			durationData = {
				durationTo: serviceDurationData?.durationTo
			}
		}
	}

	// set event time based on service duration data
	if (!isNil(durationData.durationTo)) {
		const [hoursFrom, minutesFrom] = timeFrom.split(':')

		const timeFromDayjs = dayjs().startOf('day').add(Number(hoursFrom), 'hours').add(Number(minutesFrom), 'minutes')
		let timeToDayjs = timeFromDayjs.add(durationData.durationTo, 'minutes')

		timeRangeDifferenceInMinutes = timeToDayjs.diff(timeFromDayjs, 'minute')
		// dispatch(change(formName, 'timeRangeDifferenceInMinutes', timeToDayjs.diff(timeFromDayjs, 'minute')))

		const endOfADay = dayjs().startOf('day').add(23, 'hours').add(59, 'minutes')

		if (!dayjs(timeToDayjs).isSameOrBefore(endOfADay)) {
			timeToDayjs = endOfADay
		}

		timeTo = timeToDayjs.format(DEFAULT_TIME_FORMAT)
		// dispatch(change(formName, 'timeTo', timeToDayjs.format(DEFAULT_TIME_FORMAT)))
	}

	return {
		timeRangeDifferenceInMinutes,
		timeTo
	}
}

export const getRepeatEventDescription = (repeatOn: ICalendarEventForm['repeatOn']) => {
	if (!repeatOn || isEmpty(repeatOn)) {
		return ''
	}

	if (repeatOn.length === 7) {
		return i18next.t('loc:Každý deň')
	}

	if (repeatOn.length === 5 && repeatOn.filter((day) => day !== DAY.SATURDAY && day !== DAY.SUNDAY).length === 5) {
		return i18next.t('loc:Každý pracovný deň')
	}

	if (repeatOn.length === 2 && repeatOn.filter((day) => day === DAY.SATURDAY || day === DAY.SUNDAY).length === 2) {
		return i18next.t('loc:Každý víkend')
	}

	const dayTranslations = SHORTCUT_DAYS_OPTIONS(repeatOn.length > 3 ? 3 : 0)
	return dayTranslations
		.reduce<string[]>((acc, day) => {
			if (isEnumValue(day.value, DAY) && repeatOn.includes(day.value)) {
				return [...acc, day.label]
			}
			return acc
		}, [])
		.join(', ')
}

export const getServicesOptions = (
	servicesData: NonNullable<RequestResponse<GetUrls['/api/b2b/admin/services/']>['groupedServicesByCategory']>
): ICalendarReservationFormServiceGroupOption[] => {
	return flatten(
		servicesData.map((industry) =>
			(industry.category.children || []).map((category) => {
				return {
					label: category.category.name || category.category.id,
					key: category.category.id,
					children:
						category.category.children?.reduce<ICalendarReservationFormServiceOption[]>((acc, item) => {
							if (!item.category) {
								return acc
							}

							const option: ICalendarReservationFormServiceOption = {
								// NOTE: custom service can be present more than a once in the services tree - every time with the same ID
								// Antd has the problem rendering options when there are multiple keys with same ID - even when they are in different children arrays
								key: `${item.service.id}_${category.category?.id}`,
								label: item.category?.name || item.service.id,
								value: item.service.id,
								extra: {
									priceAndDurationData: item.service.priceAndDurationData,
									useCategoryParameter: item.service.useCategoryParameter,
									serviceCategoryParameter: item.service.serviceCategoryParameter,
									categoryId: item.category.id,
									color: item.service.color,
									serviceName: item.category?.name || item.service.id
								}
							}

							return [...acc, option]
						}, []) || []
				}
			})
		)
	)
}

export const getCalendarEventTypeDefaultColor = (eventType: CALENDAR_EVENT_TYPE | undefined): CalendarEvent['color'] => {
	if (!eventType) {
		return CALENDAR_COLORS.DEFAULT
	}

	return CALENDAR_COLORS[eventType]
}
