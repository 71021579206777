import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Row, Spin } from 'antd'
import { initialize, isPristine, submit } from 'redux-form'
import { compose } from 'redux'
import cx from 'classnames'

// components
import DeleteButton from '../../components/DeleteButton'
import Breadcrumbs from '../../components/Breadcrumbs'
import SupportContactForm from './components/SupportContactForm'
import { scrollToTopFn } from '../../components/ScrollToTop'
import {
	checkSameOpeningHours,
	checkWeekend,
	createSameOpeningHours,
	initOpeningHours,
	mapRawOpeningHoursToComponentOpeningHours,
	orderDaysInWeek,
	useChangeOpeningHoursFormFields
} from '../../components/OpeningHours/OpeningHoursUtils'

// enums
import { DELETE_BUTTON_ID, FORM, PERMISSION, STRINGS, SUBMIT_BUTTON_ID } from '../../utils/enums'

// types
import { IBreadcrumbs, ISupportContactForm, PatchUrls, RequestPayload } from '../../types/interfaces'

// reducers
import { RootState } from '../../reducers'
import { getSupportContact, getSupportContacts } from '../../reducers/supportContacts/supportContactsActions'

// utils
import { deleteReq, patchReq, postReq } from '../../utils/request'
import Permissions, { withPermissions } from '../../utils/Permissions'
import { formFieldID, getPrefixCountryCode } from '../../utils/helper'

// hooks
import useBackUrl from '../../hooks/useBackUrl'

// assets
import EditIcon from '../../assets/icons/edit-icon.svg?react'
import CreateIcon from '../../assets/icons/plus-icon.svg?react'

// schema
import { OpeningHours } from '../../schemas/baseSchema'

type SupportContactPatch = RequestPayload<PatchUrls['/api/b2b/admin/enums/support-contacts/{supportContactID}']>

const permissions: PERMISSION[] = [PERMISSION.ENUM_EDIT]

type Props = {}

const SupportContactPage: FC<Props> = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { supportContactID } = useParams<{ supportContactID: string }>()

	const [submitting, setSubmitting] = useState<boolean>(false)
	const [isRemoving, setIsRemoving] = useState<boolean>(false)

	const authUser = useSelector((state: RootState) => state.user.authUser)
	const config = useSelector((state: RootState) => state.config.config)
	const { optionsPhonePrefixesAll } = config
	const rolloutCountries = config.data?.rolloutCountries
	const supportContact = useSelector((state: RootState) => state.supportContacts.supportContact)
	const supportContacts = useSelector((state: RootState) => state.supportContacts.supportContacts)

	const formValues = useSelector((state: RootState) => state.form?.[FORM.SUPPORT_CONTACT]?.values)
	const isFormPristine = useSelector(isPristine(FORM.SUPPORT_CONTACT))

	const sameOpenHoursOverWeekFormValue = formValues?.sameOpenHoursOverWeek
	const openOverWeekendFormValue = formValues?.openOverWeekend

	const isLoading = supportContact.isLoading || config?.isLoading || authUser?.isLoading || isRemoving

	const [backUrl] = useBackUrl(t('paths:support-contacts'))

	useEffect(() => {
		const phonePrefixCountryCode = getPrefixCountryCode(optionsPhonePrefixesAll)

		const fetchData = async () => {
			const { data } = await dispatch(getSupportContact(supportContactID))

			if (!data?.supportContact?.id) {
				navigate('/404')
			} else {
				// init data for existing supportContact
				const mappedOpeningHours = mapRawOpeningHoursToComponentOpeningHours(data?.supportContact?.openingHours)
				const openOverWeekend: boolean = checkWeekend(mappedOpeningHours)
				const sameOpenHoursOverWeek: boolean = checkSameOpeningHours(mappedOpeningHours)
				const openingHours: OpeningHours = initOpeningHours(mappedOpeningHours, sameOpenHoursOverWeek, openOverWeekend)?.sort(orderDaysInWeek) as OpeningHours

				dispatch(
					initialize(FORM.SUPPORT_CONTACT, {
						...data?.supportContact,
						openOverWeekend,
						sameOpenHoursOverWeek,
						openingHours,
						city: data?.supportContact?.address?.city,
						street: data?.supportContact?.address?.street,
						zipCode: data?.supportContact?.address?.zipCode,
						countryCode: data?.supportContact?.address?.countryCode,
						streetNumber: data?.supportContact?.address?.streetNumber,
						emails: data?.supportContact?.emails?.map((email) => ({ email })),
						phones: data?.supportContact?.phones.reduce(
							(phones, phone) => {
								if (phone) {
									return [...phones, { phonePrefixCountryCode: phone.phonePrefixCountryCode, phone: phone.phone }]
								}
								return phones
							},
							[] as ISupportContactForm['phones']
						)
					})
				)
			}
		}

		if (supportContactID) {
			fetchData()
		} else {
			// init data for new "creating process" supportContact
			dispatch(
				initialize(FORM.SUPPORT_CONTACT, {
					openOverWeekend: false,
					sameOpenHoursOverWeek: true,
					openingHours: initOpeningHours(undefined, true, false),
					emails: [{ email: '' }],
					phones: [
						{
							phonePrefixCountryCode,
							phone: ''
						}
					]
				})
			)
		}
	}, [dispatch, optionsPhonePrefixesAll, supportContactID, navigate])

	useEffect(() => {
		dispatch(getSupportContacts())
	}, [dispatch])

	useChangeOpeningHoursFormFields(FORM.SUPPORT_CONTACT, formValues?.openingHours, sameOpenHoursOverWeekFormValue, openOverWeekendFormValue)

	const handleSubmit = async (data: ISupportContactForm) => {
		try {
			setSubmitting(true)
			const openingHours = createSameOpeningHours(data.openingHours, !!data.sameOpenHoursOverWeek, !!data.openOverWeekend)?.sort(
				orderDaysInWeek
			) as SupportContactPatch['openingHours']
			const phones = data.phones?.filter((phone) => phone?.phone) as SupportContactPatch['phones']
			const emails = data.emails?.reduce((acc, email) => (email?.email ? [...acc, email.email] : acc), [] as string[])

			const supportContactData: SupportContactPatch = {
				openingHours: openingHours || [],
				address: {
					city: data.city,
					countryCode: data.countryCode,
					street: data.street,
					streetNumber: data.streetNumber,
					zipCode: data.zipCode
				},
				countryCode: data.countryCode,
				note: data.note,
				phones,
				emails
			}

			if (supportContactID) {
				// update existing supportContact
				await patchReq('/api/b2b/admin/enums/support-contacts/{supportContactID}', { params: { path: { supportContactID } }, reqBody: supportContactData })
				dispatch(getSupportContact(supportContactID))
			} else {
				// create new supportContact
				const result = await postReq('/api/b2b/admin/enums/support-contacts/', { params: {}, reqBody: supportContactData })
				if (result?.data?.supportContact?.id) {
					// select new supportContact
					navigate(t('paths:support-contacts/{{supportContactID}}', { supportContactID: result.data.supportContact.id }))
				}
			}
			dispatch(initialize(FORM.SUPPORT_CONTACT, data))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			setSubmitting(false)
			scrollToTopFn()
		}
	}

	const breadcrumbDetailItem = supportContactID
		? {
				name: t('loc:Detail podpory'),
				titleName: supportContact?.data?.supportContact.country.name || ''
			}
		: {
				name: t('loc:Vytvoriť podporu'),
				link: t('paths:support-contacts/create')
			}

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam podporných centier'),
				link: backUrl
			},
			breadcrumbDetailItem
		]
	}

	const deleteSupportContact = async () => {
		if (isRemoving) {
			return
		}
		try {
			setIsRemoving(true)
			await deleteReq('/api/b2b/admin/enums/support-contacts/{supportContactID}', { params: { path: { supportContactID: supportContactID as string } }, reqBody: {} })
			// clear redux
			dispatch(getSupportContact())
			navigate(t('paths:support-contacts'))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			setIsRemoving(false)
		}
	}

	const hasEveryCountrySupportContact = !rolloutCountries?.some((country) => !supportContacts?.data?.supportContacts?.find((contact) => contact.country.code === country.code))

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} backButtonPath={t('paths:index')} />
			</Row>
			<Spin spinning={isLoading}>
				<div className='content-body small'>
					{!supportContactID && hasEveryCountrySupportContact && (
						<Alert
							message={
								<>
									{t('loc:Ďalšiu podporu nie je možné vytvoriť. Pre každú krajinu môžete vytvoriť maximálne jednu.')}{' '}
									<Link to={t('paths:support-contacts')} className={'underline'}>
										{t('loc:Stále však môžete editovať existujúce')}
									</Link>
									{'.'}
								</>
							}
							showIcon
							type={'warning'}
							className={'noti-alert mb-4'}
						/>
					)}
					<SupportContactForm onSubmit={handleSubmit} supportContactID={supportContactID} disabledForm={!supportContactID && hasEveryCountrySupportContact} />
					<div className={'content-footer'} id={'content-footer-container'}>
						<Row className={cx('flex flex-col gap-2 md:flex-row', { 'md:justify-between': supportContactID, 'md:justify-center': !supportContactID })}>
							{supportContactID && (
								<DeleteButton
									permissions={permissions}
									className={'w-full md:w-auto md:min-w-50 xl:min-w-60'}
									onConfirm={deleteSupportContact}
									entityName={t('loc:podporu')}
									type={'default'}
									id={formFieldID(FORM.SUPPORT_CONTACT, DELETE_BUTTON_ID)}
									getPopupContainer={() => document.getElementById('content-footer-container') || document.body}
								/>
							)}
							<Permissions
								allowed={permissions}
								render={(hasPermission, { openForbiddenModal }) => (
									<Button
										type={'primary'}
										size={'middle'}
										id={formFieldID(FORM.SUPPORT_CONTACT, SUBMIT_BUTTON_ID)}
										className={'noti-btn m-regular w-full md:w-auto md:min-w-50 xl:min-w-60'}
										htmlType={'submit'}
										icon={supportContactID ? <EditIcon /> : <CreateIcon />}
										onClick={(e) => {
											if (hasPermission) {
												dispatch(submit(FORM.SUPPORT_CONTACT))
											} else {
												e.preventDefault()
												openForbiddenModal()
											}
										}}
										disabled={(!supportContactID && hasEveryCountrySupportContact) || submitting || isFormPristine}
										loading={submitting}
									>
										{supportContactID ? t('loc:Uložiť') : STRINGS(t).createRecord(t('loc:podporu'))}
									</Button>
								)}
							/>
						</Row>
					</div>
				</div>
			</Spin>
		</>
	)
}

export default compose(withPermissions(permissions))(SupportContactPage)
