import React from 'react'
import { useTranslation } from 'react-i18next'

// assets
import BellCrossedIcon from '../../../assets/icons/bell-crossed-icon.svg?react'

const EmptyNotification = () => {
	const { t } = useTranslation()

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div className={'h-60 w-full flex items-center justify-center flex-col gap-2 cursor-default'} onClick={(e) => e.stopPropagation()}>
			<div className={'w-18 h-18 flex items-center justify-center bg-notino-grayLighter rounded-full'}>
				<BellCrossedIcon className={'w-10 h-10 text-notino-gray'} strokeWidth={2.5} />
			</div>
			<span className={'text-notino-grayDark font-medium text-xs text-center w-full'}>{t('loc:Nemáte žiadne notifikácie.')}</span>
		</div>
	)
}

export default React.memo(EmptyNotification)
