/* eslint-disable import/no-cycle */
import { RESET_STORE } from '../generalTypes'
import { ILoadingAndFailure } from '../../types/interfaces'
import { DYNAMIC_NOTIFICATION, DYNAMIC_NOTIFICATIONS } from './dynamicNotificationsTypes'
import { IDynamicNotificationPayload, IDynamicNotificationsActions, IDynamicNotificationsPayload } from './dynamicNotificationsActions'

export const initState = {
	dynamicNotifications: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IDynamicNotificationsPayload & ILoadingAndFailure,
	dynamicNotification: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IDynamicNotificationPayload & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: IDynamicNotificationsActions) => {
	switch (action.type) {
		case DYNAMIC_NOTIFICATIONS.DYNAMIC_NOTIFICATIONS_LOAD_START:
			return {
				...state,
				dynamicNotifications: {
					...state.dynamicNotifications,
					isLoading: true
				}
			}
		case DYNAMIC_NOTIFICATIONS.DYNAMIC_NOTIFICATIONS_LOAD_FAIL:
			return {
				...state,
				dynamicNotifications: {
					...initState.dynamicNotifications,
					isFailure: true
				}
			}
		case DYNAMIC_NOTIFICATIONS.DYNAMIC_NOTIFICATIONS_LOAD_DONE:
			return {
				...state,
				dynamicNotifications: {
					...initState.dynamicNotifications,
					data: action.payload.data
				}
			}
		case DYNAMIC_NOTIFICATION.DYNAMIC_NOTIFICATION_LOAD_START:
			return {
				...state,
				dynamicNotification: {
					...state.dynamicNotification,
					isLoading: true
				}
			}
		case DYNAMIC_NOTIFICATION.DYNAMIC_NOTIFICATION_LOAD_FAIL:
			return {
				...state,
				dynamicNotification: {
					...initState.dynamicNotification,
					isFailure: true
				}
			}
		case DYNAMIC_NOTIFICATION.DYNAMIC_NOTIFICATION_LOAD_DONE:
			return {
				...state,
				dynamicNotification: {
					...initState.dynamicNotification,
					data: action.payload.data
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
