import { z } from 'zod'
import { serializeValidationMessage, stringConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH, CHANGELOG_PLATFORM } from '../utils/enums'
import { versionRegex } from '../utils/regex'

export const mainFeature = z.object({
	name: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_50),
	description: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255)
})

export const otherFeatures = z.object({
	description: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1000)
})

export const changelogSchema = z
	.object({
		platform: z.nativeEnum(CHANGELOG_PLATFORM),
		version: z.string().regex(versionRegex, serializeValidationMessage('loc:Zadajte platný formát verzie, napr. 1.0')),
		mainFeatures: z.array(mainFeature).min(1),
		otherFeatures
	})
	.superRefine((values, ctx) => {
		values.mainFeatures.forEach((feature, index) => {
			if ((values.mainFeatures.length <= 1 && !feature.name) || (values.mainFeatures.length > 1 && feature.description && !feature.name)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['mainFeatures', index, 'name']
				})
			}
		})
	})
