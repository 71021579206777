import { z } from 'zod'
import { localizedValuesConstraint, imageConstraint, serializeValidationMessage } from './baseSchema'
import { VALIDATION_MAX_LENGTH } from '../utils/enums'

// eslint-disable-next-line import/prefer-default-export
export const categorySchema = z
	.object({
		nameLocalizations: localizedValuesConstraint(true),
		descriptionLocalizations: localizedValuesConstraint(false, VALIDATION_MAX_LENGTH.LENGTH_1500).nullish(),
		level: z.union([z.literal(0), z.literal(1), z.literal(2)]),
		image: imageConstraint.array().max(1).nullish(),
		icon: imageConstraint.array().max(1).nullish()
	})
	.superRefine((values, ctx) => {
		// root category requires image and icon
		if (values.level === 0) {
			if (!values.image) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['image']
				})
			}

			if (!values.icon) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['icon']
				})
			}
		}
	})
