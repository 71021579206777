/* eslint-disable import/no-cycle */
import { IResetStore } from '../generalTypes'

// types
import { CONFIG } from './configTypes'
import { ThunkResult } from '../index'
import { IConfigPayload, SelectOptionItemWithImage } from '../../types/interfaces'

// utils
import { getReq } from '../../utils/request'

export type IConfigActions = IResetStore | IGetConfig

interface IGetConfig {
	type: CONFIG
	payload: IConfigPayload
}

export const getConfig = (): ThunkResult<Promise<IConfigPayload>> => async (dispatch) => {
	let payload = {} as IConfigPayload

	try {
		dispatch({ type: CONFIG.CONFIG_LOAD_START })
		const { data } = await getReq('/api/b2b/admin/config/', { params: {}, reqBody: {} })

		const optionsPhonePrefixesAll: SelectOptionItemWithImage[] = []
		const optionsPhonePrefixesRollout: SelectOptionItemWithImage[] = []
		const optionsRolloutCountries: SelectOptionItemWithImage[] = []
		const optionsAllCountries: SelectOptionItemWithImage[] = []
		const reservationCancellationQuickFeedbackAnswerOptions: SelectOptionItemWithImage[] = []

		data.allCountries.forEach((item) => {
			optionsAllCountries.push({
				key: item.code,
				label: item.name || item.code,
				value: item.code,
				extra: {
					image: item.flag
				}
			})

			optionsPhonePrefixesAll.push({
				key: item.code,
				label: item.phonePrefix,
				value: item.code,
				extra: {
					image: item.flag
				}
			})
		})
		data.reservationCancellationQuickFeedbackAnswers.forEach((item) => {
			reservationCancellationQuickFeedbackAnswerOptions.push({
				key: item.code,
				label: item.name,
				value: item.code
			})
		})
		data.rolloutCountries.forEach((item) => {
			optionsRolloutCountries.push({
				key: item.code,
				label: item.name || item.code,
				value: item.code,
				extra: {
					image: item.flag
				}
			})

			optionsPhonePrefixesRollout.push({
				key: item.code,
				label: item.phonePrefix,
				value: item.code,
				extra: {
					image: item.flag
				}
			})
		})

		payload = { data, optionsPhonePrefixesAll, optionsRolloutCountries, optionsAllCountries, optionsPhonePrefixesRollout, reservationCancellationQuickFeedbackAnswerOptions }
		dispatch({ type: CONFIG.CONFIG_LOAD_DONE, payload })
	} catch (err) {
		dispatch({ type: CONFIG.CONFIG_LOAD_FAIL })
		// eslint-disable-next-line no-console
		console.error(err)
	}

	return payload
}
