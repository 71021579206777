import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Spin } from 'antd'
import { useNavigate } from 'react-router'
import { ColumnsType } from 'antd/es/table'
import { useDispatch, useSelector } from 'react-redux'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import CustomTable from '../../components/CustomTable'

// utils
import { formatDateTimeByLocale } from '../../utils/intl'
import { DATA_LAYER_EVENTS, GA_SCREEN_CLASS, GA_SCREEN_NAME } from '../../utils/dataLayerEnums'
import { pushEventToDataLayer } from '../../utils/dataLayer'

// assets
import DocumentIcon from '../../assets/icons/document-icon.svg?react'

// types
import { IBreadcrumbs, MyDocumentDetail } from '../../types/interfaces'
import { RootState } from '../../reducers'

// redux
import { getUserDocuments } from '../../reducers/users/userActions'
import { CYPRESS_CLASS_NAMES } from '../../utils/enums'

// hooks
import usePushScreenInfoToDataLayer from '../../hooks/usePushScreenInfoToDataLayer'

const commonBadgeStyles = 'text-xs leading-4 font-medium h-6 px-2 inline-flex items-center truncate rounded-full'

const MyDocumentsPage = () => {
	const [t] = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const authUser = useSelector((state: RootState) => state.user.authUser)
	const userDocuments = useSelector((state: RootState) => state.user.userDocuments)

	// Google Analytics initial events
	usePushScreenInfoToDataLayer(GA_SCREEN_NAME.DOCUMENTS, GA_SCREEN_CLASS.DOCUMENTS)

	useEffect(() => {
		if (authUser?.data?.id) {
			dispatch(getUserDocuments(authUser.data.id))
		}
	}, [dispatch, authUser?.data?.id])

	const columns: ColumnsType<MyDocumentDetail> = useMemo(
		() => [
			{
				title: t('loc:Názov dokumentu'),
				dataIndex: 'name',
				key: 'name',
				width: '40%',
				ellipsis: true,
				render: (value) => {
					return (
						<div className={'flex items-center gap-3'}>
							<DocumentIcon className={'text-notino-gray flex-shrink-0'} />
							<span className={'truncate font-semibold text-notino-black'}>{value}</span>
						</div>
					)
				}
			},
			{
				title: t('loc:Stav'),
				dataIndex: 'readAt',
				key: 'readAt',
				width: '40%',
				ellipsis: true,
				render: (_value, record) => {
					const isNew = !record.readAt

					return isNew ? (
						<span className={`${commonBadgeStyles} ${CYPRESS_CLASS_NAMES.MY_DOCUMENTS_NEW_BADGE} bg-notino-pink text-notino-white`}>{t('loc:Nové')}</span>
					) : (
						<span className={`${commonBadgeStyles} ${CYPRESS_CLASS_NAMES.MY_DOCUMENTS_READ_BADGE} bg-notino-grayLighter text-notino-gray-darker`}>
							{t('loc:Prečítané {{ dateTime }}', { dateTime: formatDateTimeByLocale(record.readAt) })}
						</span>
					)
				}
			},
			{
				title: t('loc:Platnosť od'),
				dataIndex: 'createdAt',
				key: 'createdAt',
				align: 'right',
				width: '20%',
				ellipsis: true,
				render: (_value, record) => {
					return <span className={'text-notino-grayDark'}>{formatDateTimeByLocale(record.createdAt, { timeStyle: null })}</span>
				}
			}
		],
		[t]
	)

	const breadcrumbs: IBreadcrumbs = {
		items: [{ name: t('loc:Dokumenty') }]
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} backButtonPath={t('paths:index')} />
			</Row>

			<div className='content-body medium no-padding overflow-hidden'>
				<Spin spinning={userDocuments.isLoading}>
					<CustomTable
						className='table-my-documents'
						columns={columns}
						dataSource={userDocuments.data?.documents}
						rowClassName={'clickable-row'}
						rowKey='id'
						onRow={(record) => ({
							onClick: () => {
								pushEventToDataLayer({
									event: DATA_LAYER_EVENTS.CLICK_BUTTON,
									button_name: 'open_document_detail',
									screen_name: GA_SCREEN_NAME.DOCUMENTS,
									is_document_unread: !record.readAt
								})

								navigate(t('paths:my-documents/{{documentID}}', { documentID: record.id }))
							}
						})}
						pagination={false}
					/>
				</Spin>
			</div>
		</>
	)
}

export default MyDocumentsPage
