/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react'
import { Empty, Modal, Select, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

// assets
import CloseIcon from '../../../../assets/icons/close-icon.svg?react'
import GlobeIcon from '../../../../assets/icons/globe-icon.svg?react'

// reducers
import { getSpecialistContact, getSpecialistContacts } from '../../../../reducers/specialistContacts/specialistContactsActions'

// types
import { RootState } from '../../../../reducers'

// utils
import { LOCALES } from '../../../../utils/enums'
import i18n from '../../../../utils/i18n'
import { getCountryPrefix, optionRenderWithImage } from '../../../../utils/helper'
import { getLanguageCode } from '../../../../utils/intl'

type Props = {
	visible: boolean
	onCancel: () => void
}

const { Option } = Select

const SpecialistModal = (props: Props) => {
	const { visible, onCancel } = props
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const specialistContacts = useSelector((state: RootState) => state.specialistContacts.specialistContacts)
	const specialistContact = useSelector((state: RootState) => state.specialistContacts.specialistContact)

	const config = useSelector((state: RootState) => state.config.config)
	const contactPhonePrefix = getCountryPrefix(config.data?.allCountries, specialistContact?.data?.phonePrefixCountryCode)

	const [view, setView] = useState<'empty' | 'default' | 'not_found'>()

	const isLoading = specialistContacts?.isLoading || specialistContact?.isLoading || config?.isLoading

	useEffect(() => {
		const fetchData = async () => {
			// if specialist concat is already selected, just set the view to default
			if (specialistContact.data?.id) {
				setView('default')
				return
			}

			// otherwise find the appropriate specialist concat based on current app language
			const specialistContactsData = await dispatch(getSpecialistContacts())

			if (isEmpty(specialistContactsData.data)) {
				setView('empty')
				return
			}

			const lng = getLanguageCode(i18n.resolvedLanguage, i18n.language)
			const langToCompare = LOCALES[lng]?.countryCode?.toLowerCase()
			const currentLngCountry = specialistContactsData?.data?.find((specialist) => specialist.country?.code?.toLowerCase() === langToCompare)

			if (currentLngCountry?.id) {
				await dispatch(getSpecialistContact(currentLngCountry?.id))
				setView('default')
				return
			}

			dispatch(getSpecialistContact())
			setView('not_found')
		}

		fetchData()
	}, [dispatch, specialistContact.data?.id])

	const handleCountryChange = async (item: any) => {
		await dispatch(getSpecialistContact(item.value))
		setView('default')
	}

	const specialistContent = () => {
		if (view === 'empty') {
			return (
				<div className={'w-full justify-center px-4'}>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('loc:Ľutujeme, momentálne nie sú k dispozícii žiadni špecialisti.')} />
				</div>
			)
		}

		return (
			<section className={'noti-specialist-contact-wrapper'}>
				<label htmlFor={'noti-specialist-select'} className={'block'}>
					{t('loc:Zvoľte si krajinu')}
				</label>
				<Select
					id={'noti-specialist-select'}
					onChange={handleCountryChange}
					value={{ value: specialistContact?.data?.id }}
					style={{ zIndex: 999 }}
					className={'noti-select-input w-full'}
					size={'large'}
					labelInValue
					popupClassName={'noti-select-dropdown dropdown-match-select-width'}
					getPopupContainer={() => document.querySelector('.noti-specialist-contact-wrapper') as HTMLElement}
				>
					{specialistContacts?.options?.map((option) => (
						<Option value={option.value} key={option.key}>
							{optionRenderWithImage(option, <GlobeIcon />)}
						</Option>
					))}
				</Select>
				{(() => {
					if (view === 'not_found') {
						return <p className={'my-5 text-center not-found-msg'}>{t('loc:Ľutujeme, pre zvolenú krajinu momentálne neexistuje žiadny špecialista')}</p>
					}
					if (view === 'default') {
						return (
							<div>
								<ul className={'noti-contact-list mb-0 mt-4'}>
									{contactPhonePrefix && specialistContact?.data?.phone && (
										<li className={'phone-list-item'}>
											<a href={`tel:${contactPhonePrefix}${specialistContact.data.phone}`}>{`${contactPhonePrefix} ${specialistContact.data.phone}`}</a>
										</li>
									)}
									{
										<li className={'email-list-item'}>
											{specialistContact?.data?.email ? (
												<a href={`mailto:${specialistContact?.data?.email}`}>{specialistContact?.data?.email}</a>
											) : (
												<i>{t('loc:Email nie je k dispozícii')}</i>
											)}
										</li>
									}
								</ul>
							</div>
						)
					}
					return null
				})()}
			</section>
		)
	}

	return (
		<Modal key={'noti-specialist'} open={visible} footer={null} closable={false} width={450} centered onCancel={onCancel}>
			<div className={'noti-modal-content noti-specialist-modal-content'}>
				<header>
					<button type={'button'} onClick={onCancel}>
						<CloseIcon />
					</button>
					<h1>{t('loc:Notino Špecialista')}</h1>
				</header>
				<Spin spinning={isLoading}>
					<main>
						<p>{t('loc:Neviete vyplniť svoj profil salónu? Nedarí sa vám spraviť pekné fotky?')}</p>
						<p>
							<strong>{t('loc:Nevadí, radi vám s tým pomôžeme. Ak využijete naše služby, získate')}</strong>
						</p>
						<ul className={'what-you-get-list'}>
							<li>{t('loc:profesionálne vyplnené údaje o salóne,')}</li>
							<li>{t('loc:zabezpečíme fotografa, ktorý spraví profi fotografie vašich priestorov a tímu,')}</li>
							<li>{t('loc:nami vyplnený profil salónu automaticky schválime')}</li>
						</ul>
						<p>
							{t('loc:Cena')}: {t('loc:zdarma')}
						</p>
						<div>
							<h2 className={'text-notino-black'}>{t('loc:Kontaktovať Špecialistu')}</h2>
							<div className={'ant-form-item mb-0 pb-0'}>{specialistContent()}</div>
						</div>
					</main>
				</Spin>
			</div>
		</Modal>
	)
}

export default SpecialistModal
