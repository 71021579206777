export enum NOTINO_DASHBOARD {
	NOTINO_DASHBOARD_LOAD_START = 'NOTINO_DASHBOARD_LOAD_START',
	NOTINO_DASHBOARD_LOAD_DONE = 'NOTINO_DASHBOARD_LOAD_DONE',
	NOTINO_DASHBOARD_LOAD_FAIL = 'NOTINO_DASHBOARD_LOAD_FAIL'
}

export enum RESERVATIONS_STATS {
	RESERVATIONS_STATS_LOAD_START = 'RESERVATIONS_STATS_LOAD_START',
	RESERVATIONS_STATS_LOAD_DONE = 'RESERVATIONS_STATS_LOAD_DONE',
	RESERVATIONS_STATS_LOAD_FAIL = 'RESERVATIONS_STATS_LOAD_FAIL'
}

export enum SALONS_ANNUAL_STATS {
	SALONS_ANNUAL_STATS_LOAD_START = 'SALONS_ANNUAL_STATS_LOAD_START',
	SALONS_ANNUAL_STATS_LOAD_DONE = 'SALONS_ANNUAL_STATS_LOAD_DONE',
	SALONS_ANNUAL_STATS_LOAD_FAIL = 'SALONS_ANNUAL_STATS_LOAD_FAIL'
}

export enum SALONS_MONTH_STATS {
	SALONS_MONTH_STATS_LOAD_START = 'SALONS_MONTH_STATS_LOAD_START',
	SALONS_MONTH_STATS_LOAD_DONE = 'SALONS_MONTH_STATS_LOAD_DONE',
	SALONS_MONTH_STATS_LOAD_FAIL = 'SALONS_MONTH_STATS_LOAD_FAIL'
}

export enum SALON_DASHBOARD {
	SALON_DASHBOARD_LOAD_START = 'SALON_DASHBOARD_LOAD_START',
	SALON_DASHBOARD_LOAD_DONE = 'SALON_DASHBOARD_LOAD_DONE',
	SALON_DASHBOARD_LOAD_FAIL = 'SALON_DASHBOARD_LOAD_FAIL'
}
