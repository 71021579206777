import React, { useEffect, useCallback } from 'react'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Spin } from 'antd'
import { getFormInitialValues, initialize } from 'redux-form'
import { useNavigate, useParams } from 'react-router-dom'
import { difference } from 'lodash'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import CategoryParamsForm from './components/CategoryParamsForm'
import { EMPTY_NAME_LOCALIZATIONS } from '../../components/LanguagePicker'

// redux
import { getCategoryParameter } from '../../reducers/categoryParams/categoryParamsActions'

// utils
import { withPermissions } from '../../utils/Permissions'
import { PERMISSION, FORM, PARAMETERS_VALUE_TYPES, PARAMETERS_UNIT_TYPES } from '../../utils/enums'
import { normalizeNameLocalizations } from '../../utils/helper'
import { patchReq, deleteReq, putReq } from '../../utils/request'

// types
import { IBreadcrumbs, ICategoryParamsForm, PatchUrls, PutUrls, RequestPayload } from '../../types/interfaces'
import { RootState } from '../../reducers'

// hooks
import useBackUrl from '../../hooks/useBackUrl'

type PutValuesRequestBody = RequestPayload<PutUrls['/api/b2b/admin/enums/category-parameters/{categoryParameterID}/values/']>
type PatchParameterRequestBody = RequestPayload<PatchUrls['/api/b2b/admin/enums/category-parameters/{categoryParameterID}']>

const EditCategoryParamsPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { parameterID } = useParams<Required<{ parameterID: string }>>()

	const parameter = useSelector((state: RootState) => state.categoryParams.parameter)
	const initFormValues = useSelector((state: RootState) => getFormInitialValues(FORM.CATEGORY_PARAMS)(state)) as ICategoryParamsForm | undefined

	const [backUrl] = useBackUrl(t('paths:category-parameters'))

	const fetchData = useCallback(async () => {
		const { data } = await dispatch(getCategoryParameter(parameterID as string))
		if (!data?.id) {
			navigate('/404')
		} else {
			dispatch(
				initialize(FORM.CATEGORY_PARAMS, {
					valueType: data.valueType,
					nameLocalizations: normalizeNameLocalizations(data.nameLocalizations),
					localizedValues:
						data.valueType === PARAMETERS_VALUE_TYPES.TIME
							? [{ valueLocalizations: EMPTY_NAME_LOCALIZATIONS }]
							: data.values.map((item) => ({ valueLocalizations: normalizeNameLocalizations(item.valueLocalizations || []), id: item.id })),
					values:
						data.valueType === PARAMETERS_VALUE_TYPES.ENUM
							? [{ value: null }]
							: data.values.map((item) => ({
									value: Number(item.value),
									id: item.id
								}))
				})
			)
		}
	}, [dispatch, navigate, parameterID])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const handleSubmit = async (formData: ICategoryParamsForm) => {
		let values = []
		let unitType: PARAMETERS_UNIT_TYPES | undefined

		if (formData.valueType === PARAMETERS_VALUE_TYPES.TIME) {
			unitType = PARAMETERS_UNIT_TYPES.MINUTES
			values = formData.values
		} else {
			values = formData.localizedValues
		}

		const categoryParameterValues: Partial<PutValuesRequestBody['categoryParameterValues']> = []

		values.forEach((value) => {
			if (unitType === PARAMETERS_UNIT_TYPES.MINUTES && 'value' in value && value.value) {
				categoryParameterValues.push({ value: value.value.toString(), categoryParameterValueID: value.id || undefined })
			}

			if (unitType !== PARAMETERS_UNIT_TYPES.MINUTES && 'valueLocalizations' in value) {
				const valueLocalizations = value.valueLocalizations.filter(
					(localization) => !!localization.value
				) as PutValuesRequestBody['categoryParameterValues'][0]['valueLocalizations']

				if (valueLocalizations?.length) {
					categoryParameterValues.push({
						categoryParameterValueID: value.id || undefined,
						valueLocalizations
					})
				}
			}
		})

		try {
			const reqBody: PatchParameterRequestBody = {
				nameLocalizations: formData.nameLocalizations.reduce<PatchParameterRequestBody['nameLocalizations']>((acc, nameLocalization) => {
					if (!nameLocalization.value) {
						return acc
					}

					return [...acc, nameLocalization as PatchParameterRequestBody['nameLocalizations'][0]]
				}, []),
				valueType: formData.valueType,
				unitType
			}
			const hasDirtyParameterData = difference(formData.nameLocalizations, initFormValues?.nameLocalizations || []).length || initFormValues?.valueType !== formData.valueType
			let hasDirtyValuesData = false
			if (unitType === PARAMETERS_UNIT_TYPES.MINUTES) {
				hasDirtyValuesData = JSON.stringify(formData.values) !== JSON.stringify(initFormValues?.values)
			} else {
				hasDirtyValuesData = JSON.stringify(formData.localizedValues) !== JSON.stringify(initFormValues?.localizedValues)
			}
			if (hasDirtyParameterData) {
				await patchReq('/api/b2b/admin/enums/category-parameters/{categoryParameterID}', { params: { path: { categoryParameterID: parameterID as string } }, reqBody })
			}
			if (hasDirtyValuesData) {
				await putReq('/api/b2b/admin/enums/category-parameters/{categoryParameterID}/values/', {
					params: { path: { categoryParameterID: parameterID as string } },
					reqBody: { categoryParameterValues: categoryParameterValues as PutValuesRequestBody['categoryParameterValues'] }
				})
			}

			fetchData()
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
	}

	const handleDelete = async () => {
		try {
			await deleteReq('/api/b2b/admin/enums/category-parameters/{categoryParameterID}', { params: { path: { categoryParameterID: parameterID as string } }, reqBody: {} })
			navigate(t('paths:category-parameters'))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
	}

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam parametrov kategórií'),
				link: backUrl
			},
			{
				name: t('loc:Upraviť parameter kategórie')
			}
		]
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} backButtonPath={t('paths:index')} />
			</Row>
			<div className='content-body small'>
				<Spin spinning={parameter.isLoading}>
					<CategoryParamsForm onSubmit={handleSubmit} onDelete={handleDelete} />
				</Spin>
			</div>
		</>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO, PERMISSION.CATEGORY_PARAMETER_EDIT]))(EditCategoryParamsPage)
