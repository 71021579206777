import { z } from 'zod'
import { isNil } from 'lodash'

import { serializeValidationMessage, stringConstraint, twoCharsConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH, SALON_SOURCE_TYPE } from '../utils/enums'

// eslint-disable-next-line import/prefer-default-export
export const rechargeSmsCreditSchema = z.object({
	amount: z.number().positive().finite(),
	transactionNote: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255)
})

export const rechargeSmsCreditFilterSchema = z
	.object({
		walletAvailableBalanceFrom: z.number().finite().min(0).nullish(),
		walletAvailableBalanceTo: z.number().finite().min(0).nullish()
	})
	.superRefine((values, ctx) => {
		if (
			!isNil(values?.walletAvailableBalanceFrom) &&
			!isNil(values?.walletAvailableBalanceTo) &&
			(values?.walletAvailableBalanceFrom || 0) > (values?.walletAvailableBalanceTo || 0)
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Stav konta “OD” musí byť menší alebo rovnaký ako stav konta ”DO”'),
				path: ['walletAvailableBalanceFrom']
			})
		}
	})
	.and(
		z.object({
			search: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255),
			countryCode: twoCharsConstraint.optional(),
			sourceType: z.nativeEnum(SALON_SOURCE_TYPE).nullish()
		})
	)
