import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import { change, Field } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

// atoms
import InputField from '../atoms/InputField'
import PhonePrefixField from '../atoms/PhonePrefixField'

// reducers
import { RootState } from '../reducers'

// utils
import { validationPhone } from '../utils/helper'
import { FORM, SET_OF_COUNTRIES } from '../utils/enums'

type Props = {
	formName?: FORM
	placeholder?: string
	fallbackDefaultValue?: string
	label?: string
	size?: string
	prefixName?: string
	phoneName?: string
	required?: boolean
	disabled?: boolean
	className?: string
	style?: React.CSSProperties
	focused?: boolean
	getPopupContainer?: Function
	readOnly?: boolean
	allowClear?: boolean
	toCheck?: boolean
	countries?: SET_OF_COUNTRIES
}

const PhoneWithPrefixField = (props: Props) => {
	const {
		placeholder,
		label,
		size,
		prefixName = 'phonePrefixCountryCode',
		phoneName = 'phone',
		disabled,
		required = false,
		className,
		style,
		formName,
		fallbackDefaultValue,
		getPopupContainer,
		readOnly,
		allowClear,
		toCheck,
		countries = SET_OF_COUNTRIES.ALL
	} = props

	const dispatch = useDispatch()

	const config = useSelector((state: RootState) => state.config.config)

	useEffect(() => {
		if (formName && prefixName && fallbackDefaultValue) {
			dispatch(change(formName, prefixName, fallbackDefaultValue))
		}
	}, [dispatch, formName, prefixName, fallbackDefaultValue])

	return (
		<Row wrap={false} className={className} style={style}>
			<Col>
				<Field
					label={label}
					component={PhonePrefixField}
					name={prefixName}
					allowClear={allowClear}
					size={size}
					options={countries === SET_OF_COUNTRIES.ALL ? config.optionsPhonePrefixesAll : config.optionsPhonePrefixesRollout}
					loading={config.isLoading}
					defaultValue={fallbackDefaultValue}
					required={required}
					readOnly={readOnly}
					disabled={disabled}
					getPopupContainer={getPopupContainer}
					className='phone-prefix'
					toCheck={toCheck}
					showSearch
					optionFilterProp={'label'}
				/>
			</Col>
			<Col flex='auto'>
				<Field
					className={label ? 'hidden-label' : undefined}
					label={label}
					readOnly={readOnly}
					component={InputField}
					placeholder={placeholder}
					name={phoneName}
					size={size}
					disabled={disabled}
					validate={validationPhone}
					toCheck={toCheck}
				/>
			</Col>
		</Row>
	)
}

export default PhoneWithPrefixField
