import dayjs from 'dayjs'

// types
import { GET_ASYNC_JOB_STATE, CLEAR_ASYNC_JOB } from './asyncJobsTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'

// utils
import { getReq } from '../../utils/request'
import { AsyncJob } from '../../types/interfaces'
import { ASYNC_JOB_STATE, ASYNC_JOB_TYPE } from '../../utils/enums'
import { isEnumValue } from '../../utils/intl'
import { IResetStore } from '../generalTypes'

export type IAsyncJobsActions = IResetStore | IGetAsyncJobState | IClearAsyncJob

interface IGetAsyncJobState {
	type: GET_ASYNC_JOB_STATE
	payload: AsyncJob
}

interface IClearAsyncJob {
	type: typeof CLEAR_ASYNC_JOB
	payload: IClearAsyncJobPayload
}

export interface IClearAsyncJobPayload {
	jobKey: string
}

const generateJobKey = (jobID: string, jobType: ASYNC_JOB_TYPE) => `${jobType}_${jobID}`

export const getAsyncJobState =
	(jobID: string, jobType: ASYNC_JOB_TYPE): ThunkResult<Promise<AsyncJob>> =>
	async (dispatch) => {
		let payload: AsyncJob = {
			/**
			 * jobID is uniq only within jobType, so we need to create an uniq identifier for the async job
			 */
			jobKey: generateJobKey(jobID, jobType),
			jobID,
			jobState: ASYNC_JOB_STATE.IN_PROGRESS,
			progressPercentage: 0,
			jobType,
			startDateTime: dayjs().toString()
		}
		try {
			dispatch({ type: GET_ASYNC_JOB_STATE.GET_ASYNC_JOB_STATE_START, payload })

			const { data } = await getReq('/api/b2b/admin/scheduler/jobs/{jobID}/state', {
				params: { path: { jobID }, query: { queue: jobType } },
				reqBody: {},
				customConfig: { allowAbort: true, abortSignalKey: payload.jobKey, displayNotification: false }
			})

			if (!isEnumValue(data.jobState, ASYNC_JOB_STATE)) {
				throw new Error(`${data.jobState} - Unsupported job state type`)
			}

			payload = {
				...payload,
				jobState: data.jobState,
				progressPercentage: data.progressPercentage
			}

			dispatch({ type: GET_ASYNC_JOB_STATE.GET_ASYNC_JOB_STATE_DONE, payload })
		} catch (err) {
			dispatch({
				type: GET_ASYNC_JOB_STATE.GET_ASYNC_JOB_STATE_FAIL,
				payload: {
					...payload,
					jobState: ASYNC_JOB_STATE.COULD_NOT_VERIFY
				}
			})
			// eslint-disable-next-line no-console
			console.error(err)
		}
		return payload
	}

export const clearAsyncJob =
	(jobID: string, jobType: ASYNC_JOB_TYPE): ThunkResult<void> =>
	(dispatch) => {
		const payload: IClearAsyncJobPayload = { jobKey: generateJobKey(jobID, jobType) }
		dispatch({ type: CLEAR_ASYNC_JOB, payload })
	}
