import React, { useEffect, useMemo, useRef } from 'react'
import { WrappedFieldProps, autofill, change } from 'redux-form'
import { isEmpty } from 'lodash'
import cx from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form, Upload, UploadProps } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { UploadChangeParam } from 'antd/lib/upload'
import { FormItemProps } from 'antd/lib/form/FormItem'

// utils
import { UPLOAD_IMG_CATEGORIES, UPLOAD_IN_PROGRESS_PROP, MSG_TYPE, URL_UPLOAD_FILE } from '../utils/enums'
import { formFieldID, formatFileFormValues, getMaxSizeNotifMessage, showNotifications } from '../utils/helper'
import { uploadFiles } from '../utils/request'

// assets
import UploadIcon from '../assets/icons/upload-icon.svg?react'

// types
import { FileUploadFieldValueType, FileUploadParam, SignUrlType } from '../types/interfaces'

const { Item } = Form

type Props = WrappedFieldProps &
	FormItemProps &
	UploadProps & {
		category: UPLOAD_IMG_CATEGORIES
		// manualUpload - len zobrazi nahrate subory z pocitaca, o upload je potrebne sa postarat mimo komponentu, len pre FE nahravanie
		manualUpload?: boolean
		// /** Max file size in Bytes */
		maxFileSize: number
		signUrl: SignUrlType
		onUploaderClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	}

const FileUploadField = (props: Props) => {
	const {
		label,
		input,
		required,
		meta: { error, touched, form },
		accept,
		maxFileSize,
		manualUpload,
		disabled,
		className,
		multiple,
		maxCount,
		category,
		signUrl = URL_UPLOAD_FILE,
		onUploaderClick
	} = props

	const [t] = useTranslation()
	const dispatch = useDispatch()

	const value: FileUploadFieldValueType[] = useMemo(() => input.value || [], [input.value])

	const fileRef = useRef<FileUploadParam>({})

	useEffect(() => {
		/**
		 * If the user refreshes the page while files are still uploading, it's necessary to clear the files from the form that have not yet been uploaded
		 */
		const onBeforeUnload = () => {
			if (manualUpload) {
				return
			}

			input.onChange(value.filter((file) => file.id))
			dispatch(autofill(form, UPLOAD_IN_PROGRESS_PROP, undefined))
		}

		window.addEventListener('beforeunload', onBeforeUnload)

		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload)
		}
	}, [input, value, dispatch, form, manualUpload])

	const onChange = async (info: UploadChangeParam<UploadFile<any>>) => {
		if (info.file.status === 'error') {
			// NOTE: if uploaded file has a bad format (eg. txt)
			showNotifications(info.file.response?.messages)
			// uploading finished with error -> remove UPLOAD_IN_PROGRESS_PROP from bodyForm
			dispatch(autofill(form, UPLOAD_IN_PROGRESS_PROP, undefined))
		}

		if (info.file.status === 'done' || info.file.status === 'removed') {
			const values = formatFileFormValues(info.fileList, fileRef.current)
			input.onChange(values)

			// uploading is done -> remove UPLOAD_IN_PROGRESS_PROP from bodyForm
			dispatch(autofill(form, UPLOAD_IN_PROGRESS_PROP, undefined))
		}

		if (info.file.status === 'uploading') {
			input.onChange(info.fileList)
		}

		if (isEmpty(info.fileList)) {
			input.onChange([])
		}
	}

	const showUploadList = {
		showRemoveIcon: true,
		showPreviewIcon: false
	}

	const uploader = (
		<Upload
			className={'-mb-2 min-h-[114px] min-w-[114px]'}
			accept={accept}
			disabled={disabled}
			onChange={onChange}
			customRequest={(options) => {
				dispatch(change(form, UPLOAD_IN_PROGRESS_PROP, true))
				// BE upload ktory podpise url na s3 - funguje aj multiple
				uploadFiles(options, signUrl, category, fileRef)
			}}
			beforeUpload={(file, fileList) => {
				if (accept) {
					const acceptTypes = accept.split(',')

					if (!acceptTypes.includes(file.type)) {
						showNotifications([
							{
								type: MSG_TYPE.ERROR,
								message: (
									<Trans
										defaults={t('loc:Súbor <strong>{{fileName}}</strong> musí byť jeden z podporovaných typov {{accept}}')}
										components={{ strong: <strong /> }}
										values={{ fileName: file.name, accept: acceptTypes.join(', ') }}
									/>
								)
							}
						])
						return Upload.LIST_IGNORE
					}
				}

				if (file.size >= maxFileSize) {
					const messages = [getMaxSizeNotifMessage(maxFileSize, file.name)]
					showNotifications(messages)
					return Upload.LIST_IGNORE
				}

				if (maxCount !== undefined && fileList.length > maxCount) {
					const { uid: uidCurrent } = file
					const { uid: uidLast } = fileList[fileList.length - 1]
					if (uidCurrent === uidLast) showNotifications([{ type: MSG_TYPE.ERROR, message: t('loc:Nahrajte maximálne {{maxCount}} súborov', { maxCount }) }])
					return Upload.LIST_IGNORE
				}

				if (manualUpload) {
					input.onChange(fileList)
					return false
				}

				return true
			}}
			showUploadList={showUploadList}
			fileList={value as UploadFile[]}
			listType='picture-card'
			id={formFieldID(form, input.name)}
			multiple={multiple}
			maxCount={maxCount}
		>
			{((maxCount !== undefined && value.length < maxCount) || maxCount === undefined) && (
				<button type='button' disabled={disabled} onClick={onUploaderClick} className={'border-0 outline-none bg-transparent'}>
					<UploadIcon className={`text-xl mx-auto ${touched && error ? 'text-red-600' : 'text-gray-600'}`} />
					<div className={`text-sm upload-input ${touched && error ? 'text-red-600' : 'text-gray-600'}`}>{manualUpload ? t('loc:Vybrať') : t('loc:Nahrať')}</div>
				</button>
			)}
		</Upload>
	)

	return (
		<Item
			className={cx(className, 'file-upload-field', { 'hide-overlay': disabled })}
			label={label}
			required={required}
			style={{ width: '100%' }}
			help={touched && error}
			validateStatus={touched && error ? 'error' : undefined}
		>
			{uploader}
		</Item>
	)
}
export default React.memo(FileUploadField)
