import { IResetStore } from '../generalTypes'

// types
import { SYSTEM_ROLES, SALON_ROLES } from './rolesTypes'
import { GetUrls, IRoleDescription, ISelectOptionItem, RequestResponse } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'

// utils
import { getReq } from '../../utils/request'

export type IRolesActions = IResetStore | IGetSystemRoles | IGetSalonRoles

interface IGetSystemRoles {
	type: SYSTEM_ROLES
	payload: IRolesPayload
}

interface IGetSalonRoles {
	type: SALON_ROLES
	payload: IRolesPayload
}

export interface IRolesPayload {
	data: ISelectOptionItem[] | null
	rolesDescriptions: IRoleDescription[] | null
}

export type SalonRole = RequestResponse<GetUrls['/api/b2b/admin/roles/system-user']>['roles'][0]

export const getSystemRoles = (): ThunkResult<Promise<void>> => async (dispatch) => {
	try {
		dispatch({ type: SYSTEM_ROLES.SYSTEM_ROLES_LOAD_START })

		const { data } = await getReq('/api/b2b/admin/roles/system-user', { params: {}, reqBody: {} })

		const parsedData: ISelectOptionItem[] = []

		data.roles.forEach((role) => {
			parsedData.push({
				label: role?.name || '',
				value: role?.id,
				key: role?.id,
				extra: { permissions: role?.permissions }
			})
		})

		dispatch({ type: SYSTEM_ROLES.SYSTEM_ROLES_LOAD_DONE, payload: { data: parsedData, rolesDescriptions: null } })
	} catch (err) {
		dispatch({ type: SYSTEM_ROLES.SYSTEM_ROLES_LOAD_FAIL })
		// eslint-disable-next-line no-console
		console.error(err)
	}
}

export const getSalonRoles = (): ThunkResult<Promise<ISelectOptionItem[]>> => async (dispatch) => {
	const options: ISelectOptionItem[] = []
	const rolesDescriptions: IRoleDescription[] = []
	try {
		dispatch({ type: SALON_ROLES.SALON_ROLES_LOAD_START })
		const { data } = await getReq('/api/b2b/admin/roles/salon', { params: {}, reqBody: {} })

		data.roles.forEach((role) => {
			options.push({
				label: role.name || '',
				value: role.id,
				key: role.id,
				extra: { permissions: role.permissions }
			})

			rolesDescriptions.push({
				key: role.id,
				name: role.name || '',
				permissions: role.descriptions.map((description) => ({
					description: description.name,
					checked: description.activated
				}))
			})
		})

		dispatch({ type: SALON_ROLES.SALON_ROLES_LOAD_DONE, payload: { data: options, rolesDescriptions } })
	} catch (error) {
		dispatch({ type: SYSTEM_ROLES.SYSTEM_ROLES_LOAD_FAIL })
		// eslint-disable-next-line no-console
		console.error(error)
	}
	return options
}
