import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Spin, TableProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { compose } from 'redux'
import { useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'

// components
import CustomTable from '../../components/CustomTable'
import Breadcrumbs from '../../components/Breadcrumbs'
import AdminUsersFilter from './components/AdminUsersFilter'

// utils
import { FORM, PERMISSION, ROW_GUTTER_X_DEFAULT } from '../../utils/enums'
import { getLinkWithEncodedBackUrl, normalizeDirectionKeys, setOrder } from '../../utils/helper'
import Permissions, { withPermissions } from '../../utils/Permissions'

// reducers
import { getSystemRoles } from '../../reducers/roles/rolesActions'
import { getUsers, IUsersPayload } from '../../reducers/users/userActions'
import { RootState } from '../../reducers'

// types
import { IBreadcrumbs, IUsersFilter } from '../../types/interfaces'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// schema
import { usersPageURLQueryParams } from '../../schemas/queryParams'

type TableDataItem = NonNullable<IUsersPayload['data']>['users'][0]

const UsersPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const users = useSelector((state: RootState) => state.user.users)
	const optionsPhonePrefixesAll = useSelector((state: RootState) => state.config.config.optionsPhonePrefixesAll)
	const [prefixOptions, setPrefixOptions] = useState<{ [key: string]: string }>({})
	const navigate = useNavigate()

	const [query, setQuery] = useQueryParams(usersPageURLQueryParams, {
		page: 1,
		order: 'fullName:ASC'
	})

	useEffect(() => {
		dispatch(initialize(FORM.ADMIN_USERS_FILTER, { search: query.search, roleID: query.roleID }))
		dispatch(
			getUsers({
				page: query.page,
				limit: query.limit,
				order: query.order,
				search: query.search,
				roleID: query.roleID
			})
		)
	}, [dispatch, query.limit, query.order, query.page, query.roleID, query.search])

	useEffect(() => {
		const prefixes: { [key: string]: string } = {}
		dispatch(getSystemRoles())

		optionsPhonePrefixesAll.forEach((option) => {
			prefixes[option.key] = option.label
		})

		setPrefixOptions(prefixes)
	}, [optionsPhonePrefixesAll, dispatch])

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (_pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}`
			const newQuery = {
				...query,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === users?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}

	const handleSubmit = (values: IUsersFilter) => {
		const newQuery = {
			...query,
			...values,
			page: 1
		}
		setQuery(newQuery)
	}

	const columns: ColumnsType<TableDataItem> = [
		{
			title: t('loc:Meno'),
			dataIndex: 'fullName',
			key: 'fullName',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(query.order, 'fullName'),
			width: '20%',
			render: (_value, record) => {
				if (!record.firstName && !record.lastName) {
					return '-'
				}
				return (
					<>
						{record.firstName} {record.lastName}
					</>
				)
			}
		},
		{
			title: t('loc:Email'),
			dataIndex: 'email',
			key: 'email',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			width: '25%',
			sortOrder: setOrder(query.order, 'email')
		},
		{
			title: t('loc:Telefón'),
			dataIndex: 'phone',
			key: 'phone',
			ellipsis: true,
			sorter: false,
			width: '15%',
			render: (_value, record) => {
				const prefix = record.phonePrefixCountryCode ? prefixOptions[record.phonePrefixCountryCode] : ''
				return prefix && record.phone ? `${prefix} ${record.phone}` : '-'
			}
		},
		{
			title: t('loc:Rola'),
			dataIndex: 'roles',
			key: 'roleName',
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(query.order, 'roleName'),
			ellipsis: {
				showTitle: false
			},
			width: '15%',
			render(value: TableDataItem['roles']) {
				return value.map((role) => {
					return role?.name
				})
			}
		}
	]

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam používateľov')
			}
		]
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} backButtonPath={t('paths:index')} />
			</Row>
			<Row gutter={ROW_GUTTER_X_DEFAULT}>
				<Col span={24}>
					<div className='content-body'>
						<Spin spinning={users?.isLoading}>
							<Permissions
								allowed={[PERMISSION.USER_CREATE]}
								render={(hasPermission, { openForbiddenModal }) => (
									<AdminUsersFilter
										createUser={() => {
											if (hasPermission) {
												navigate(getLinkWithEncodedBackUrl(t('paths:users/create')))
											} else {
												openForbiddenModal()
											}
										}}
										onSubmit={handleSubmit}
									/>
								)}
							/>
							<CustomTable
								className='table-fixed'
								onChange={onChangeTable}
								columns={columns}
								dataSource={users?.data?.users}
								rowClassName={'clickable-row'}
								twoToneRows
								rowKey='id'
								scroll={{ x: 800 }}
								onRow={(record) => ({
									onClick: () => {
										navigate(getLinkWithEncodedBackUrl(t('paths:users/{{userID}}', { userID: record.id })))
									}
								})}
								useCustomPagination
								pagination={{
									pageSize: users?.data?.pagination?.limit,
									total: users?.data?.pagination?.totalCount,
									current: users?.data?.pagination?.page,
									onChange: onChangePagination,
									disabled: users?.isLoading
								}}
							/>
						</Spin>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default compose(withPermissions([PERMISSION.USER_BROWSING]))(UsersPage)
