// eslint-disable-next-line import/prefer-default-export
export enum SERVICES {
	SERVICES_LOAD_START = 'SERVICES_LOAD_START',
	SERVICES_LOAD_DONE = 'SERVICES_LOAD_DONE',
	SERVICES_LOAD_FAIL = 'SERVICES_LOAD_FAIL'
}

export enum SERVICE {
	SERVICE_LOAD_START = 'SERVICE_LOAD_START',
	SERVICE_LOAD_DONE = 'SERVICE_LOAD_DONE',
	SERVICE_LOAD_FAIL = 'SERVICE_LOAD_FAIL'
}

export enum SERVICE_COLORS {
	SERVICE_COLORS_LOAD_START = 'SERVICE_COLORS_LOAD_START',
	SERVICE_COLORS_LOAD_DONE = 'SERVICE_COLORS_LOAD_DONE',
	SERVICE_COLORS_LOAD_FAIL = 'SERVICE_COLORS_LOAD_FAIL'
}

export const SET_SERVICES_ACTIVE_KEYS = 'SET_SERVICES_ACTIVE_KEYS'
