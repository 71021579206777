import React from 'react'
import { WrappedFieldProps } from 'redux-form'
import dayjs, { Dayjs } from 'dayjs'
import cx from 'classnames'
import { FormItemProps } from 'antd/lib/form/FormItem'
import { Form, DatePicker, ConfigProvider } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'

// assets
import DateSuffixIcon from '../assets/icons/date-suffix-icon.svg?react'
import RemoveIcon from '../assets/icons/remove-select-icon.svg?react'

// utils
import { DEFAULT_DATE_INIT_FORMAT, DROPDOWN_POSITION } from '../utils/enums'
import { formFieldID } from '../utils/helper'

type Props = WrappedFieldProps &
	FormItemProps &
	Omit<DatePickerProps, 'clearIcon' | 'mode'> & {
		disableFuture?: boolean // for disable startDate from past
		disablePast?: boolean // for disable startDate from past
		hideHelp?: boolean
		/** disable od dátumu D1 do minulosti */
		compareFrom1?: Dayjs
		/** disable od dátumu D2 do minulosti */
		compareFrom2?: Dayjs
		/** disable od dátumu D1 do budúcnosti */
		compareTo1?: Dayjs
		rounded?: boolean
		readOnly?: boolean
		showToday?: boolean
		validateTo?: string /** disable podla datumu ktory sa posle na validovanie */
		pickerClassName?: string
		dateFormat?: string
		customOnChange?: (value: Dayjs | null) => void
	}

const DateField = (props: Props) => {
	const {
		input,
		label,
		required,
		style,
		meta: { form, error, touched },
		dateFormat,
		placeholder,
		disabledDate,
		disabled,
		allowClear,
		hideHelp,
		disableFuture,
		disablePast,
		getPopupContainer,
		compareFrom1,
		compareFrom2,
		compareTo1,
		size,
		rounded,
		readOnly,
		suffixIcon = <DateSuffixIcon className={'text-gray-600'} />,
		showNow = true,
		defaultValue,
		validateTo,
		open,
		className,
		pickerClassName,
		popupAlign,
		inputReadOnly,
		components,
		picker,
		customOnChange,
		onCalendarChange,
		popupClassName
	} = props

	let value

	if (input.value && dayjs(input.value).isValid()) {
		value = dayjs(input.value)
	}

	const { locale } = React.useContext(ConfigProvider.ConfigContext)

	const disabledDateWrap: Props['disabledDate'] = (currentDate, info) => {
		let disable = false
		if (disabledDate) {
			disable = disabledDate(currentDate, info)
		} else if (disableFuture) {
			disable = currentDate && currentDate > dayjs().endOf('day')
		} else if (disablePast) {
			disable = currentDate && currentDate < dayjs().startOf('day')
		}

		if (compareFrom1 || compareFrom2) {
			const dates = []
			if (compareFrom1) {
				dates.push(compareFrom1)
			}
			if (compareFrom2) {
				dates.push(compareFrom2)
			}
			const date = dayjs.max(dates)
			disable = currentDate.isBefore(date, 'date')
		}

		if (!disable && compareTo1) {
			if (disableFuture) {
				disable = currentDate && currentDate > dayjs().endOf('day')
			} else {
				disable = currentDate.isAfter(compareTo1, 'date')
			}
		}

		if (validateTo) {
			disable = currentDate.isBefore(validateTo)
		}

		return disable
	}

	const allowClearWrap = () => {
		if (typeof allowClear === 'object' && allowClear.clearIcon) {
			return allowClear
		}

		if (allowClear) {
			return {
				clearIcon: <RemoveIcon className={'text-notino-black'} />
			}
		}

		return false
	}

	return (
		<div id={formFieldID(form, input.name)}>
			<Form.Item
				label={label}
				required={required}
				style={style}
				help={hideHelp ? undefined : touched && error}
				className={cx({ 'form-item-disabled': disabled, readOnly }, className)}
				validateStatus={error && touched ? 'error' : undefined}
			>
				<DatePicker
					name={input.name}
					className={(cx('noti-date-input w-full', { 'rounded-full': rounded, 'allow-clear': allowClear }), pickerClassName)}
					popupAlign={popupAlign || DROPDOWN_POSITION.BOTTOM_LEFT}
					onBlur={() => {}}
					onChange={(val) => {
						if (customOnChange) {
							customOnChange(val)
						} else if (val) {
							input.onChange(val.format(DEFAULT_DATE_INIT_FORMAT))
						} else {
							input.onChange(null)
						}
					}}
					// NOTE: deprecated warning => sice pise ze by sa mal pouzit fieldDateFormat miesto dateFormat, avsak v locale sa taka hodnota nenachadza a potom to neformatuje spravne
					// takze zatial to ignorujeme
					format={dateFormat || locale?.DatePicker?.lang.dateFormat}
					value={value}
					defaultValue={defaultValue}
					size={size}
					suffixIcon={suffixIcon}
					placeholder={placeholder}
					disabledDate={disabledDateWrap}
					popupClassName={popupClassName}
					disabled={disabled}
					allowClear={allowClearWrap()}
					getPopupContainer={getPopupContainer || ((node) => node)}
					showNow={showNow}
					inputReadOnly={inputReadOnly}
					picker={picker}
					open={open}
					onCalendarChange={onCalendarChange}
					components={components}
				/>
			</Form.Item>
		</div>
	)
}

export default DateField
