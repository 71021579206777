import { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// types
import { useTranslation } from 'react-i18next'
import { RootState } from '../reducers'

// redux
import { selectSalon } from '../reducers/selectedSalon/selectedSalonActions'

// utils
import { checkPermissions } from '../utils/Permissions'
import { PERMISSION } from '../utils/enums'

const useInitializeSelectedSalon = (loadingUser: boolean) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [t] = useTranslation()
	const { salonID: salonIdUrlParams } = useParams()
	const salonIdLocalStorage = useSelector((state: RootState) => state.selectedSalon.selectedSalon.data?.id)

	const currentUser = useSelector((state: RootState) => state.user.authUser)
	const [initialLoading, setInitialLoading] = useState(true)

	useEffect(() => {
		;(async () => {
			if (loadingUser || !currentUser.data?.id || !initialLoading) {
				return
			}

			let salonID: string | undefined
			const isNotinoUser = checkPermissions(currentUser.data.uniqPermissions, [PERMISSION.NOTINO])

			if (salonIdUrlParams) {
				salonID = salonIdUrlParams
			} else if (salonIdLocalStorage) {
				salonID = salonIdLocalStorage
			}

			if (!salonID) {
				// NOTINO users
				if (isNotinoUser) {
					setInitialLoading(false)
					return
				}
				// PARTNER
				if (!currentUser.data.salons.length) {
					setInitialLoading(false)
					return
				}
				salonID = currentUser.data.salons[0].id
			}

			await dispatch(selectSalon(salonID))
			setInitialLoading(false)
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		t,
		// NOTE: do not include salonIdLocalStorage as useEffect dependency, because it causes to run selectSalon async function twice
		// we want to check salonIdLocalStorage only once in the salon initialization process to know if user is already working under some salon, but don't want to re-run the this effect if it changes
		// salonIdLocalStorage,
		salonIdUrlParams,
		currentUser.data?.id,
		currentUser.data?.salons,
		initialLoading,
		loadingUser,
		navigate,
		dispatch
	])

	return { initialSalonLoading: initialLoading }
}

export default useInitializeSelectedSalon
