export enum REVIEWS {
	REVIEWS_LOAD_START = 'REVIEWS_LOAD_START',
	REVIEWS_LOAD_DONE = 'REVIEWS_LOAD_DONE',
	REVIEWS_LOAD_FAIL = 'REVIEWS_LOAD_FAIL'
}

export enum SALON_REVIEWS {
	SALON_REVIEWS_LOAD_START = 'SALON_REVIEWS_LOAD_START',
	SALON_REVIEWS_LOAD_DONE = 'SALON_REVIEWS_LOAD_DONE',
	SALON_REVIEWS_LOAD_FAIL = 'SALON_REVIEWS_LOAD_FAIL'
}

export enum SALON_REVIEW {
	SALON_REVIEW_LOAD_START = 'SALON_REVIEW_LOAD_START',
	SALON_REVIEW_LOAD_DONE = 'SALON_REVIEW_LOAD_DONE',
	SALON_REVIEW_LOAD_FAIL = 'SALON_REVIEW_LOAD_FAIL'
}
