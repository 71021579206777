import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { change, FormAction, WrappedFieldProps } from 'redux-form'
import { useDispatch } from 'react-redux'
import cx from 'classnames'
import { debounce, get, isEmpty, isString, last, map, size as length, take } from 'lodash'
import i18next from 'i18next'

// ant
import { Button, Divider, Empty, Form, Popconfirm, Select, Spin, Tooltip } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { FormItemProps } from 'antd/lib/form/FormItem'

// icons
import ArrowIcon from '../assets/icons/select-arrow-icon.svg?react'
import CheckedIcon from '../assets/icons/check-icon.svg?react'
import CloseIconSmall from '../assets/icons/close-icon.svg?react'
import RemoveIcon from '../assets/icons/remove-select-icon.svg?react'
import PlusIcon from '../assets/icons/plus-icon.svg?react'

// utils
import { FIELD_MODE, FORM } from '../utils/enums'
import { createSlug, formFieldID, transformToLowerCaseWithoutAccent } from '../utils/helper'

// assets
import LoadingIcon from '../assets/icons/loading-icon.svg?react'
import { ILabelInValue, ISelectOptionItem, ISelectOptionItemWithChildren } from '../types/interfaces'

const { Item } = Form
const { Option } = Select

export const initLabelInValueSelect = <ValuePropsType extends string = string, ExtraType = any>({
	key,
	label,
	value,
	extra
}: {
	key: string
	label: string
	value: ValuePropsType
	extra?: ExtraType
}): ILabelInValue<ValuePropsType, ExtraType> => {
	return {
		key,
		value,
		label,
		extra
	}
}

type Action = {
	title: string
	icon?: ReactNode
	onAction: () => void
}

type ValueType = string | number | ILabelInValue

export type Props<ValuePropsType extends ValueType | ValueType[] | null | undefined = ValueType, OptionPropsType extends OptionType = OptionType> = {
	update?: (value: ValuePropsType, ref: any) => FormAction
	actions?: Action[] | null
	allowInfinityScroll?: boolean
	maxTagLength?: number
	fieldMode?: FIELD_MODE
	maxTagsLimit?: number
	backgroundColor?: string
	showErrorWhenUntouched?: boolean
	hideHelp?: boolean
	/** Klúč podľa ktorého sa vytiahnu dáta v onSearch */
	dataSourcePath?: string
	/** propa urcena predovsetkym pre filtre, kedy mozeme skopirovat URL na novy TAB
	 *  propa zabezpeci spravne initializovanie z query filtra a formu filtra (forcne dotiahnutie options dat pre select)
	 *  posielat len vtedy ak mame v selecte search a dotahujeme vsetky data (spravidla vtedy ked nie je BE vyhladavanie, alebo neexistuje paginacia)
	 */
	onDidMountSearch?: boolean
	/**
	 * Propa renderuje labels vo vnútri vstupného poľa
	 * Use case: po vybraní položky z dropdown chcem aby sa položka vyrenderovala do inputu nezávysle od toho ako sa renderuje v dropdowne
	 */
	renderInnerLabel?: (option: any, parentOpt: any) => ReactNode | string
	emptyText?: string
	itemRef?: any
	autoBlur?: boolean
	readOnly?: boolean
	disableTpStyles?: boolean // Vypne styly ktore dava classa noti-input ked je potrebne (obrazovka /vyhladavanie vo filtroch su pouzite ine styly pre selecty z global)
	disableMenuItemSelectedIcon?: boolean // niekedy tuto ikonu renderujeme nie cez propu ale cez position absolute a vtedy by sa tu dve zobrazovali lebo je || SearchIcon (vo filtroch pre vyhladavanie)
	optionRender?: (optionData: OptionPropsType) => React.ReactNode // custom render for item(option)
	formName?: FORM
	confirmSelection?: boolean
	confirmModalExtraTitle?: string
	tooltipSelect?: string | null
	/**
	 * niekedy moze nastat situacia, ze vyinicializovane hodnoty v selecte neexistuju v options (napr. pri asynchronnom vyhladavani)
	 * jedna moznost ako riesit tento scenar je pouzit labelInValue, kedy sa vyinicializuju hodnoty ako objekty a pre spravne zobrazenie nie je potrebne parovanie z options
	 * problem vsak moze nastat ak pouzivame 'optionRender', kedy vytvarame custom styly pre options. Ak chceme, aby sa custom styly pouzivane pre options aplikovali aj na vyinicializovane hodnoty, vtedy je potrebne, aby sa vyinicializovane hodnoty posielali ako IDcka a existovali k tomu aj options, s ktorymi sa potom sparuju
	 * options vytvorene pre inicializacne hodnoty mozeme poslat cez propu initialOptions - tie sa mergnu s ostatnymi options tak, aby nedoslo k duplicitam
	 */
	initialOptions?: OptionPropsType[]
	options?: OptionPropsType[]
	toCheck?: boolean
	customOnChange?: (val: ValuePropsType | null | undefined, opt: OptionPropsType | OptionPropsType[]) => void
} & WrappedFieldProps &
	Omit<SelectProps<ValuePropsType, OptionPropsType>, 'options' | 'optionRender'> &
	FormItemProps

type IPagination = {
	limit: number
	page: number
	totalPages: number
	totalCount: number
}

type OptionType<ExtraType = any> = ISelectOptionItem<ExtraType> | ISelectOptionItemWithChildren<ExtraType>

type SelectStateTypes<OptionPropsType extends OptionType = OptionType> = {
	data?: OptionPropsType[]
	fetching?: boolean
	searchValue?: string
	emptyText?: string | null
	pagination?: IPagination | null
}

const findOptionNode = <OptionPropsType extends OptionType = OptionType>(options: OptionPropsType[], value: string | number): OptionPropsType | null => {
	let option = null
	for (let i = 0; i < options.length; i += 1) {
		const currentOption = options[i]
		if ('children' in currentOption) {
			const optionNode = currentOption.children.find((opt) => opt.value === value)
			if (optionNode) {
				option = optionNode
				break
			}
		} else {
			const optionNode = currentOption.value === value
			if (optionNode) {
				option = currentOption
				break
			}
		}
	}
	return option as OptionPropsType
}

const setGetPopupContainer = (mode: Props['mode'], getPopupContainer: Props['getPopupContainer']) => {
	let popupContainer = (node: any) => node
	// ak je multiple alebo tags tak sa nastavuje pre .ant-select-selector overflow: auto, aby sa scrollovali selectnute multiple option v selecte preto sa nastavuje container na document.body (aby sa to vzdy z hora nemuselo posielat)
	if (mode === 'multiple' || mode === 'tags') {
		popupContainer = (node: any) => node.closest('.ant-drawer-body') || node.closest('.ant-modal-body') || document.body
	} else if (getPopupContainer) {
		// Ak existuje getPopupContainer nastav ho -> vacsinou v editovatelnych tabulkach sa posiela
		popupContainer = getPopupContainer
	}
	return popupContainer
}

const renderMenuItemSelectedIcon = (
	mode: Props['mode'],
	menuItemSelectedIcon: Props['menuItemSelectedIcon'],
	disableMenuItemSelectedIcon: Props['disableMenuItemSelectedIcon']
) => {
	// NOTE: menuItemSelectedIcon sa renderuje len ak je select typu tags / multiple alebo ak pretazim logiku a zhora ju poslem v prope menuItemSelectedIcon
	let icon: any
	if (menuItemSelectedIcon) {
		icon = menuItemSelectedIcon
	} else if (disableMenuItemSelectedIcon) {
		icon = null
	} else if ((mode === 'tags' || mode === 'multiple') && !disableMenuItemSelectedIcon) {
		icon = menuItemSelectedIcon || <CheckedIcon className={'medium-icon'} />
	}
	return icon
}

const getOptions = <ValuePropsType extends ValueType | ValueType[] | null | undefined = ValueType | null | undefined, OptionPropsType extends OptionType = OptionType>(
	options: OptionPropsType[],
	optionRender: Props<ValuePropsType, OptionPropsType>['optionRender']
) => {
	return options.map((option) => {
		// NOTE: ak option obsahuje pole children, tak to znamena ze ma vnorene options a bude sa pouzivat OptGroup a druha uroven ako Option
		if ('children' in option) {
			return (
				<Select.OptGroup key={option.key} label={option.label}>
					{option.children.map((childrenOpt) => {
						return (
							<Option
								key={childrenOpt.key}
								value={childrenOpt.value}
								disabled={childrenOpt.disabled}
								label={childrenOpt.label}
								className={childrenOpt.className}
								style={childrenOpt.level ? { paddingLeft: 16 * childrenOpt.level } : undefined}
							>
								{optionRender ? optionRender(childrenOpt as OptionPropsType) : childrenOpt.label}
							</Option>
						)
					})}
				</Select.OptGroup>
			)
		}
		return (
			<Option
				key={option.key}
				value={option.value}
				disabled={option.disabled}
				label={option.label}
				extra={option.extra}
				className={option.className}
				style={option.level ? { paddingLeft: 16 * option.level } : undefined}
			>
				{optionRender ? optionRender(option) : option.label}
			</Option>
		)
	})
}
const customDropdown = (actions: Action[] | null | undefined, menu: React.ReactElement, fetching: boolean | undefined) => {
	const divider = isEmpty(actions) ? null : <Divider style={{ margin: 0 }} />

	return (
		<Spin
			indicator={<LoadingIcon className={'loading-spinner text-notino-black'} />}
			className={'justify-start flex-center text-notino-black m-2-5'}
			tip={i18next.t('loc:Načítavam...')}
			spinning={fetching}
		>
			{menu}
			<div className={'w-11/12 m-auto'}>{divider}</div>
			{map(actions, (item, index) => (
				<div className={'flex items-center h-12'} key={index}>
					<Button
						key={item.title}
						type='link'
						size={'large'}
						htmlType='button'
						className={'noti-btn text-notino-pink'}
						icon={item.icon || <PlusIcon />}
						onClick={item.onAction}
					>
						{item.title}
					</Button>
				</div>
			))}
		</Spin>
	)
}

type HandleChangeData<ValuePropsType extends ValueType | ValueType[] | null | undefined = ValueType | null | undefined, OptionPropsType extends OptionType = OptionType> = {
	value: ValuePropsType
	antdOptions: OptionPropsType | OptionPropsType[]
	options: OptionPropsType[]
	autoBlur: Props<ValuePropsType, OptionPropsType>['autoBlur']
	input: Props<ValuePropsType, OptionPropsType>['input']
	maxTagLength: Props<ValuePropsType, OptionPropsType>['maxTagLength']
	maxTagsLimit: Props<ValuePropsType, OptionPropsType>['maxTagsLimit']
	mode: Props<ValuePropsType, OptionPropsType>['mode']
	update: Props<ValuePropsType, OptionPropsType>['update']
	customOnChange: Props<ValuePropsType, OptionPropsType>['customOnChange']
	itemRef: any
}

const handleChange = async <ValuePropsType extends ValueType | ValueType[] | null | undefined = ValueType | null | undefined, OptionPropsType extends OptionType = OptionType>(
	data: HandleChangeData<ValuePropsType, OptionPropsType>
) => {
	const { value, options, antdOptions, autoBlur, input, itemRef, maxTagLength, maxTagsLimit, mode, update, customOnChange } = data
	let val: ValueType | ValueType[] | null | undefined = value
	// NOTE condition for checking if select field has 'tags' mode with maxTagLength prop for checking length string of added tag
	// if input value's length is larger than maxTagLength, filter this value from tags
	if (mode === 'tags' && maxTagLength && Array.isArray(value) && length(last(value)) > maxTagLength) {
		val = value.filter((_v, i) => i !== value.length - 1)
	}
	if ((mode === 'tags' || mode === 'multiple') && Array.isArray(val)) {
		val = val.map((valInput) => {
			if (typeof valInput === 'object') {
				const nodeFromOptions = findOptionNode(options, valInput?.value)
				return initLabelInValueSelect({
					key: valInput.key,
					value: valInput.value,
					label: valInput.label,
					extra: nodeFromOptions && 'extra' in nodeFromOptions ? nodeFromOptions.extra : undefined
				})
			}
			return valInput
		})
	} else if (value && typeof value === 'object' && !Array.isArray(value)) {
		let extra
		// NOTE: ak je objekt tak antdOptions neni pole ale objekt
		if ('extra' in antdOptions) {
			extra = antdOptions.extra
		} else {
			// NOTE: v niektorych pripadoch Antd odfiltruje extra objekt z antdOptions
			const nodeFromOptions = findOptionNode(options, value.value)
			extra = nodeFromOptions && 'extra' in nodeFromOptions ? nodeFromOptions.extra : undefined
		}

		val = initLabelInValueSelect({
			key: value.key,
			value: value.value,
			label: 'label' in antdOptions && typeof antdOptions.label === 'string' ? antdOptions.label : value.label,
			extra
		})
	}
	if (maxTagsLimit && Array.isArray(val) && val.length > maxTagsLimit) {
		val = take(val, maxTagsLimit)
	}

	if (customOnChange) {
		customOnChange((val === undefined ? null : val) as ValuePropsType, antdOptions)
	} else {
		await input.onChange(val === undefined ? null : val)
	}
	if (update) {
		// NOTE: update prop for onSelect and onDeselect submitting form (eg. setting Tags)
		update(val as ValuePropsType, itemRef.current)
	}

	if (autoBlur && itemRef.current) {
		itemRef.current.blur()
	}
}

const fetchSearchData = async ({
	selectState,
	value,
	page,
	onSearch,
	dataSourcePath,
	allowInfinityScroll,
	missingValues
}: {
	selectState: SelectStateTypes
	value: string
	page: number
	onSearch: any
	dataSourcePath: string
	allowInfinityScroll: boolean | undefined
	missingValues: number[] // used in select with pagination (allowInfinityScroll) when not all options are loaded during initialization
}) => {
	let newState = {}
	try {
		let collectedData: OptionType[] = []
		if (page !== 1 && selectState.data) collectedData = selectState.data

		const newData: any = await onSearch(value, page, missingValues)
		const dataOptions = get(newData, dataSourcePath)
		if (newData.pagination || dataOptions) {
			const mergedData = [...collectedData, ...dataOptions]
			newState = { data: mergedData, pagination: newData.pagination, fetching: false }
		} else if (!allowInfinityScroll && Array.isArray(newData)) {
			// NOTE: Výsledky sa neodliepajú
			newState = { data: newData, fetching: false }
		} else {
			newState = {
				data: [],
				pagination: null,
				fetching: false,
				searchValue: ''
			}
		}
		if (newData.emptyText) {
			newState = {
				emptyText: newData.emptyText
			}
		}
	} catch (e) {
		newState = {
			data: [],
			pagination: null,
			fetching: false,
			searchValue: ''
		}
	}

	return newState
}

const SelectField = <ValuePropsType extends ValueType | ValueType[] | null | undefined = ValueType | null | undefined, OptionPropsType extends OptionType = OptionType>(
	props: Props<ValuePropsType, OptionPropsType>
) => {
	const {
		input,
		size,
		placeholder,
		label,
		required,
		meta,
		showErrorWhenUntouched,
		hideHelp,
		options: propsOptions,
		loading,
		mode,
		tagRender,
		allowClear,
		style,
		showSearch,
		filterOption,
		suffixIcon,
		labelInValue,
		actions,
		disabled,
		notFoundContent,
		removeIcon,
		allowInfinityScroll,
		defaultValue,
		backgroundColor,
		className,
		optionLabelProp,
		open,
		menuItemSelectedIcon,
		popupClassName,
		dropdownStyle,
		popupMatchSelectWidth = true,
		listHeight,
		emptyText,
		autoClearSearchValue,
		maxTagTextLength,
		showAction,
		getPopupContainer,
		disableMenuItemSelectedIcon,
		fieldMode = FIELD_MODE.INPUT,
		readOnly,
		autoFocus,
		optionRender,
		dataSourcePath = 'data',
		onDidMountSearch,
		update,
		maxTagLength,
		maxTagsLimit,
		autoBlur,
		formName,
		confirmSelection,
		confirmModalExtraTitle,
		onClear,
		tooltipSelect,
		initialOptions,
		id,
		toCheck,
		optionFilterProp,
		dropdownAlign,
		onDropdownVisibleChange,
		maxTagCount,
		customOnChange,
		variant,
		onSearch
	} = props

	const dispatch = useDispatch()
	const localItemRef = useRef()
	const [t] = useTranslation()
	const itemRef = props.itemRef || localItemRef
	const [confVisibility, setConfVisibility] = useState<boolean>(false)
	const [onChangeValue, setOnChangeValue] = useState<ValuePropsType>()
	const [onChangeAntdOptions, setOnChangeAntdOptions] = useState<any>()

	const [selectState, setSelectState] = useState<SelectStateTypes<OptionPropsType>>({
		data: [],
		fetching: false,
		searchValue: '',
		emptyText: null,
		pagination: null
	})

	let options: OptionPropsType[] = propsOptions || []
	if (isEmpty(options) && isEmpty(selectState.data)) {
		options = []
	} else if (isEmpty(options)) {
		options = selectState.data || []
	}

	const missingAdditionalOptions = initialOptions?.filter((addOpt) => {
		if (!('value' in addOpt)) {
			return false
		}
		if (findOptionNode(options, addOpt.value)) {
			return false
		}
		return true
	})

	// TODO: dorobit aj pre options with children
	options = [...(missingAdditionalOptions || []), ...(options || [])]

	const renderDropdown = useCallback(
		(antdActions?: Action[] | null) => (menu: React.ReactElement) => {
			return customDropdown(antdActions, menu, selectState.fetching)
		},
		[selectState.fetching]
	)

	const handleSearch = useCallback(
		async (value = '', page = 1, missingValues = []) => {
			if (selectState.fetching) {
				return
			}
			if (onSearch) {
				setSelectState({ ...selectState, fetching: true, searchValue: value })
				const newState = await fetchSearchData({ selectState, value, page, onSearch, dataSourcePath, allowInfinityScroll, missingValues })
				setSelectState(newState)
			}
		},
		[selectState, allowInfinityScroll, dataSourcePath, onSearch]
	)
	const onSearchDebounced = useMemo(() => debounce(handleSearch, 300), [handleSearch])

	const onChange: NonNullable<Props<ValuePropsType, OptionPropsType>['onChange']> = async (value, antdOptions) => {
		if (!input.onChange) return
		// if confirmSelection is active show confirmation modal and save selected option and value
		if (confirmSelection) {
			setOnChangeValue(value)
			setOnChangeAntdOptions(antdOptions)
			setConfVisibility(true)
			return
		}
		handleChange<ValuePropsType, OptionPropsType>({
			value,
			antdOptions,
			autoBlur,
			input,
			itemRef,
			maxTagLength,
			maxTagsLimit,
			mode,
			update,
			options,
			customOnChange
		})
	}

	const onSelectWrap: Props<ValuePropsType, OptionPropsType>['onSelect'] = async (value, option) => {
		const { onSelect } = props
		if (onSelect) {
			let val = value
			let opt = option
			if (value && typeof value === 'object') {
				opt = findOptionNode(options, value.value) as OptionPropsType
				val = {
					...value,
					label: opt.label || option.label,
					extra: opt && 'extra' in opt ? opt.extra : undefined
				}
			} else if (typeof value === 'string' || typeof value === 'number') {
				opt = findOptionNode(options, value) as OptionPropsType
			}
			await onSelect(val, opt)
		}
	}

	const onDeselectWrap: Props<ValuePropsType, OptionPropsType>['onDeselect'] = async (value, option) => {
		const { onDeselect } = props
		if (onDeselect) {
			let val = value
			let opt = option
			if (value && typeof value === 'object') {
				opt = findOptionNode(options, value.value) as OptionPropsType
				val = {
					...value,
					label: opt.label || option.label,
					extra: opt && 'extra' in opt ? opt.extra : undefined
				}
			} else if (typeof value === 'string' || typeof value === 'number') {
				opt = findOptionNode(options, value) as OptionPropsType
			}
			await onDeselect(val, option)
		}
	}

	const onScroll = useCallback(
		(e: any) => {
			let hasMore = true
			let nextPage = 1
			const { pagination, searchValue, fetching } = selectState

			if (pagination) {
				hasMore = pagination.page < pagination.totalPages
				nextPage = pagination.page + 1
			}
			if (Math.ceil(e.target.scrollTop + e.target.offsetHeight) >= e.target.scrollHeight && !fetching && hasMore) {
				handleSearch(searchValue, nextPage)
			}
		},
		[selectState, handleSearch]
	)

	const onDropdownVisibleChangeWrap: Props<ValuePropsType, OptionPropsType>['onDropdownVisibleChange'] = (isOpen) => {
		if (onDropdownVisibleChange) {
			onDropdownVisibleChange(isOpen)
		}

		if (isOpen && onSearch) {
			// NOTE: Po vyhladani, vybrani polozky a znovu otvoreni ostavali vo vysledkoch stare vyhladane vysledky, nie 1. strana zo vsetkych
			handleSearch('', 1)
		}
	}

	const onBlur = () => {
		// NOTE: let the function empty
	}

	const onFocus: Props<ValuePropsType, OptionPropsType>['onFocus'] = (e) => {
		if (input.onFocus) {
			input.onFocus(e)
		}
	}

	useEffect(() => {
		if (onDidMountSearch) {
			handleSearch('', 1)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onDidMountSearch])

	/**
	 * check if initial selected values are all loaded
	 * only for select with pagination (allowInfinityScroll)
	 */
	const checkInitialSelectedValues = useRef(true)

	useEffect(() => {
		// options must be loaded and input value available to run the check
		if (!onDidMountSearch || !allowInfinityScroll || selectState.data?.length === 0 || !input.value || !checkInitialSelectedValues.current) return

		// check if all input values are loaded
		const values = Array.isArray(input.value) ? new Set([...input.value]) : new Set([input.value])
		selectState.data?.some((item) => {
			if ('value' in item) {
				values.delete(item.value)
			}
			if (values.size === 0) return true
			return false
		})

		// refetch options if any value is missing
		if (values.size > 0) handleSearch('', 1, [...(values as never)])

		checkInitialSelectedValues.current = false
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [input.value, selectState.data])

	const localFilterOption = (inputValue: any, option: any) =>
		transformToLowerCaseWithoutAccent(createSlug(option.label.toLowerCase())).indexOf(transformToLowerCaseWithoutAccent(createSlug(inputValue.toLowerCase()))) >= 0
	const value = input.value === null || input.value === '' ? undefined : input.value

	// select first option
	useEffect(() => {
		if (options.length === 1 && formName) {
			const firstOption = 'children' in options[0] ? options[0].children[0].value : options[0]?.value
			if (mode === 'tags' || mode === 'multiple') {
				dispatch(change(formName, input?.name, [firstOption]))
			} else {
				dispatch(change(formName, input?.name, firstOption))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options, formName])

	let suffIcon
	if (!loading && !selectState.fetching) {
		if (showSearch && !suffixIcon) {
			suffIcon = <ArrowIcon className={'text-notino-black'} />
		} else if (suffixIcon) {
			suffIcon = suffixIcon
		} else {
			suffIcon = <ArrowIcon className={'text-notino-black'} />
		}
	}

	let notFound = notFoundContent
	if (emptyText || selectState.emptyText) {
		notFound = <Empty className={'m-4'} image={Empty.PRESENTED_IMAGE_SIMPLE} description={selectState.emptyText || emptyText} />
	}

	const allowClearWrap = () => {
		if (typeof allowClear === 'object' && allowClear.clearIcon) {
			return allowClear
		}

		if (allowClear) {
			return {
				clearIcon: <RemoveIcon className={'text-notino-black'} />
			}
		}

		return false
	}

	const maxTagPlaceholderWrap = (omittedValues: any[]) => {
		return `+${omittedValues.length}`
	}

	const labelRender: SelectProps<ValuePropsType, OptionPropsType>['labelRender'] =
		labelInValue && optionRender && !optionLabelProp && options.length
			? (data) => {
					const optionNode = findOptionNode(options, data.value)
					return optionNode ? optionRender(optionNode) : null
				}
			: undefined

	const select = (
		<div className={cx('input-inner-wrapper', { 'to-check-changes': toCheck })}>
			<Select<ValuePropsType, OptionPropsType>
				variant={variant}
				style={{ backgroundColor }}
				className={cx('noti-select-input', { rounded: backgroundColor, 'filter-select': fieldMode === FIELD_MODE.FILTER })}
				tagRender={tagRender}
				mode={mode}
				{...input}
				id={id || formFieldID(meta.form, input.name)}
				onFocus={onFocus}
				onChange={onChange}
				size={size || 'middle'}
				// if is only one option select it
				value={value}
				onBlur={onBlur}
				placeholder={placeholder || ''}
				loading={loading || selectState.fetching}
				allowClear={allowClearWrap()}
				showSearch={showSearch}
				// NOTE: set to FALSE when we expect filtering on BE
				filterOption={typeof filterOption === 'function' ? filterOption : filterOption && localFilterOption}
				onSearch={showSearch ? onSearchDebounced : undefined}
				suffixIcon={suffIcon}
				labelInValue={labelInValue}
				dropdownRender={props.dropdownRender || renderDropdown(actions)}
				disabled={disabled}
				removeIcon={removeIcon || <CloseIconSmall className={'text-blue-600'} />}
				notFoundContent={notFound}
				onPopupScroll={allowInfinityScroll ? onScroll : undefined}
				onDropdownVisibleChange={onDropdownVisibleChangeWrap}
				ref={itemRef}
				defaultValue={defaultValue}
				optionLabelProp={optionLabelProp}
				open={open}
				onDeselect={onDeselectWrap}
				onSelect={onSelectWrap}
				menuItemSelectedIcon={renderMenuItemSelectedIcon(mode, menuItemSelectedIcon, disableMenuItemSelectedIcon)}
				popupClassName={cx(`noti-select-dropdown ${popupClassName}`, { 'dropdown-match-select-width': popupMatchSelectWidth })}
				dropdownStyle={dropdownStyle}
				popupMatchSelectWidth={popupMatchSelectWidth}
				listHeight={listHeight}
				autoClearSearchValue={autoClearSearchValue}
				maxTagTextLength={maxTagTextLength}
				showAction={showAction}
				getPopupContainer={setGetPopupContainer(mode, getPopupContainer)}
				labelRender={labelRender}
				autoFocus={autoFocus}
				onClear={onClear}
				optionFilterProp={optionFilterProp}
				dropdownAlign={dropdownAlign}
				// NOTE: Do not show chrome suggestions dropdown and do not autofill this field when user picks chrome suggestion for other field
				{...{ autoComplete: 'new-password' }}
				maxTagCount={maxTagCount}
				maxTagPlaceholder={maxTagPlaceholderWrap}
			>
				{getOptions(options, optionRender)}
			</Select>
		</div>
	)

	const selectItem = (
		<Item
			label={label}
			required={required}
			style={style}
			className={cx(className, { 'form-item-disabled': disabled, readOnly })}
			help={(meta?.touched || showErrorWhenUntouched) && !hideHelp && isString(meta?.error) ? meta?.error : undefined}
			validateStatus={(meta?.touched || showErrorWhenUntouched) && meta?.error ? 'error' : undefined}
		>
			{tooltipSelect ? <Tooltip title={tooltipSelect}>{select}</Tooltip> : select}
		</Item>
	)

	return (
		<>
			{confirmSelection ? (
				<Popconfirm
					open={confVisibility}
					placement={'bottom'}
					title={
						<>
							<p className={'font-bold'}>{t('loc:Upozornenie!')}</p>
							{confirmModalExtraTitle}
						</>
					}
					okButtonProps={{
						type: 'default',
						className: 'noti-btn'
					}}
					cancelButtonProps={{
						type: 'primary',
						className: 'noti-btn'
					}}
					okText={t('loc:Potvrdiť')}
					onConfirm={() => {
						// change input value
						handleChange<ValuePropsType, OptionPropsType>({
							value: onChangeValue as ValuePropsType,
							options,
							antdOptions: onChangeAntdOptions,
							customOnChange,
							autoBlur,
							input,
							itemRef,
							maxTagLength,
							maxTagsLimit,
							mode,
							update
						})
						// close conf modal
						setConfVisibility(false)
					}}
					cancelText={t('loc:Zrušiť')}
					onCancel={() => {
						setConfVisibility(false)
					}}
				>
					{selectItem}
				</Popconfirm>
			) : (
				selectItem
			)}
		</>
	)
}

export default SelectField
