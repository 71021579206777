// ant
import { Checkbox, Form } from 'antd'
import { CheckboxChangeEvent, CheckboxGroupProps } from 'antd/lib/checkbox'
import { FormItemProps } from 'antd/lib/form/FormItem'
import cx from 'classnames'
import React from 'react'
import { WrappedFieldProps } from 'redux-form'
import { CheckboxOptionItem } from '../types/interfaces'

const { Item } = Form

type ComponentProps = {
	checkboxGroupStyles?: React.CSSProperties
	horizontal?: boolean
	size: 'small' | 'medium' | 'large'
	rounded?: boolean
	optionRender?: (option: CheckboxOptionItem, isChecked: boolean, disabled?: boolean) => React.ReactNode
	nullAsEmptyValue?: boolean
	useCustomColor?: boolean
	onOptionChange?: (event: CheckboxChangeEvent) => void
	options?: CheckboxOptionItem[]
	singleSelect?: boolean
}

type Props = WrappedFieldProps & Omit<CheckboxGroupProps, 'options'> & FormItemProps & ComponentProps

const CheckboxGroupField = (props: Props) => {
	const {
		input,
		options,
		label,
		required,
		meta: { error, touched },
		style,
		checkboxGroupStyles,
		defaultValue,
		horizontal,
		className,
		size = 'medium',
		rounded,
		optionRender,
		disabled,
		nullAsEmptyValue,
		useCustomColor,
		onOptionChange,
		singleSelect
	} = props

	const checkboxes = options?.map((option) => {
		const isChecked = input.value?.includes(option.value)
		const checkerStyle = useCustomColor ? ({ '--checkbox-color': option.color || option.extra?.color || '#000' } as React.CSSProperties) : undefined

		return (
			<Checkbox
				disabled={option.disabled || disabled}
				key={`${option.value}`}
				id={option.value}
				value={option.value}
				className={cx({ horizontal })}
				style={checkerStyle}
				onChange={(e) => {
					let newValue = input.value
					if (nullAsEmptyValue && input.value?.length === 0) {
						newValue = null
					}
					if (isChecked) {
						input.onChange(Array.isArray(newValue) ? newValue.filter((v) => v !== option.value) : newValue)
					} else {
						input.onChange(singleSelect ? [option.value] : [...(newValue || []), option.value])
					}

					if (onOptionChange) {
						onOptionChange(e)
					}
				}}
			>
				{optionRender ? optionRender(option, isChecked, disabled) : option.label}
			</Checkbox>
		)
	})

	return (
		<Item
			label={label}
			required={required}
			help={touched && error}
			className={cx(className, `noti-checkbox-group noti-checkbox-group-${size}`, {
				'noti-checkbox-group-horizontal': horizontal,
				'noti-checkbox-group-vertical': !horizontal,
				'noti-checkbox-group-rounded': rounded,
				'noti-checkbox-group-custom-color': useCustomColor
			})}
			validateStatus={error && touched ? 'error' : undefined}
			style={style}
		>
			<Checkbox.Group
				className={'flex flex-wrap'}
				value={input.value || []}
				/**
				 * The onChange event for the entire checkbox.group field causes problems because if you have values in input.values that do not correspond to any options, these values are ignored by Ant Design in the onChange callback.
				 * To fix the issue, we use onChange callback on the option itself
				 */
				/* onChange={(checkedValue) => {
					let newValue: CheckboxValueType[] | null = checkedValue
					if (nullAsEmptyValue && checkedValue?.length === 0) {
						newValue = null
					}
					input.onChange(newValue)
				}} */
				defaultValue={defaultValue}
				disabled={disabled}
				style={{
					...checkboxGroupStyles,
					flexDirection: horizontal ? 'row' : 'column'
				}}
			>
				{checkboxes}
			</Checkbox.Group>
		</Item>
	)
}

export default CheckboxGroupField
