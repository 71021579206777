import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Divider } from 'antd'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { TFunction } from 'i18next'

// utils
import { ADMIN_RESERVATIONS_ORDER, ASYNC_JOB_TYPE, MAX_RESERVATIONS_REPORT_DATE_INTERVAL, SUBMIT_ASYNC_JOB_BUTTON_ID } from '../../../utils/enums'
import { LocalizedDateTimeRange } from '../../../utils/intl'

// types
import { INotinoReservationsPageURLQueryParams } from '../../../types/schemaTypes'
import { GetUrls, ISelectOptionItem, RequestParams } from '../../../types/interfaces'
import { RootState } from '../../../reducers'

// components
import FilterListItem from './FilterListItem'

// redux
import { getNotinoReservationsRequestQueryParams } from '../../../reducers/paginatedReservations/paginatedReservationsActions'

export type ReservationsReportRequestAdminQueryParams = RequestParams<GetUrls['/api/b2b/admin/calendar-events/reservations/export']>['query']

type Props = {
	submitting: boolean
	handleSubmit: (query: ReservationsReportRequestAdminQueryParams) => void
	query: INotinoReservationsPageURLQueryParams
	sourceTypeOptions: ISelectOptionItem[]
	paymentMethodOptions: ISelectOptionItem[]
	reservationStatesOptions: ISelectOptionItem[]
	industriesOptions: ISelectOptionItem[]
	countriesOptions: ISelectOptionItem[]
}

enum FILTER_KEY {
	ORDER = 'order',
	CREATED_AT = 'createdAt',
	DATE = 'date',
	SEARCH = 'search',
	COUNTRY_CODE = 'countryCode',
	CATEGORY_FIRST_LEVEL_IDS = 'categoryFirstLevelIDs',
	RESERVATION_STATES = 'reservationStates',
	RESERVATION_PAYMENT_METHODS = 'reservationPaymentMethods',
	RESERVATION_CREATE_SOURCE_TYPE = 'reservationCreateSourceType'
}

const getOrderName = (order: ADMIN_RESERVATIONS_ORDER, t: TFunction) => {
	switch (order) {
		case ADMIN_RESERVATIONS_ORDER.CREATED_AT_ASC:
			return `${t('loc:Dátum vytvorenia')} - ${t('loc:vzostupne')}`
		case ADMIN_RESERVATIONS_ORDER.CREATED_AT_DESC:
			return `${t('loc:Dátum vytvorenia')} - ${t('loc:zostupne')}`
		case ADMIN_RESERVATIONS_ORDER.START_DATE_ASC:
			return `${t('loc:Dátum rezervácie')} - ${t('loc:vzostupne')}`
		case ADMIN_RESERVATIONS_ORDER.START_DATE_DESC:
			return `${t('loc:Dátum rezervácie')} - ${t('loc:zostupne')}`
		default:
			return ''
	}
}

const ReservationsReportAdmin = (props: Props) => {
	const [t] = useTranslation()
	const { handleSubmit, submitting, query, industriesOptions, sourceTypeOptions, paymentMethodOptions, reservationStatesOptions, countriesOptions } = props

	const totalCount = useSelector((state: RootState) => state.paginatedReservations.notinoReservations.data?.pagination.totalCount)

	const [errors, setErrors] = useState<Partial<Record<FILTER_KEY, string>>>({})

	const handleSubmitReport = () => {
		const requiredError = t('loc:Tento filter je povinný')
		const rangeError = t('loc:Maximálne povolené rozpätie je {{daysCount}} dní', { daysCount: MAX_RESERVATIONS_REPORT_DATE_INTERVAL })

		const params = getNotinoReservationsRequestQueryParams(query)

		if (!params.createdAtFrom || !params.createdAtTo) {
			setErrors((prev) => ({
				...prev,
				[FILTER_KEY.CREATED_AT]: `${requiredError}. ${rangeError}.`
			}))
			return
		}

		if (dayjs(params.createdAtTo).diff(dayjs(params.createdAtFrom), 'days') > MAX_RESERVATIONS_REPORT_DATE_INTERVAL) {
			setErrors((prev) => ({
				...prev,
				[FILTER_KEY.CREATED_AT]: rangeError
			}))
			return
		}

		const requestQuery: ReservationsReportRequestAdminQueryParams = {
			createdAtFrom: params.createdAtFrom,
			createdAtTo: params.createdAtTo,
			dateFrom: params.dateFrom,
			dateTo: params.dateTo,
			reservationStates: params.reservationStates,
			reservationCreateSourceType: params.reservationCreateSourceType,
			reservationPaymentMethods: params.reservationPaymentMethods,
			categoryFirstLevelIDs: params.categoryFirstLevelIDs,
			countryCode: params.countryCode,
			order: params.order,
			search: params.search
		}

		handleSubmit(requestQuery)
	}

	const renderFiltersInfo = () => {
		const filters: React.ReactNode[] = []

		if (query.order) {
			filters.push(<FilterListItem key={FILTER_KEY.ORDER} filterName={t('loc:Zoradenie')} filterValue={getOrderName(query.order, t)} />)
		}

		// This filter si required
		filters.push(
			<FilterListItem
				key={FILTER_KEY.CREATED_AT}
				filterName={t('loc:Dátum vytvorenia rezervácie')}
				filterValue={<LocalizedDateTimeRange dateFrom={query.createdAtFrom} dateTo={query.createdAtTo} timeStyle={null} fallback={'-'} />}
				errorMsg={errors[FILTER_KEY.CREATED_AT]}
				required
			/>
		)

		if (query.dateFrom || query.dateTo) {
			filters.push(
				<FilterListItem
					key={FILTER_KEY.DATE}
					filterName={t('loc:Dátum plánovanej rezervácie')}
					filterValue={<LocalizedDateTimeRange dateFrom={query.dateFrom} dateTo={query.dateTo} timeStyle={null} />}
				/>
			)
		}

		if (query.search) {
			filters.push(<FilterListItem key={FILTER_KEY.SEARCH} filterName={t('loc:Hľadať podľa názvu alebo ID')} filterValue={query.search} />)
		}

		if (query.countryCode) {
			const countryOption = countriesOptions.find((option) => option.value === query.countryCode)

			filters.push(
				<FilterListItem
					key={FILTER_KEY.COUNTRY_CODE}
					filterName={t('loc:Krajina')}
					filterValue={
						<div className={'inline-flex items-center gap-1'}>
							{countryOption?.extra.image && <img src={countryOption?.extra.image} width={16} height={16} className={'flex-shrink-0'} alt={''} />}
							{countryOption?.label || query.countryCode}
						</div>
					}
				/>
			)
		}

		if (query.categoryFirstLevelIDs && query.categoryFirstLevelIDs.length) {
			filters.push(
				<FilterListItem
					key={FILTER_KEY.CATEGORY_FIRST_LEVEL_IDS}
					filterName={t('loc:Obor')}
					filterValue={query.categoryFirstLevelIDs
						.map((categoryID) => {
							const industryOption = industriesOptions.find((option) => option.value === categoryID)
							return industryOption?.label || categoryID
						})
						.join(', ')}
				/>
			)
		}

		if (query.reservationStates && query.reservationStates.length) {
			filters.push(
				<FilterListItem
					key={FILTER_KEY.RESERVATION_STATES}
					filterName={t('loc:Stav')}
					filterValue={query.reservationStates
						.map((reservationState) => {
							const reservationStateOption = reservationStatesOptions.find((option) => option.value === reservationState)

							return reservationStateOption?.label || reservationState
						})
						.join(', ')}
				/>
			)
		}

		if (query.reservationPaymentMethods && query.reservationPaymentMethods.length) {
			filters.push(
				<FilterListItem
					key={FILTER_KEY.RESERVATION_PAYMENT_METHODS}
					filterName={t('loc:Spôsob úhrady')}
					filterValue={query.reservationPaymentMethods
						.map((reservationPaymentMethod) => {
							const reservationPaymentMethodOption = paymentMethodOptions.find((option) => option.value === reservationPaymentMethod)

							return reservationPaymentMethodOption?.label || reservationPaymentMethod
						})
						.join(', ')}
				/>
			)
		}

		if (query.reservationCreateSourceType) {
			const sourceTypeOption = sourceTypeOptions.find((option) => option.value === query.reservationCreateSourceType)

			filters.push(
				<FilterListItem
					key={FILTER_KEY.RESERVATION_CREATE_SOURCE_TYPE}
					filterName={t('loc:Vytvoril')}
					filterValue={sourceTypeOption?.label || query.reservationCreateSourceType}
				/>
			)
		}

		return filters
	}

	const disabledButton = submitting || !isEmpty(errors)

	return (
		<div>
			<h4 className={'mb-0'}>{t('loc:Report rezervácií zahŕňa')}</h4>
			<ul className={'pl-8 list-[circle] mb-4 py-4 px-4 rounded-lg bg-notino-grayLighter'}>
				<li>{t('loc:názov a ID salónu')}</li>
				<li>{t('loc:ID rezervácie')}</li>
				<li>{t('loc:dátum vytvorenia rezervácie')}</li>
				<li>{t('loc:dátum a čas rezervácie')}</li>
				<li>{t('loc:informácie o klientovi, službe a kolegovi')}</li>
				<li>{t('loc:kto vytvoril rezerváciu')}</li>
				<li>{t('loc:stav rezervácie')}</li>
				<li>{t('loc:spôsob úhrady')}</li>
				<li>{t('loc:prípadne dátum vymazania')}</li>
			</ul>

			<h4 className={'mb-0'}>{t('loc:Vami požadovaný report bude vygenerovaný so zohľadnením filtrácie')}</h4>
			<Divider className={'mb-2 mt-0'} />
			{renderFiltersInfo().length ? <ul className={'pl-5 list-[circle] mb-4 flex flex-col gap-1'}>{renderFiltersInfo()}</ul> : null}

			<Button
				id={SUBMIT_ASYNC_JOB_BUTTON_ID(ASYNC_JOB_TYPE.EXPORT_RESERVATIONS)}
				className={`noti-btn sticky bottom-6 shadow-[0px_8px_24px_rgba(255,255,255,0.84),_0px_4px_14px_rgba(255,255,255,0.84),_0px_2px_4px_rgba(255,255,255,0.84)] ${!disabledButton ? `hover:shadow-[0px_8px_24px_rgba(0,0,0,0.24),_0px_4px_14px_rgba(0,0,0,0.14),_0px_2px_4px_rgba(0,0,0,0.16)]` : ''}`}
				block
				size='large'
				type='primary'
				htmlType='button'
				onClick={handleSubmitReport}
				disabled={disabledButton}
				loading={submitting}
			>
				{totalCount !== undefined ? t('loc:Generovať ({{ itemsCount }} záznamov)', { itemsCount: totalCount }) : t('loc:Generovať')}
			</Button>
		</div>
	)
}

export default ReservationsReportAdmin
