import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Button, Result } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// types
import { RootState } from '../reducers'

// assets
import TrashIcon from '../assets/icons/deleted.svg?react'

type Props = PropsWithChildren

const PreventShowDeletedSalon = (props: Props) => {
	const { children } = props
	const navigate = useNavigate()
	const selectedSalon = useSelector((state: RootState) => state.selectedSalon.selectedSalon)
	const [t] = useTranslation()

	if (!selectedSalon?.data?.deletedAt) {
		return children
	}

	return (
		<Result
			status='error'
			title={t('loc:Salón bol vymazaný')}
			icon={<TrashIcon className={'w-14 h-14 mx-auto'} />}
			extra={
				<Button
					type={'primary'}
					size={'middle'}
					className={'noti-btn m-regular'}
					onClick={() => navigate(t('paths:salons/{{salonID}}', { salonID: selectedSalon?.data?.id }))}
				>
					{t('loc:Detail salónu')}
				</Button>
			}
		/>
	)
}

export default PreventShowDeletedSalon
