import { z } from 'zod'
import { isEmpty, get, isNil } from 'lodash'
import { localizedValuesConstraint, serializeValidationMessage, uuidConstraint } from './baseSchema'
import { PARAMETERS_VALUE_TYPES } from '../utils/enums'

// eslint-disable-next-line import/prefer-default-export
export const categoryParamsSchema = z
	.object({
		valueType: z.nativeEnum(PARAMETERS_VALUE_TYPES).default(PARAMETERS_VALUE_TYPES.ENUM),
		values: z
			.object({
				value: z.number().positive().finite().nullish(),
				id: uuidConstraint.nullish()
			})
			.array()
			.superRefine((val, ctx) => {
				if (!isEmpty(val)) {
					const onlyValues = val.map((item) => item.value)

					val.forEach((item, index) => {
						if (item.value && onlyValues.indexOf(item.value) !== index) {
							ctx.addIssue({
								code: z.ZodIssueCode.custom,
								message: serializeValidationMessage('loc:Táto hodnota už je zadaná'),
								path: [index, 'value']
							})
						}
					})
				}
			})
	})
	.superRefine((val, ctx) => {
		// values are numbers
		if (val.valueType === PARAMETERS_VALUE_TYPES.TIME) {
			val.values.forEach((value, index) => {
				if (isNil(value.value)) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: serializeValidationMessage('loc:Toto pole je povinné'),
						path: ['values', index, 'value']
					})
				}
			})
		}
	})
	.and(
		z.object({
			localizedValues: z
				.object({
					id: uuidConstraint.nullish(),
					valueLocalizations: localizedValuesConstraint(true)
				})
				.array(),
			nameLocalizations: localizedValuesConstraint(true)
		})
	)
	.superRefine((val, ctx) => {
		if (val.valueType === PARAMETERS_VALUE_TYPES.ENUM) {
			if (!get(val, 'localizedValues[0].valueLocalizations[0].value', undefined)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['localizedValues', 0, 'valueLocalizations', 0, 'value']
				})
			} else {
				val.localizedValues.forEach((localizedValue, rootIndex) => {
					if (localizedValue && localizedValue.valueLocalizations) {
						const defaultValueIsEmpty = !localizedValue.valueLocalizations[0].value
						const otherValueIsFilled = localizedValue.valueLocalizations.some((entry, index) => index > 0 && entry.value)

						if (defaultValueIsEmpty && otherValueIsFilled) {
							ctx.addIssue({
								code: z.ZodIssueCode.custom,
								message: serializeValidationMessage('loc:Toto pole je povinné'),
								path: ['localizedValues', rootIndex, 'valueLocalizations', 0, 'value']
							})
						}
					}
				})
			}
		}
	})
