import { z } from 'zod'
import { serializeValidationMessage, uuidConstraint } from './baseSchema'

export const industriesSchema = z.object({
	categoryIDs: uuidConstraint.array().min(1, { message: serializeValidationMessage('loc:Vyberte aspoň jedno odvetvie') })
})

export const industrySchema = z.object({
	categoryIDs: z.record(
		z.object({
			serviceCategoryIDs: uuidConstraint.array()
		})
	)
})
