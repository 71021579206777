/* eslint-disable import/no-cycle */
import {
	RESERVATIONS,
	NOTINO_RESERVATIONS,
	PENDING_RESERVATIONS_COUNT,
	UPCOMING_CUSTOMER_RESERVATIONS_COUNT,
	UPCOMING_EMPLOYEE_RESERVATIONS_COUNT
} from './paginatedReservationsTypes'
import { RESET_STORE } from '../generalTypes'
import {
	ICalendarActions,
	INotinoReservationsPayload,
	IPendingReservationsCount,
	ISalonReservationsPayload,
	IUpcomingCustomerReservationsCount,
	IUpcomingEmployeeReservationsCount
} from './paginatedReservationsActions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	salonReservations: {
		data: null,
		tableData: [],
		isLoading: false,
		isFailure: false
	} as ISalonReservationsPayload & ILoadingAndFailure,
	notinoReservations: {
		data: null,
		tableData: [],
		isLoading: false,
		isFailure: false
	} as INotinoReservationsPayload & ILoadingAndFailure,
	pendingReservationsCount: {
		count: 0,
		isLoading: false,
		isFailure: false
	} as IPendingReservationsCount & ILoadingAndFailure,
	upcomingCustomerReservationsCount: {
		count: 0,
		isLoading: false,
		isFailure: false
	} as IUpcomingCustomerReservationsCount & ILoadingAndFailure,
	upcomingEmployeeReservationsCount: {
		count: 0,
		isLoading: false,
		isFailure: false
	} as IUpcomingEmployeeReservationsCount & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: ICalendarActions) => {
	switch (action.type) {
		// Salon Reservations
		case RESERVATIONS.RESERVATIONS_LOAD_START:
			return {
				...state,
				salonReservations: {
					...state.salonReservations,
					isLoading: true
				}
			}
		case RESERVATIONS.RESERVATIONS_LOAD_FAIL:
			return {
				...state,
				salonReservations: {
					...initState.salonReservations,
					isFailure: true
				}
			}
		case RESERVATIONS.RESERVATIONS_LOAD_DONE:
			return {
				...state,
				salonReservations: {
					...initState.salonReservations,
					data: action.payload.data,
					tableData: action.payload.tableData
				}
			}
		// Notino Reservations
		case NOTINO_RESERVATIONS.NOTINO_RESERVATIONS_LOAD_START:
			return {
				...state,
				notinoReservations: {
					...state.notinoReservations,
					isLoading: true
				}
			}
		case NOTINO_RESERVATIONS.NOTINO_RESERVATIONS_LOAD_FAIL:
			return {
				...state,
				notinoReservations: {
					...initState.notinoReservations,
					isFailure: true
				}
			}
		case NOTINO_RESERVATIONS.NOTINO_RESERVATIONS_LOAD_DONE:
			return {
				...state,
				notinoReservations: {
					...initState.notinoReservations,
					data: action.payload.data,
					tableData: action.payload.tableData
				}
			}
		// Pending Reservations count
		case PENDING_RESERVATIONS_COUNT.PENDING_RESERVATIONS_COUNT_LOAD_START:
			return {
				...state,
				pendingReservationsCount: {
					...state.pendingReservationsCount,
					isLoading: true
				}
			}
		case PENDING_RESERVATIONS_COUNT.PENDING_RESERVATIONS_COUNT_LOAD_FAIL:
			return {
				...state,
				pendingReservationsCount: {
					...initState.pendingReservationsCount,
					isFailure: true
				}
			}
		case PENDING_RESERVATIONS_COUNT.PENDING_RESERVATIONS_COUNT_LOAD_DONE:
			return {
				...state,
				pendingReservationsCount: {
					...initState.pendingReservationsCount,
					count: action.payload.count
				}
			}
		// Upcoming Customer Reservations count
		case UPCOMING_CUSTOMER_RESERVATIONS_COUNT.UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_START:
			return {
				...state,
				upcomingCustomerReservationsCount: {
					...state.upcomingCustomerReservationsCount,
					isLoading: true
				}
			}
		case UPCOMING_CUSTOMER_RESERVATIONS_COUNT.UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_FAIL:
			return {
				...state,
				upcomingCustomerReservationsCount: {
					...initState.upcomingCustomerReservationsCount,
					isFailure: true
				}
			}
		case UPCOMING_CUSTOMER_RESERVATIONS_COUNT.UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_DONE:
			return {
				...state,
				upcomingCustomerReservationsCount: {
					...initState.upcomingCustomerReservationsCount,
					count: action.payload.count
				}
			}
		// Upcoming Employee Reservations count
		case UPCOMING_EMPLOYEE_RESERVATIONS_COUNT.UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_START:
			return {
				...state,
				upcomingEmployeeReservationsCount: {
					...state.upcomingEmployeeReservationsCount,
					isLoading: true
				}
			}
		case UPCOMING_EMPLOYEE_RESERVATIONS_COUNT.UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_FAIL:
			return {
				...state,
				upcomingEmployeeReservationsCount: {
					...initState.upcomingEmployeeReservationsCount,
					isFailure: true
				}
			}
		case UPCOMING_EMPLOYEE_RESERVATIONS_COUNT.UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_DONE:
			return {
				...state,
				upcomingEmployeeReservationsCount: {
					...initState.upcomingEmployeeReservationsCount,
					count: action.payload.count
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
