import React from 'react'
import { ConfigProvider, DatePicker, Form } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { RangePickerProps } from 'antd/es/date-picker'
import { FormItemProps } from 'antd/lib/form/FormItem'
import dayjs from 'dayjs'
import cx from 'classnames'
import SeparatorIcon from '../assets/icons/datepicker-separator-icon.svg?react'
import RemoveIcon from '../assets/icons/remove-select-icon.svg?react'

import { DEFAULT_DATE_INIT_FORMAT, DEFAULT_DATE_WITH_TIME_FORMAT, DEFAULT_TIME_FORMAT, TIME_FORMAT_12_HOUR } from '../utils/enums'
import { formFieldID } from '../utils/helper'
import { is12HourFormat } from '../utils/intl'

export type Props = WrappedFieldProps &
	FormItemProps &
	Omit<RangePickerProps, 'format'> & {
		disableFuture?: boolean // for disable startDate from past
		disablePast?: boolean // for disable startDate from past
		itemRef?: any
		rangePickerClassName?: string
		showTime?: boolean
		use12hours?: boolean
		format?: string
	}

const { RangePicker } = DatePicker

const DateRangePickerField = (props: Props) => {
	const {
		allowEmpty,
		renderExtraFooter,
		input,
		placeholder,
		label,
		format,
		open,
		getPopupContainer,
		style,
		popupClassName,
		separator,
		suffixIcon,
		disableFuture,
		disablePast,
		disabledDate,
		itemRef,
		required,
		meta,
		size,
		disabled,
		className,
		presets,
		rangePickerClassName,
		showTime,
		popupAlign,
		locale,
		allowClear,
		use12hours = is12HourFormat()
	} = props

	const { locale: antdLocale } = React.useContext(ConfigProvider.ConfigContext)

	const defaultFormat = format || (showTime ? DEFAULT_DATE_WITH_TIME_FORMAT : DEFAULT_DATE_INIT_FORMAT)

	const value: Props['value'] = [
		input.value && input.value.dateFrom && dayjs(input.value.dateFrom).isValid() ? dayjs(input.value.dateFrom) : null,
		input.value && input.value.dateTo && dayjs(input.value.dateTo).isValid() ? dayjs(input.value.dateTo) : null
	]

	const onChange: NonNullable<Props['onChange']> = (vals) => {
		if (vals && vals[0] && vals[1]) {
			input.onChange({
				dateFrom: vals[0].format(defaultFormat),
				dateTo: vals[1].format(defaultFormat)
			})
		} else {
			input.onChange(null)
		}
	}

	const disabledDateWrap: Props['disabledDate'] = (currentDate, info) => {
		let disable = false
		const now = dayjs()
		if (disabledDate) {
			disable = disabledDate(currentDate, info)
		} else if (disableFuture) {
			disable = currentDate && currentDate > now.endOf('day')
		} else if (disablePast) {
			disable = currentDate && currentDate < now.startOf('day')
		}
		return disable
	}

	const getShowTime = () => {
		if (typeof showTime === 'object') {
			return showTime
		}

		return showTime ? { format: use12hours ? TIME_FORMAT_12_HOUR : DEFAULT_TIME_FORMAT } : false
	}

	const allowClearWrap = () => {
		if (typeof allowClear === 'object' && allowClear.clearIcon) {
			return allowClear
		}

		if (allowClear) {
			return {
				clearIcon: <RemoveIcon className={'text-notino-black'} />
			}
		}

		return false
	}

	return (
		<Form.Item
			className={className}
			style={style}
			label={label}
			required={required}
			help={meta?.touched && meta?.error}
			validateStatus={meta?.error && meta?.touched ? 'error' : undefined}
		>
			<div id={formFieldID(meta?.form, input?.name)}>
				<RangePicker
					ref={itemRef}
					allowEmpty={allowEmpty}
					className={cx('noti-date-picker', rangePickerClassName)}
					value={value}
					popupAlign={popupAlign}
					onChange={onChange}
					showTime={getShowTime()}
					format={
						format ||
						(showTime
							? `${antdLocale?.DatePicker?.lang?.dateFormat} ${use12hours ? TIME_FORMAT_12_HOUR : DEFAULT_TIME_FORMAT}`
							: antdLocale?.DatePicker?.lang?.dateFormat)
					}
					placeholder={placeholder}
					suffixIcon={suffixIcon}
					separator={separator || <SeparatorIcon />}
					open={open}
					disabledDate={disabledDateWrap}
					popupClassName={popupClassName}
					renderExtraFooter={renderExtraFooter}
					getPopupContainer={getPopupContainer || ((node) => node)}
					size={size}
					disabled={disabled}
					presets={presets}
					locale={locale}
					allowClear={allowClearWrap()}
					use12Hours={use12hours}
					showSecond={false}
				/>
			</div>
		</Form.Item>
	)
}

export default DateRangePickerField
