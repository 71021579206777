// validate password with rules
//	- min length is 8 characters: (?=.{8,})
//	- contains at least one lowercase letter: (?=.*[a-z]+)
//	- contains at least one uppercase letter: (?=.*[A-Z]+)
//	- contains at least one number: (?=.*\d+)

export const passwordRegEx = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/ // 8 znakov, 1 číslo, 1 veľký, 1 malý a 1 špeciálny znak
export const phoneRegEx = /^\d+$/
export const socialMediaRegex = {
	facebook: /^(https?:\/\/)?(www\.)?facebook\.[a-zA-Z0-9()]{1,3}?\b([\S]{0,255})$/,
	instagram: /^(https?:\/\/)?(www\.)?instagram\.[a-zA-Z0-9()]{1,3}?\b([\S]{0,255})$/,
	youtube: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,10}\.)?youtube\.[a-zA-Z0-9()]{1,3}?\b([\S]{0,255})$/,
	tiktok: /^(https?:\/\/)?(www\.)?tiktok\.[a-zA-Z0-9()]{1,3}?\b([\S]{0,255})$/,
	pinterest: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,10}\.)?pinterest\.[a-zA-Z0-9()]{1,3}?\b([\S]{0,255})$/,
	website: /^(https?:\/\/)?(www\.)?[\S]{1,50}\.[a-zA-Z0-9()]{1,10}?\b([\S]{0,255})$/
}
// validate YYYY-MM-DD date format
export const dateRegex = /^(\d{4})[-]((0[1-9])|(1[012]))[-]((0[1-9])|([12][0-9])|(3[01]))$/

// validate HH:mm time format
export const timeRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d)$/

/**
 * @see https://ihateregex.io/expr/uuid/
 */
export const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/

export const removeWhiteSpacesRegex = /\s/g

// validate hex color, #(3 - 6 numbers), eg. #000, #666555
export const hexColorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/

// validate version, 0.1
export const versionRegex = /^\d{1,3}\.\d{1,3}$/

export const dynamicNotificationUrlRegex = /^(https?:\/\/)(www\.)?[\S]{1,50}\.[a-zA-Z0-9()]{1,10}?\b([\S]{0,255})$/
