/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react'
import { Button, Dropdown } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

// assets
import MoreIcon from '../../../assets/icons/more-info-horizontal-icon.svg?react'

// types
import { RootState } from '../../../reducers'

// hooks
import useOnClickOutside from '../../../hooks/useClickOutside'

// utils
import { ActionsDropdownProps, NOTIFICATIONS_DROPDOWN_WRAPPER_ID } from '../notificationsUtils'

const ActionsDropdown = (props: ActionsDropdownProps) => {
	const [t] = useTranslation()

	const { okProps, deleteProps, disabled, id, type = 'primary', onOpenChange } = props
	const { onOk, okText = t('loc:Označiť všetky ako prečítané'), okDisabled } = okProps
	const { onDelete, deleteText = t('loc:Vymazať všetky notifikácie'), deleteDisabled } = deleteProps

	const [open, setIsOpen] = useState(false)

	const wrapperRef = useRef<HTMLDivElement | null>(null)
	const triggerButtonRef = useRef<HTMLButtonElement | null>(null)

	const notifications = useSelector((state: RootState) => state.userNotifications.userNotifications)
	const notificationsLength = notifications.data?.notifications.length || 0

	useOnClickOutside([wrapperRef, triggerButtonRef], () => {
		setIsOpen(false)
	})

	return (
		<>
			<Dropdown
				menu={{
					className: 'shadow-md max-w-xs min-w-52 mt-1 p-0 rounded',
					items: [
						{
							key: `${id || ''}actionsOK`,
							className: 'p-0 border-0 border-b border-notino-grayLight border-solid',
							label: (
								<button
									onClick={(e) => {
										e.stopPropagation()
										onOk()
										setIsOpen(false)
									}}
									className={
										'w-full py-2 px-4 text-sm otuline-0 bg-white text-notino-grayDarker cursor-pointer border-0 leading-4 font-medium hover:bg-notino-grayLighter text-left'
									}
									type={'button'}
									disabled={disabled || okDisabled}
								>
									{okText}
								</button>
							),
							disabled
						},
						{
							key: `${id || ''}actionsDelete`,
							className: 'p-0',
							label: (
								<button
									onClick={(e) => {
										e.stopPropagation()
										onDelete()
										setIsOpen(false)
									}}
									className={
										'w-full py-2 px-4 text-sm otuline-0 bg-white text-notino-red cursor-pointer border-0 leading-4 font-medium hover:bg-notino-grayLighter text-left'
									}
									type={'button'}
									disabled={disabled || deleteDisabled}
								>
									{deleteText}
								</button>
							),
							disabled
						}
					]
				}}
				open={open}
				placement='bottomRight'
				trigger={['click']}
				dropdownRender={(originNode) => <div ref={wrapperRef}>{originNode}</div>}
				destroyPopupOnHide
				getPopupContainer={(triggerNode) =>
					(notificationsLength <= 1 ? document.querySelector(`#${NOTIFICATIONS_DROPDOWN_WRAPPER_ID}`) : triggerNode.parentElement) as HTMLElement
				}
				disabled={disabled}
				onOpenChange={onOpenChange}
			>
				<Button
					className={cx('outline-0 rounded h-5 w-5 p-0 flex items-center justify-center bg-transparent border-0 outline-0 shadow-none', {
						'h-6': type === 'primary',
						'w-6': type === 'primary'
					})}
					onClick={(e) => {
						e.stopPropagation()
						setIsOpen(!open)
					}}
					ref={triggerButtonRef}
					disabled={disabled}
				>
					<MoreIcon
						className={cx({
							'text-notino-grayDarker': type === 'primary',
							'medum-icon': type === 'primary',
							'hover:bg-notino-grayLighter': type === 'primary',
							'text-notino-grayDark': type === 'secondary',
							'small-icon': type === 'secondary'
						})}
						style={{ transform: 'rotate(90deg)' }}
					/>
				</Button>
			</Dropdown>
		</>
	)
}

export default React.memo(ActionsDropdown)
