import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Divider } from 'antd'
import { useSelector } from 'react-redux'
import { TFunction } from 'i18next'

// utils
import { findNodeInTree, getAssignedUserLabel } from '../../../utils/helper'
import { ASYNC_JOB_TYPE, SALONS_ORDER, SUBMIT_ASYNC_JOB_BUTTON_ID, WITHOUT_ASSIGNED_USER } from '../../../utils/enums'
import { LocalizedDateTimeRange } from '../../../utils/intl'
import { getReq } from '../../../utils/request'

// types
import { GetUrls, ISelectOptionItem, ISelectOptionItemWithChildren, RequestParams } from '../../../types/interfaces'
import { RootState } from '../../../reducers'

// schemas
import { ISalonsActivePageURLQueryParams } from '../../../types/schemaTypes'

// components
import FilterListItem from './FilterListItem'

// redux
import { getSalonsRequestQueryParams } from '../../../reducers/salons/salonsActions'

export type SalonsReportRequestQueryParams = RequestParams<GetUrls['/api/b2b/admin/reports/salons']>['query']

type Props = {
	visible: boolean
	submitting: boolean
	handleSubmit: (query: SalonsReportRequestQueryParams) => void
	query: ISalonsActivePageURLQueryParams
	publishedSalonOptions: ISelectOptionItem[]
	salonChangesOptions: ISelectOptionItem[]
	salonCreateTypesOptions: ISelectOptionItem[]
	countriesOptions: ISelectOptionItem[]
	industriesOptions: ISelectOptionItem[]
	servicesOptions: ISelectOptionItemWithChildren[]
	sourceOptions: ISelectOptionItem[]
	premiumSourceOptions: ISelectOptionItem[]
	rsOptions: ISelectOptionItem[]
	rsAvailableOnlineOptions: ISelectOptionItem[]
	upcomingReservationsOptions: ISelectOptionItem[]
	openingHoursOptions: ISelectOptionItem[]
}

enum FILTER_KEY {
	ORDER = 'order',
	STATUSES = 'statuses',
	DATE = 'date',
	SEARCH = 'search',
	COUNTRY_CODE = 'countryCode',
	SOURCE_TYPE = 'sourceType',
	PREMIUM_SOURCE_USER_TYPE = 'premiumSourceUserType',
	ENABLED_RESERVATIONS_SETTING = 'enabledReservationsSetting',
	HAS_AVAILABLE_RESERVATION_SYSTEM = 'hasAvailableReservationSystem',
	HAS_UPCOMING_RESERVATIONS = 'hasUpcomingReservations',
	HAS_SET_OPENING_HOURS = 'hasSetOpeningHours',
	CATEGORY_FIRST_LEVEL_IDS = 'categoryFirstLevelIDs',
	CATEGORY_THIRD_LEVEL_IDS = 'categoryThirdLevelID',
	ASSIGNED_USER_ID = 'assignedUserID',
	LAST_UPDATED_AT = 'lastUpdatedAt'
}

const getOrderName = (order: SALONS_ORDER, t: TFunction) => {
	switch (order) {
		case SALONS_ORDER.CREATED_AT_ASC:
			return `${t('loc:Vytvorené')} - ${t('loc:vzostupne')}`
		case SALONS_ORDER.CREATED_AT_DESC:
			return `${t('loc:Vytvorené')} - ${t('loc:zostupne')}`
		case SALONS_ORDER.NAME_ASC:
			return `${t('loc:Názov')} - ${t('loc:vzostupne')}`
		case SALONS_ORDER.NAME_DESC:
			return `${t('loc:Názov')} - ${t('loc:zostupne')}`
		case SALONS_ORDER.FILLING_PROGRESS_ASC:
			return `${t('loc:Vyplnenie profilu')} - ${t('loc:vzostupne')}`
		case SALONS_ORDER.FILLING_PROGRESS_DESC:
			return `${t('loc:Vyplnenie profilu')} - ${t('loc:zostupne')}`
		default:
			return ''
	}
}

const SalonsReport = (props: Props) => {
	const [t] = useTranslation()
	const {
		visible,
		handleSubmit,
		submitting,
		query,
		publishedSalonOptions,
		salonChangesOptions,
		salonCreateTypesOptions,
		countriesOptions,
		industriesOptions,
		servicesOptions,
		sourceOptions,
		premiumSourceOptions,
		rsOptions,
		rsAvailableOnlineOptions,
		upcomingReservationsOptions,
		openingHoursOptions
	} = props

	const totalCount = useSelector((state: RootState) => state.salons.salons.data?.pagination.totalCount)
	const [assignedUser, setAssignedUser] = useState<string | undefined>(query.assignedUserID || undefined)

	useEffect(() => {
		;(async () => {
			if (visible && query.assignedUserID) {
				if (query.assignedUserID === WITHOUT_ASSIGNED_USER) {
					setAssignedUser(t('loc:Bez Notino používateľa'))
					return
				}

				try {
					const { data } = await getReq('/api/b2b/admin/users/notino-users', {
						params: {
							query: {
								userIDs: [query.assignedUserID]
							}
						},
						reqBody: {}
					})

					const user = data.users[0]
					setAssignedUser(user && user.id === query.assignedUserID ? getAssignedUserLabel(data.users[0]) : query.assignedUserID || undefined)
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error(e)
					setAssignedUser(query.assignedUserID || undefined)
				}
			} else {
				setAssignedUser(undefined)
			}
		})()
	}, [query.assignedUserID, visible, t])

	const handleSubmitReport = () => {
		const params = getSalonsRequestQueryParams(query)

		const requestQuery: SalonsReportRequestQueryParams = {
			order: params.order,
			search: params.search,
			categoryFirstLevelIDs: params.categoryFirstLevelIDs,
			categoryThirdLevelIDs: params.categoryThirdLevelIDs,
			countryCode: params.countryCode,
			lastUpdatedAtFrom: params.lastUpdatedAtFrom,
			lastUpdatedAtTo: params.lastUpdatedAtTo,
			createType: params.createType,
			statuses: params.statuses,
			hasSetOpeningHours: params.hasSetOpeningHours,
			sourceType: params.sourceType,
			premiumSourceUserType: params.premiumSourceUserType,
			assignedUserID: params.assignedUserID,
			enabledReservationsSetting: params.enabledReservationsSetting,
			hasAvailableReservationSystem: params.hasAvailableReservationSystem,
			walletAvailableBalanceFrom: params.walletAvailableBalanceFrom,
			walletAvailableBalanceTo: params.walletAvailableBalanceTo,
			hasDaysWithAvailableReservationSlot: params.hasDaysWithAvailableReservationSlot,
			hasUpcomingReservations: params.hasUpcomingReservations
		}

		handleSubmit(requestQuery)
	}

	const renderFiltersInfo = () => {
		const filters: React.ReactNode[] = []

		if (query.order) {
			filters.push(<FilterListItem key={FILTER_KEY.ORDER} filterName={t('loc:Zoradenie')} filterValue={getOrderName(query.order, t)} />)
		}

		if (query.search) {
			filters.push(<FilterListItem key={FILTER_KEY.SEARCH} filterName={t('loc:Hľadať podľa názvu, adresy alebo ID')} filterValue={query.search} />)
		}

		if (query.statuses_all || query.statuses_published || query.statuses_changes || query.createType) {
			const statusesTexts: string[] = []
			if (query.statuses_all) {
				statusesTexts.push(t('loc:Všetky'))
			}

			if (query.statuses_published) {
				const statusesPublishedOption = publishedSalonOptions.find((option) => option.value === query.statuses_published)
				statusesTexts.push(statusesPublishedOption?.label || query.statuses_published)
			}

			if (query.statuses_changes) {
				const statusesChangesOption = salonChangesOptions.find((option) => option.value === query.statuses_changes)
				statusesTexts.push(statusesChangesOption?.label || query.statuses_changes)
			}

			if (query.createType) {
				const salonCreateTypeOption = salonCreateTypesOptions.find((option) => option.value === query.createType)
				statusesTexts.push(salonCreateTypeOption?.label || query.createType)
			}

			filters.push(<FilterListItem key={FILTER_KEY.STATUSES} filterName={t('loc:Stavy')} filterValue={statusesTexts.join(', ')} />)
		}

		if (query.countryCode) {
			const countryOption = countriesOptions.find((option) => option.value === query.countryCode)

			filters.push(
				<FilterListItem
					key={FILTER_KEY.COUNTRY_CODE}
					filterName={t('loc:Krajina')}
					filterValue={
						<div className={'inline-flex items-center gap-1'}>
							{countryOption?.extra.image && <img src={countryOption?.extra.image} width={16} height={16} className={'flex-shrink-0'} alt={''} />}
							{countryOption?.label || query.countryCode}
						</div>
					}
				/>
			)
		}

		if (query.categoryFirstLevelIDs && query.categoryFirstLevelIDs.length) {
			filters.push(
				<FilterListItem
					key={FILTER_KEY.CATEGORY_FIRST_LEVEL_IDS}
					filterName={t('loc:Obor')}
					filterValue={query.categoryFirstLevelIDs
						.map((categoryID) => {
							const industryOption = industriesOptions.find((option) => option.value === categoryID)
							return industryOption?.label || categoryID
						})
						.join(', ')}
				/>
			)
		}

		if (query.categoryThirdLevelIDs && query.categoryThirdLevelIDs.length) {
			filters.push(
				<FilterListItem
					key={FILTER_KEY.CATEGORY_THIRD_LEVEL_IDS}
					filterName={t('loc:Služby')}
					filterValue={query.categoryThirdLevelIDs
						.map((categoryID) => {
							const serviceOption = findNodeInTree({ children: servicesOptions }, categoryID, 'value')

							return serviceOption?.label || categoryID
						})
						.join(', ')}
				/>
			)
		}

		if (query.sourceType) {
			const sourceOption = sourceOptions.find((option) => option.value === query.sourceType)

			filters.push(<FilterListItem key={FILTER_KEY.SOURCE_TYPE} filterName={t('loc:Zdroj vytvorenia')} filterValue={sourceOption?.label || query.sourceType} />)
		}

		if (query.premiumSourceUserType) {
			const premiumSourceUserTypeOption = premiumSourceOptions.find((option) => option.value === query.premiumSourceUserType)

			filters.push(
				<FilterListItem
					key={FILTER_KEY.PREMIUM_SOURCE_USER_TYPE}
					filterName={t('loc:Zdroj PREMIUM')}
					filterValue={premiumSourceUserTypeOption?.label || query.premiumSourceUserType}
				/>
			)
		}

		if (query.enabledReservationsSetting) {
			const rsOption = rsOptions.find((option) => option.value === query.enabledReservationsSetting)

			filters.push(
				<FilterListItem
					key={FILTER_KEY.ENABLED_RESERVATIONS_SETTING}
					filterName={t('loc:Rezervačný systém')}
					filterValue={rsOption?.label || query.enabledReservationsSetting}
				/>
			)
		}

		if (query.hasAvailableReservationSystem) {
			const rsAvailableOnlineOption = rsAvailableOnlineOptions.find((option) => option.value === query.hasAvailableReservationSystem)

			filters.push(
				<FilterListItem
					key={FILTER_KEY.HAS_AVAILABLE_RESERVATION_SYSTEM}
					filterName={t('loc:Dostupné pre online rezervácie')}
					filterValue={rsAvailableOnlineOption?.label || query.hasAvailableReservationSystem}
				/>
			)
		}

		if (query.hasUpcomingReservations) {
			const upcomingReservationsOption = upcomingReservationsOptions.find((option) => option.value === query.hasUpcomingReservations)

			filters.push(
				<FilterListItem
					key={FILTER_KEY.HAS_UPCOMING_RESERVATIONS}
					filterName={t('loc:Rezervácie v budúcnosti')}
					filterValue={upcomingReservationsOption?.label || query.hasUpcomingReservations}
				/>
			)
		}

		if (query.hasSetOpeningHours) {
			const openingHoursOption = openingHoursOptions.find((option) => option.value === query.hasSetOpeningHours)

			filters.push(
				<FilterListItem key={FILTER_KEY.HAS_SET_OPENING_HOURS} filterName={t('loc:Otváracie hodiny')} filterValue={openingHoursOption?.label || query.hasSetOpeningHours} />
			)
		}

		if (assignedUser) {
			filters.push(<FilterListItem key={FILTER_KEY.ASSIGNED_USER_ID} filterName={t('loc:Priradený Notino používateľ')} filterValue={assignedUser} />)
		}

		if (query.lastUpdatedAtFrom && query.lastUpdatedAtTo) {
			filters.push(
				<FilterListItem
					key={FILTER_KEY.LAST_UPDATED_AT}
					filterName={t('loc:Dátum poslednej zmeny')}
					filterValue={<LocalizedDateTimeRange dateFrom={query.lastUpdatedAtFrom} dateTo={query.lastUpdatedAtTo} timeStyle={null} fallback={'-'} />}
				/>
			)
		}

		return filters
	}

	const disabledButton = submitting

	return (
		<div>
			<h4 className={'mb-0'}>{t('loc:Report pre každý salón zahŕňa')}:</h4>
			<ul className={'pl-8 list-[circle] mb-4 py-4 px-4 rounded-lg bg-notino-grayLighter'}>
				<li>{t('loc:základné informácie')}</li>
				<li>{t('loc:kontaktné údaje')}</li>
				<li>{t('loc:stav salónu')}</li>
				<li>{t('loc:informácie o rezervačnom systéme')}</li>
				<li>{t('loc:informácie o ponúkaných službách')}</li>
				<li>{t('loc:počet kolegov a klientov')}</li>
				<li>{t('loc:dátum vytvorenia')}</li>
				<li>{t('loc:dátum zmeny')}</li>
			</ul>

			<h4 className={'mb-0'}>{t('loc:Vami požadovaný report bude vygenerovaný so zohľadnením filtrácie')}</h4>
			<Divider className={'mb-2 mt-0'} />
			{renderFiltersInfo().length ? (
				<ul className={'pl-5 list-[circle] mb-4 flex flex-col gap-1'}>{renderFiltersInfo()}</ul>
			) : (
				<p className={'text-center my-6'}>{t('loc:Nie sú zvolené žiadne filtre')}</p>
			)}

			<Button
				id={SUBMIT_ASYNC_JOB_BUTTON_ID(ASYNC_JOB_TYPE.EXPORT_SALONS_REPORT)}
				className={`noti-btn sticky bottom-6 shadow-[0px_8px_24px_rgba(255,255,255,0.84),_0px_4px_14px_rgba(255,255,255,0.84),_0px_2px_4px_rgba(255,255,255,0.84)] ${!disabledButton ? `hover:shadow-[0px_8px_24px_rgba(0,0,0,0.24),_0px_4px_14px_rgba(0,0,0,0.14),_0px_2px_4px_rgba(0,0,0,0.16)]` : ''}`}
				block
				size='large'
				type='primary'
				htmlType='button'
				onClick={handleSubmitReport}
				disabled={disabledButton}
				loading={submitting}
			>
				{totalCount !== undefined ? t('loc:Generovať ({{ itemsCount }} záznamov)', { itemsCount: totalCount }) : t('loc:Generovať')}
			</Button>
		</div>
	)
}

export default SalonsReport
