import React from 'react'
import { Spin } from 'antd'

type Props = {
	className?: string
}

const MainLayoutSpin = (props: Props) => {
	const { className = '' } = props

	return (
		<div className={`absolute inset-0 flex items-center justify-center main-background ${className}`}>
			<Spin size='large' />
		</div>
	)
}

export default MainLayoutSpin
