import { useIdleTimer } from 'react-idle-timer'
import { REFRESH_PAGE_INTERVAL } from '../../utils/enums'

const onIdle = () => {
	document.location.reload()
}

const IdleTimer = () => {
	useIdleTimer({
		element: document,
		onIdle,
		debounce: 250,
		timeout: REFRESH_PAGE_INTERVAL // refresh page if user is longer time inactive
	})
	return null
}

export default IdleTimer
