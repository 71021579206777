import React, { FC } from 'react'
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button, Divider, Form } from 'antd'
import { useSelector } from 'react-redux'

// utils
import { formFieldID, showErrorNotification } from '../../../utils/helper'
import { ASYNC_JOB_TYPE, CYPRESS_CLASS_NAMES, DOWNLOAD_BUTTON_ID, FORM, SUBMIT_ASYNC_JOB_BUTTON_ID } from '../../../utils/enums'

// types
import { IConfigPayload, IImportReservationsForm, ILoadingAndFailure, ISelectOptionItem } from '../../../types/interfaces'
import { RootState } from '../../../reducers'

// components
import FileUploadField from '../../../atoms/FileUploadField'
import SelectField from '../../../atoms/SelectField'

// validation
import { validationImportReservationsFn } from '../../../utils/formValidation'

type ComponentProps = {
	config: IConfigPayload & ILoadingAndFailure
}

type Props = InjectedFormProps<IImportReservationsForm, ComponentProps> & ComponentProps

const ImportReservationsForm: FC<Props> = (props) => {
	const [t] = useTranslation()

	const { handleSubmit, submitting, config } = props

	const formValues: Partial<IImportReservationsForm> = useSelector((state: RootState) => getFormValues(FORM.IMPORT_RESERVATIONS)(state))

	const templateOptions: ISelectOptionItem[] = Object.entries(config.data?.importOfReservationsXlsxTemplate || {}).map(([key, value]) => ({
		key: value.id,
		value: value.original,
		label: t('loc:Stiahnuť šablónu {{ template }}', { template: key })
	}))

	const disabledButton = submitting || !formValues?.file

	return (
		<Form onSubmitCapture={handleSubmit} layout={'vertical'} className={'form'}>
			<Field
				component={FileUploadField}
				name={'file'}
				label={t('loc:Vyberte súbor vo formáte {{ formats }}', { formats: '.xlsx' })}
				accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'}
				maxCount={1}
				type={'file'}
				disabled={submitting}
				manualUpload
				required
			/>
			<Divider className={'mt-1 mb-3'} />
			<label htmlFor={formFieldID(FORM.IMPORT_RESERVATIONS, 'template')} className={'block mb-2'}>
				{t('loc:Vzorové šablóny súborov')}
			</label>
			<div className={'flex items-center justify-between gap-1'}>
				<Field
					component={SelectField}
					name={'template'}
					style={{ zIndex: 999 }}
					className={'max-w-64 w-full pb-0'}
					size={'large'}
					showSearch={false}
					labelInValue
					options={templateOptions}
					popupMatchSelectWidth={false}
					placeholder={t('loc:Vyberte šablónu na stiahnutie')}
					getPopupContainer={(node: HTMLElement) => node.closest('.ant-modal-body')}
					popupClassName={CYPRESS_CLASS_NAMES.SELECT_TEMPLATE_RESERVATIONS_DROPDOWN}
				/>
				<Button
					id={formFieldID(FORM.IMPORT_RESERVATIONS, DOWNLOAD_BUTTON_ID)}
					className={'noti-btn flex-shrink-0'}
					href={formValues?.template?.value || undefined}
					target='_blank'
					rel='noopener noreferrer'
					type={'default'}
					disabled={!formValues?.template?.value}
					htmlType={'button'}
					download
				>
					<div>{t('loc:Stiahnuť')}</div>
				</Button>
			</div>
			<Button
				id={formFieldID(FORM.IMPORT_RESERVATIONS, SUBMIT_ASYNC_JOB_BUTTON_ID(ASYNC_JOB_TYPE.IMPORT_CALENDAR_EVENTS))}
				className={`noti-btn sticky mt-4 bottom-6 shadow-[0px_8px_24px_rgba(255,255,255,0.84),_0px_4px_14px_rgba(255,255,255,0.84),_0px_2px_4px_rgba(255,255,255,0.84)] ${!disabledButton ? `hover:shadow-[0px_8px_24px_rgba(0,0,0,0.24),_0px_4px_14px_rgba(0,0,0,0.14),_0px_2px_4px_rgba(0,0,0,0.16)]` : ''}`}
				block
				size='large'
				type='primary'
				htmlType='submit'
				disabled={submitting || !formValues?.file}
				loading={submitting}
			>
				{t('loc:Importovať')}
			</Button>
		</Form>
	)
}

const form = reduxForm<IImportReservationsForm, ComponentProps>({
	form: FORM.IMPORT_RESERVATIONS,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	onSubmitFail: showErrorNotification,
	validate: validationImportReservationsFn
})(ImportReservationsForm)

export default form
