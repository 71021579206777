import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row, Spin, TableProps } from 'antd'
import { initialize } from 'redux-form'

// components
import CustomTable from '../../components/CustomTable'
import SalonsDeletedFilter from './components/filters/SalonsDeletedFilter'

// utils
import { FORM, ROW_GUTTER_X_DEFAULT, SALONS_ORDER, SALONS_TAB_KEYS } from '../../utils/enums'
import { getLinkWithEncodedBackUrl, normalizeDirectionKeys } from '../../utils/helper'
import { SalonsPageCommonProps, getSalonsColumns } from './components/salonUtils'

// reducers
import { getSalons } from '../../reducers/salons/salonsActions'
import { RootState } from '../../reducers'
import { getCategories } from '../../reducers/categories/categoriesActions'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// schema
import { salonsDeletedPageURLQueryParamsSchema } from '../../schemas/queryParams'

// types
import { GetUrls, ISalonsDeletedFilter, RequestResponse } from '../../types/interfaces'

type TableDataItem = RequestResponse<GetUrls['/api/b2b/admin/salons/']>['salons'][0]

type Props = SalonsPageCommonProps & {}

const SalonsDeletedPage: React.FC<Props> = (props) => {
	const { selectedCountry, t, dispatch, navigate } = props

	const salons = useSelector((state: RootState) => state.salons.salons)
	const categories = useSelector((state: RootState) => state.categories.categories)

	const [query, setQuery] = useQueryParams(salonsDeletedPageURLQueryParamsSchema, {
		page: 1,
		order: SALONS_ORDER.CREATED_AT_DESC
	})

	const countryCode = selectedCountry || query.countryCode

	const salonsColumns = useMemo(() => getSalonsColumns(query.order, categories.data), [query.order, categories.data])

	const columns = [
		salonsColumns.id({ width: '8%' }),
		salonsColumns.name({ width: '20%' }),
		salonsColumns.address({ width: '16%' }),
		salonsColumns.categories({ width: '16%' }),
		salonsColumns.deletedAt({ width: '16%' }),
		salonsColumns.fillingProgress({ width: '16%' }),
		salonsColumns.createdAt({ width: '20%' })
	]

	useEffect(() => {
		dispatch(getCategories())
	}, [dispatch])

	useEffect(() => {
		dispatch(
			initialize(FORM.SALONS_FILTER_DELETED, {
				search: query.search,
				categoryFirstLevelIDs: query.categoryFirstLevelIDs,
				categoryThirdLevelIDs: query.categoryThirdLevelIDs,
				countryCode
			})
		)
		dispatch(
			getSalons({
				page: query.page,
				limit: query.limit,
				search: query.search,
				order: query.order,
				countryCode,
				categoryFirstLevelIDs: query.categoryFirstLevelIDs,
				categoryThirdLevelIDs: query.categoryThirdLevelIDs,
				salonState: SALONS_TAB_KEYS.DELETED
			})
		)
	}, [dispatch, query.page, query.limit, query.search, query.order, query.categoryFirstLevelIDs, query.categoryThirdLevelIDs, countryCode])

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (_pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}` as SALONS_ORDER
			const newQuery = {
				...query,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page
		}
		setQuery(newQuery)
	}

	const handleSubmitDeleted = (values: ISalonsDeletedFilter) => {
		const newQuery = {
			...query,
			...values,
			page: 1
		}
		setQuery(newQuery)
	}

	return (
		<Row gutter={ROW_GUTTER_X_DEFAULT}>
			<Col span={24}>
				<Spin spinning={salons?.isLoading}>
					<div className='content-body'>
						<SalonsDeletedFilter onSubmit={handleSubmitDeleted} />
						<CustomTable
							className='table-fixed'
							onChange={onChangeTable}
							columns={columns || []}
							dataSource={salons?.data?.salons}
							scroll={{ x: 1000 }}
							rowKey='id'
							rowClassName={'clickable-row'}
							twoToneRows
							useCustomPagination
							pagination={{
								pageSize: salons?.data?.pagination?.limit,
								total: salons?.data?.pagination?.totalCount,
								current: salons?.data?.pagination?.page,
								onChange: onChangePagination,
								disabled: salons?.isLoading
							}}
							onRow={(record) => ({
								onClick: () => {
									navigate(getLinkWithEncodedBackUrl(t('paths:salons/{{salonID}}', { salonID: record.id })))
								}
							})}
						/>
					</div>
				</Spin>
			</Col>
		</Row>
	)
}

export default SalonsDeletedPage
