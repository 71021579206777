import dayjs from 'dayjs'

// types
import { ISearchable, IAuthUserPayload, RequestResponse, GetUrls } from '../../types/interfaces'
import { USER_NOTIFICATIONS, SLICE_USER_NOTIFICATIONS, DELETE_USER_NOTIFICATION, MARK_USER_NOTIFICATION_AS_READ } from './notificationsTypes'
import { IResetStore } from '../generalTypes'
import { IGetUserNotificationsQueryParams } from '../../types/schemaTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'

// utils
import { getReq } from '../../utils/request'
import { AUTH_USER } from '../users/userTypes'

export type INotificationsActions = IResetStore | IGetUserNotifications | ISliceUserNotifications | IDeleteUserNotification | IMarkUserNotificationAsRead

interface IGetUserNotifications {
	type: USER_NOTIFICATIONS
	payload: IUserNotificationsPayload
}

interface ISliceUserNotifications {
	type: typeof SLICE_USER_NOTIFICATIONS
	payload: IUserNotificationsPayload
}

interface IDeleteUserNotification {
	type: typeof DELETE_USER_NOTIFICATION
	payload: IUserNotificationsPayload
}

interface IMarkUserNotificationAsRead {
	type: typeof MARK_USER_NOTIFICATION_AS_READ
	payload: IUserNotificationsPayload
}

export interface IUserNotificationsPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/users/{userID}/notifications/']>> {}

const NOTIFICATIONS_PAGE_LIMIT = 20

export const getUserNotifications =
	(queryParams: IGetUserNotificationsQueryParams, mergeWithPreviousData = true): ThunkResult<Promise<IUserNotificationsPayload>> =>
	// eslint-disable-next-line consistent-return
	async (dispatch, getState) => {
		let payload = {} as IUserNotificationsPayload
		try {
			const { limit = NOTIFICATIONS_PAGE_LIMIT, page = 1, onlyUnread = false, userID, notificationEventGroupType } = queryParams
			dispatch({ type: USER_NOTIFICATIONS.USER_NOTIFICATIONS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/users/{userID}/notifications/', {
				params: { path: { userID }, query: { limit, page, onlyUnread, notificationEventGroupType } },
				reqBody: {}
			})

			let notificationsData = data.notifications

			if (mergeWithPreviousData) {
				// existing notifications
				const stateNotifications = getState().userNotifications.userNotifications.data?.notifications || []
				// filter notifications with same id and merge with existing data
				const filteredNotifications = data.notifications.filter((notif) => !stateNotifications.find((stateNotif) => stateNotif.id === notif.id))
				notificationsData = [...stateNotifications, ...filteredNotifications]
			}

			payload = {
				data: {
					...data,
					notifications: notificationsData
				}
			}

			dispatch({ type: USER_NOTIFICATIONS.USER_NOTIFICATIONS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: USER_NOTIFICATIONS.USER_NOTIFICATIONS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const sliceUserNotifications =
	(sliceIndex = NOTIFICATIONS_PAGE_LIMIT): ThunkResult<IUserNotificationsPayload> =>
	// eslint-disable-next-line consistent-return
	(dispatch, getState) => {
		let payload = {} as IUserNotificationsPayload

		const notificationsData = getState().userNotifications.userNotifications.data

		if (notificationsData) {
			payload = {
				data: {
					...notificationsData,
					notifications: notificationsData.notifications.splice(0, sliceIndex),
					pagination: {
						...notificationsData.pagination,
						page: 1
					}
				}
			}
		}

		dispatch({ type: SLICE_USER_NOTIFICATIONS, payload })

		return payload
	}

export const deleteUserNotification =
	(notificationID: string): ThunkResult<IUserNotificationsPayload> =>
	// eslint-disable-next-line consistent-return
	(dispatch, getState) => {
		let payload = {} as IUserNotificationsPayload

		const notificationsData = getState().userNotifications.userNotifications.data

		if (notificationsData) {
			payload = {
				data: {
					...notificationsData,
					notifications: notificationsData.notifications.filter((notif) => notif.id !== notificationID)
				}
			}
		}

		dispatch({ type: DELETE_USER_NOTIFICATION, payload })

		return payload
	}

export const markUserNotificationAsRead =
	(markAsRead: boolean, onlyUnread: boolean, notificationID?: string): ThunkResult<IUserNotificationsPayload> =>
	// eslint-disable-next-line consistent-return
	(dispatch, getState) => {
		let payload = {} as IUserNotificationsPayload

		const notificationsData = getState().userNotifications.userNotifications.data

		if (notificationsData) {
			let notifications: NonNullable<IUserNotificationsPayload['data']>['notifications'] = []

			if (notificationID) {
				// update only one entity
				if (onlyUnread) {
					// remove from the list
					notifications = notificationsData.notifications.filter((notif) => notif.id !== notificationID)
				} else {
					// update readAt attribute
					notifications = notificationsData.notifications.map((notif) => {
						if (notif.id === notificationID) {
							return { ...notif, readAt: markAsRead ? dayjs().toISOString() : undefined }
						}
						return notif
					})
				}
			} else if (!onlyUnread) {
				// update all entites
				notifications = notificationsData.notifications.map((notif) => ({ ...notif, readAt: markAsRead ? dayjs().toISOString() : undefined }))
			}

			payload = {
				data: {
					...notificationsData,
					notifications
				}
			}
		}

		dispatch({ type: MARK_USER_NOTIFICATION_AS_READ, payload })

		return payload
	}

export const updateUnreadNotificationsCount = (): ThunkResult<Promise<IAuthUserPayload>> => async (dispatch, getState) => {
	let payload = {} as IAuthUserPayload

	try {
		const authUser = getState().user.authUser.data

		if (authUser) {
			const { data } = await getReq('/api/b2b/admin/users/{userID}', { params: { path: { userID: authUser.id } }, reqBody: {} })

			payload = {
				data: {
					...authUser,
					notificationCentre: data.user.notificationCentre
				}
			}
			dispatch({ type: AUTH_USER.AUTH_USER_LOAD_DONE, payload })
		}
	} catch (err) {
		dispatch({ type: AUTH_USER.AUTH_USER_LOAD_FAIL })
		// eslint-disable-next-line no-console
		console.error(err)
	}

	return payload
}
