import React, { FC } from 'react'
import { Field, FieldArray, InjectedFormProps, WrappedFieldArrayProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button, Col, Divider, Form, FormProps, Row, TabsProps } from 'antd'

// utils
import {
	ADD_MAIN_FEATURE_BUTTON_ID,
	CHANGELOG_MAIN_FEATURES_MAX_COUNT,
	CHANGELOG_PLATFORM,
	DELETE_BUTTON_ID,
	FORM,
	LANGUAGE,
	LOCALES,
	ROW_GUTTER_X_M,
	SAVE_CONCEPT_BUTTON_ID,
	SHOW_BUTTON_ID,
	SUBMIT_BUTTON_ID,
	VALIDATION_MAX_LENGTH
} from '../../../utils/enums'
import { CHANGELOG_PLATFORM_OPTIONS, formFieldID, showErrorNotification } from '../../../utils/helper'

// components
import InputField from '../../../atoms/InputField'
import TextareaField from '../../../atoms/TextareaField'
import SelectField from '../../../atoms/SelectField'
import DeleteButton from '../../../components/DeleteButton'
import TabsComponent from '../../../components/TabsComponent'

// types
import { IChangelogForm } from '../../../types/interfaces'
import { IChangelogMainFeature } from '../../../types/schemaTypes'

// validation
import { validationChangelogsFn } from '../../../utils/formValidation'

// assets
import PlusIcon from '../../../assets/icons/plus-icon.svg?react'
import EyeIcon from '../../../assets/icons/eye-icon.svg?react'

type MainFeatureFieldProps = WrappedFieldArrayProps<IChangelogMainFeature> & {
	disabled?: boolean
	onAddMainFeature: () => void
}

const DEFAULT_MAIN_FEATURE: IChangelogForm['mainFeatures'][0] = { name: '', description: '' }

const MainFeatureField: FC<MainFeatureFieldProps> = (props) => {
	const [t] = useTranslation()

	const { fields, disabled, onAddMainFeature } = props

	const handleAddField = () => {
		fields.push(DEFAULT_MAIN_FEATURE)
		onAddMainFeature()
	}

	return (
		<div className={'flex flex-col gap-4'}>
			{fields.map((field, index: number) => {
				const { name, description } = fields.get(index)
				const showPopConfirm = name || description

				const handleDeleteField = () => {
					fields.remove(index)
				}

				return (
					<div className={'bg-notino-grayLighter rounded-2xl p-4'} key={index}>
						<h3 className={'text-lg leading-6 text-notino-black font-bold mb-4'}>{t('loc:{{index}}. funkcia', { index: index + 1 })}</h3>
						<Field
							component={InputField}
							label={t('loc:Názov funkcie')}
							name={`${field}.name`}
							size={'large'}
							placeholder={t('loc:Napíšte názov funkcie')}
							maxLength={VALIDATION_MAX_LENGTH.LENGTH_50}
							className={'pb-4'}
							required
							disabled={disabled}
						/>
						<Field
							component={TextareaField}
							label={t('loc:Popis funkcie')}
							name={`${field}.description`}
							size={'large'}
							placeholder={t('loc:Napíšte popis upozornenia')}
							maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
							showLettersCount
							rows={4}
							disabled={disabled}
							className={'pb-2'}
						/>
						<p className={'text-notino-grayDark text-xs leading-4'}>{t('loc:Odporúčaná dĺžka popisu je do 150 znakov.')}</p>
						<div className={'flex justify-end items-center'}>
							<DeleteButton
								id={formFieldID(FORM.CHANGELOG, `${field}-${DELETE_BUTTON_ID}`)}
								entityName={t('loc:promovanú funkciu')}
								type='text'
								size='middle'
								className={'noti-changelog-delete-button bg-transparent'}
								disabled={fields.length <= 1}
								onConfirm={handleDeleteField}
								onClick={() => {
									if (showPopConfirm) {
										return
									}
									handleDeleteField()
								}}
							/>
						</div>
					</div>
				)
			})}
			<Button
				id={formFieldID(FORM.CHANGELOG, ADD_MAIN_FEATURE_BUTTON_ID)}
				type={'primary'}
				icon={<PlusIcon />}
				size={'middle'}
				className={'noti-btn self-start'}
				htmlType={'button'}
				onClick={handleAddField}
				disabled={disabled || fields.length >= CHANGELOG_MAIN_FEATURES_MAX_COUNT}
			>
				{t('loc:Pridať promovanú funkciu')}
			</Button>
		</div>
	)
}

type ComponentProps = FormProps & {
	onChangePlatform?: (value: CHANGELOG_PLATFORM) => void
	tabKey: LANGUAGE
	onTabChange: (newTabKey: string) => void
	onAddMainFeature: () => void
	submitHandler: (values: IChangelogForm, isDraft: boolean, currentTabKey: LANGUAGE) => Promise<void>
	setIsWhatsNewModalOpen: (open: boolean) => void
	disabled?: boolean
	isCreate: boolean
	isPublished: boolean
}

type Props = InjectedFormProps<IChangelogForm, ComponentProps> & ComponentProps

const tabContent: TabsProps['items'] = Object.values(LANGUAGE).map((lng) => ({
	key: lng,
	label: LOCALES[lng].countryCode.toUpperCase()
}))

const ChangelogForm: FC<Props> = (props) => {
	const { onChangePlatform, tabKey, onTabChange, onAddMainFeature, setIsWhatsNewModalOpen, disabled, isCreate, isPublished } = props

	const [t] = useTranslation()

	const { handleSubmit, submitHandler } = props

	const showSaveChangesButton = !isCreate && isPublished

	return (
		<Form layout={'vertical'} className={'form'} onSubmitCapture={handleSubmit((values) => submitHandler(values, false, tabKey))}>
			<h2 className={'text-2xl leading-8 text-notino-black mb-0 font-bold'}>{t('loc:Aplikácia')}</h2>
			<p className={'text-sm text-notino-grayMedium font-normal leading-5 mb-4'}>
				{t('loc:Vyberte aplikáciu, ktorej chcete nastaviť upozornenie o novinkách a zadajte jej verziu.')}
			</p>
			<Row gutter={ROW_GUTTER_X_M}>
				<Col span={12}>
					<Field
						component={SelectField}
						name={'platform'}
						label={t('loc:Aplikácia')}
						placeholder={t('loc:Vyberte aplikáciu')}
						size={'large'}
						options={CHANGELOG_PLATFORM_OPTIONS()}
						onChange={onChangePlatform ? (value: any) => onChangePlatform(value) : undefined}
						required
					/>
				</Col>
				<Col span={12}>
					<Field
						component={InputField}
						label={t('loc:Názov verzie')}
						name={'version'}
						size={'large'}
						placeholder={t('loc:Zadajte verziu aplikácie')}
						maxLength={VALIDATION_MAX_LENGTH.LENGTH_50}
						className={'pb-4'}
						required
						disabled={disabled}
					/>
				</Col>
			</Row>
			<TabsComponent activeKey={tabKey} onChange={onTabChange} items={tabContent} className={'noti-changelog-tabs sticky top-16  mb-6'} style={{ zIndex: 1 }} />
			<h2 className={'text-2xl leading-8 text-notino-black mb-0 font-bold'}>{t('loc:Promované funkcie')}</h2>
			<p className={'text-sm text-notino-grayMedium font-normal leading-5 mb-4'}>
				{t('loc:Vyberte funkcie, ktoré sú vo vybranej verzii aplikácie najdôležitejšie. Promované funkcie sa zobrazia na vrchu oznámenia.')}
			</p>
			<FieldArray name={'mainFeatures'} component={MainFeatureField} onAddMainFeature={onAddMainFeature} />
			<Divider className={'my-6'} />
			<h2 className={'text-2xl leading-8 text-notino-black mb-0 font-bold'}>{t('loc:Ďalšie funkcie')}</h2>
			<p className={'text-sm text-notino-grayMedium font-normal leading-5 mb-4'}>
				{t('loc:Napíšte ďalšie funkcie, ktoré vybraná verzia aplikácie obsahuje. Tieto funkcie budú zobrazené pod promovanými funkciami ako zoznam.')}
			</p>
			<Field
				component={TextareaField}
				label={t('loc:Zoznam ďalších funkcií')}
				name={'otherFeatures.description'}
				size={'large'}
				placeholder={t('loc:Napíšte popis upozornenia')}
				maxLength={VALIDATION_MAX_LENGTH.LENGTH_1000}
				showLettersCount
				rows={4}
				disabled={disabled}
				className={'pb-2'}
			/>
			<p className={'text-notino-grayDark text-xs leading-4'}>{t('loc:Jednotlivé funkcie oddeľte bodkočiarkou bez medzery.')}</p>
			<div className={'content-footer flex items-center flex-wrap justify-end gap-3'} id={'content-footer-container'}>
				<Button
					id={formFieldID(FORM.CHANGELOG, SHOW_BUTTON_ID)}
					type={'dashed'}
					size={'middle'}
					className={'noti-btn'}
					icon={<EyeIcon />}
					htmlType={'button'}
					disabled={disabled}
					onClick={() => setIsWhatsNewModalOpen(true)}
				>
					{t('loc:Zobraziť náhľad')}
				</Button>
				{!showSaveChangesButton && (
					<Button
						id={formFieldID(FORM.CHANGELOG, SAVE_CONCEPT_BUTTON_ID)}
						type={'dashed'}
						size={'middle'}
						className={'noti-btn'}
						htmlType={'button'}
						disabled={disabled}
						onClick={handleSubmit((values) => {
							submitHandler(values, true, tabKey)
						})}
					>
						{t('loc:Uložiť ako koncept')}
					</Button>
				)}
				<Button id={formFieldID(FORM.CHANGELOG, SUBMIT_BUTTON_ID)} type={'primary'} size={'middle'} className={'noti-btn'} htmlType={'submit'} disabled={disabled}>
					{!showSaveChangesButton ? t('loc:Publikovať') : t('loc:Uložiť zmeny')}
				</Button>
			</div>
		</Form>
	)
}

const form = reduxForm<IChangelogForm, ComponentProps>({
	form: FORM.CHANGELOG,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	onSubmitFail: showErrorNotification,
	validate: validationChangelogsFn
})(ChangelogForm)

export default form
