import { z } from 'zod'
import { fileConstraint } from './baseSchema'

export const importCustomers = z.object({
	file: fileConstraint.array(),
	template: z
		.object({
			value: z.string(),
			label: z.string()
		})
		.nullish()
})

export const importReservations = z.object({
	file: fileConstraint.array(),
	template: z
		.object({
			value: z.string(),
			label: z.string()
		})
		.nullish()
})

export const importSalons = z.object({
	file: fileConstraint.array()
})
