/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { gtag } from '../utils/dataLayer'

// env
import envConfig from '../config'

const useTagManager = () => {
	useEffect(() => {
		;(async () => {
			if (envConfig.VITE_NOTINO_GTM_ID) {
				TagManager.initialize({ gtmId: envConfig.VITE_NOTINO_GTM_ID })
				gtag('consent', 'default', {
					ad_storage: 'denied',
					analytics_storage: 'denied',
					functionality_storage: 'granted',
					personalization_storage: 'granted',
					wait_for_update: 2000
				})
			}
		})()
	}, [])

	return null
}

export default useTagManager
