import { z } from 'zod'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'

import { dateConstraint, selectObjConstraint, serializeValidationMessage, stringConstraint } from './baseSchema'
import { CALENDAR_EVENT_TYPE, DAY, EVERY_REPEAT, INVALID_DATE_FORMAT, VALIDATION_MAX_LENGTH } from '../utils/enums'
import { timeRegex } from '../utils/regex'
import { formatDateTimeByLocale } from '../utils/intl'

// https://notino-admin.goodrequest.dev/api/doc/?urls.primaryName=v2.2.55#/B2b-%3Eadmin/postApiB2BAdminSalonsSalonIdCalendarEvents
// eslint-disable-next-line import/prefer-default-export
export const eventSchema = z
	.object({
		recurring: z.boolean().optional(),
		end: dateConstraint.optional(),
		allDay: z.boolean().nullish(),
		every: z.nativeEnum(EVERY_REPEAT).nullish(),
		repeatOn: z.nativeEnum(DAY).array().nullish(),
		date: dateConstraint.nullish()
	})
	.superRefine(({ recurring, end, repeatOn, date }, ctx) => {
		if (!date) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Toto pole je povinné'),
				path: ['date']
			})
		}
		if (recurring && isEmpty(repeatOn)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Možnosť opakovania musí obsahovať aspoň jeden platný deň'),
				path: ['repeatOn']
			})
		}
		if (date && end && dayjs(date).isSameOrAfter(dayjs(end))) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Koniec opakovania musí byť po dátume {{ date }}', {
					date: formatDateTimeByLocale(date, { timeStyle: null, fallback: INVALID_DATE_FORMAT })
				}),
				path: ['end']
			})
		}
	})
	.and(
		z.object({
			eventType: z.nativeEnum(CALENDAR_EVENT_TYPE),
			employee: selectObjConstraint,
			timeFrom: z.string().regex(timeRegex),
			timeTo: z.string().regex(timeRegex),
			note: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1500)
		})
	)
