import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { useNavigate } from 'react-router-dom'
import { ColumnsType, TableProps } from 'antd/es/table'

// components
import CustomTable from '../../components/CustomTable'
import ChangelogsFilter from './components/ChangelogsFilter'

// utils
import { CHANGELOG_PLATFORM, FORM } from '../../utils/enums'
import { CHANGELOG_PLATFORM_TRANSLATIONS, getChangelogStatusTag, normalizeDirectionKeys, setOrder } from '../../utils/helper'
import { isEnumValue, LocalizedDateTime } from '../../utils/intl'

// reducers
import { RootState } from '../../reducers'
import { getChangelogs, IChangelogsPayload } from '../../reducers/changelogs/changelogsActions'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// schema
import { changelogsPageURLQueryParams } from '../../schemas/queryParams'

// types
import { IChangelogsFilter } from '../../types/interfaces'

type TableDataItem = NonNullable<IChangelogsPayload['data']>['changelogs'][0]

const ChangelogsTab = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const changelogs = useSelector((state: RootState) => state.changelogs.changelogs)
	const navigate = useNavigate()

	const [query, setQuery] = useQueryParams(changelogsPageURLQueryParams, {
		page: 1,
		order: 'updatedAt:DESC'
	})

	const PLATFORM_TRANSLATIONS = CHANGELOG_PLATFORM_TRANSLATIONS()

	useEffect(() => {
		dispatch(
			initialize(FORM.DYNAMIC_NOTIFICATIONS_FILTER, {
				platform: query.platform,
				search: query.search
			})
		)
		dispatch(
			getChangelogs({
				platform: query.platform,
				search: query.search,
				page: query.page,
				order: query.order,
				limit: query.limit
			})
		)
	}, [dispatch, query.order, query.platform, query.limit, query.page, query.search])

	const handleSubmit = (values: IChangelogsFilter) => {
		const newQuery = {
			...query,
			search: values.search,
			platform: values.platform,
			page: 1
		}
		setQuery(newQuery)
	}

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}`
			const newQuery = {
				...query,
				limit: pagination.pageSize,
				page: pagination.current,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === changelogs?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}

	const columns: ColumnsType<TableDataItem> = [
		{
			title: t('loc:Aplikácia'),
			dataIndex: 'platform',
			key: 'platform',
			width: '20%',
			render: (value: TableDataItem['platform']) => {
				return isEnumValue(value, CHANGELOG_PLATFORM) ? PLATFORM_TRANSLATIONS[value] : '-'
			}
		},
		{
			title: t('loc:Verzia'),
			dataIndex: 'version',
			key: 'version',
			width: '20%'
		},
		{
			title: t('loc:Stav'),
			dataIndex: 'status',
			key: 'status',
			width: '20%',
			render: (value: TableDataItem['status']) => {
				return getChangelogStatusTag(value)
			}
		},
		{
			title: t('loc:Dátum publikovania'),
			dataIndex: 'publishedAt',
			key: 'publishedAt',
			width: '20%',
			render: (value: TableDataItem['publishedAt']) => <LocalizedDateTime date={value} timeStyle={null} ellipsis fallback={'-'} />
		},
		{
			title: t('loc:Dátum poslednej úpravy'),
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			width: '20%',
			sortOrder: setOrder(query.order, 'updatedAt'),
			render: (value: TableDataItem['updatedAt']) => <LocalizedDateTime date={value} timeStyle={null} ellipsis fallback={'-'} />
		}
	]

	return (
		<>
			<ChangelogsFilter onSubmit={handleSubmit} />
			<CustomTable<TableDataItem>
				className='table-fixed'
				columns={columns}
				rowKey={'id'}
				rowClassName={'clickable-row'}
				onChange={onChangeTable}
				loading={changelogs?.isLoading}
				dataSource={changelogs.data?.changelogs}
				onRow={(record) => ({
					onClick: () => {
						navigate({
							pathname: t('paths:notifications/changelogs/{{changelogID}}', { changelogID: record.id })
						})
					}
				})}
				twoToneRows
				scroll={{ x: 800 }}
				useCustomPagination
				pagination={{
					pageSize: changelogs.data?.pagination.limit,
					total: changelogs.data?.pagination.totalCount,
					current: changelogs.data?.pagination.page,
					onChange: onChangePagination,
					disabled: changelogs.isLoading
				}}
			/>
		</>
	)
}

export default ChangelogsTab
