import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// components
import CreatePasswordForm from './components/CreatePasswordForm'
import ForgottenPasswordModal from '../../components/ForgottenPassword/ForgottenPasswordModal'
import SelectAccountModal from '../../components/SelectAccountModal'

// actions
import { getCurrentUser, processAuthorizationResult } from '../../reducers/users/userActions'

// utils
import { postReq } from '../../utils/request'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// schema
import { createPasswordPageURLQueryParamsSchema } from '../../schemas/queryParams'

// types
import { IAuthUserPayload, ICreatePasswordForm } from '../../types/interfaces'
import { getAssignedUserLabel, showNotifications } from '../../utils/helper'
import { getAccessToken, getRefreshToken, isLoggedIn } from '../../utils/auth'
import { MSG_TYPE } from '../../utils/enums'

const CreatePasswordPage = () => {
	const dispatch = useDispatch()
	const [t] = useTranslation()

	const [query] = useQueryParams(createPasswordPageURLQueryParamsSchema)

	const [modalVisible, setModalVisible] = useState(false)
	const [selectAccountModalData, setSelectAccountModalData] = useState<{ user: IAuthUserPayload['data']; values: ICreatePasswordForm } | null>(null)
	const [submitting, setSubmitting] = useState(false)

	const handleCreatePasswordRequest = async (values: ICreatePasswordForm, postMessage = false) => {
		setSubmitting(true)
		try {
			const input = {
				password: values.confirmPassword
			}

			const config = {
				headers: {
					Authorization: `Bearer ${query.t}`
				}
			}

			const { data } = await postReq('/api/b2b/admin/auth/reset-password', { params: {}, reqBody: input, customConfig: config })
			dispatch(processAuthorizationResult(data, t('paths:index'), postMessage))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		} finally {
			setSubmitting(false)
		}
	}

	const handleCreatePasswordSubmit = async (values: ICreatePasswordForm) => {
		if (isLoggedIn()) {
			setSubmitting(true)
			try {
				const { data: user } = await dispatch(getCurrentUser())
				setSelectAccountModalData({ user, values })
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
				handleCreatePasswordRequest(values, true)
			} finally {
				setSubmitting(false)
			}
			return
		}

		handleCreatePasswordRequest(values)
	}

	const handleContinueWithCurrentAccount = async (user: IAuthUserPayload['data']) => {
		const accessToken = getAccessToken()
		const refreshToken = getRefreshToken()

		if (accessToken && refreshToken && user) {
			await dispatch(processAuthorizationResult({ accessToken, refreshToken, user }))
		} else {
			showNotifications([{ type: MSG_TYPE.ERROR, message: t('loc:Ups niečo sa pokazilo') }])
		}
	}

	const handleLoginWithNewAccount = async (values: ICreatePasswordForm) => {
		handleCreatePasswordRequest(values, true)
	}

	return (
		<div className={'pb-8 w-full max-w-80 mx-auto'}>
			<CreatePasswordForm onSubmit={handleCreatePasswordSubmit} showForgottenPasswordModal={() => setModalVisible(true)} isSubmitting={submitting} />
			{modalVisible && <ForgottenPasswordModal visible={modalVisible} onClose={() => setModalVisible(false)} />}
			{selectAccountModalData && (
				<SelectAccountModal
					open
					onCancel={() => setSelectAccountModalData(null)}
					currentAccountName={selectAccountModalData.user ? getAssignedUserLabel(selectAccountModalData.user, true) : t('loc:iným')}
					handleContinueWithCurrentAccount={() => handleContinueWithCurrentAccount(selectAccountModalData.user)}
					handleLoginWithNewAccount={() => handleLoginWithNewAccount(selectAccountModalData.values)}
					submitting={submitting}
					additionalText={<p className={'mb-4'}>{t('loc:Chcete ďalej pokračovať pod týmto účtom alebo chcete pokračovať pod novým účtom?')}</p>}
					primaryButtonText={t('loc:Prihlásiť pod nový účtom')}
					secondaryButtonText={t('loc:Pokračovať pod prihláseným účtom')}
				/>
			)}
		</div>
	)
}

export default CreatePasswordPage
