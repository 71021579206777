import { z } from 'zod'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { stringConstraint, imageConstraint, emailConstraint, twoCharsConstraint, uuidConstraint, serializeValidationMessage, dateConstraint } from './baseSchema'
import { DAY, DEFAULT_TIME_FORMAT, EMPLOYEE_WORKING_HOURS_TYPE, EVERY_REPEAT, INVALID_DATE_FORMAT, VALIDATION_MAX_LENGTH } from '../utils/enums'
import { timeRegex } from '../utils/regex'
import { formatDateTimeByLocale } from '../utils/intl'

// https://notino-admin.goodrequest.dev/api/doc/#/B2b-%3Eadmin/postApiB2BAdminEmployees
export const employeeSchema = z.object({
	avatar: imageConstraint.array().max(1).nullish(),
	firstName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_50, true),
	lastName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_50, true),
	email: emailConstraint.optional().nullable(),
	phonePrefixCountryCode: twoCharsConstraint.optional(),
	phone: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_20)
})

export const employeeServicesSchema = z.object({
	serviceIDs: uuidConstraint.array().nullish(),
	services: z
		.object({
			id: uuidConstraint
		})
		.array()
})

// https://notino-admin.goodrequest.dev/api/doc/#/B2b-%3Eadmin/postApiB2BAdminEmployeesInvite
export const inviteEmployeeSchema = z.object({
	roleID: uuidConstraint,
	email: emailConstraint,
	firstName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100, true),
	lastName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100, true)
})

const timeRangesSchema = z.object({
	timeFrom: z.string().regex(timeRegex).nullish(),
	timeTo: z.string().regex(timeRegex).nullish()
})

export const workingHoursDaySchema = z.object({
	day: z.nativeEnum(DAY),
	checked: z.boolean(),
	timeRanges: z.array(timeRangesSchema)
})

type TimeInterval = z.infer<typeof timeRangesSchema>

const doIntervalsOverlap = (interval1: TimeInterval, interval2: TimeInterval): boolean => {
	if (!interval1.timeFrom || !interval1.timeTo || !interval2.timeFrom || !interval2.timeTo) {
		return false
	}

	const startTime1 = dayjs(interval1.timeFrom, DEFAULT_TIME_FORMAT)
	const endTime1 = dayjs(interval1.timeTo, DEFAULT_TIME_FORMAT)
	const startTime2 = dayjs(interval2.timeFrom, DEFAULT_TIME_FORMAT)
	const endTime2 = dayjs(interval2.timeTo, DEFAULT_TIME_FORMAT)

	return (
		startTime1.isBetween(startTime2, endTime2, null, '[]') ||
		endTime1.isBetween(startTime2, endTime2, null, '[]') ||
		startTime2.isBetween(startTime1, endTime1, null, '[]') ||
		endTime2.isBetween(startTime1, endTime1, null, '[]')
	)
}

const intervalsOverlap = (intervals: TimeInterval[]): boolean => {
	let overlap = false

	intervals.forEach((interval1, index1) => {
		intervals.slice(index1 + 1).forEach((interval2) => {
			if (doIntervalsOverlap(interval1, interval2)) {
				overlap = true
			}
		})
	})

	return overlap
}

export const employeeShiftsSchema = z
	.object({
		workingHoursType: z.nativeEnum(EMPLOYEE_WORKING_HOURS_TYPE),
		workingHours: z.array(workingHoursDaySchema)
	})
	.superRefine(({ workingHoursType, workingHours }, ctx) => {
		if (workingHoursType === EMPLOYEE_WORKING_HOURS_TYPE.BY_DAY) {
			workingHours.forEach((day, dayIndex) => {
				if (day.checked) {
					if (!day.timeRanges.length) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							message: serializeValidationMessage('loc:Zadajte aspoň jeden interval'),
							path: ['workingHours', dayIndex, 'timeRanges', '_error']
						})
					}

					const validTimeRanges = day.timeRanges.filter(
						(timeRange) =>
							timeRange.timeFrom && timeRange.timeTo && dayjs(timeRange.timeFrom, DEFAULT_TIME_FORMAT).isBefore(dayjs(timeRange.timeTo, DEFAULT_TIME_FORMAT))
					)
					if (validTimeRanges.length && intervalsOverlap(validTimeRanges)) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							message: serializeValidationMessage('loc:Intervaly sa nesmú prelínať'),
							path: ['workingHours', dayIndex, 'timeRanges', '_error']
						})
					}

					day.timeRanges.forEach(({ timeFrom, timeTo }, timeRangeIndex) => {
						if (!timeTo) {
							ctx.addIssue({
								code: z.ZodIssueCode.custom,
								message: serializeValidationMessage('loc:Toto pole je povinné'),
								path: ['workingHours', dayIndex, 'timeRanges', timeRangeIndex, 'timeTo']
							})
						}

						if (!timeFrom) {
							ctx.addIssue({
								code: z.ZodIssueCode.custom,
								message: serializeValidationMessage('loc:Toto pole je povinné'),
								path: ['workingHours', dayIndex, 'timeRanges', timeRangeIndex, 'timeFrom']
							})
						}
					})
				}
			})
		}
	})

export const employeeShiftPlanningSchema = z
	.object({
		recurring: z.boolean().optional(),
		untilDate: dateConstraint.nullish(),
		every: z.nativeEnum(EVERY_REPEAT).nullish(),
		repeatOn: z.nativeEnum(DAY).array().nullish(),
		date: dateConstraint.nullish()
	})
	.superRefine(({ recurring, untilDate, repeatOn, date }, ctx) => {
		if (!date) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Toto pole je povinné'),
				path: ['date']
			})
		}
		if (recurring && isEmpty(repeatOn)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Možnosť opakovania musí obsahovať aspoň jeden platný deň'),
				path: ['repeatOn']
			})
		}
		if (date && untilDate && dayjs(date).isSameOrAfter(dayjs(untilDate))) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Koniec opakovania musí byť po dátume {{ date }}', {
					date: formatDateTimeByLocale(date, { timeStyle: null, fallback: INVALID_DATE_FORMAT })
				}),
				path: ['untilDate']
			})
		}
	})
	.and(
		z.object({
			timeFrom: z.string().regex(timeRegex),
			timeTo: z.string().regex(timeRegex)
		})
	)
