import { z } from 'zod'

import { imageConstraint, stringConstraint } from './baseSchema'
import { UPLOAD, VALIDATION_MAX_LENGTH } from '../utils/enums'

export const photoalbumImageConstraint = imageConstraint.extend({
	description: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255)
})

export const photoalbumSchema = z.object({
	name: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255, true),
	photos: photoalbumImageConstraint.nullish().array().max(UPLOAD.PHOTOALBUMS.MAX_COUNT).optional()
})
