import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// components
import LoginForm from './components/LoginForm'
import ForgottenPasswordModal from '../../components/ForgottenPassword/ForgottenPasswordModal'
import SelectAccountModal from '../../components/SelectAccountModal'

// types
import { IAuthUserPayload, ILoginForm } from '../../types/interfaces'

// actions
import { getCurrentUser, processAuthorizationResult } from '../../reducers/users/userActions'

// utils
import { postReq } from '../../utils/request'
import { MSG_TYPE, PERMISSION } from '../../utils/enums'
import { getAccessToken, getRefreshToken, isLoggedIn } from '../../utils/auth'
import { getAssignedUserLabel, showNotifications } from '../../utils/helper'

type Props = {}

const LoginPage: FC<Props> = () => {
	const dispatch = useDispatch()
	const [t] = useTranslation()
	const location = useLocation()

	const [forgottenPasswordModalVisible, setForgottenPasswordModalVisible] = useState(false)
	const [selectAccountModalData, setSelectAccountModalData] = useState<{ user: IAuthUserPayload['data']; values: ILoginForm } | null>(null)
	const [submitting, setSubmitting] = useState(false)

	const handleLoginRequest = async (values: ILoginForm, postMessage = false): Promise<void> => {
		setSubmitting(true)
		try {
			/**
			 * v pripade, ze uzivatel nebol prihlaseny a v prehliadaci zvolil nejaku autorizovanu URL, tak ho to redirectne sem na login pagu
			 * v AuthRoute sa pri redirecte do state objektu ulozi patha, z ktorej sem bol redirectnuty (state = { redirectFrom: 'redirect path' })
			 * ak sa teda v redirectFrom nachadza nejaka URL, tak ma to na nu po uspensom logine presmeruje
			 * do postReq sa posiela skipRedirectToLoginPage kvoli tomu, ze ak uzivatel zada zle heslo, tak sa nasledne vyvola akcia logOut user (vid: postReq => catch => showErrorNotifications => logOutUser)
			 * v nej sa po uspesnom odlhaseni vola redirect na login pagu, ktory by premazal URLcku ulozenu v state objekte.. kedze vsak viem, ze uz sa nachadzam na login page, tento redirect v logOut akcii uz nie je nutny
			 */
			const { data } = await postReq('/api/b2b/admin/auth/login', { params: {}, reqBody: values, customConfig: { skipRedirectToLoginPage: true, skip401Logout: true } })

			const isSuperAdmin = data.user.roles.some((role) => role.permissions.findIndex((permission) => permission.name === PERMISSION.NOTINO_SUPER_ADMIN) > -1)
			await dispatch(processAuthorizationResult(data, isSuperAdmin ? t('paths:login-as-partner') : location?.state?.redirectFrom, postMessage))
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	const handleContinueWithCurrentAccount = async (user: IAuthUserPayload['data'], onError: () => void) => {
		const accessToken = getAccessToken()
		const refreshToken = getRefreshToken()

		if (accessToken && refreshToken && user) {
			await dispatch(processAuthorizationResult({ accessToken, refreshToken, user }, location?.state?.redirectFrom))
			return
		}
		onError()
	}

	const handleLoginSubmit = async (values: ILoginForm) => {
		if (isLoggedIn()) {
			try {
				setSubmitting(true)
				const { data: user } = await dispatch(getCurrentUser())

				if (values.email !== user?.email) {
					setSelectAccountModalData({ user, values })
				} else {
					handleContinueWithCurrentAccount(user, () => handleLoginRequest(values, true))
				}

				return
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
				handleLoginRequest(values, true)
				return
			} finally {
				setSubmitting(false)
			}
		}

		handleLoginRequest(values)
	}

	const handleLoginWithNewAccount = async (values: ILoginForm) => {
		handleLoginRequest(values, true)
	}

	return (
		<div className='mt-16 max-w-80 w-full mx-auto'>
			<h3>{t('loc:Prihlásenie')}</h3>
			<LoginForm onSubmit={handleLoginSubmit} showForgottenPasswordModal={() => setForgottenPasswordModalVisible(true)} isSubmitting={submitting} />
			{forgottenPasswordModalVisible && <ForgottenPasswordModal visible={forgottenPasswordModalVisible} onClose={() => setForgottenPasswordModalVisible(false)} />}
			{selectAccountModalData && (
				<SelectAccountModal
					open
					onCancel={() => setSelectAccountModalData(null)}
					currentAccountName={selectAccountModalData.user ? getAssignedUserLabel(selectAccountModalData.user, true) : t('loc:iným')}
					handleContinueWithCurrentAccount={() =>
						handleContinueWithCurrentAccount(selectAccountModalData.user, () => {
							showNotifications([{ type: MSG_TYPE.ERROR, message: t('loc:Ups niečo sa pokazilo') }])
						})
					}
					handleLoginWithNewAccount={() => handleLoginWithNewAccount(selectAccountModalData.values)}
					submitting={submitting}
					additionalText={
						<p className={'mb-4'}>
							<Trans
								defaults={t('loc:Chcete ďalej pokračovať pod týmto účtom alebo sa chcete prihlásiť s novým účtom <strong>{{user}}</strong>?')}
								components={{ strong: <strong className={'text-notino-black font-semibold'} /> }}
								values={{ user: selectAccountModalData.values.email }}
							/>
						</p>
					}
				/>
			)}
		</div>
	)
}

export default LoginPage
