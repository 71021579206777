export enum CHANGELOGS {
	CHANGELOGS_LOAD_START = 'CHANGELOGS_LOAD_START',
	CHANGELOGS_LOAD_DONE = 'CHANGELOGS_LOAD_DONE',
	CHANGELOGS_LOAD_FAIL = 'CHANGELOGS_LOAD_FAIL'
}

export enum CHANGELOG {
	CHANGELOG_LOAD_START = 'CHANGELOG_LOAD_START',
	CHANGELOG_LOAD_DONE = 'CHANGELOG_LOAD_DONE',
	CHANGELOG_LOAD_FAIL = 'CHANGELOG_LOAD_FAIL'
}
