import { z } from 'zod'

import { stringConstraint, twoCharsConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH } from '../utils/enums'

// eslint-disable-next-line import/prefer-default-export
export const ibanCalculatorSchema = z.object({
	countryCode: twoCharsConstraint,
	bankAccountNumber: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255, true),
	bankCode: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255, true)
})
