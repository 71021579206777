import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Divider } from 'antd'
import { useSelector } from 'react-redux'
import { TFunction } from 'i18next'

// utils
import { findNodeInTree } from '../../../utils/helper'
import { ASYNC_JOB_TYPE, CUSTOM_SERVICES_ORDER, SUBMIT_ASYNC_JOB_BUTTON_ID } from '../../../utils/enums'
import { LocalizedDateTimeRange } from '../../../utils/intl'

// types
import { ICustomServicesPageURLQueryParams } from '../../../types/schemaTypes'
import { GetUrls, ISelectOptionItem, ISelectOptionItemWithChildren, RequestParams } from '../../../types/interfaces'
import { RootState } from '../../../reducers'

// components
import FilterListItem from './FilterListItem'

// redux
import { getCustomServicesRequestQueryParams } from '../../../reducers/categories/categoriesActions'

export type CustomServicesReportRequestQueryParams = NonNullable<RequestParams<GetUrls['/api/b2b/admin/services/custom/export']>['query']>

type Props = {
	submitting: boolean
	handleSubmit: (query: CustomServicesReportRequestQueryParams) => void
	query: ICustomServicesPageURLQueryParams
	secondLevelCategoriesOptions: ISelectOptionItemWithChildren[]
	checkedOptions: ISelectOptionItem[]
	countriesOptions: ISelectOptionItem[]
}

enum FILTER_KEY {
	ORDER = 'order',
	SERVICE_CREATED_AT = 'serviceCreatedAt',
	SEARCH = 'search',
	COUNTRY_CODE = 'countryCode',
	CATEGORY_IDS = 'categoryIDs',
	IS_CHECKED = 'isChecked'
}

const getOrderName = (order: CUSTOM_SERVICES_ORDER, t: TFunction) => {
	switch (order) {
		case CUSTOM_SERVICES_ORDER.CREATED_AT_ASC:
			return `${t('loc:Vytvorené')} - ${t('loc:vzostupne')}`
		case CUSTOM_SERVICES_ORDER.CREATED_AT_DESC:
			return `${t('loc:Vytvorené')} - ${t('loc:zostupne')}`
		default:
			return ''
	}
}

const CustomServicesReport = (props: Props) => {
	const [t] = useTranslation()
	const { handleSubmit, submitting, query, secondLevelCategoriesOptions, checkedOptions, countriesOptions } = props

	const totalCount = useSelector((state: RootState) => state.categories.customServices.data?.pagination.totalCount)

	const handleSubmitReport = () => {
		const params = getCustomServicesRequestQueryParams(query)

		const requestQuery: CustomServicesReportRequestQueryParams = {
			search: params.search,
			countryCode: params.countryCode,
			categoryIDs: params.categoryIDs,
			serviceCreatedAtFrom: params.serviceCreatedAtFrom,
			serviceCreatedAtTo: params.serviceCreatedAtTo,
			isChecked: params.isChecked,
			order: params.order
		}

		handleSubmit(requestQuery)
	}

	const renderFiltersInfo = () => {
		const filters: React.ReactNode[] = []

		if (query.order) {
			filters.push(<FilterListItem key={FILTER_KEY.ORDER} filterName={t('loc:Zoradenie')} filterValue={getOrderName(query.order, t)} />)
		}

		if (query.search) {
			filters.push(<FilterListItem key={FILTER_KEY.SEARCH} filterName={t('loc:Hľadať podľa názvu salónu alebo ID')} filterValue={query.search} />)
		}

		if (query.countryCode) {
			const countryOption = countriesOptions.find((option) => option.value === query.countryCode)

			filters.push(
				<FilterListItem
					key={FILTER_KEY.COUNTRY_CODE}
					filterName={t('loc:Krajina')}
					filterValue={
						<div className={'inline-flex items-center gap-1'}>
							{countryOption?.extra.image && <img src={countryOption?.extra.image} width={16} height={16} className={'flex-shrink-0'} alt={''} />}
							{countryOption?.label || query.countryCode}
						</div>
					}
				/>
			)
		}

		if (query.checkedState) {
			const checkedStateOption = checkedOptions.find((option) => option.value === query.checkedState)

			filters.push(<FilterListItem key={FILTER_KEY.IS_CHECKED} filterName={t('loc:Stav služby')} filterValue={checkedStateOption?.label || query.checkedState} />)
		}

		if (query.categoryIDs && query.categoryIDs.length) {
			filters.push(
				<FilterListItem
					key={FILTER_KEY.CATEGORY_IDS}
					filterName={t('loc:Kategórie')}
					filterValue={query.categoryIDs
						.map((categoryID) => {
							const categoryOption = findNodeInTree({ children: secondLevelCategoriesOptions }, categoryID, 'value')

							return categoryOption?.label || categoryID
						})
						.join(', ')}
				/>
			)
		}

		if (query.serviceCreatedAtFrom || query.serviceCreatedAtTo) {
			filters.push(
				<FilterListItem
					key={FILTER_KEY.SERVICE_CREATED_AT}
					filterName={t('loc:Dátum vytvorenia služby')}
					filterValue={<LocalizedDateTimeRange dateFrom={query.serviceCreatedAtFrom} dateTo={query.serviceCreatedAtTo} timeStyle={null} fallback={'-'} />}
				/>
			)
		}

		return filters
	}

	const disabledButton = submitting

	return (
		<div>
			<h4 className={'mb-0'}>{t('loc:Report pre každú službu zahŕňa')}</h4>
			<ul className={'pl-8 list-[circle] mb-4 py-4 px-4 rounded-lg bg-notino-grayLighter'}>
				<li>{t('loc:názov salónu')}</li>
				<li>{t('loc:adresa salónu')}</li>
				<li>{t('loc:názov služby')}</li>
				<li>{t('loc:zoznam odvetví služby')}</li>
				<li>{t('loc:zoznam kategórií služby')}</li>
				<li>{t('loc:dátum pridania služby')}</li>
				<li>{t('loc:príznak o skontrolovaní služby')}</li>
			</ul>

			<h4 className={'mb-0'}>{t('loc:Vami požadovaný report bude vygenerovaný so zohľadnením filtrácie')}</h4>
			<Divider className={'mb-2 mt-0'} />
			{renderFiltersInfo().length ? (
				<ul className={'pl-5 list-[circle] mb-4 flex flex-col gap-1'}>{renderFiltersInfo()}</ul>
			) : (
				<p className={'text-center my-6'}>{t('loc:Nie sú zvolené žiadne filtre')}</p>
			)}

			<Button
				id={SUBMIT_ASYNC_JOB_BUTTON_ID(ASYNC_JOB_TYPE.EXPORT_CUSTOM_SERVICES)}
				className={`noti-btn sticky bottom-6 shadow-[0px_8px_24px_rgba(255,255,255,0.84),_0px_4px_14px_rgba(255,255,255,0.84),_0px_2px_4px_rgba(255,255,255,0.84)] ${!disabledButton ? `hover:shadow-[0px_8px_24px_rgba(0,0,0,0.24),_0px_4px_14px_rgba(0,0,0,0.14),_0px_2px_4px_rgba(0,0,0,0.16)]` : ''}`}
				block
				size='large'
				type='primary'
				htmlType='button'
				onClick={handleSubmitReport}
				disabled={disabledButton}
				loading={submitting}
			>
				{totalCount !== undefined ? t('loc:Generovať ({{ itemsCount }} záznamov)', { itemsCount: totalCount }) : t('loc:Generovať')}
			</Button>
		</div>
	)
}

export default CustomServicesReport
