/* eslint-disable import/no-cycle */
import { IConfigPayload, ILoadingAndFailure } from '../../types/interfaces'
import { CONFIG } from './configTypes'
import { IConfigActions } from './configActions'

export const initState = {
	config: {
		data: null,
		optionsRolloutCountries: [],
		optionsAllCountries: [],
		optionsPhonePrefixesAll: [],
		optionsPhonePrefixesRollout: [],
		reservationQuickFeedbackAnswerOptions: [],
		reservationCancellationQuickFeedbackAnswerOptions: [],
		isLoading: false,
		isFailure: false
	} as IConfigPayload & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: IConfigActions) => {
	switch (action.type) {
		case CONFIG.CONFIG_LOAD_START:
			return {
				...state,
				config: {
					...state.config,
					isLoading: true
				}
			}
		case CONFIG.CONFIG_LOAD_FAIL:
			return {
				...state,
				config: {
					...initState.config,
					isFailure: true
				}
			}
		case CONFIG.CONFIG_LOAD_DONE:
			return {
				...state,
				config: {
					...initState.config,
					data: action.payload.data,
					optionsRolloutCountries: action.payload.optionsRolloutCountries,
					optionsAllCountries: action.payload.optionsAllCountries,
					optionsPhonePrefixesAll: action.payload.optionsPhonePrefixesAll,
					optionsPhonePrefixesRollout: action.payload.optionsPhonePrefixesRollout,
					reservationCancellationQuickFeedbackAnswerOptions: action.payload.reservationCancellationQuickFeedbackAnswerOptions
				}
			}
		default:
			return state
	}
}
