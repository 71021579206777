import Axios, { AxiosError, AxiosResponse } from 'axios'
import i18next from 'i18next'
import { get, has, isEmpty, split } from 'lodash'
import qs from 'qs'

// env
import envConfig from '../config'

// redux
// eslint-disable-next-line import/no-cycle
import rootReducer from '../reducers'
import { logOutUser } from '../reducers/users/userActions'

// utils
import initInterceptors from './interceptors'
import { isLoggedIn } from './auth'
// eslint-disable-next-line import/no-cycle
import configureStore from './configureStore'
import { CANCEL_TOKEN_MESSAGES, ERROR_MSG_CODE, MSG_TYPE, UPLOAD_IMG_CATEGORIES, URL_UPLOAD_FILE } from './enums'
import Navigator from './navigation'
import { getReCaptchaErrorMessage, showNotificationModal, showNotifications } from './helper'

// types
import {
	CustomRequestOptions,
	IErrorMessage,
	FileUploadParam,
	GetUrls,
	RequestResponse,
	PostUrls,
	PatchUrls,
	PutUrls,
	DeleteUrls,
	RequestArguments,
	ICustomConfig
} from '../types/interfaces'

export const showErrorNotifications = (error: AxiosError | Error | any) => {
	let messages: IErrorMessage[] = get(error, 'response.data.messages') || (error.message ? [{ type: MSG_TYPE.ERROR, message: error.message }] : [])

	const { displayNotification, skipRedirectToLoginPage: skipRedirect, skip401Logout } = get(error, 'config', {}) as Partial<ICustomConfig>

	if (!displayNotification) {
		return
	}

	if (get(error, 'response.status') === 401 && isLoggedIn() && !skip401Logout) {
		messages = [
			{
				type: MSG_TYPE.INFO,
				message: i18next.t('loc:Boli ste automaticky odhlásený')
			}
		]

		showNotifications(messages)
		const { store } = configureStore(rootReducer)
		logOutUser(skipRedirect)(store.dispatch, store.getState, undefined)
	} else if (get(error, 'response.status') === 504 || get(error, 'response') === undefined || get(error, 'message') === 'Network Error') {
		messages = [
			{
				type: MSG_TYPE.ERROR,
				message: i18next.t('loc:Chyba pripojenia k serveru')
			}
		]
		showNotifications(messages)
	} else if (messages.some((msg) => msg.code === ERROR_MSG_CODE.MISSING_COUNTRY_CODE)) {
		const urlArray = split(get(error, 'config.url'), '/')
		const salonsIndex = urlArray.indexOf(i18next.t('paths:salons'))
		const salonId = urlArray[salonsIndex + 1]
		showNotificationModal({
			message: i18next.t('loc:Na vykonanie požadovanej akcie je potrebné mať nastavenú adresu salónu.'),
			actionButtonLabel: i18next.t('loc:Nastaviť adresu'),
			action: salonId ? () => Navigator.navigate(`${i18next.t('paths:salons')}/${salonId}`) : undefined
		})
	} else if (isEmpty(messages)) {
		// if BE do not send message set general error message
		messages = [{ type: MSG_TYPE.ERROR, message: i18next.t('loc:Ups niečo sa pokazilo') }]
		showNotifications(messages)
	} else {
		// replace BE reCaptcha errors for custom FE errors
		messages = messages.map((cv) => {
			if (cv.code === ERROR_MSG_CODE.RECAPTCHA_TOKEN_NOT_PROVIDED || cv.code === ERROR_MSG_CODE.RECAPTCHA_VERIFICATION_FAILED) {
				return getReCaptchaErrorMessage(cv.code, error)
			}
			return cv
		})

		showNotifications(messages)
	}
}

/**
 * Create new axios instance with default headers
 */
const axios = Axios.create({
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Access-Control-Allow-Credentials': 'true',
		'Cache-Control': 'no-cache, no-store',
		Pragma: 'no-cache',
		'x-module-admin-version': envConfig.APP_VERSION
	}
})

/**
 * Initialize interceptors for Axios requests and responses
 */
initInterceptors(axios, showErrorNotifications)

const DEFAULT_CUSTOM_CONFIG: Pick<ICustomConfig, 'displayNotification' | 'abortSignalKey' | 'headers'> = {
	displayNotification: true,
	abortSignalKey: '',
	headers: {}
}

const fullFillURL = (urlTemplate: string, params: any) => {
	const pathParams = []
	const queryParams = { ...(params?.query || {}) }
	const fullfilURL = urlTemplate
		.split('/')
		.map((blok) => {
			if (/{[^}]*\}/.test(blok)) {
				const param = blok.replace('{', '').replace('}', '')
				pathParams.push(param)
				delete queryParams[param]
				return (params?.path || {})[param]
			}
			return blok
		})
		.join('/')
	return {
		fullfilURL,
		queryParams
	}
}

type HttpAbortRequest = 'httpGet' | 'httpPatch' | 'httpPost' | 'httpDelete' | 'httpPut'

/**
 * @type {Map<HttpAbortRequest, Map<string, AbortController>>} abort controllers for http requests
 */
export const abortControllers: Record<HttpAbortRequest, Map<string, AbortController>> = {
	httpGet: new Map<string, AbortController>(),
	httpPatch: new Map<string, AbortController>(),
	httpPost: new Map<string, AbortController>(),
	httpDelete: new Map<string, AbortController>(),
	httpPut: new Map<string, AbortController>()
}

/**
 * Builds an abort controller if allowAbort is true, and aborts the previous request if the abortControllerKey exists in the abortControllers map.
 *
 * @param {HttpAbortRequest} requestType - the type of the HTTP request
 * @param {string} abortControllerKey - the key used to identify the abort controller
 * @param {boolean} allowAbort - flag indicating whether to allow aborting
 * @return {object} an object with the signal property pointing to the abort controller's signal, if allowAbort is true; otherwise, an empty object
 */
const buildAbortController = (requestType: HttpAbortRequest, abortControllerKey: string, allowAbort?: boolean) => {
	if (!allowAbort) {
		return undefined
	}

	if (abortControllers[requestType].has(abortControllerKey)) {
		abortControllers[requestType].get(abortControllerKey)?.abort(CANCEL_TOKEN_MESSAGES.CANCELED_DUE_TO_NEW_REQUEST)
	}

	abortControllers[requestType].set(abortControllerKey, new AbortController())

	return abortControllers[requestType].get(abortControllerKey)?.signal
}

const prepareRequestConfig = (requestType: HttpAbortRequest, urlTemplate: string, params: any, customConfig?: ICustomConfig) => {
	const { fullfilURL, queryParams } = fullFillURL(urlTemplate, params)

	const config = { ...DEFAULT_CUSTOM_CONFIG, ...customConfig }
	const { abortSignalKey, allowAbort, headers } = config

	const signal = buildAbortController(requestType, abortSignalKey || fullfilURL, allowAbort)

	const requestConfig: ICustomConfig = {
		...config,
		signal,
		headers
	}

	if (queryParams) {
		requestConfig.params = queryParams
	}

	return {
		requestConfig,
		fullfilURL
	}
}

const showRequestNotification = (config: ICustomConfig, res: any) => {
	const { displayNotification, messages } = config

	if (displayNotification) {
		if (messages) {
			showNotifications(messages)
		} else if (has(res, 'data.messages')) {
			showNotifications(get(res, 'data.messages') as IErrorMessage[])
		}
	}
}

const EMPTY_ARGS = {
	params: undefined,
	customConfig: undefined,
	reqBody: undefined
}
/**
 * Retrieves data from the specified URL using the HTTP GET method.
 *
 * @param {T} url - The URL to retrieve data from.
 * @param {RequestArguments<GetUrls[T]>} args - The arguments for the GET request.
 * @return {Promise<AxiosResponse<RequestResponse<GetUrls[T]>>>} A Promise that resolves to the data retrieved from the specified URL.
 */
export const getReq = async <T extends keyof GetUrls>(url: T, args: RequestArguments<GetUrls[T]>): Promise<AxiosResponse<RequestResponse<GetUrls[T]>>> => {
	const { params, customConfig } = { ...EMPTY_ARGS, ...args }
	const { fullfilURL, requestConfig } = prepareRequestConfig('httpGet', url, params, customConfig)

	requestConfig.paramsSerializer = {
		serialize: (serializeParams: Record<string, any>) => qs.stringify(serializeParams, { arrayFormat: 'brackets' }), // mimic pre 1.x behavior and send entire params object to a custom serializer func. Allows consumer to control how params are serialized.
		indexes: false // array indexes format (null - no brackets, false (default) - empty brackets, true - brackets with indexes)
	}

	const res = await axios.get<RequestResponse<GetUrls[T]>>(fullfilURL, requestConfig)

	showRequestNotification(requestConfig, res)

	return res
}

/**
 * Performs a POST request to the specified URL with the provided parameters and request body.
 *
 * @param {string} url - the URL endpoint for the POST request
 * @param {RequestArguments<PostUrls[T]>} args - The arguments for POST request.
 * @return {Promise<AxiosResponse<RequestResponse<PostUrls[T]>>>} A Promise that resolves to the data retrieved from the specified URL.
 */
export const postReq = async <T extends keyof PostUrls>(url: T, args: RequestArguments<PostUrls[T]>): Promise<AxiosResponse<RequestResponse<PostUrls[T]>>> => {
	const { customConfig, params, reqBody } = { ...EMPTY_ARGS, ...args }
	const { fullfilURL, requestConfig } = prepareRequestConfig('httpPost', url, params, customConfig)

	const res = await axios.post<RequestResponse<PostUrls[T]>>(fullfilURL, reqBody, requestConfig)

	showRequestNotification(requestConfig, res)

	return res
}

/**
 * Performs a PATCH request to the specified URL with the provided parameters and request body.
 *
 * @param {string} url - the URL endpoint for the PATCH request
 * @param {RequestArguments<PatchUrls[T]>} args - The arguments for PATCH request.
 * @return {Promise<AxiosResponse<RequestResponse<PatchUrls[T]>>>} A Promise that resolves to the data retrieved from the specified URL.
 */
export const patchReq = async <T extends keyof PatchUrls>(url: T, args: RequestArguments<PatchUrls[T]>): Promise<AxiosResponse<RequestResponse<PatchUrls[T]>>> => {
	const { customConfig, params, reqBody } = { ...EMPTY_ARGS, ...args }
	const { fullfilURL, requestConfig } = prepareRequestConfig('httpPatch', url, params, customConfig)

	const res = await axios.patch<RequestResponse<PatchUrls[T]>>(fullfilURL, reqBody, requestConfig)

	showRequestNotification(requestConfig, res)

	return res
}

/**
 * Performs a PUT request to the specified URL with the provided parameters and request body.
 *
 * @param {string} url - the URL endpoint for the PUT request
 * @param {RequestArguments<PutUrls[T]>} args - The arguments for PUT request.
 * @return {Promise<AxiosResponse<RequestResponse<PutUrls[T]>>>} A Promise that resolves to the data retrieved from the specified URL.
 */
export const putReq = async <T extends keyof PutUrls>(url: T, args: RequestArguments<PutUrls[T]>): Promise<AxiosResponse<RequestResponse<PutUrls[T]>>> => {
	const { customConfig, params, reqBody } = { ...EMPTY_ARGS, ...args }
	const { fullfilURL, requestConfig } = prepareRequestConfig('httpPut', url, params, customConfig)

	const res = await axios.put<RequestResponse<PutUrls[T]>>(fullfilURL, reqBody, requestConfig)

	showRequestNotification(requestConfig, res)

	return res
}

/**
 * Performs a DELETE request to the specified URL with the provided parameters and request body.
 *
 * @param {T} url - the URL endpoint for the DELETE request
 * @param {RequestArguments<DeleteUrls[T]>} args - The arguments for DELETE request.
 * @return {Promise<AxiosResponse<RequestResponse<DeleteUrls[T]>>>} A Promise that resolves to the data retrieved from the specified URL.
 */
export const deleteReq = async <T extends keyof DeleteUrls>(url: T, args: RequestArguments<DeleteUrls[T]>): Promise<AxiosResponse<RequestResponse<DeleteUrls[T]>>> => {
	const { customConfig, params, reqBody } = { ...EMPTY_ARGS, ...args }
	const { fullfilURL, requestConfig } = prepareRequestConfig('httpDelete', url, params, customConfig)

	if (!isEmpty(reqBody)) {
		requestConfig.data = reqBody
	}

	const res = await axios.delete<RequestResponse<DeleteUrls[T]>>(fullfilURL, requestConfig)

	showRequestNotification(requestConfig, res)

	return res
}

// Allow to upload multiple files (images, pdfs, docs...) to s3
// use in beforeUpload or handleFunction
export const uploadFiles = async (
	options: CustomRequestOptions,
	signUrl: typeof URL_UPLOAD_FILE = URL_UPLOAD_FILE,
	category: UPLOAD_IMG_CATEGORIES,
	uploadRef: React.MutableRefObject<FileUploadParam>
) => {
	const { file, onSuccess, onError } = options

	if (typeof file === 'object' && 'uid' in file) {
		const { uid, name, size, type } = file
		const files = [{ name, size, mimeType: type }]

		try {
			// sign imageUrl
			const { data } = await postReq(signUrl, { params: {}, reqBody: { files, category } })
			const fileData = data?.files?.[0]
			// eslint-disable-next-line no-param-reassign
			uploadRef.current[uid] = { uid, ...fileData }
			// upload file to signed URL
			const result = await axios.put(fileData.signedUrl, file, {
				headers: {
					'Content-Type': file.type,
					Authorization: null,
					'X-Module-Admin-Version': null
				}
			})

			if (onSuccess) {
				onSuccess(result.status, result.request)
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
			// NOTE: call onError causes to show duplicate toast error messages
			// onError(error)
		}
	} else if (onError) {
		onError(new Error(i18next.t('loc:Nahrávanie súboru zlyhalo')))
	} else {
		// eslint-disable-next-line no-console
		console.error('Image upload failed')
	}
}

// NOTE: for href download with token authorization use this function instead of <a href={downloadUrl} download={fileName} />
// For cases if there is not query token authorizations for example href={`/api/v1/exports?fileName=${get(data, ‘zipFileName’)}&t=${getAccessToken()}`}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleAuthorizedDownload = async (event: any, downloadUrl: string, fileName = i18next.t('loc:súbor')) => {
	event.preventDefault()

	try {
		const { data } = await axios.get(downloadUrl, {
			responseType: 'blob'
		})
		// Create a temporary URL for the blob
		const url = URL.createObjectURL(data)
		// Create a temporary anchor element
		const a = document.createElement('a')
		a.href = url
		a.download = fileName
		a.click()
		// Clean up the temporary URL and anchor element
		URL.revokeObjectURL(url)
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
	}
}
