import React, { useEffect } from 'react'
import { Button, notification } from 'antd'
import { Trans, useTranslation } from 'react-i18next'

// components
import ConfirmModal from '../atoms/ConfirmModal'

// utils
import { LOGOUT_NOTIFICATION_KEY } from '../hooks/useBroadcastChannelListeners'

type Props = {
	open: boolean
	handleContinueWithCurrentAccount: () => void
	handleLoginWithNewAccount: () => void
	onCancel: () => void
	currentAccountName: string
	submitting: boolean
	additionalText?: React.ReactNode
	primaryButtonText?: string
	secondaryButtonText?: string
}

const SelectAccountModal = (props: Props) => {
	const { open, onCancel, submitting, handleLoginWithNewAccount, handleContinueWithCurrentAccount, currentAccountName, additionalText, primaryButtonText, secondaryButtonText } =
		props

	const [t] = useTranslation()

	useEffect(() => {
		if (open) {
			notification.destroy(LOGOUT_NOTIFICATION_KEY)
		}
	}, [open])

	return (
		<ConfirmModal open={open} width={500} footer={null} onCancel={onCancel} title={t('loc:Výber účtu')}>
			<p className={'mb-4'}>
				<Trans
					defaults={t('loc:Vo vašom prehliadači ste už do aplikácie Notino Partner prihlásený pod <strong>{{user}}</strong> účtom')}
					components={{ strong: <strong className={'text-notino-black font-semibold'} /> }}
					values={{ user: currentAccountName }}
				/>
			</p>
			{additionalText}
			<div className={'flex gap-4 mt-6 flex-col'}>
				<Button
					type={'dashed'}
					size={'middle'}
					className={'noti-btn w-full'}
					htmlType={'button'}
					onClick={handleContinueWithCurrentAccount}
					disabled={submitting}
					loading={submitting}
				>
					{secondaryButtonText || t('loc:Pokračovať pod prihláseným účtom')}
				</Button>

				<Button
					type={'primary'}
					size={'middle'}
					className={'noti-btn w-full'}
					htmlType={'button'}
					onClick={handleLoginWithNewAccount}
					disabled={submitting}
					loading={submitting}
				>
					{primaryButtonText || t('loc:Prihlásiť pod nový účtom')}
				</Button>
			</div>
		</ConfirmModal>
	)
}

export default SelectAccountModal
