export enum CATEGORY_PARAMETERS {
	CATEGORY_PARAMETERS_LOAD_START = 'CATEGORY_PARAMETERS_LOAD_START',
	CATEGORY_PARAMETERS_LOAD_DONE = 'CATEGORY_PARAMETERS_LOAD_DONE',
	CATEGORY_PARAMETERS_LOAD_FAIL = 'CATEGORY_PARAMETERS_LOAD_FAIL'
}

export enum CATEGORY_PARAMETER {
	CATEGORY_PARAMETER_LOAD_START = 'CATEGORY_PARAMETER_LOAD_START',
	CATEGORY_PARAMETER_LOAD_DONE = 'CATEGORY_PARAMETER_LOAD_DONE',
	CATEGORY_PARAMETER_LOAD_FAIL = 'CATEGORY_PARAMETER_LOAD_FAIL'
}
