import { z } from 'zod'
import { stringConstraint, emailConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH } from '../utils/enums'

export const loginSchema = z.object({
	email: emailConstraint,
	password: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255, true)
})

export const loginAsPartnerSchema = z.object({
	email: emailConstraint.nullish()
})
