import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import dayjs, { Dayjs } from 'dayjs'
import { useDispatch } from 'react-redux'
import colors from 'tailwindcss/colors'
import i18next from 'i18next'
import cx from 'classnames'
import { Row } from 'antd'
import { ColumnsType } from 'antd/es/table'

// redux
import { INotinoDashboardPayload, IReservationStatsPayload, getReservationStats } from '../../../reducers/dashboard/dashboardActions'

// types
import { ISalonsActivePageURLQueryParams } from '../../../types/schemaTypes'
import { ILoadingAndFailure, TimeStats } from '../../../types/interfaces'

// utils
import { LineContentFunction, TimeStatsFilterFunction, transformToReservationsStatsData } from '../dashboardUtils'
import {
	PUBLISHED_PREMIUM_SALONS_BAR_ID,
	RESERVATIONS_STATS_TYPE,
	SALON_CREATE_TYPE,
	SALON_FILTER_RS,
	SALON_FILTER_RS_AVAILABLE_ONLINE,
	SALON_FILTER_STATES
} from '../../../utils/enums'
import { formatObjToQuery } from '../../../hooks/useQueryParamsZod'

// assets
import ChevronDownIcon from '../../../assets/icons/chevron-down.svg?react'

// dynamic imports
const BarChart = React.lazy(() => import('./BarChart'))

type Props = {
	selectedCountry?: string
	notinoData: INotinoDashboardPayload & ILoadingAndFailure
	reservationsStatsData: IReservationStatsPayload & ILoadingAndFailure
	lineContent: LineContentFunction
	timeStatsFilter: TimeStatsFilterFunction
}

const optionsForPremiumSalonBar = {
	plugins: {
		tooltip: {
			backgroundColor: colors.white,
			titleColor: colors.gray['900'],
			bodyColor: colors.neutral['700'],
			cornerRadius: 4
		},
		legend: {
			position: 'right',
			align: 'center',
			labels: {
				color: colors.black,
				font: {
					weight: '100'
				}
			},
			display: false
		}
	},
	scales: {
		x: {
			display: false // hide the X axis
		},
		y: {
			grid: {
				color: '#E6E6E6' // set the color of the X axis grid lines
			},
			ticks: {
				stepSize: 10 // set the X axis step size to 200
			}
		}
	},
	responsive: true
}

const reservationsColumns = (labels: string[] = [], futureBreak = 0): ColumnsType<any> => {
	return [
		{
			key: 'type',
			dataIndex: 'type',
			render: (value) => {
				switch (value) {
					case RESERVATIONS_STATS_TYPE.NEW_RS_B2B:
						return (
							<div className={'flex flex-1 items-center'}>
								<div className='h-2-5 w-2-5 rounded-full mr-1 stats-circle' style={{ backgroundColor: colors.blue[200], flex: '0 0 auto' }} />
								<span className='xs-bold'>{i18next.t('loc:Rezervácie vytvorené salónmi')}</span>
							</div>
						)
					case RESERVATIONS_STATS_TYPE.NEW_RS_B2C:
						return (
							<div className={'flex flex-1 items-center'}>
								<div className='h-2-5 w-2-5 rounded-full mr-1 stats-circle' style={{ backgroundColor: colors.blue[700], flex: '0 0 auto' }} />
								<span className='xs-bold'>{i18next.t('loc:Rezervácie vytvorené klientmi')}</span>
							</div>
						)
					default:
						return ''
				}
			}
		},
		...labels.map((label: string, index: number) => {
			return {
				key: index,
				dataIndex: index,
				className: cx({ 'future-divider': futureBreak - 0.5 === index }), // 0.5 is delta for display devider between columns
				title: <span className={cx('xs-semibold', { 'text-notino-gray': futureBreak <= index })}>{label}</span>,
				render: (value: number) => <span className={cx('xs-regular', { 'text-notino-gray': futureBreak <= index })}>{value}</span>
			}
		}),
		{
			key: 'summary',
			dataIndex: 'summary',
			title: () => <span className='xs-semibold'>{i18next.t('loc:Súčet')}</span>,
			render: (value) => <span className='xs-regular'>{value}</span>,
			align: 'center'
		}
	]
}

const barContent = (data: any) => {
	return (
		<div className='statistics-box py-4 px-6 md:py-8 md:px-12 statistics-box-wide' id={PUBLISHED_PREMIUM_SALONS_BAR_ID}>
			<div className='flex flex-wrap justify-between w-full'>
				<h4>{data.title}</h4>
			</div>
			<div className='flex flex-wrap justify-between w-full mt-4'>
				<div className='w-2/5 mr-4 3xl:w-12/25 flex items-center'>
					<BarChart data={data} />
				</div>
				<div className='flex flex-1 items-center'>
					<div className='w-full flex flex-col gap-4'>
						{data.legend.map((item: any, index: number) => (
							// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
							<div key={index} className='flex items-center w-full h-6 cursor-pointer' onClick={item.onClick}>
								<div className={'flex flex-1 items-center legend-row'}>
									<div className='h-6 w-6 rounded-full mr-4 stats-circle' style={{ backgroundColor: item.backgroundColor }} />
									<span className='base-semibold mr-3 leading-4'>{item.data}</span>
									<span className='text-sm'>{item.label}</span>
								</div>
								<ChevronDownIcon style={{ transform: 'rotate(-90deg)' }} />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

const ReservationsDashboardTab = (props: Props) => {
	const dispatch = useDispatch()
	const [t] = useTranslation()
	const navigate = useNavigate()
	const { selectedCountry, notinoData, reservationsStatsData, lineContent, timeStatsFilter } = props

	const [monthReservationsStatsDate, setMonthReservationsStatsDate] = useState<Dayjs>(dayjs())

	useEffect(() => {
		dispatch(
			getReservationStats({
				countryCode: selectedCountry,
				year: monthReservationsStatsDate.year(),
				month: monthReservationsStatsDate.month() + 1
			})
		)
	}, [dispatch, monthReservationsStatsDate, selectedCountry])

	const publishedPremiumSalonsData = useMemo(() => {
		const query: ISalonsActivePageURLQueryParams = {
			statuses_published: SALON_FILTER_STATES.PUBLISHED,
			createType: SALON_CREATE_TYPE.NON_BASIC,
			countryCode: selectedCountry
		}
		return {
			title: t('loc:Publikované Premium salóny'),
			data: {
				labels: [t('loc:Premium bez RS'), t('loc:Premium s RS'), t('loc:Premium RS online')],
				datasets: [
					{
						label: t('loc:Počet'),
						data: [
							notinoData.data?.publishedPremiumSalons.premiumDisabledRs,
							notinoData.data?.publishedPremiumSalons.premiumEnabledRsB2b,
							notinoData.data?.publishedPremiumSalons.premiumEnabledRsB2c
						],
						backgroundColor: ['#144896', '#2277F3', '#BBD6FE'],
						borderRadius: 4
					}
				]
			},
			legend: [
				{
					label: t('loc:Premium bez RS'),
					data: notinoData.data?.publishedPremiumSalons.premiumDisabledRs,
					onClick: () =>
						navigate({
							pathname: t('paths:salons'),
							search: formatObjToQuery<ISalonsActivePageURLQueryParams>({ ...query, enabledReservationsSetting: SALON_FILTER_RS.NOT_ENABLED })
						}),
					backgroundColor: '#144896'
				},
				{
					label: t('loc:Premium s RS'),
					data: notinoData.data?.publishedPremiumSalons.premiumEnabledRsB2b,
					onClick: () =>
						navigate({
							pathname: t('paths:salons'),
							search: formatObjToQuery<ISalonsActivePageURLQueryParams>({ ...query, enabledReservationsSetting: SALON_FILTER_RS.ENABLED })
						}),
					backgroundColor: '#2277F3'
				},
				{
					label: t('loc:Premium RS online'),
					data: notinoData.data?.publishedPremiumSalons.premiumEnabledRsB2c,
					onClick: () =>
						navigate({
							pathname: t('paths:salons'),
							search: formatObjToQuery<ISalonsActivePageURLQueryParams>({
								...query,
								enabledReservationsSetting: SALON_FILTER_RS.ENABLED,
								hasAvailableReservationSystem: SALON_FILTER_RS_AVAILABLE_ONLINE.AVAILABLE
							})
						}),
					backgroundColor: '#BBD6FE'
				}
			],
			options: optionsForPremiumSalonBar
		}
	}, [
		navigate,
		notinoData.data?.publishedPremiumSalons.premiumDisabledRs,
		notinoData.data?.publishedPremiumSalons.premiumEnabledRsB2b,
		notinoData.data?.publishedPremiumSalons.premiumEnabledRsB2c,
		selectedCountry,
		t
	])

	const unpublishedPremiumSalonsData = useMemo(() => {
		const query: ISalonsActivePageURLQueryParams = {
			statuses_published: SALON_FILTER_STATES.NOT_PUBLISHED,
			createType: SALON_CREATE_TYPE.NON_BASIC,
			countryCode: selectedCountry
		}
		return {
			title: t('loc:Nepublikované Premium salóny'),
			data: {
				labels: [t('loc:Premium bez RS'), t('loc:Premium s RS'), t('loc:Premium RS online')],
				datasets: [
					{
						label: t('loc:Počet'),
						data: [
							notinoData.data?.unpublishedPremiumSalons.premiumDisabledRs,
							notinoData.data?.unpublishedPremiumSalons.premiumEnabledRsB2b,
							notinoData.data?.unpublishedPremiumSalons.premiumEnabledRsB2c
						],
						backgroundColor: ['#144896', '#2277F3', '#BBD6FE'],
						borderRadius: 4
					}
				]
			},
			legend: [
				{
					label: t('loc:Premium bez RS'),
					data: notinoData.data?.unpublishedPremiumSalons.premiumDisabledRs,
					onClick: () =>
						navigate({
							pathname: t('paths:salons'),
							search: formatObjToQuery<ISalonsActivePageURLQueryParams>({ ...query, enabledReservationsSetting: SALON_FILTER_RS.NOT_ENABLED })
						}),
					backgroundColor: '#144896'
				},
				{
					label: t('loc:Premium s RS'),
					data: notinoData.data?.unpublishedPremiumSalons.premiumEnabledRsB2b,
					onClick: () =>
						navigate({
							pathname: t('paths:salons'),
							search: formatObjToQuery<ISalonsActivePageURLQueryParams>({ ...query, enabledReservationsSetting: SALON_FILTER_RS.ENABLED })
						}),
					backgroundColor: '#2277F3'
				},
				{
					label: t('loc:Premium RS online'),
					data: notinoData.data?.unpublishedPremiumSalons.premiumEnabledRsB2c,
					onClick: () =>
						navigate({
							pathname: t('paths:salons'),
							search: formatObjToQuery<ISalonsActivePageURLQueryParams>({
								...query,
								enabledReservationsSetting: SALON_FILTER_RS.ENABLED,
								hasAvailableReservationSystem: SALON_FILTER_RS_AVAILABLE_ONLINE.AVAILABLE
							})
						}),
					backgroundColor: '#BBD6FE'
				}
			],
			options: optionsForPremiumSalonBar
		}
	}, [
		navigate,
		notinoData.data?.unpublishedPremiumSalons.premiumDisabledRs,
		notinoData.data?.unpublishedPremiumSalons.premiumEnabledRsB2b,
		notinoData.data?.unpublishedPremiumSalons.premiumEnabledRsB2c,
		selectedCountry,
		t
	])

	const reservationsMonthStats: TimeStats = useMemo(() => {
		return transformToReservationsStatsData(reservationsStatsData.data, reservationsStatsData.isLoading, reservationsStatsData.isFailure, monthReservationsStatsDate)
	}, [reservationsStatsData.data, reservationsStatsData.isLoading, reservationsStatsData.isFailure, monthReservationsStatsDate])

	return (
		<div>
			{/* // RS stats */}
			<Row className={'gap-4'}>
				{barContent(publishedPremiumSalonsData)}
				{barContent(unpublishedPremiumSalonsData)}
			</Row>
			{/* Reservations stats */}
			{lineContent(
				t('loc:Vývoj rezervácií - mesačný'),
				reservationsMonthStats,
				timeStatsFilter(
					(date) => {
						if (date) {
							setMonthReservationsStatsDate(date)
							dispatch(
								getReservationStats({
									year: Number(date.year()),
									month: Number(date.month() + 1),
									countryCode: selectedCountry
								})
							)
						}
					},
					'MMMM - YYYY',
					undefined,
					reservationsStatsData.isLoading
				),
				reservationsColumns(reservationsMonthStats.data?.labels, reservationsMonthStats.data?.breakIndex)
			)}
		</div>
	)
}

export default ReservationsDashboardTab
