import { z } from 'zod'
import { stringConstraint, twoCharsConstraint, serializeValidationMessage, uuidConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH, ALL_LANGUAGES, DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE } from '../utils/enums'
import { dynamicNotificationUrlRegex } from '../utils/regex'

export const dynamicNotificationsSettingSchema = z
	.object({
		allLanguages: z.nativeEnum(ALL_LANGUAGES),
		selectedLanguages: twoCharsConstraint.array()
	})
	.superRefine((values, ctx) => {
		if (values.allLanguages === ALL_LANGUAGES.SELECTED_LANGUAGES && !values.selectedLanguages.length) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Vyberte aspoň jeden jazyk'),
				path: ['selectedLanguages']
			})
		}
	})

export const localizationItemSchema = z.object({
	title: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_50, true),
	content: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255, true),
	notificationType: z.nativeEnum(DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE),
	externalLink: z
		.string()
		.regex(dynamicNotificationUrlRegex, serializeValidationMessage('loc:Zadajte správny formát adresy (napr. {{url}})', { url: 'https://www.notino.com/' }))
		.nullish(),
	document: z
		.object({
			id: uuidConstraint,
			name: z.string(),
			link: z.string()
		})
		.array()
		.nullish()
})

export const dynamicNotificationsSchema = z
	.object({
		allLanguages: z.nativeEnum(ALL_LANGUAGES).optional(),
		selectedLanguages: twoCharsConstraint.array(),
		localizations: z.record(twoCharsConstraint, localizationItemSchema)
	})
	.superRefine((values, ctx) => {
		values.selectedLanguages?.forEach((language) => {
			const value = values.localizations[language]

			if (!value?.content) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['localizations', language, 'content']
				})
			}

			if (!value?.title) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['localizations', language, 'title']
				})
			}
		})
	})
