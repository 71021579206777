import React from 'react'
import { useTranslation } from 'react-i18next'

// env
import envConfig from '../../config'

// assets
import logo from '../../assets/images/logo.png'

// hooks
import usePushScreenInfoToDataLayer from '../../hooks/usePushScreenInfoToDataLayer'

// utils
import { GA_SCREEN_CLASS, GA_SCREEN_NAME } from '../../utils/dataLayerEnums'

const AboutApplicationPage = () => {
	const [t] = useTranslation()

	// Google Analytics initial events
	usePushScreenInfoToDataLayer(GA_SCREEN_NAME.ABOUT_APPLICATION, GA_SCREEN_CLASS.ABOUT_APPLICATION)

	return (
		<div className='content-body small'>
			<h4 className={'mb-6 text-lg text-notino-black'}>{t('loc:O aplikácii')}</h4>
			<img src={logo} alt={'Notino Partner app'} width={100} height={100} className={'mb-6'} />
			<h4 className={'mb-2 text-lg text-notino-black'}>{t('loc:Notino Partner')}</h4>
			<p className={'mb-6 text-notino-grayDarker'}>
				{t(
					'loc:Aplikáciu Notino Partner vyvinula a prevádzkuje spoločnosť Notino, s.r.o., sídlom Londýnske námestie 881/6, 63900 Brno, Česká republika, IČO 27609057, DIČ CZ27609057, zapísaná na Krajskom súde v Brne pod číslom C 101025.'
				)}
			</p>
			<div className={'flex justify-between'}>
				<span className={'text-notino-grayDark'}>{t('loc:Verzia aplikácie')}</span>
				<span className={'text-notino-black'}>v{envConfig.APP_VERSION}</span>
			</div>
		</div>
	)
}

export default AboutApplicationPage
