import React, { FC } from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button, Form } from 'antd'

// utils
import { formFieldID, showErrorNotification } from '../utils/helper'
import { FORM, SUBMIT_BUTTON_ID } from '../utils/enums'

// components
import { LanguagePickerField } from './LanguagePicker'

// types
import { IUserLanguageSelectionForm } from '../types/interfaces'

// validation
import { validationUserLanguageSelectionFn } from '../utils/formValidation'

type ComponentProps = {
	loading?: boolean
}

type Props = InjectedFormProps<IUserLanguageSelectionForm, ComponentProps> & ComponentProps

const UserLanguageSelectionForm: FC<Props> = (props) => {
	const [t] = useTranslation()

	const { handleSubmit, loading } = props

	return (
		<Form onSubmitCapture={handleSubmit} layout={'vertical'} className={'form'}>
			<p className={'mb-6 mt-2 text-base'}>
				{t(
					'loc:Vybraný jazyk zmení jazyk mobilnej aplikácie aj Admin aplikácie Notino partner. V tomto jazyku vám budú prichádzať aj všetky upozornenia vrátane sms a emailov.'
				)}
			</p>

			<LanguagePickerField name={'languageCode'} label={t('loc:Vyberte jazyk')} required className={'pb-6'} />
			<Button
				id={formFieldID(FORM.USER_LANGUAGE_SELECTION, SUBMIT_BUTTON_ID)}
				className='noti-btn mb-3 w-full'
				size='large'
				type='primary'
				htmlType='submit'
				disabled={loading}
				loading={loading}
			>
				{t('loc:Potvrdiť')}
			</Button>
			<p className={'text-notino-grayDark mb-0'}>{t('loc:Jazyk si môžete kedykoľvek zmeniť v nastaveniach v časti “Moje konto”.')}</p>
		</Form>
	)
}

const form = reduxForm<IUserLanguageSelectionForm, ComponentProps>({
	form: FORM.USER_LANGUAGE_SELECTION,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validationUserLanguageSelectionFn,
	onSubmitFail: showErrorNotification
})(UserLanguageSelectionForm)

export default form
