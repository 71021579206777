// utils
import { getAssignedUserLabel, CALENDAR_COLORS } from '../../utils/helper'
import { CLEAR_CALENDAR_EMPLOYEES, SET_CALENDAR_EMPLOYEES } from './calendarEmployeesTypes'

// types
import { IResetStore } from '../generalTypes'
import { CalendarEmployee, GetUrls, ICalendarEmployeeOptionItem, ICalendarEmployeesPayload, RequestResponse } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '..'

export type ICalendarEmployeesActions = IResetStore | IGetEmployees | IClearEmployees

interface IGetEmployees {
	type: typeof SET_CALENDAR_EMPLOYEES
	payload: ICalendarEmployeesPayload
}

interface IClearEmployees {
	type: typeof CLEAR_CALENDAR_EMPLOYEES
}

export const setCalendarEmployees =
	(
		employees: RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/calendar-events/']>['employees'][0][],
		salonID: string,
		getDeletedEmployees = true
	): ThunkResult<ICalendarEmployeesPayload> =>
	(dispatch, getState) => {
		let payload = {
			data: null,
			options: []
		} as ICalendarEmployeesPayload
		const options: ICalendarEmployeeOptionItem[] = []
		const calendarEmployees: CalendarEmployee[] = []

		employees.forEach((employee, index) => {
			const isDeleted = !!employee.deletedAt

			if (!getDeletedEmployees && isDeleted) {
				return
			}

			const employeeData = {
				id: employee.id,
				firstName: employee.firstName,
				lastName: employee.lastName,
				email: employee.email,
				inviteEmail: employee.inviteEmail,
				orderIndex: index,
				image: employee.image,
				isDeleted
			}

			calendarEmployees.push(employeeData)
			options.push({
				label: getAssignedUserLabel({ id: employee.id, firstName: employee.firstName, lastName: employee.lastName, email: employee.email || employee.inviteEmail }),
				value: employee.id,
				key: employee.id,
				extra: { thumbnail: employee.image.resizedImages.thumbnail, color: isDeleted ? CALENDAR_COLORS.DELETED_EMPLOYEE : CALENDAR_COLORS.ACTIVE_EMPLOYEE, isDeleted }
			})
		})

		payload = {
			data: calendarEmployees,
			options,
			salonID
		}

		// update state only when new employees are different from currently stored employees
		const currentCalendarEmployees = getState().calendarEmployees.calendarEmployees.data

		if (JSON.stringify(currentCalendarEmployees) !== JSON.stringify(calendarEmployees)) {
			dispatch({ type: SET_CALENDAR_EMPLOYEES, payload })
		}

		return payload
	}

export const clearCalendarEmployees = (): ThunkResult<void> => (dispatch) => dispatch({ type: CLEAR_CALENDAR_EMPLOYEES })
