import React from 'react'

// helpers
import { optionRenderWithImage } from '../utils/helper'

// atoms
import SelectField, { Props as SelectProps } from './SelectField'

// assets
import PhoneIcon from '../assets/icons/phone-2-icon.svg?react'

type Props = SelectProps & {}

const PhonePrefixField = (props: Props) => {
	return <SelectField optionRender={(itemData: any) => optionRenderWithImage(itemData, <PhoneIcon />)} {...props} />
}

export default PhonePrefixField
