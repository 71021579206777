import { z } from 'zod'
import { serializeValidationMessage } from './baseSchema'
import { PIN_LENGTH } from '../utils/enums'

const errorMsg = serializeValidationMessage('loc:Zadajte celý PIN')

// eslint-disable-next-line import/prefer-default-export
export const activationSchema = z.object({
	code: z.string({ required_error: errorMsg }).length(PIN_LENGTH, errorMsg)
})
