import React from 'react'
import cx from 'classnames'

type Props = {
	required?: boolean
	filterName: string
	filterValue: React.ReactNode
	errorMsg?: string
}

const FilterListItem = (props: Props) => {
	const { filterName, filterValue, required, errorMsg } = props

	const isError = !!errorMsg

	return (
		<li className={cx('rounded-md', { 'bg-red-100': isError, 'p-1': isError })}>
			<strong className={'text-xs'}>
				{filterName} {required && <span className={'text-danger'}>*</span>}
			</strong>
			<br />
			{filterValue}
			{isError ? <p className={'text-xs text-danger mb-0 mt-1'}>{errorMsg}</p> : null}
		</li>
	)
}

export default FilterListItem
