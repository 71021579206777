/* eslint-disable import/no-cycle */
import { IAsyncJobsActions } from './asyncJobsActions'
import { AsyncJob, ILoadingAndFailure } from '../../types/interfaces'
import { GET_ASYNC_JOB_STATE, CLEAR_ASYNC_JOB } from './asyncJobsTypes'
import { RESET_STORE } from '../generalTypes'

export interface IAsyncJobStateData {
	data: AsyncJob[]
}

export const initState = {
	asyncJobs: {
		data: [],
		isLoading: false,
		isFailure: false
	} as IAsyncJobStateData & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: IAsyncJobsActions) => {
	switch (action.type) {
		// get async job state
		case GET_ASYNC_JOB_STATE.GET_ASYNC_JOB_STATE_START:
			return {
				...state,
				asyncJobs: {
					...state.asyncJobs,
					data: state.asyncJobs.data.find((job) => job.jobKey === action.payload.jobKey) ? [...state.asyncJobs.data] : [...state.asyncJobs.data, action.payload],
					isLoading: true
				}
			}
		case GET_ASYNC_JOB_STATE.GET_ASYNC_JOB_STATE_FAIL:
			return {
				...state,
				asyncJobs: {
					...state.asyncJobs,
					data: state.asyncJobs.data.map((item) => (item.jobKey === action.payload.jobKey ? action.payload : item)),
					isFailure: true
				}
			}
		case GET_ASYNC_JOB_STATE.GET_ASYNC_JOB_STATE_DONE:
			return {
				...state,
				asyncJobs: {
					...state.asyncJobs,
					data: state.asyncJobs.data.map((item) => (item.jobKey === action.payload.jobKey ? action.payload : item))
				}
			}
		case CLEAR_ASYNC_JOB:
			return {
				...state,
				asyncJobs: {
					...state.asyncJobs,
					data: state.asyncJobs.data.filter((item) => item.jobKey !== action.payload.jobKey) || []
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
