import { RESET_STORE } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { IGoogleAnalyticsActions } from './googleAnalyticsActions'
import { DEFAULT_SCREEN_NAME } from '../../utils/dataLayerEnums'
import { SET_GA_SCREEN_NAME } from './googleAnalyticsTypes'

const initState = {
	screenName: DEFAULT_SCREEN_NAME
}

export default (state = initState, action: IGoogleAnalyticsActions) => {
	switch (action.type) {
		case SET_GA_SCREEN_NAME:
			return {
				...state,
				screenName: action.payload
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
