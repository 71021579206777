import { z } from 'zod'

import { emailConstraint, serializeValidationMessage } from './baseSchema'
import { formatPriceByLocale, formatPriceRangeByLocale } from '../utils/intl'

export const affiliateProgramSmsTransactionSchema = z
	.object({
		availableBalance: z.number(),
		minLimitSmsTransaction: z.number(),
		currencyCode: z.string(),
		amount: z.number().finite()
	})
	.superRefine((values, ctx) => {
		if (values.amount < 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Suma musí byť väčšia ako {{ amount }}', { amount: formatPriceByLocale(0, values.currencyCode) }),
				path: ['amount']
			})
		}

		if (values.amount > values.availableBalance) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Suma musí byt menšia alebo rovnaká ako hodnota konta'),
				path: ['amount']
			})
		}

		if (values.amount < values.minLimitSmsTransaction) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Suma musí byť väčšia alebo rovná {{ amount }}', {
					amount: formatPriceByLocale(values.minLimitSmsTransaction, values.currencyCode)
				}),
				path: ['amount']
			})
		}
	})

export const affiliateProgramVoucherTransactionSchema = z
	.object({
		availableBalance: z.number(),
		minLimitVoucherTransaction: z.number(),
		maxLimitVoucherTransaction: z.number(),
		currencyCode: z.string(),
		amount: z.number().finite(),
		email: emailConstraint.nullish(),
		isSendEmail: z.boolean()
	})
	.superRefine((values, ctx) => {
		if (values.amount < 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Suma musí byť väčšia ako {{ amount }}', { amount: formatPriceByLocale(0, values.currencyCode) }),
				path: ['amount']
			})
		}

		if (values.amount > values.availableBalance) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Suma musí byt menšia alebo rovnaká ako hodnota konta'),
				path: ['amount']
			})
		}

		if (values.amount < values.minLimitVoucherTransaction || values.amount > values.maxLimitVoucherTransaction) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Čiastka musí byť v rozmedzí {{ voucherTransactionRange }}', {
					voucherTransactionRange: formatPriceRangeByLocale(values.minLimitVoucherTransaction, values.maxLimitVoucherTransaction, values.currencyCode)
				}),
				path: ['amount']
			})
		}

		if (values.isSendEmail && !values.email) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Toto pole je povinné'),
				path: ['email']
			})
		}
	})
