import Axios, { AxiosError, InternalAxiosRequestConfig, AxiosInstance } from 'axios'
import { Mutex } from 'async-mutex'
import i18next from 'i18next'

// utils
import { getAccessToken, getRefreshToken, hasRefreshToken, isLoggedIn, setAccessToken, setRefreshToken, isTokenValid } from './auth'
import { CANCEL_TOKEN_MESSAGES } from './enums'
import { BROADCAST_CHANNEL_TYPE, BroadcastMessageEvent, appBroadcastChannel } from './broadcast'

/**
 * Assign interceptors for requests and responses into Axios instance
 */
export default (axios: AxiosInstance, showErrorNotifications: (error: AxiosError | Error | unknown) => void) => {
	const REFRESH_ENDPOINT = '/api/b2b/admin/auth/refresh-token'
	const BROADCAST_CHANNEL = 'token-refresh'
	const REFRESH_TOKEN_THROTTLE_TIME = 800 // 0,8 sec

	const refreshTokenAbort = new AbortController()

	const mutex = new Mutex(new Error('Error in mutex during refetching tokens'))

	// register listener for broadcast channel
	appBroadcastChannel.addEventListener('message', (event: BroadcastMessageEvent) => {
		if ((event.currentTarget as BroadcastChannel)?.name === BROADCAST_CHANNEL && event.data.type === BROADCAST_CHANNEL_TYPE.REFRESH_TOKEN) {
			refreshTokenAbort.abort(CANCEL_TOKEN_MESSAGES.CANCELED_ON_DEMAND)
		}
	})

	/**
	 * Function to fetch tokens asynchronously. During the process, the mutex is used to avoid multiple concurrent requests.
	 * For more information about mutex @see https://www.npmjs.com/package/async-mutex
	 * @return {string | null} The authentication token retrieved from the server.
	 */
	const fetchTokens = async () => {
		// acquire (lock) mutex
		const release = await mutex.acquire()

		let authToken: string | null = null

		try {
			const { data } = await axios.post(
				REFRESH_ENDPOINT,
				{
					refreshToken: getRefreshToken()
				},
				{
					signal: refreshTokenAbort.signal
				}
			)

			// notify other tabs with timestamp of successful refresh
			appBroadcastChannel.postMessage(new Date().getTime())

			authToken = data.accessToken
			setAccessToken(data.accessToken)
			setRefreshToken(data.refreshToken)
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			// release mutex
			release()
		}

		return authToken
	}

	/**
	 * ############################
	 * Axios interceptors
	 * ############################
	 */

	/**
	 * Set headers for HTTP requests. Just in case if value of the header is undefined. Otherwise, it will ignore header
	 * and use value from original config.
	 * E.g.
	 * - if is wanted to skip header from request, the value in `originalConfig` must be `null`
	 * - if is wanted to use different authorization token as current's user, the value in `originalConfig` must be `Bearer <differentToken>`
	 * @param originalConfig config from request wrapper @see src\utils\request.ts
	 * @returns config with headers for HTTP request
	 */
	const addRequestHeaders = (originalConfig: InternalAxiosRequestConfig) => {
		if (typeof originalConfig.headers['accept-language'] === 'undefined') {
			originalConfig.headers.set('accept-language', i18next.resolvedLanguage || i18next.language)
		}

		if (typeof originalConfig.headers.Authorization === 'undefined') {
			originalConfig.headers.setAuthorization(`Bearer ${getAccessToken()}`, true)
		}

		return originalConfig
	}

	/**
	 * Handle refetching of JWT tokens if the user is logged in. If mutex is locked, wait until it is unlocked. Otherwise, fetch tokens.
	 */
	const handleRefetchJWTTokens = async (originalConfig: InternalAxiosRequestConfig) => {
		if (isLoggedIn()) {
			const authToken = getAccessToken()

			if (!isTokenValid(authToken) && hasRefreshToken() && originalConfig.url !== REFRESH_ENDPOINT) {
				if (mutex.isLocked()) {
					await mutex.waitForUnlock()
				} else {
					await fetchTokens()
				}
			}
		}

		return originalConfig
	}

	/**
	 * Add a delay to the refresh token request due sync between another tabs
	 */
	const throttleRefreshTime = async (config: InternalAxiosRequestConfig) => {
		if (config.url === REFRESH_ENDPOINT) {
			await new Promise((resolve) => {
				setTimeout(resolve, REFRESH_TOKEN_THROTTLE_TIME)
			})
		}

		return config
	}

	const handleResponseError = (error: AxiosError | Error | any) => {
		if (!Axios.isCancel(error)) {
			showErrorNotifications(error)
		}

		return Promise.reject(error)
	}

	/**
	 * NOTE: order of registering interceptors is reversed!
	 * @see https://github.com/axios/axios/issues/1663
	 */
	axios.interceptors.request.use(addRequestHeaders)
	axios.interceptors.request.use(handleRefetchJWTTokens)
	axios.interceptors.request.use(throttleRefreshTime)

	axios.interceptors.response.use((response) => response, handleResponseError)
}
