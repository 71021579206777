import { z } from 'zod'
import { stringConstraint, emailsConstraint, phoneNumbersConstraint, openingHoursConstraint, twoCharsConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH } from '../utils/enums'

// eslint-disable-next-line import/prefer-default-export
export const supportContactSchema = z.object({
	emails: emailsConstraint(),
	note: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1000),
	street: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100),
	city: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100),
	zipCode: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_10),
	streetNumber: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_10),
	countryCode: twoCharsConstraint,
	phones: phoneNumbersConstraint(),
	openingHours: openingHoursConstraint(),
	sameOpenHoursOverWeek: z.boolean().nullish(),
	openOverWeekend: z.boolean().nullish()
})
