import React, { FC } from 'react'
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form'
import { Form, FormProps } from 'antd'
import { useSelector } from 'react-redux'
import i18next from 'i18next'

// utils
import { FORM, ALL_LANGUAGES } from '../../../utils/enums'
import { languageOptions } from '../../../components/LanguagePicker'

// types
import { RootState } from '../../../reducers'

// components
import RadioGroupField from '../../../atoms/RadioGroupField'
import CheckboxGroupField from '../../../atoms/CheckboxGroupField'

// types
import { IDynamicNotificationsSettingsForm } from '../../../types/interfaces'

// validation
import { validationDynamicNotificationsSettingsFn } from '../../../utils/formValidation'
import { formFieldID } from '../../../utils/helper'

type ComponentProps = FormProps & {}

type Props = InjectedFormProps<IDynamicNotificationsSettingsForm, ComponentProps> & ComponentProps

const ALL_LANGUAGES_OPTIONS = () => [
	{
		key: ALL_LANGUAGES.ALL_LANGUAGES,
		value: ALL_LANGUAGES.ALL_LANGUAGES,
		label: i18next.t('loc:Pre všetky jazyky')
	},
	{
		key: ALL_LANGUAGES.SELECTED_LANGUAGES,
		value: ALL_LANGUAGES.SELECTED_LANGUAGES,
		label: i18next.t('loc:Pre vybrané jazyky')
	}
]

const optionRenderWithIcon = (itemData: any) => {
	const { label, icon } = itemData
	return (
		<div className='flex items-center'>
			{icon}
			<span className='truncate inline-block'>{label}</span>
		</div>
	)
}

const DynamicNotificationsSettingsForm: FC<Props> = (props) => {
	const { handleSubmit } = props
	const formValues: Partial<IDynamicNotificationsSettingsForm> = useSelector((state: RootState) => getFormValues(FORM.DYNAMIC_NOTIFICATIONS_SETTINGS)(state))

	return (
		<Form layout={'vertical'} className={'form'} onSubmitCapture={handleSubmit}>
			<Field className={'p-0 m-0'} component={RadioGroupField} name={'allLanguages'} options={ALL_LANGUAGES_OPTIONS()} direction={'vertical'} />
			{formValues?.allLanguages === ALL_LANGUAGES.SELECTED_LANGUAGES ? (
				<div id={formFieldID(FORM.DYNAMIC_NOTIFICATIONS_SETTINGS, 'selectedLanguages')}>
					<Field
						className={'p-0 m-0 ml-6 mt-4 notifications-languages-picker'}
						component={CheckboxGroupField}
						name={'selectedLanguages'}
						options={languageOptions}
						optionRender={optionRenderWithIcon}
					/>
				</div>
			) : null}
		</Form>
	)
}

const form = reduxForm<IDynamicNotificationsSettingsForm, ComponentProps>({
	form: FORM.DYNAMIC_NOTIFICATIONS_SETTINGS,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validationDynamicNotificationsSettingsFn
})(DynamicNotificationsSettingsForm)

export default form
